import React, { useState, useContext, createContext, useMemo } from "react";

import H2ToolsModal from "components/H2ToolsModal";

const H2ToolsContext = createContext([]);

const H2ToolsProvider = ({ children }) => {
  const [H2ToolsVisibility, setH2ToolsVisibility] = useState(false);
  const [mounted, setMounted] = useState(false);

  const openH2ToolsModal = () => {
    setH2ToolsVisibility(true);
  };
  const closeH2ToolsModal = () => {
    setH2ToolsVisibility(false);
  };

  const mount = () => {
    setMounted(true);
  };
  const unmount = () => {
    setMounted(false);
  };

  const values = useMemo(
    () => ({
      mount,
      unmount,
      H2ToolsVisibility,
      openH2Tools: openH2ToolsModal,
      closeH2Tools: closeH2ToolsModal,
    }),
    [H2ToolsVisibility]
  );

  return (
    <H2ToolsContext.Provider value={values}>
      {!mounted && <H2ToolsModal visibility={H2ToolsVisibility} close={closeH2ToolsModal} />}
      {children}
    </H2ToolsContext.Provider>
  );
};

const useH2Tools = () => useContext(H2ToolsContext);

export { useH2Tools, H2ToolsProvider };
