import { ReactComponent as H2RefuelIcon } from "assets/h2-refuel-icon.svg";

export const OFFSETS = {
  LEFT: 40,
  RIGHT: 0,
  TOP: 10,
  BOTTOM: 35,
};
export const EVENT_FLIP_RIGHT = 120;

export const ANNOTATION_TYPES = {
  REFUEL_START: {
    color: "#1890FF",
    Icon: H2RefuelIcon,
    label: "Refuel Start",
  },
  REFUEL_END: {
    color: "#1890FF",
    Icon: H2RefuelIcon,
    label: "Refuel End",
  },
};
