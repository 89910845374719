import { useUser } from "context/UserContext";
import { GET_INCIDENT_REPORT } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useIncidentReport({ id }) {
  const { currentOrganization } = useUser();
  const { data, loading, error } = useQuery(GET_INCIDENT_REPORT, {
    variables: {
      organizationSlug: currentOrganization,
      id,
    },
  });

  const incidentReport = data?.getIncidentReport;

  return { incidentReport, loading, error };
}
