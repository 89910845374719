import { useUser } from "context/UserContext";
import { GET_SHARED_EQUIPMENT_ORGANIZATIONS } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useSharedEquipmentRecipients({ equipmentId }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_SHARED_EQUIPMENT_ORGANIZATIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      organizationSlug: currentOrganization,
      equipmentId,
    },
  });

  const sharedEquipmentRecipients =
    data?.getSharedEquipmentMeasures?.map((recipient) => recipient.receivingOrganizationSlug) || [];

  return { sharedEquipmentRecipients, loading, error };
}
