import React from "react";

import { Form, Input } from "antd";
import { t } from "@lingui/macro";

export default function ActivitiesPerformed() {
  return (
    <Form.Item
      name="activities"
      labelCol={{ span: 24 }}
      label={t({
        id: "incident-report-form.activities",
        message: "List of Activities being Performed",
      })}
    >
      <Input.TextArea
        rows={22}
        maxLength={1400}
        showCount
        placeholder={t({
          id: "incident-report-form.activities.placeholder",
          message: "Add List of Activities being Performed (If applicable)",
        })}
      />
    </Form.Item>
  );
}
