import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { REVOKE_ORGANIZATION_DATA_PARTNER_REQUEST } from "graphql/apiService";

export default function useRevokeDataPartnerRequest() {
  const { currentOrganization } = useUser();

  const [revokeDataPartnerRequestMutation, { loading, error }] = useMutation(REVOKE_ORGANIZATION_DATA_PARTNER_REQUEST, {
    variables: { organizationSlug: currentOrganization },
  });

  const revokeDataPartnerRequest = async ({ id }) => {
    const res = await revokeDataPartnerRequestMutation({ variables: { id } });
    return res.data.revokeOrganizationDataPartnerRequest;
  };

  return [revokeDataPartnerRequest, { loading, error }];
}
