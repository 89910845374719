import React from "react";
import { useNavigate, Link } from "react-router-dom";

import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { useUser } from "context/UserContext";
import { useDeleteIncidentReport } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Row, Col, Button, Divider, App } from "antd";
import { ArrowLeftOutlined, DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import Card from "components/Card";

import { severities as severitiesFn } from "../../helpers/enums";

import styles from "./IncidentReportView.module.less";

export default function IncidentReportView({ incidentReport }) {
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const { message } = useCustomMessage();
  const { hasPermissions } = useUser();

  const severities = severitiesFn();

  const [deleteIncidentReport] = useDeleteIncidentReport();

  const {
    title,
    summary,
    severity,
    localIndex,
    workOrderNb,
    createdAt,
    authorName,
    supervisorName,
    peopleInvolved,
    incidentDate,
    incidentLocation,
    witnesses,
    partsInvolved,
    discoveryFactor,
    incidentDescription,
    cause,
    activities,
    employeeSignature,
    employeeSignatureDate,
    supervisorSignature,
    supervisorSignatureDate,
  } = incidentReport;

  const showArchiveConfirmModal = () => {
    const reportTitle = incidentReport.title;
    modal.confirm({
      title: t({
        id: "incident-reports.confirm-archive.title",
        message: `Are you sure you want to archive ${reportTitle}?`,
      }),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t({
        id: "incident-reports.confirm-archive.button",
        message: "Archive",
      }),
      onOk: () => {
        handleIncidentReportArchive();
      },
    });
  };

  const handleIncidentReportArchive = async () => {
    try {
      await deleteIncidentReport({ id: incidentReport.id });
      navigate("/incident-reports");
    } catch (err) {
      message.error(
        t({
          id: "incident-reports.archive-error",
          message: `Failed to archive incident report`,
        })
      );
    }
  };

  const DisplayRow = ({ title: rowTitle, value }) => (
    <Row gutter={64} key={rowTitle}>
      <Col span={6} className={styles.bold}>
        {rowTitle}
      </Col>
      <Col span={18}>{value || "---"}</Col>
    </Row>
  );

  const Signatures = () => (
    <div className={styles.signaturesContainer}>
      <div className={styles.signatureColumn}>
        <div className={styles.signatureTextContainer}>
          <Trans id="incident-report.author" />: {authorName}
          <span>
            <Trans id="incident-report.display-date">
              Date: {i18n.date(new Date(employeeSignatureDate), { dateStyle: "medium" })}
            </Trans>
          </span>
        </div>
        <div className={styles.signatureImageContainer}>
          <img className={styles.signatureImage} src={employeeSignature} alt="employee signature" />
        </div>
      </div>

      <div className={styles.signatureColumn}>
        <div className={styles.signatureTextContainer}>
          <Trans id="incident-report.supervisor" />: {supervisorName}
          <span>
            <Trans id="incident-report.display-date">
              Date: {i18n.date(new Date(supervisorSignatureDate), { dateStyle: "medium" })}
            </Trans>
          </span>
        </div>
        <div className={styles.signatureImageContainer}>
          <img className={styles.signatureImage} src={supervisorSignature} alt="supervisor signature" />
        </div>
      </div>
    </div>
  );

  return (
    <Card>
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.backButton}
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate("/incident-reports")}
        >
          <Trans id="common.back">Back</Trans>
        </Button>
        {hasPermissions(["delete:incident_reports"]) && (
          <Button className={styles.archiveButton} icon={<DeleteFilled />} danger onClick={showArchiveConfirmModal}>
            <Trans id="common.archive">Archive</Trans>
          </Button>
        )}
      </div>
      <div className={styles.titleContainer}>
        <h2>{title}</h2>
      </div>
      <Divider className={styles.titleDivider} />
      <div className={styles.container}>
        <DisplayRow
          title={t({
            id: "incident-report.summary",
            message: "Incident Summary",
          })}
          value={summary}
        />
        <DisplayRow
          title={t({
            id: "incident-report.severity",
            message: "Severity",
          })}
          value={severities.find((severityObject) => severityObject.value === severity).name}
        />
        <DisplayRow
          title={t({
            id: "incident-report.index",
            message: "Report Index",
          })}
          value={`#${localIndex}`}
        />
        <DisplayRow
          title={t({
            id: "incident-report.wo-number",
            message: "Work Order Number",
          })}
          value={workOrderNb ? <Link to={`/work-requests/${workOrderNb}`}>#{workOrderNb}</Link> : null}
        />
        <DisplayRow
          title={t({
            id: "incident-report.date",
            message: "Date of Report",
          })}
          value={i18n.date(new Date(createdAt), { dateStyle: "medium" })}
        />
        <DisplayRow
          title={t({
            id: "incident-report.author",
            message: "Report Author",
          })}
          value={authorName}
        />
        <DisplayRow
          title={t({
            id: "incident-report.supervisor",
            message: "Supervisor",
          })}
          value={supervisorName}
        />
        <DisplayRow
          title={t({
            id: "incident-report.people-involved",
            message: "People Involved",
          })}
          value={peopleInvolved.map((p) => (p.label ? p.label : p.value)).join(", ")}
        />
        <DisplayRow
          title={t({
            id: "incident-report.date-&-time",
            message: "Date & Time Of Incident",
          })}
          value={i18n.date(new Date(incidentDate), { dateStyle: "medium", timeStyle: "short" })}
        />
        <DisplayRow
          title={t({
            id: "incident-report.location",
            message: "Incident Location",
          })}
          value={incidentLocation?.formattedAddress}
        />
        <DisplayRow
          title={t({
            id: "incident-report.witnesses",
            message: "Witnesses",
          })}
          value={witnesses.map((w) => (w.label ? w.label : w.value)).join(", ")}
        />
        <DisplayRow
          title={t({
            id: "incident-report.assets-involved",
            message: "Assets/Parts Involved",
          })}
          value={partsInvolved.map((p) => (p.label ? p.label : p.value)).join(", ")}
        />
        <DisplayRow
          title={t({
            id: "incident-report.how-discovered",
            message: "How Incident Discovered",
          })}
          value={discoveryFactor}
        />
        <DisplayRow
          title={t({
            id: "incident-report.description",
            message: "Incident Description",
          })}
          value={incidentDescription}
        />
        <DisplayRow
          title={t({
            id: "incident-report.cause",
            message: "Probable Root Cause",
          })}
          value={cause}
        />
        <DisplayRow
          title={t({
            id: "incident-report.activities",
            message: "List of Activities Being Performed",
          })}
          value={activities}
        />
        <Divider />
        <DisplayRow
          title={t({
            id: "incident-report.signatures-&-dates",
            message: "Signatures & Dates",
          })}
          value={<Signatures />}
        />
      </div>
    </Card>
  );
}
