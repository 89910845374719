import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import mixpanel from "mixpanel-browser";

import captureException from "utils/capture-sentry-exception";
import {
  extractDashboardId as extractGrafanaDashboardId,
  generateDashboardURL as generateGrafanaDashboardURL,
} from "utils/grafana-utils";

import {
  extractDashboardId as extractSupersetDashboardId,
  generateDashboardURL as generateSupersetDashboardURL,
  getSupersetUrl,
} from "utils/superset-utils";

import { useUser } from "context/UserContext";
import { useCreateDashboard, useUpdateDashboard, useDashboards } from "hooks/apiService";

import { ArrowLeftOutlined } from "@ant-design/icons";

import { Form, Input, Button, Card, Space, message, Select } from "antd";
import GrafanaButton from "components/GrafanaButton";
import Tag from "../Tag";

import { types as typesFn } from "../../helpers/mapping";

import styles from "./DashboardInput.module.less";

const grafanaDomain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";

const layout = {
  labelCol: { lg: { span: 4 }, sm: { span: 6 } },
  wrapperCol: { lg: { span: 10 }, md: { span: 12 }, sm: { span: 14 } },
};
const tailLayout = {
  wrapperCol: { lg: { offset: 4 }, sm: { offset: 6 } },
};

function DashboardInput({ mode, dashboard }) {
  const [form] = Form.useForm();
  const { grafanaOrgId, features } = useUser();
  const { id } = useParams();
  const [createDashboard] = useCreateDashboard();
  const [updateDashboard] = useUpdateDashboard();
  const { dashboards } = useDashboards();

  const [loading, setLoading] = useState(false);

  const types = typesFn();
  const allTags = [...new Set(dashboards.map((item) => item.tags).flat())];

  const typeOptions = Object.entries(types)
    .filter(([_, { requiredFeature }]) => {
      if (requiredFeature && !features.includes(requiredFeature)) {
        return false;
      }
      return true;
    })
    .map(([type, { label }]) => ({
      value: type,
      label: `${label} Dashboard`,
    }));

  useEffect(() => {
    if (mode === "add") {
      mixpanel.track("Dashboard Add Page Viewed");
    } else if (mode === "edit") {
      mixpanel.track("Dashboard Edit Page Viewed", { "Dashboard ID": id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const initializeDashboardForm = () => {
    const formFields = {
      name: dashboard.name,
      type: dashboard.type,
      tags: dashboard.tags,
    };
    if (dashboard.type === "GRAFANA") {
      const grafanaUrl = generateGrafanaDashboardURL({ id: dashboard.grafanaId, orgId: grafanaOrgId });
      formFields.grafanaUrl = grafanaUrl;
    }
    if (dashboard.type === "SUPERSET") {
      formFields.supersetUrl = generateSupersetDashboardURL({ id: dashboard.externalId });
    }

    form.setFieldsValue(formFields);
  };

  useEffect(() => {
    if (dashboard) {
      initializeDashboardForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard]);

  const navigate = useNavigate();

  const onAddDashboard = async (dashboardData) => {
    try {
      setLoading(true);
      const newDashboard = await createDashboard({ input: dashboardData });
      message.success(
        t({
          id: "dashboards.success.add",
          message: `Successfully added dashboard ${dashboardData.name}`,
        })
      );
      mixpanel.track("Dashboard Added", {
        "Dashboard ID": newDashboard.id,
        "Dashboard Name": dashboardData.name,
      });
      navigate(`/dashboards/${newDashboard.id}`);
    } catch (err) {
      captureException(err);
      message.error(t({ id: "dashboards.errors.add", message: `Failed to add dashboard ${dashboardData.name}` }));
    } finally {
      setLoading(false);
    }
  };

  const onUpdateDashboard = async (dashboardData) => {
    try {
      setLoading(true);
      await updateDashboard({ input: { id, ...dashboardData } });
      message.success(
        t({
          id: "dashboards.success.update-dashboard",
          message: `Successfully updated dashboard ${dashboardData.name}`,
        })
      );
      mixpanel.track("Dashboard Updated", {
        "Dashboard ID": id,
        "Dashboard Name": dashboardData.name,
      });
      navigate(`/dashboards/${id}`);
    } catch (err) {
      message.error(
        t({ id: "dashboards.errors.update-dashboard", message: `Failed to update dashboard ${dashboardData.name}` })
      );
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (formData) => {
    const dashboardData = { name: formData.name, tags: formData.tags };
    if (formData.type === "GRAFANA") {
      dashboardData.grafanaId = extractGrafanaDashboardId(formData.grafanaUrl);
    }
    if (formData.type === "SUPERSET") {
      dashboardData.externalId = extractSupersetDashboardId(formData.supersetUrl);
    }
    if (mode === "add") {
      dashboardData.type = formData.type;
      onAddDashboard(dashboardData);
    } else if (mode === "edit") {
      onUpdateDashboard(dashboardData);
    }
  };

  const grafanaIdValidator = async (_rule, dashboardUrl) => {
    try {
      const grafanaId = extractGrafanaDashboardId(dashboardUrl);
      if (!grafanaId) return Promise.reject(new Error());
      if (!dashboardUrl.startsWith(`https://grafana.${grafanaDomain}`)) {
        return Promise.reject(new Error(`Dashboard url must begin with https://grafana.${grafanaDomain}`));
      }
      return Promise.resolve();
    } catch (err) {
      captureException(err);
      message.error(t({ id: "dashboards.errors.grafana-id-validation", message: "Error validating dashboard ID" }));
      setLoading(false);
      return Promise.reject(new Error());
    }
  };
  const supersetIDValidator = async (_rule, dashboardUrl) => {
    try {
      const supersetID = extractSupersetDashboardId(dashboardUrl);
      if (!supersetID) return Promise.reject(new Error());
      if (!dashboardUrl.startsWith(getSupersetUrl())) {
        return Promise.reject(new Error(`Dashboard url must begin with ${getSupersetUrl()}`));
      }
      return Promise.resolve();
    } catch (err) {
      captureException(err);
      message.error(t({ id: "dashboards.errors.superset-id-validation", message: "Error validating dashboard ID" }));
      setLoading(false);
      return Promise.reject(new Error());
    }
  };

  const onBack = () => {
    navigate("/dashboards");
  };

  const GrafanaFormItem = ({ value, onChange }) => (
    <div className={styles.buttonsContainer}>
      <Input
        value={value}
        onChange={onChange}
        placeholder={t({
          id: "dashboards.form.grafana-url.hint",
          message: "Enter the Grafana dashboard URL",
        })}
      />
      <GrafanaButton text={t({ id: "dashboards.form.grafana-url.button", message: "Open Grafana" })} />
    </div>
  );

  const SupersetFormItem = ({ value, onChange }) => (
    <div className={styles.buttonsContainer}>
      <Input
        value={value}
        onChange={onChange}
        placeholder={t({
          id: "dashboards.form.superset-url.hint",
          message: "Enter the Superset dashboard URL",
        })}
      />
    </div>
  );

  return (
    <Card
      title={
        <div className={styles.formHeader}>
          <Button className={styles.backButton} icon={<ArrowLeftOutlined />} onClick={onBack}>
            <Trans id="dashboards.back">Back</Trans>
          </Button>
          <div className={styles.title}>
            {mode === "add" ? (
              <Trans id="dashboards.form.add.title">Add Dashboard</Trans>
            ) : (
              <Trans id="dashboards.form.edit.title">Edit Dashboard</Trans>
            )}
          </div>
        </div>
      }
    >
      <Form {...layout} form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label={t({ id: "dashboards.form.name", message: "Name" })}
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t({ id: "dashboards.form.name.hint", message: "Name your dashboard" })} />
        </Form.Item>
        <Form.Item name="type" label={t({ id: "dashboards.form.type", message: "Type" })} rules={[{ required: true }]}>
          <Select
            options={typeOptions}
            placeholder={t({ id: "dashboards.form.type.hint", message: "Select Type" })}
            disabled={mode === "edit"}
          />
        </Form.Item>
        <Form.Item name="tags" label={t({ id: "dashboards.form.tags", message: "Tags" })}>
          <Select
            mode="tags"
            options={allTags.map((tag) => ({
              value: tag,
              label: tag,
            }))}
            tagRender={({ label, closable, onClose }) => (
              <Tag key={label} closable={closable} onClose={onClose} label={label} />
            )}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) =>
            getFieldValue("type") === "GRAFANA" || dashboard?.type === "GRAFANA" ? (
              <Form.Item
                name="grafanaUrl"
                label={t({ id: "dashboards.form.grafana-url.label", message: "Grafana URL" })}
                tooltip={t({
                  id: "dashboards.form.grafana-url.tooltip",
                  message: "Paste the Grafana dashboard URL",
                })}
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: grafanaIdValidator,
                    validateTrigger: "onSubmit",
                    message: t({ id: "dashboards.form.error.invalid-url", message: "Invalid dashboard URL" }),
                  },
                ]}
              >
                <GrafanaFormItem />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) =>
            getFieldValue("type") === "SUPERSET" || dashboard?.type === "SUPERSET" ? (
              <Form.Item
                name="supersetUrl"
                label={t({ id: "dashboards.form.superset-url.label", message: "Superset URL" })}
                tooltip={t({
                  id: "dashboards.form.superset-url.tooltip",
                  message: 'Paste the Superset dashboard URL from "Share", "Copy permalink to clipboard".',
                })}
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: supersetIDValidator,
                    validateTrigger: "onSubmit",
                    message: t({ id: "dashboards.form.error.invalid-url", message: "Invalid dashboard URL" }),
                  },
                ]}
              >
                <SupersetFormItem />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button loading={false} disabled={false} onClick={onBack}>
              <Trans id="dashboards.form.cancel">Cancel</Trans>
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              {mode === "add" ? (
                <Trans id="dashboards.form.add">Add Dashboard</Trans>
              ) : (
                <Trans id="dashboards.form.update">Update</Trans>
              )}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default DashboardInput;
