/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Form, Input, Checkbox, Collapse, Select, Typography, ColorPicker, Tooltip } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";

import styles from "./AxisForm.module.less";

function AxisForm({ fieldName, fieldKey, onRemove, form }) {
  const { Title } = Typography;

  const axisName = Form.useWatch(["axes", fieldName, "name"]);

  return (
    <Collapse
      bordered={false}
      collapsible="icon"
      className={styles.collapse}
      defaultActiveKey={fieldKey}
      items={[
        {
          key: fieldKey,
          label: (
            <div
              style={{ margin: 0, height: 20 }}
              className={styles.collapseTitleContainer}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Form.Item name={[fieldName, "name"]}>
                <Title
                  level={5}
                  editable={{
                    tooltip: true,
                    onChange: (newName) => {
                      if (newName?.length) {
                        form.setFieldValue(["axes", fieldName, "name"], newName);
                      }
                    },
                  }}
                >
                  {axisName}
                </Title>
              </Form.Item>
            </div>
          ),
          extra: (
            <DeleteOutlined
              style={{ fontSize: "18px" }}
              onClick={(event) => {
                event.stopPropagation();
                onRemove();
              }}
            />
          ),
          children: (
            <div>
              <Form.Item
                label="Unit"
                name={[fieldName, "unit"]}
                rules={[
                  {
                    message: "Unit",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Position" name={[fieldName, "position"]}>
                <Select
                  options={[
                    { value: "left", label: "Left" },
                    { value: "right", label: "Right" },
                  ]}
                />
              </Form.Item>
              <Form.Item name={[fieldName, "scale"]} label="Scale">
                <Checkbox.Group>
                  <div className={styles.checkboxGroupContent}>
                    <div>
                      <Checkbox value="logarithmic">Logarithmic</Checkbox>
                      <Tooltip title="Only positive values supported">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                    <div>
                      <Checkbox value="reversed">Reversed</Checkbox>
                    </div>
                  </div>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item label="Color" name={[fieldName, "color"]}>
                <ColorPicker style={{ borderRadius: 2 }} size="small" />
              </Form.Item>
            </div>
          ),
        },
      ]}
    />
  );
}

export default AxisForm;
