import * as Sentry from "@sentry/react";

const captureException = (error) => {
  console.error(error); // eslint-disable-line no-console
  Sentry.captureException(error);
};

const captureExceptionWithScope = (error, errorInfo) => {
  console.error(error); // eslint-disable-line no-console
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
    Sentry.captureException(error);
  });
};

export { captureExceptionWithScope };

export default captureException;
