import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import styles from "./ErrorModal.module.less";

function ErrorModal({ visible, setVisible, org, type, _auth0Error, auth0ErrorDescription }) {
  let message = `Error signing in: ${auth0ErrorDescription}`;

  if (auth0ErrorDescription && auth0ErrorDescription.includes("is not part of the org")) {
    message = (
      <Trans id="authentication.errors.not-member">
        You are not a member of the <span className={styles.orgText}>{org}</span> organization. Please reach out to your
        account manager to request an invitation to join.
      </Trans>
    );
  }

  if (type === "ORG_NOT_PRESENT") {
    message = (
      <Trans id="authentication.errors.org-not-present">
        Cannot find the organization you are attempting to login to. Please make sure to use the correct URL to login
        into your organization.
      </Trans>
    );
  }

  if (type === "ORG_NOT_FOUND") {
    message = (
      <Trans id="authentication.errors.org-not-found">
        The <span className={styles.orgText}>{org}</span> organization does not exist. Please make sure the URL
        subdomain matches the name of your organization.
      </Trans>
    );
  }

  if (type === "GET_ORG_FAIL") {
    message = (
      <Trans id="authentication.errors.get-org-fail">
        Failed to fetch info for the <span className={styles.orgText}>{org}</span> organization. Please try again later.
      </Trans>
    );
  }

  return (
    <Modal
      title={
        <div className={styles.titleContainer}>
          <ExclamationCircleOutlined className={styles.icon} />{" "}
          <Trans id="authentication.error-modal.title">
            <span className={styles.title}>Login Error</span>
          </Trans>
        </div>
      }
      open={visible}
      cancelButtonProps={{ style: { display: "none" } }}
      centered
      onCancel={() => setVisible(false)}
      onOk={() => {
        setVisible(false);
      }}
    >
      <div className={styles.description}>{message}</div>
    </Modal>
  );
}

export default ErrorModal;
