import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_DASHBOARD } from "graphql/apiService";

export default function useUpdateDashboard() {
  const { currentOrganization } = useUser();

  const [updateDashboardMutation, { loading, error }] = useMutation(UPDATE_DASHBOARD, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateDashboard = async ({ input }) => {
    const res = await updateDashboardMutation({ variables: { input } });
    return res.data.updateDashboard;
  };

  return [updateDashboard, { loading, error }];
}
