import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_PANEL } from "graphql/apiService";

export default function useUpdatePanel() {
  const { currentOrganization } = useUser();

  const [updatePanelMutation, { loading, error }] = useMutation(UPDATE_PANEL, {
    variables: { organizationSlug: currentOrganization },
  });

  const updatePanel = async ({ input }) => {
    const res = await updatePanelMutation({ variables: { input } });
    return res?.data?.updatePanel;
  };

  return [updatePanel, { loading, error }];
}
