import React from "react";

import { Avatar as AvatarAntD } from "antd";
import { avatarString, colorShade, stringToColor } from "./helpers/avatar.helpers";

import styles from "./Avatar.module.less";

export default function Avatar({ className, name, showName = true, size }) {
  const color = colorShade(stringToColor(name || "default"), 40);
  return (
    <div className={`${styles.container} ${className && className}`}>
      <AvatarAntD className={showName && styles.avatar} size={size} style={{ background: color }}>
        {avatarString(name)}
      </AvatarAntD>
      {showName && <span>{name}</span>}
    </div>
  );
}
