import { useUser } from "context/UserContext";
import { GET_TRIGGERED_ALARMS } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useTriggeredAlarms({ from, alarmId }) {
  const { currentOrganization } = useUser();

  const { data, loading, error, refetch } = useQuery(GET_TRIGGERED_ALARMS, {
    variables: {
      organizationSlug: currentOrganization,
      from,
      alarmId,
    },
  });

  const triggeredAlarms = data?.getTriggeredAlarms || [];

  return { triggeredAlarms, loading, error, refetch };
}
