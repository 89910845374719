import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_INCIDENT_REPORT } from "graphql/apiService";

export default function useUpdateIncidentReport() {
  const { currentOrganization } = useUser();

  const [updateIncidentReportMutation, { loading, error }] = useMutation(UPDATE_INCIDENT_REPORT, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateIncidentReport = async ({ input }) => {
    const res = await updateIncidentReportMutation({ variables: { input } });
    return res?.data?.updateIncidentReport;
  };

  return [updateIncidentReport, { loading, error }];
}
