import React from "react";
import { Link } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import dayjs from "dayjs";

import { useUser } from "context/UserContext";
import { useAlarms, useDeleteAlarm, useUpdateAlarm } from "hooks/apiService";

import { BellOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { Avatar as AvatarAntD, Button, Popconfirm, Table, Tag, Tooltip } from "antd";
import Avatar from "components/Avatar";
import Card from "components/Card";

import { priorities as prioritiesFn, status as statusFn } from "./helpers/AlarmRules.helpers";

import styles from "./AlarmRules.module.less";

const getMeasureName = (a) =>
  a?.timeseriesMeasure?.description ? a?.timeseriesMeasure?.description : a?.timeseriesMeasure?.slug || "";

export default function AlarmRules() {
  const { alarms, loading } = useAlarms();
  const [deleteAlarm] = useDeleteAlarm();
  const [updateAlarm] = useUpdateAlarm();
  const { hasPermissions } = useUser();

  const priorities = prioritiesFn();
  const status = statusFn();

  const handleDelete = async (record) => {
    await deleteAlarm({ id: record.id });
  };

  const handleMuteClick = async (record) => {
    await updateAlarm({ id: record.id, muted: !record.muted });
  };

  const customAlarms = alarms.filter((a) => !a.plcAlarmInfo);
  const plcAlarms = alarms
    .filter((a) => a.plcAlarmInfo && a.plcAlarmInfo.timeseriesMeasure)
    .map((a) => ({ id: a.id, ...a.plcAlarmInfo }))
    .sort((a, b) => getMeasureName(a).localeCompare(getMeasureName(b)));

  const customAlarmCols = [
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.name", message: "Alarm Name" }),
      dataIndex: "name",
      key: "name",
      width: "auto",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) =>
        name || (
          <span className={styles.noDescription}>
            <Trans id="alarm-rules.rules-overview.table.no-alarm-name">not set</Trans>
          </span>
        ),
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.priority", message: "Priority" }),
      dataIndex: "priority",
      key: "priority",
      render: (priority) => <Tag color={priorities[priority].color}>{priorities[priority].name}</Tag>,
      width: 100,
      filters: Object.entries(priorities).map(([key, value]) => ({ text: value.name, value: key })),
      onFilter: (value, record) => record.priority === value,
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.status", message: "Status" }),
      dataIndex: "status",
      key: "status",
      render: (itemStatus) => <Tag color={status[itemStatus].color}>{status[itemStatus].name}</Tag>,
      width: 100,
      filters: Object.entries(status).map(([key, value]) => ({ text: value.name, value: key })),
      onFilter: (value, record) => record.itemStatus === value,
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.last-time-triggered", message: "Last Time Triggered" }),
      dataIndex: "lastTimeTriggered",
      key: "lastTimeTriggered",
      render: (timestamp) =>
        timestamp ? (
          dayjs(timestamp).format("MM-DD-YYYY HH:mm:ss")
        ) : (
          <span className={styles.noDescription}>
            <Trans id="alarm-rules.rules-overview.table.not-triggered">not triggered</Trans>
          </span>
        ),
      width: 200,
      sorter: (a, b) => dayjs(a.lastTimeTriggered).unix() - dayjs(b.lastTimeTriggered).unix(),
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.description", message: "Description" }),
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      render: (description) =>
        description || (
          <span className={styles.noDescription}>
            <Trans id="alarm-rules.rules-overview.table.no-description">No description provided</Trans>
          </span>
        ),
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.notify-users", message: "Notify Users" }),
      dataIndex: "notifyUsers",
      key: "notifiyUsers",
      render: (notifyUsers) => (
        <div className={styles.notifiedUsers}>
          {notifyUsers?.length > 0 ? (
            <AvatarAntD.Group maxCount={5} maxPopoverPlacement="bottom" size="small">
              {notifyUsers.map((user) => (
                <Tooltip key={user.auth0Id} title={user.name}>
                  <div>
                    {user.pictureUrl ? (
                      <AvatarAntD className={styles.userAvatar} src={user.pictureUrl} size="small" />
                    ) : (
                      <Avatar className={styles.userAvatar} name={user.name} showName={false} size={24} />
                    )}
                  </div>
                </Tooltip>
              ))}
            </AvatarAntD.Group>
          ) : (
            <span className={styles.noDescription}>
              <Trans id="alarm-rules.rules-overview.table.no-notified-users">not set</Trans>
            </span>
          )}
        </div>
      ),
      width: 140,
    },
    hasPermissions(["delete:alarms", "update:alarms"], { mode: "some" }) && {
      title: t({ id: "alarm-rules.rules-overview.table.column.action", message: "Action" }),
      key: "action",
      width: 210,
      render: (_, record) => (
        <div className={styles.actionContainer}>
          <Link to={`/alarm-rules/${record.id}`}>
            <Button className={styles.actionButton} type="text" size="small">
              <Trans id="alarm-rules.rules-overview.table.actions.view">View</Trans>
            </Button>
          </Link>
          {hasPermissions(["update:alarms"]) && (
            <Link to={`/alarm-rules/${record.id}/edit`}>
              <Button className={styles.actionButton} type="text" size="small">
                <Trans id="alarm-rules.rules-overview.table.actions.edit">Edit</Trans>
              </Button>
            </Link>
          )}

          {hasPermissions(["delete:alarms"]) && (
            <Popconfirm
              title={t({ id: "alarm-rules.rules-overview.delete-pop-confirm.title", message: "Delete Alarm Rule" })}
              description={t({
                id: "alarm-rules.rules-overview.delete-pop-confirm.description",
                message: "Are you sure to delete this Alarm Rule?",
              })}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleDelete(record)}
            >
              <Button className={styles.actionButton} type="text" size="small">
                <Trans id="alarm-rules.rules-overview.table.actions.delete">Delete</Trans>
              </Button>
            </Popconfirm>
          )}
          {hasPermissions(["update:alarms"]) && (
            <Tooltip
              title={t({
                id: "alarm-rules.rules-overview.table.actions.mute",
                message: `Press to ${record.muted ? "unmute" : "mute"}`,
              })}
              placement="left"
            >
              <BellOutlined
                className={`${styles.snoozeIcon} ${!record.muted && styles.active}`}
                onClick={() => handleMuteClick(record)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ].filter(Boolean);

  const plcAlarmCols = [
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.measure", message: "Measure" }),
      dataIndex: "timeseriesMeasure",
      key: "timeseriesMeasure",
      sorter: (a, b) => getMeasureName(a).localeCompare(getMeasureName(b)),
      width: "auto",
      render: (measure) => (measure.description ? `${measure.description} (${measure.slug})` : measure.slug),
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.type", message: "Type" }),
      dataIndex: "type",
      key: "type",
      width: "auto",
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.threshold", message: "Threshold" }),
      dataIndex: "threshold",
      key: "threshold",
      width: "auto",
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.status", message: "Status" }),
      dataIndex: "status",
      key: "status",
      render: (itemStatus) => <Tag color={status[itemStatus].color}>{status[itemStatus].name}</Tag>,
      width: 100,
      filters: Object.entries(status).map(([key, value]) => ({ text: value.name, value: key })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: t({ id: "alarm-rules.rules-overview.table.column.action", message: "Action" }),
      key: "action",
      width: 102,
      render: (_, record) => (
        <div className={styles.actionContainer}>
          <Link to={`/alarm-rules/${record.id}`}>
            <Button className={styles.actionButton} type="text" size="small">
              <Trans id="alarm-rules.rules-overview.table.actions.view">View</Trans>
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card>
      <div className={styles.titleActionContainer}>
        <h2>
          <Trans id="alarm-rules.rules-overview.title">Alarm Rules</Trans>
        </h2>
        {hasPermissions(["create:alarms"]) && (
          <Link to="/alarm-rules/new">
            <Button type="primary" icon={<PlusOutlined className={styles.backIcon} />}>
              <Trans id="alarm-rules.rules-overview.new-alarm-rule-button">New Alarm Rule</Trans>
            </Button>
          </Link>
        )}
      </div>
      <div className={styles.alarmsTableContainer}>
        <Table
          className={styles.alarmsTable}
          columns={customAlarmCols}
          dataSource={customAlarms}
          rowKey="id"
          loading={loading}
        />
      </div>
      {plcAlarms?.length ? (
        <div className={styles.alarmsTableContainer}>
          <h2>
            <Trans id="alarm-rules.plc-alarms.title">PLC Alarms</Trans>
          </h2>
          <Table
            className={styles.alarmsTable}
            columns={plcAlarmCols}
            dataSource={plcAlarms}
            rowKey="id"
            loading={loading}
          />
        </div>
      ) : null}
    </Card>
  );
}
