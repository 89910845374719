import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { t } from "@lingui/macro";
import { notification as antdNotification } from "antd";
import { useKnockFeed } from "@knocklabs/react-notification-feed";

const NotificationToaster = () => {
  const { feedClient } = useKnockFeed();
  const navigate = useNavigate();

  const getNotificationTitle = (notification) => {
    switch (notification?.source?.key) {
      case "annotation-mentions":
        return t({ id: "notification-toast.title.annotation-mention", message: "Annotation Mention" });
      default:
        return t({ id: "notification-toast.title.default", message: "Notification" });
    }
  };

  const onNotificationsReceived = ({ items }) => {
    items.forEach((notification) => {
      antdNotification.info({
        message: getNotificationTitle(notification),
        description: parse(notification.blocks[0].rendered),
        key: notification.id,
        onClick: () => {
          if (notification.data?.relativeUrl) {
            navigate(notification.data.relativeUrl);
            antdNotification.destroy(notification.id);
          }
        },
      });
    });
    feedClient.markAsSeen(items);
  };

  useEffect(() => {
    feedClient.on("items.received.realtime", onNotificationsReceived);

    return () => feedClient.off("items.received.realtime", onNotificationsReceived);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedClient]);
};

export default NotificationToaster;
