const domain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";

export function generateDashboardURL({ id, orgId, iframe = true }) {
  return `https://grafana.${domain}/d/${id}?orgId=${orgId}${iframe ? "&theme=light&x_hide_navigation=1" : ""}`;
}

export function extractDashboardId(url) {
  const regex = /\/d\/[a-zA-Z0-9-_]+/;
  const idMatch = url.match(regex);
  if (!idMatch) return null;
  return idMatch[0].substring(3);
}
