import { useUser } from "context/UserContext";
import { GET_PANEL } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function usePanel({ id }) {
  const { currentOrganization } = useUser();
  const { data, loading, error } = useQuery(GET_PANEL, {
    variables: {
      organizationSlug: currentOrganization,
      id,
    },
  });

  const panel = data?.getPanel;

  return { panel, loading, error };
}
