import React from "react";

import { Form, Input } from "antd";
import { t } from "@lingui/macro";

export default function IncidentDescription() {
  return (
    <Form.Item
      name="incidentDescription"
      labelCol={{ span: 24 }}
      label={t({
        id: "incident-report-form.description",
        message: "Incident Description",
      })}
      required
      rules={[
        {
          required: true,
          message: t({
            id: "incident-report-form.rules.description",
            message: "Please enter the description",
          }),
        },
      ]}
    >
      <Input.TextArea
        rows={22}
        maxLength={1400}
        showCount
        placeholder={t({
          id: "incident-report-form.description.placeholder",
          message: "Add Incident Description",
        })}
      />
    </Form.Item>
  );
}
