import React from "react";
import { MentionsInput as MentionsInputReact, Mention } from "react-mentions";
import { t } from "@lingui/macro";

import styles from "./MentionsInput.module.less";

function MentionsInput({ users, input, setInput }) {
  const usersMap = users.reduce(
    (result, user) => ({
      [user.auth0Id]: user.name,
      ...result,
    }),
    {}
  );

  return (
    <MentionsInputReact
      className="mentions"
      value={input}
      onChange={(ev, newValue) => setInput(newValue)}
      placeholder={t({
        id: "panel.annotation.form.first-message.placeholder",
        message: "Mention people using '@'",
      })}
      allowSuggestionsAboveCursor
      allowSpaceInQuery
    >
      <Mention
        appendSpaceOnAdd
        className={styles.mentionHighlight}
        markup="(user:__id__)"
        trigger="@"
        data={users.map(({ auth0Id, name }) => ({ id: auth0Id, display: name }))}
        displayTransform={(userId) => `@${usersMap[userId]}`}
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
          <div className={`user ${focused ? "focused" : ""}`}>{highlightedDisplay}</div>
        )}
      />
    </MentionsInputReact>
  );
}

export default MentionsInput;
