/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Responsive } from "react-grid-layout";
import { SizeMe } from "react-sizeme";
import { t, Trans } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";
import isEqual from "lodash/isEqual";
import dayjs from "dayjs";

import { useUser } from "context/UserContext";
import {
  useDeletePanel,
  useUpdateDashboard,
  useDeleteDashboard,
  useUpdatePanel,
  useUserOrganizationProfiles,
} from "hooks/apiService";

import { Button, Dropdown, Spin, Switch, App, Tooltip, Layout, Badge } from "antd";
import { MoreOutlined, PlusOutlined, CommentOutlined } from "@ant-design/icons";
import PanelCard from "components/PanelCard";
import TimeSelectorAdvanced from "components/TimeSelectorAdvanced";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import DashboardSelector from "../DashboardSelector";
import CustomDashboardComments from "./CustomDashboardComments";

import styles from "./CustomDashboard.module.less";

const { Sider, Content } = Layout;

function CustomDashboard({ dashboard, dashboards }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [deletePanel] = useDeletePanel({ dashboardId: id });
  const [updatePanel] = useUpdatePanel();
  const [updateDashboard] = useUpdateDashboard();
  const [deleteDashboard] = useDeleteDashboard();
  const { modal, message } = App.useApp();
  const { hasPermissions } = useUser();
  const { userOrganizationProfiles, error: usersError } = useUserOrganizationProfiles();
  const [searchParams, setSearchParams] = useSearchParams();
  const comments = searchParams.get("comments");

  const [layoutBreakpoint, setLayoutBreakpoint] = useState("lg");
  const [startTime, setStartTime] = useState(dayjs().add(-3, "hours"));
  const [endTime, setEndTime] = useState(dayjs());
  const [timeInterval, setTimeInterval] = useState("3h");
  const [timeMode, setTimeMode] = useState("REALTIME");
  const [timeOverride, setTimeOverride] = useState(false);
  const [pollInterval, setPollInterval] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [layouts, setLayouts] = useState({});
  const [commentCollasped, setCommentCollasped] = useState(comments !== "open");
  const [numUnread, setNumUnread] = useState();

  const panels = dashboard?.panels || [];

  const panelsRef = useRef([]);

  useEffect(() => {
    if (timeMode === "HISTORICAL") {
      setPollInterval(0);
    }
  }, [timeMode]);

  const initializeTime = () => {
    if (!dashboard?.time) return;
    const { type, from, to, interval } = dashboard.time;
    if (from) {
      setStartTime(dayjs(from));
    }
    if (to) {
      setEndTime(dayjs(to));
    }
    if (interval) {
      setTimeInterval(interval);
    }
    setTimeOverride(dashboard?.timeOverride);
    setTimeMode(type);
  };

  useEffect(() => {
    initializeTime();
    if (dashboard?.layouts) {
      setLayouts(dashboard.layouts);
    }
    if (dashboard?.pollInterval) {
      setPollInterval(dashboard.pollInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard]);

  useEffect(() => {
    if (comments) {
      setCommentCollasped(false);
    }
  }, [comments]);

  const users = useMemo(
    () =>
      (userOrganizationProfiles &&
        userOrganizationProfiles.map(({ userAccount }) => userAccount).sort((a, b) => a.name.localeCompare(b.name))) ||
      [],
    [userOrganizationProfiles]
  );

  const userIds = useMemo(
    () => (userOrganizationProfiles && userOrganizationProfiles.map((profile) => profile.id)) || [],
    [userOrganizationProfiles]
  );

  useEffect(() => {
    if (usersError) {
      message.error("Failed to get users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersError]);

  const onPanelRemove = async (panelId) => {
    try {
      await deletePanel({ id: panelId });
    } catch (err) {
      captureException(err);
      message.error(
        t({
          id: "dashboards.errors.remove-panel",
          message: "Failed to remove panel from dashboard",
        })
      );
    }
  };

  const onPanelZoom = ({ zoomStartTime, zoomEndTime }) => {
    if (timeOverride) {
      setTimeMode("HISTORICAL");
      setStartTime(zoomStartTime);
      setEndTime(zoomEndTime);
    }
  };

  const onDashboardSave = async () => {
    try {
      setUpdateLoading(true);
      await updateDashboard({
        input: {
          id,
          layouts,
          timeOverride,
          pollInterval,
          time: {
            from: startTime,
            to: endTime,
            interval: timeInterval,
            type: timeMode,
          },
        },
      });
      if (!timeOverride) {
        await Promise.all(panelsRef.current.map((p) => p?.savePanelTime()));
      }
      message.success(
        t({
          id: "dashboards.success.update",
          message: "Successfully saved dashboard",
        })
      );
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "dashboards.errors.update",
          message: "Failed to update dashboard",
        })
      );
    } finally {
      setUpdateLoading(false);
    }
  };

  const confirmDashboardDelete = () => {
    const { name } = dashboard;
    modal.confirm({
      title: t({ id: "dashboards.confirm.remove-dashboard", message: `Are you sure you want to remove ${name}?` }),
      centered: true,
      onOk: async () => {
        try {
          await deleteDashboard({ id });
          navigate("/dashboards");
          message.success(
            t({
              id: "dashboards.success.remove-dashboard",
              message: `Successfully removed ${name}`,
            })
          );
        } catch (err) {
          captureException(err);
          message.error(
            t({
              id: "dashboards.errors.remove-dashboard",
              message: `Failed to remove ${name}`,
            })
          );
        }
      },
    });
  };

  const onLayoutChange = (layout, newLayouts) => {
    if (!isEqual(layouts, newLayouts)) {
      setLayouts(newLayouts);
    }
  };

  const onToggleComments = () => {
    if (commentCollasped) {
      handleOpenComments();
    } else {
      handleCloseComments();
    }
  };

  const handleOpenComments = () => {
    setCommentCollasped(false);
    searchParams.set("comments", "open");
    setSearchParams(searchParams, { replace: true });
  };

  const handleCloseComments = () => {
    setCommentCollasped(true);
    searchParams.delete("comments");
    setSearchParams(searchParams, { replace: true });
  };

  const dropdownMenuItems = [
    { label: "Edit", key: "edit", onClick: () => navigate(`./edit`) },
    { label: "Delete", key: "delete", danger: true, onClick: confirmDashboardDelete },
  ];

  const dashboardTime =
    timeMode === "REALTIME"
      ? {
          type: "REALTIME",
          interval: timeInterval,
        }
      : {
          type: "HISTORICAL",
          from: startTime,
          to: endTime,
        };

  if (!dashboard) {
    return (
      <div className={styles.spinContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Layout>
      <Content>
        <div className={styles.outerContainer}>
          <SizeMe>
            {({ size }) => (
              <div className={styles.container}>
                <div className={styles.headerContainer}>
                  <div className={styles.header}>
                    <DashboardSelector dashboard={dashboard} dashboards={dashboards} />
                    {hasPermissions(["update:dashboards"]) && (
                      <div className={styles.headerRight}>
                        <Tooltip
                          placement="bottom"
                          overlayInnerStyle={{ borderRadius: 2, padding: "6px 8px" }}
                          title={
                            <div className={styles.tooltipContent}>
                              <Trans id="dashboards.all-comments">All Comments</Trans>
                            </div>
                          }
                        >
                          <Badge size="small" offset={[-5, 5]} count={numUnread}>
                            <Button shape="circle" onClick={onToggleComments}>
                              <CommentOutlined />
                            </Button>
                          </Badge>
                        </Tooltip>
                        <Button className={styles.addButton} onClick={() => navigate("add-panel")}>
                          <PlusOutlined className={styles.plusIcon} />
                          <Trans id="dashboards.add-panel">Panel</Trans>
                        </Button>
                        <Button type="primary" onClick={onDashboardSave} loading={updateLoading}>
                          <Trans id="common.save">Save</Trans>
                        </Button>
                        <Dropdown menu={{ items: dropdownMenuItems }} trigger={["click"]}>
                          <Button shape="circle" type="text" className={styles.moreIcon}>
                            <MoreOutlined className={styles.moreIcon} />
                          </Button>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                  <div className={styles.timeTools}>
                    <div className={styles.switchContainer}>
                      <Trans id="dashboards.sync-toggle">Sync Panel Time</Trans>
                      <Switch
                        checked={timeOverride}
                        className={styles.switch}
                        onChange={(checked) => setTimeOverride(checked)}
                      />
                    </div>
                    <TimeSelectorAdvanced
                      {...{
                        setStartTime,
                        setEndTime,
                        startTime,
                        endTime,
                        timeInterval,
                        setTimeInterval,
                        timeMode,
                        setTimeMode,
                        pollInterval,
                        setPollInterval,
                        setRefetch,
                        disabled: !timeOverride,
                      }}
                    />
                  </div>
                </div>
                {size.width && panels.length > 0 && (
                  <Responsive
                    className={styles.layout}
                    compactType="vertical"
                    cols={{ xl: 18, lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
                    rowHeight={30}
                    measureBeforeMount={false}
                    layouts={layouts}
                    onBreakpointChange={(breakpoint) => setLayoutBreakpoint(breakpoint)}
                    onLayoutChange={(layout, newLayouts) => onLayoutChange(layout, newLayouts)}
                    width={size.width}
                    draggableHandle=".draggableHandle"
                    draggableCancel=".draggableCancel"
                  >
                    {panels.map((panel, index) => (
                      <div
                        key={panel.id}
                        className={styles.card}
                        data-grid={
                          !dashboard.layouts?.[layoutBreakpoint]?.find((item) => item.i === panel.id)
                            ? { w: 6, h: 9, x: 0, y: 0, minW: 4, minH: 6 }
                            : null
                        }
                      >
                        <PanelCard
                          panel={panel}
                          ref={(p) => (panelsRef.current[index] = p)}
                          draggable
                          onPanelRemove={() => onPanelRemove(panel.id)}
                          onZoomChange={onPanelZoom}
                          onPanelUpdate={(data) => updatePanel(data)}
                          dashboardTime={timeOverride ? dashboardTime : undefined}
                          dashboardPollInterval={timeOverride ? pollInterval : undefined}
                          dashboardRefetch={timeOverride ? refetch : undefined}
                          dashboardId={id}
                        />
                      </div>
                    ))}
                  </Responsive>
                )}
              </div>
            )}
          </SizeMe>
        </div>
      </Content>
      <Sider
        collapsible
        collapsed={commentCollasped}
        collapsedWidth={0}
        className={styles.sidebar}
        trigger={null}
        theme="light"
        width={318}
        style={{ overflow: "scroll" }}
      >
        <CustomDashboardComments
          handleCloseComments={handleCloseComments}
          dashboardId={id}
          userOrgProfileIds={userIds}
          users={users}
          setNumUnread={setNumUnread}
        />
      </Sider>
    </Layout>
  );
}

export default CustomDashboard;
