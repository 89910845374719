import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_FAVORITE } from "graphql/apiService";

export default function useCreateFavorite() {
  const { currentOrganization } = useUser();

  const [createFavoriteMutation, { loading, error }] = useMutation(CREATE_FAVORITE, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetDashboardsByOrganization"],
  });

  const createFavorite = async ({ itemType, itemId }) => {
    const res = await createFavoriteMutation({ variables: { input: { itemType, itemId } } });
    return res.data.createFavorite;
  };

  return [createFavorite, { loading, error }];
}
