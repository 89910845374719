import React, { useEffect, useState } from "react";
import { t } from "@lingui/macro";

import { Autocomplete, useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";

import googleMapsStyle from "config/googleMapsStyle";
import useCustomMessage from "hooks/useCustomMessage";

import { Input, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getInputCoordinates } from "./helpers/locationModal.helper";

import styles from "./LocationModal.module.less";

const autoCompleteOptions = {
  fields: ["geometry.location", "formatted_address"],
};

const googleMapsLibraries = ["places"];

export default function LocationModal({ location, open, title, onOk = () => {}, onCancel = () => {} }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: googleMapsLibraries,
  });
  const { message } = useCustomMessage();

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [autoComplete, setAutoComplete] = useState(/** @type google.maps.places.Autocomplete */ (null));
  const [marker, setMarker] = useState(/** @type google.maps.Marker */ (null));

  const [locationText, setLocationText] = useState("");
  const [hasMap, setHasMap] = useState(false);

  useEffect(() => {
    if (open && map && marker) {
      handleGetGeometry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, map, marker]);

  useEffect(() => {
    if (!locationText || locationText !== location) {
      setLocationText(location);
    }
    setHasMap(!!location?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDragMarker = async ({ latLng }) => {
    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder();
    const geometry = await geocoder.geocode({ latLng });
    const address = geometry.results[0].formatted_address;
    marker.setPosition(latLng);
    setLocationText(address);
  };
  const handlePlaceChanged = async () => {
    const inputCoordinates = getInputCoordinates(locationText);
    if (inputCoordinates) {
      map.setCenter(inputCoordinates);
      marker.setPosition(inputCoordinates);
      return;
    }
    const place = autoComplete.getPlace();
    if (place) {
      await setHasMap(true);
      const address = place.formatted_address;
      const latLng = place.geometry.location.toJSON();
      map.setCenter(latLng);
      marker.setPosition(latLng);
      setLocationText(address);
    }
  };

  const handleGetGeometry = async () => {
    if (hasMap) {
      const inputCoordinates = getInputCoordinates(locationText);
      if (inputCoordinates) {
        map.setCenter(inputCoordinates);
        marker.setPosition(inputCoordinates);
        return;
      }
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      try {
        const geometry = await geocoder.geocode({ address: locationText || location });
        const latLng = geometry.results[0].geometry.location.toJSON();

        map.setCenter(latLng);
        marker.setPosition(latLng);
        setLocationText(locationText || location);
      } catch (error) {
        if (error.code === "ZERO_RESULTS") {
          setHasMap(false);
          message.error(
            t({
              id: "asset-map.location-modal.errors.no-results",
              message: "No results found for that address. Please try a different address",
            })
          );
        }
      }
    }
  };

  const handleOk = async () => {
    if (locationText) {
      try {
        const inputCoordinates = getInputCoordinates(locationText);
        if (inputCoordinates) {
          onOk({
            location: inputCoordinates,
            formattedAddress: locationText,
          });
          return;
        }
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();
        const geometry = await geocoder.geocode({ address: locationText || location });
        const latLng = geometry.results[0].geometry.location.toJSON();
        onOk({
          location: latLng,
          formattedAddress: locationText,
        });
      } catch (error) {
        if (error.code === "ZERO_RESULTS") {
          message.error(
            t({
              id: "asset-map.location-modal.errors.no-results",
              message: "No results found for that address. Please try a different address",
            })
          );
        }
      }
    } else {
      onOk({
        location: undefined,
        formatted_address: "",
      });
    }
  };
  const handleCancel = async () => {
    setLocationText(location);
    onCancel();
  };

  return (
    <Modal
      open={open}
      title={t({
        id: "asset-map.location-modal.modal-title",
        message: "Edit Location",
      })}
      centered
      okText={t({
        id: "asset-map.location-modal.modal-ok-button",
        message: "Confirm",
      })}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p className={styles.paragraph}>{title}</p>
      {isLoaded && (
        <>
          <Autocomplete
            onLoad={(instance) => setAutoComplete(instance)}
            onPlaceChanged={handlePlaceChanged}
            options={autoCompleteOptions}
          >
            <Input
              value={locationText}
              onChange={(e) => setLocationText(e.target.value)}
              className={styles.input}
              allowClear
              suffix={<SearchOutlined className={styles.searchIcon} />}
            />
          </Autocomplete>
          <GoogleMap
            zoom={16}
            mapContainerClassName={styles.mapContainer}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              zoomControl: false,
              fullscreenControl: false,
              styles: googleMapsStyle,
            }}
            onLoad={(instance) => setMap(instance)}
            mapContainerStyle={{ display: hasMap ? "block" : "none" }}
          >
            <Marker onLoad={(instance) => setMarker(instance)} draggable onDragEnd={handleDragMarker} />
          </GoogleMap>
        </>
      )}
    </Modal>
  );
}
