/* eslint-disable import/prefer-default-export */
export const getInputCoordinates = (text) => {
  const coordinateStrings = text.split(",");
  if (coordinateStrings.length !== 2) {
    return null;
  }
  const lat = parseFloat(coordinateStrings[0]);
  const lng = parseFloat(coordinateStrings[1]);
  if (Number.isNaN(lat) || Number.isNaN(lng) || lat < -90 || lat > 90 || lng < -180 || lng > 80) {
    return null;
  }
  return {
    lat,
    lng,
  };
};
