import React, { useMemo, useState } from "react";

import { Spin } from "antd";
import { Trans } from "@lingui/macro";

import { ReactComponent as PanelIllustration } from "assets/panel-illustration.svg";

import TimeSelectorAdvanced from "components/TimeSelectorAdvanced";
import Panel from "components/Panel";
import styles from "./PanelPreview.module.less";

function PanelPreview({
  previewPanel,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  timeInterval,
  setTimeInterval,
  pollInterval,
  setPollInterval,
  timeMode,
  setTimeMode,
}) {
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const onLoadingChange = ({ loading: panelLoading }) => {
    setLoading(panelLoading);
  };

  const time = useMemo(
    () => ({
      type: timeMode,
      interval: timeInterval,
      from: startTime,
      to: endTime,
    }),
    [startTime, endTime, timeMode, timeInterval]
  );
  return (
    <div className={styles.previewContainer}>
      {previewPanel ? (
        <div className={styles.panelContainer}>
          <TimeSelectorAdvanced
            {...{
              startTime,
              setStartTime,
              endTime,
              setEndTime,
              timeInterval,
              setTimeInterval,
              timeMode,
              setTimeMode,
              setRefetch,
              pollInterval,
              setPollInterval,
            }}
            containerStyle={{
              justifyContent: "flex-end",
              width: "fit-content",
              marginLeft: "auto",
              marginBottom: "1rem",
            }}
          />
          <Panel
            panel={previewPanel}
            style={{ width: "100%" }}
            time={time}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setTimeMode={setTimeMode}
            refetch={refetch}
            pollInterval={pollInterval}
            showLegend
            legendConfig={{ orientation: "h", y: -0.4 }}
            onLoadingChange={onLoadingChange}
          />
          {loading && <Spin className={styles.spin} />}
        </div>
      ) : (
        <div className={styles.previewPlaceholder}>
          <div className={styles.placeholderContent}>
            <PanelIllustration />
            <div className={styles.previewTitle}>
              <Trans id="panel-form.preview-placeholder.title">Select measures to preview panel</Trans>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PanelPreview;
