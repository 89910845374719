import { GET_MY_PERMISSIONS } from "graphql/apiService";
import { useLazyQuery } from "@apollo/client";

export default function useGetMyPermissions() {
  const [getMyPermissionsQuery, { loading, data, error }] = useLazyQuery(GET_MY_PERMISSIONS);

  const myPermissions = data?.getMyPermissions || [];

  const getMyPermissions = async ({ organizationSlug }) => {
    const res = await getMyPermissionsQuery({ variables: { organizationSlug } });
    return res.data?.getMyPermissions || [];
  };

  return [getMyPermissions, { myPermissions, loading, error }];
}
