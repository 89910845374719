import React from "react";
import { Link } from "react-router-dom";
import { t } from "@lingui/macro";

import { useLayout } from "context/GlobalLayoutContext";
import { useUser } from "context/UserContext";
import useGetOrganizationProfile from "hooks/apiService/organizationProfile/useGetOrganizationProfile";

import { Skeleton, Tooltip } from "antd";

import eh2LogoSquare from "assets/eh2-square.svg";
import styles from "./Title.module.less";

export default function Title() {
  const { currentOrganization } = useUser();
  const { organizationProfile, loading } = useGetOrganizationProfile();
  const { sidebarCollapsed } = useLayout();

  return (
    <div className={styles.title}>
      <Link to="/" className={styles.link}>
        {loading ? (
          <>
            <Skeleton.Avatar className={styles.organizationLogo} active />
            {!sidebarCollapsed && <Skeleton.Avatar className={styles.organizationNameLoading} active shape="square" />}
          </>
        ) : (
          <Tooltip title={t({ id: "menu.title.back-to-home", message: "Back to Home" })} placement="bottomLeft">
            <div className={styles.logoContainer}>
              <img
                src={organizationProfile?.pictureUrl || eh2LogoSquare}
                className={organizationProfile?.pictureUrl ? styles.organizationLogo : styles.logo}
                alt="EH2 Logo"
              />
              {!sidebarCollapsed && (
                <span className={styles.organizationName}>{currentOrganization.toUpperCase()}</span>
              )}
            </div>
          </Tooltip>
        )}
      </Link>
    </div>
  );
}
