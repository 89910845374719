import React from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { i18n } from "@lingui/core";
import { StarOutlined } from "@ant-design/icons";

import { useMyFavorites } from "hooks/apiService";
import useTableSearch from "hooks/useTableSearch";

import TableNameCell from "components/TableNameCell";
import Tag from "containers/Dashboards/components/Tag";

import { t, Trans } from "@lingui/macro";

import styles from "./FavoriteDashboards.module.less";

export default function FavoriteDashboards() {
  const { getColumnSearchProps, searchText } = useTableSearch();

  const { favorites, loading } = useMyFavorites();

  const tableData = favorites.map((favorite) => ({ ...favorite.item, isFavorite: true }));

  const tableColumns = [
    {
      key: "name",
      dataIndex: "name",
      title: t({ id: "favorites.table.name", message: "Name" }),
      className: "nameCol",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps({ dataIndex: "name", customRender: true }),
      render: (name, record) => (
        <TableNameCell
          record={record}
          name={name}
          type={record.type}
          link={`/dashboards/${record.id}`}
          searchText={searchText}
        />
      ),
    },
    {
      title: t({ id: "favorites.table.tags", message: "Tags" }),
      dataIndex: "tags",
      width: "18%",
      responsive: ["sm"],
      filters: [...new Set(tableData.map((item) => item.tags).flat())].map((tag) => ({
        value: tag,
        text: tag,
      })),
      onFilter: (selectedTag, record) => record?.tags?.includes(selectedTag),
      render: (tags, _record) =>
        tags && (
          <div>
            {tags.map((tag) => (
              <Tag key={tag} label={tag} />
            ))}
          </div>
        ),
      editable: true,
    },
    {
      key: "updatedAt",
      dataIndex: "updatedAt",
      title: t({ id: "favorites.table.updatedAt", message: "Last Updated" }),
      responsive: ["md"],
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (updatedAt, _record) => (
        <span>{updatedAt && i18n.date(new Date(updatedAt), { dateStyle: "medium", timeStyle: "short" })}</span>
      ),
    },
  ];

  return (
    <div>
      {tableData?.length ? (
        <Table
          columns={tableColumns}
          dataSource={tableData}
          loading={loading}
          rowKey={(record) => record.id}
          pagination={false}
        />
      ) : (
        !loading && (
          <div className={styles.messageContainer}>
            <Trans id="favorites.empty-message">
              Add your first favorite dashboard to “My Favorites” by clicking <StarOutlined style={{ fontSize: 19 }} />{" "}
              in the{" "}
              <Link to="/dashboards" className={styles.link}>
                Dashboards
              </Link>{" "}
              module
            </Trans>
          </div>
        )
      )}
    </div>
  );
}
