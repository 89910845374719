import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { MARK_ANNOTATION_AS_RESOLVED } from "graphql/apiService";

export default function useMarkAnnotationAsResolved() {
  const { currentOrganization } = useUser();

  const [markAnnotationAsResolvedMutation, { loading, error }] = useMutation(MARK_ANNOTATION_AS_RESOLVED, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetAnnotationsByPanel"],
  });

  const markAnnotationAsResolved = async ({ id }) => {
    const res = await markAnnotationAsResolvedMutation({
      variables: {
        id,
      },
    });
    return res.data.markAnnotationAsResolved;
  };

  return [markAnnotationAsResolved, { loading, error }];
}
