/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { Input, Tree, Spin, Empty } from "antd";
import debounce from "lodash/debounce";
import styles from "./SearchTree.module.less";

const { Search } = Input;

const generateList = (data) => {
  const dataList = [];
  const nodeKeyMap = {};

  const helper = (nodes) => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const { key, title, parentAssetId } = node;
      const dataItem = {
        key,
        title,
        parentAssetId,
      };
      dataList.push(dataItem);
      nodeKeyMap[key] = dataItem;
      if (node.children) {
        helper(node.children);
      }
    }
  };

  helper(data);
  return { dataList, nodeKeyMap };
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

const highlightTitle = (title, searchValue) => {
  const index = title.toLowerCase().indexOf(searchValue.toLowerCase());
  if (index === -1) return title;
  const beforeStr = title.substring(0, index);
  const matchedStr = title.substring(index, index + searchValue.length);
  const afterStr = title.substring(index + searchValue.length);
  return (
    <span>
      {beforeStr}
      <span className={styles.searchValue}>{matchedStr}</span>
      {afterStr}
    </span>
  );
};

const SearchTree = ({ data, onSelect }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filteredTreeData, setFilteredTreeData] = useState(data);
  const [loading, setLoading] = useState(false);

  const workerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    workerRef.current = new Worker(new URL("./filterWorker.js", import.meta.url));
    workerRef.current.onmessage = (e) => {
      setFilteredTreeData(e.data);
      setLoading(false);
    };
    return () => {
      workerRef.current.terminate();
    };
  }, []);

  const { dataList, nodeKeyMap } = useMemo(() => generateList(data), [data]);

  const onExpand = useCallback((newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      const newExpandedKeys = dataList
        .map((item) => {
          if (item.title.toLowerCase().includes(value.toLowerCase())) {
            return getParentKey(item.key, data);
          }
          return null;
        })
        .filter((item, i, self) => !!(item && self.indexOf(item) === i));
      setExpandedKeys(newExpandedKeys);
      setSearchValue(value);
      setAutoExpandParent(true);
      setDropdownVisible(value !== "");

      const serializableData = JSON.parse(JSON.stringify(data));
      setLoading(true);
      workerRef.current.postMessage({ data: serializableData, searchValue: value });
    }, 300),
    [dataList, data]
  );

  const onChange = (e) => {
    const { value } = e.target;
    debouncedSearch(value);
  };

  const handleSelect = useCallback(
    (_, e) => {
      if (e?.node?.key) {
        const selectedItem = nodeKeyMap[e.node.key];
        onSelect(selectedItem);
        setDropdownVisible(false);
      }
    },
    [nodeKeyMap, onSelect]
  );

  const treeData = useMemo(() => {
    const loop = (nodes) =>
      nodes.map((item) => {
        const title = highlightTitle(item.title, searchValue);
        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
          };
        }
        return {
          title,
          key: item.key,
        };
      });
    return loop(filteredTreeData);
  }, [filteredTreeData, searchValue]);

  useEffect(
    () => () => {
      debouncedSearch.cancel();
    },
    [debouncedSearch]
  );

  return (
    <div className={styles.searchTreeContainer} ref={containerRef}>
      <Search
        style={{ marginBottom: 8, width: "100%" }}
        placeholder="Search by Asset or Measure"
        onChange={onChange}
        onFocus={() => setDropdownVisible(true)}
      />
      <div className={`${styles.treeDropdown} ${dropdownVisible ? "" : styles.hidden}`}>
        {loading ? (
          <div className={styles.spinContainer}>
            <Spin />
          </div>
        ) : filteredTreeData.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.empty} />
        ) : (
          <Tree
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={treeData}
            onSelect={handleSelect}
          />
        )}
      </div>
    </div>
  );
};

export default SearchTree;
