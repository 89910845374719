import React from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/eh2-logo-long.svg";
import styles from "./PublicHeader.module.less";

function PublicHeader() {
  const navigate = useNavigate();
  return (
    <PageHeader
      onBack={() => navigate(-1)}
      backIcon={false}
      title={
        <Link to="/">
          <img src={logo} className={styles.logo} alt="EH2 Logo" />
        </Link>
      }
    />
  );
}

export default PublicHeader;
