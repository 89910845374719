import Card from "components/Card";
import React, { useState } from "react";
import { Select, Input, Button, Table } from "antd";

import styles from "./AlarmOverrides.module.less";

const statusOptions = [
  { value: "ALL", label: "All Statuses" },
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];

const tagOptions = [{ value: "ALL", label: "All Tags" }];

export default function AlarmOverrides() {
  const [searchString, setSearchString] = useState("");
  const [statusFilter, setStatusFilter] = useState("ALL");

  const tableColumns = [
    {
      title: "Alarm Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Alarm Tag",
      key: "tag",
      dataIndex: "tag",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Override Start",
      key: "start",
      dataIndex: "start",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Override End",
      key: "end",
      dataIndex: "end",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ];

  const tableData = [];

  return (
    <Card className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeft}>
          <Input
            value={searchString}
            placeholder="Search"
            className={styles.searchInput}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <Select value="ALL" options={tagOptions} className={styles.select} />
          <Select
            value={statusFilter}
            options={statusOptions}
            onChange={(newStatus) => setStatusFilter(newStatus)}
            className={styles.select}
          />
        </div>
        <div>
          <Button>Reset</Button>
        </div>
      </div>
      <Table columns={tableColumns} dataSource={tableData} rowKey={(record) => record.id} pagination={false} />
    </Card>
  );
}
