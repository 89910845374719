import { t } from "@lingui/macro";

export const severities = () => [
  {
    name: t({
      id: "incident-reports.severity.unsafe-condition",
      message: "Unsafe Condition",
    }),
    value: "UNSAFE_CONDITION",
  },
  {
    name: t({
      id: "incident-reports.severity.unsafe-act",
      message: "Unsafe Act",
    }),
    value: "UNSAFE_ACT",
  },
  {
    name: t({
      id: "incident-reports.severity.near-miss",
      message: "Near Miss",
    }),
    value: "NEAR_MISS",
  },
  {
    name: t({
      id: "incident-reports.severity.accident",
      message: "Accident",
    }),
    value: "ACCIDENT",
  },
];

export const statuses = () => [
  {
    name: t({
      id: "incident-reports.status.draft",
      message: "Draft",
    }),
    value: "DRAFT",
  },
  {
    name: t({
      id: "incident-reports.status.review",
      message: "In Review",
    }),
    value: "WITH_SAFETY_TEAM",
  },
  {
    name: t({
      id: "incident-reports.status.done",
      message: "Done",
    }),
    value: "DONE",
  },
];
