import React, { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";

const getDirectionalLink = ({ getAccessTokenSilently }) => {
  const databaseServiceLink = new HttpLink({
    uri: process.env.REACT_APP_DATABASE_SERVICE_API,
  });

  const apiServiceLink = new HttpLink({
    uri: process.env.REACT_APP_API_SERVICE_URL,
  });

  const authMiddleware = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
    },
  }));

  return new RetryLink().split(
    (operation) => operation.getContext().clientName === "database-service",
    authMiddleware.concat(databaseServiceLink),
    authMiddleware.concat(apiServiceLink)
  );
};

const getApolloClient = ({ getAccessTokenSilently }) =>
  new ApolloClient({
    link: getDirectionalLink({ getAccessTokenSilently }),
    cache: new InMemoryCache({
      typePolicies: {
        Principal: {
          keyFields: false,
        },
        Resource: {
          keyFields: false,
        },
        OrganizationProfile: {
          keyFields: ["organizationSlug"],
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    connectToDevTools: true,
  });

const Apollo = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useMemo(
    () =>
      getApolloClient({
        getAccessTokenSilently,
      }),
    [getAccessTokenSilently]
  );

  return client && <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
