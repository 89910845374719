import React from "react";
import dayjs from "dayjs";
import ResponsivePlot from "components/ResponsivePlot";

export default function H2ProductionBarChart({ data }) {
  const dates = Array.from({ length: 7 }, (_, i) => dayjs().subtract(i, "days").format("MMM D")).reverse();

  return (
    <ResponsivePlot
      data={[
        {
          x: dates,
          y: data,
          type: "bar",
          marker: { color: "#008b8a" },
        },
      ]}
      layout={{
        yaxis: { title: "H2 Produced [Tonnes]", tickformat: ".1f" },
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 40,
          pad: 4,
        },
      }}
      config={{
        modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
        displaylogo: false,
        responsive: true,
      }}
    />
  );
}
