import { useUser } from "context/UserContext";
import { GET_COMMENTS_BY_THREAD } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useComments({ threadId }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_COMMENTS_BY_THREAD, {
    variables: {
      organizationSlug: currentOrganization,
      threadId,
    },
  });

  const comments = data?.getCommentsByThread;

  return { comments, loading, error };
}
