import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { t, Trans } from "@lingui/macro";

import captureException from "utils/capture-sentry-exception";

import { useUser } from "context/UserContext";
import {
  useApproveDataPartnerRequest,
  useDataPartnersReceiving,
  useDataPartnersSharing,
  useRevokeDataPartnerRequest,
} from "hooks/apiService/dataPartner";
import useCustomMessage from "hooks/useCustomMessage";

import { PlusOutlined } from "@ant-design/icons";

import { Button, Table, Tabs } from "antd";
import Card from "components/Card";
import InviteModal from "./components/InviteModal";

import { columnsReceive, columnsSend } from "./helpers/columns";
import unifyDataPartners from "./helpers/uniquePartners";

import styles from "./DataPartners.module.less";

export default function DataPartners() {
  const { message } = useCustomMessage();
  const { currentOrganization, hasPermissions } = useUser();
  const screenBelowSM = useMediaQuery({
    query: "(max-width: 575px)",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const openTab = searchParams.get("tab");
  const {
    dataPartners: dataPartnersReceiving,
    loading: loadingReceiving,
    error: errorReceiving,
  } = useDataPartnersReceiving();
  const { dataPartners: dataPartnersSharing, loading: loadingSharing, error: errorSharing } = useDataPartnersSharing();

  const [approveDataPartnerRequest] = useApproveDataPartnerRequest();
  const [revokeDataPartnerRequest] = useRevokeDataPartnerRequest();

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(openTab || "sending-to");

  const openInviteModal = () => {
    setInviteModalOpen(true);
  };

  const partnersReceiving = useMemo(() => {
    if (!dataPartnersReceiving) return [];
    const dataPartners = dataPartnersReceiving.map(({ id, principal, status }) => ({
      id,
      key: `RECEIVE-${id}`,
      organization: principal.slug,
      status,
    }));
    return unifyDataPartners(dataPartners);
  }, [dataPartnersReceiving]);

  useEffect(() => {
    if (errorReceiving) {
      captureException(errorReceiving);
      message.error(
        t({
          id: "data-partners.errors.get-data-partners",
          message: "Failed to get data partners",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorReceiving]);

  const partnersSharing = useMemo(() => {
    if (!dataPartnersSharing) return [];
    const dataPartners = dataPartnersSharing.map(({ id, resource, status }) => ({
      id,
      key: `RECEIVE-${id}`,
      organization: resource.slug,
      status,
    }));
    return unifyDataPartners(dataPartners);
  }, [dataPartnersSharing]);

  useEffect(() => {
    if (errorSharing) {
      captureException(errorSharing);
      message.error(
        t({
          id: "data-partners.errors.get-data-partners",
          message: "Failed to get data partners",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSharing]);

  const handleApprove = async ({ id }) => {
    try {
      await approveDataPartnerRequest({ id });
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "data-partners.errors.approve-data-partners",
          message: "Failed to approve data partner",
        })
      );
    }
  };
  const handleRevoke = async ({ id }) => {
    try {
      await revokeDataPartnerRequest({ id });
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "data-partners.errors.revoke-data-partner",
          message: "Failed to revoke data partner",
        })
      );
    }
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey);
  };

  useEffect(() => {
    searchParams.set("tab", activeTabKey);
    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabKey]);

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.headerContainer}>
          <div>
            <h1>
              <Trans id="data-partners.module-name">Data Partners</Trans>
            </h1>
            <p>
              <Trans id="data-partners.module-description">Review and update organizations for data sharing.</Trans>
            </p>
            <p>
              <Trans id="data-partners.your-organization-code">Your organization code:</Trans>{" "}
              <span className={styles.organizationName}>{currentOrganization.toUpperCase()}</span>
            </p>
          </div>
          <div className={styles.newDataPartnerContainer} style={{ display: screenBelowSM ? "none" : "block" }}>
            {activeTabKey === "sending-to" && hasPermissions(["invite:org_data_partners"]) && (
              <Button
                onClick={openInviteModal}
                type="primary"
                icon={<PlusOutlined className={styles.newDataPartnerButtonIcon} />}
              >
                <Trans id="data-partners.new-data-partner">New Data Partner</Trans>
              </Button>
            )}
          </div>
        </div>
        <div className={styles.dataPartnersContainer}>
          <Tabs
            defaultActiveKey={openTab || "sending-to"}
            type="card"
            className={styles.tabs}
            onTabClick={handleTabChange}
            items={[
              {
                key: "sending-to",
                label: `"${currentOrganization.toUpperCase()}" ${t({
                  id: "data-partners.tabs.sending-to",
                  message: "Can Send Data To These Partners",
                })}`,
                children: (
                  <Table
                    dataSource={partnersReceiving}
                    columns={columnsReceive({ handleRevoke, hasPermissions })}
                    loading={loadingSharing}
                    pagination={false}
                  />
                ),
              },
              {
                key: "receiving-from",
                label: `"${currentOrganization.toUpperCase()}" ${t({
                  id: "data-partners.tabs.receiving-from",
                  message: "Can Receive Data From These Partners",
                })}`,
                children: (
                  <Table
                    dataSource={partnersSharing}
                    columns={columnsSend({ handleApprove, handleRevoke, hasPermissions })}
                    loading={loadingReceiving}
                    pagination={false}
                  />
                ),
              },
            ]}
          />
        </div>
      </Card>
      <InviteModal visible={inviteModalOpen} setVisible={setInviteModalOpen} />
    </>
  );
}
