import React from "react";

import { Form, Input } from "antd";
import { t } from "@lingui/macro";

export default function RootCause() {
  return (
    <Form.Item
      name="cause"
      labelCol={{ span: 24 }}
      label={t({
        id: "incident-report-form.cause",
        message: "Root Cause (If applicable)",
      })}
    >
      <Input.TextArea
        rows={22}
        maxLength={1400}
        showCount
        placeholder={t({
          id: "incident-report-form.cause.placeholder",
          message: "Add Root Cause",
        })}
      />
    </Form.Item>
  );
}
