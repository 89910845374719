import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_EQUIPMENT } from "graphql/apiService";

export default function useDeleteEquipment() {
  const { currentOrganization } = useUser();

  const [deleteEquipmentMutation, { loading, error }] = useMutation(DELETE_EQUIPMENT, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetAllEquipment"],
  });

  const deleteEquipment = async ({ id }) => {
    const res = await deleteEquipmentMutation({ variables: { id } });
    return res.data?.deleteEquipment;
  };

  return [deleteEquipment, { loading, error }];
}
