import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NotificationIconButton, NotificationFeedPopover, useKnockFeed } from "@knocklabs/react-notification-feed";
import { t } from "@lingui/macro";
import { isChrome } from "react-device-detect";

import { useAuth0 } from "@auth0/auth0-react";
import { useLayout } from "context/GlobalLayoutContext";
import { useNotifications } from "context/NotificationsContext";
import { useUser } from "context/UserContext";

import { getNewOrgUrlForOrgSwitch, handleLogout } from "utils/auth-utils";
import { menuItems as menuItemFn, userMenuItems as userMenuItemsFn } from "config/SideBarItems";

import { Dropdown, Layout } from "antd";
import { UserOutlined, CaretDownOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ReactComponent as BellIcon } from "assets/bell-outlined.svg";

import Title from "components/Title";
import ActiveModule from "components/ActiveModule";
import BrowserWarningModal from "components/BrowserWarningModal";

import styles from "./GlobalHeader.module.less";

const { Header } = Layout;

const findNestedObj = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

function GlobalHeader({ isMobile }) {
  const location = useLocation();
  const notificationButtonRef = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [warningModalVisible, setWarningModalVisible] = useState(true);

  const knockFeed = useKnockFeed();

  if (knockFeed) {
    knockFeed.feedClient.store.subscribe((state) => {
      setUnreadCount(state.metadata.unread_count);
    });
  }

  const { setDrawerVisible, sidebarCollapsed } = useLayout();
  const { toggleDrawer, loading: notificationsLoading } = useNotifications();

  const { logout } = useAuth0();
  const { organizations, user } = useUser();

  const menuItems = menuItemFn();
  const userMenuItems = userMenuItemsFn();

  const selectedKey = location.pathname.replace(/^\/|\/$/g, "");
  const currentSelectedModule = findNestedObj([...menuItems, ...userMenuItems], "key", selectedKey.split("/")[0]);

  const dropdownMenuItems = [
    {
      key: "account",
      label: (
        <Link to="/account">
          <span>{t({ id: "header.user-account", message: "User Account" })}</span>
        </Link>
      ),
    },
    organizations.length > 1 && {
      key: "orgSwitcher",
      label: t({ id: "header.org-switch", message: "Switch Organization" }),
      children: organizations.map((org) => ({
        key: org.id,
        disabled: org.id === user?.org_id,
        label: org.display_name,
        onClick: () => {
          window.location.replace(getNewOrgUrlForOrgSwitch(org.name));
        },
      })),
    },
    { type: "divider" },
    {
      key: "logout",
      onClick: () => handleLogout(logout),
      label: <span>{t({ id: "header.sign-out", message: "Sign Out" })}</span>,
    },
  ].filter(Boolean);

  return (
    <Header className={styles.header}>
      {!isChrome && <BrowserWarningModal visible={warningModalVisible} setVisible={setWarningModalVisible} />}
      <div className={styles.globalHeaderLeft} style={{ width: sidebarCollapsed ? "72px" : "256px" }}>
        {isMobile && <MenuUnfoldOutlined onClick={() => setDrawerVisible(true)} />}
        <Title />
        {!isMobile && <ActiveModule name={currentSelectedModule?.name} />}
      </div>
      <div className={styles.globalHeaderRight}>
        {notificationsLoading ? (
          <BellIcon style={{ marginRight: "4px" }} />
        ) : (
          <>
            <NotificationFeedPopover buttonRef={notificationButtonRef} isVisible={false} />
            <NotificationIconButton
              ref={notificationButtonRef}
              onClick={toggleDrawer}
              badgeCountType="unread"
              unreadCount={unreadCount}
            />
          </>
        )}

        <Dropdown menu={{ items: dropdownMenuItems }} trigger={["click"]} overlayStyle={{ zIndex: 150 }}>
          <span className={styles.action}>
            <UserOutlined className={styles.icon} />
            <CaretDownOutlined />
          </span>
        </Dropdown>
      </div>
    </Header>
  );
}

export default GlobalHeader;
