import React from "react";
import { useMediaQuery } from "react-responsive";
import { Layout } from "antd";

import useAssetTree from "hooks/useAssetTree";

import Card from "components/Card";
import AssetDisplay from "./AssetDisplay";
import Asset from "./Asset";
import AssetEdit from "./AssetEdit";
import AssetShare from "./AssetShare";
import AssetTree from "./components/AssetTree";

import styles from "./AssetMap.module.less";
import { addMeasuresToTree } from "./helpers/nestedObject";

const { Content, Sider } = Layout;

export default function AssetMap({
  allAssets = false,
  sharedAssets = false,
  isNew = false,
  editing = false,
  sharing = false,
}) {
  const isSmallScreen = useMediaQuery({ maxWidth: 1200 });
  const { assetTree, assetList, assetIdMap } = useAssetTree();
  const assetAndMeasuresTree = addMeasuresToTree(assetTree);

  const renderContent = () => {
    if (allAssets) {
      return (
        <AssetDisplay
          isFolder
          asset={{ name: "All Assets", type: "FOLDER" }}
          assetIdMap={assetIdMap}
          assetList={assetList}
          subAssets={assetList}
          assetTree={assetAndMeasuresTree}
          allowedModes={["LIST"]}
        />
      );
    }
    if (sharedAssets) {
      return (
        <AssetDisplay
          isFolder
          isShared
          assetIdMap={assetIdMap}
          asset={{ name: "Shared Assets", type: "FOLDER" }}
          assetList={assetList}
          allowedModes={["LIST"]}
        />
      );
    }
    if (isNew) {
      return <AssetEdit isNew assetTree={assetTree} />;
    }
    if (editing) {
      return <AssetEdit assetIdMap={assetIdMap} assetTree={assetTree} />;
    }
    if (sharing) {
      return <AssetShare />;
    }
    return <Asset assetTree={assetAndMeasuresTree} assetList={assetList} assetIdMap={assetIdMap} />;
  };

  return (
    <Layout>
      <Sider
        className={styles.sidebar}
        theme="light"
        width={350}
        style={{ transform: isSmallScreen ? "translateX(-100%)" : "" }}
      >
        <AssetTree assetTree={assetTree} isShared={sharedAssets} />
      </Sider>
      <Content className={styles.content} style={{ marginLeft: isSmallScreen ? 0 : 350 }}>
        <Card className={styles.contentContainer}>{renderContent()}</Card>
      </Content>
    </Layout>
  );
}
