import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_EQUIPMENT } from "graphql/apiService";

export default function useUpdateEquipment() {
  const { currentOrganization } = useUser();

  const [updateEquipmentMutation, { loading, error }] = useMutation(UPDATE_EQUIPMENT, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateEquipment = async ({ input }) => {
    const res = await updateEquipmentMutation({ variables: { input } });
    return res.data.updateEquipment;
  };

  return [updateEquipment, { loading, error }];
}
