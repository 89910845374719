import useQuery from "hooks/useQuery";
import { useUser } from "context/UserContext";
import { GET_MY_USER_ACCOUNT } from "graphql/apiService";

export default function useGetMyUserAccount() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_MY_USER_ACCOUNT, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const myUserAccount = data?.getMyUserAccount;
  return { myUserAccount, loading, error };
}
