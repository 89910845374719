import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

import useThirdPartyProxy from "hooks/useThirdPartyProxy";
import { useEquipmentList, useUserOrganizationProfiles } from "hooks/apiService";

import { InfoCircleOutlined } from "@ant-design/icons";

import { Card as AntdCard, Descriptions, Image, Tag, Tooltip } from "antd";
import Card from "components/Card";
import HeaderMenu from "./components/HeaderMenu";

import statusColor from "../helpers/statusColor";

import styles from "./WorkRequest.module.less";

export default function WorkRequest() {
  const { taskId } = useParams();

  const { equipmentList: assets } = useEquipmentList();
  const { userOrganizationProfiles: users } = useUserOrganizationProfiles();
  const { getAssets, getStatuses, getTasks, getTaskInstructions } = useThirdPartyProxy();

  const [task, setTask] = useState({});
  const [loading, setLoading] = useState(true);

  const handleGetTask = async () => {
    const limbleAssets = await getAssets();
    const limbleStatuses = await getStatuses();
    const limbleTasks = await getTasks();
    const limbleTask = limbleTasks.find((taskData) => taskData.taskID === Number(taskId));

    const taskInstructions = await getTaskInstructions({ taskID: taskId });
    const requestor = users.find((user) => user.auth0Id === limbleTask.meta2);

    const descriptionRegex = /([\s\S]+?)<br>Category:/;
    const categoryRegex = /Category:\s*<b>(.*?)<\/b>/;

    const descriptionMatch = descriptionRegex.exec(limbleTask.description);
    const categoryMatch = categoryRegex.exec(limbleTask.description);

    const description = descriptionMatch ? descriptionMatch[1] : "";
    const category = categoryMatch ? categoryMatch[1] : "";

    const initialInstruction = taskInstructions.find(
      (instruction) => instruction?.parentInstructionID === 0 && instruction.type === 9
    );

    const screenshotRegex = /.+-Autocapture.+\.png/g;
    const screenshotIndex = initialInstruction?.instructionFiles.findIndex(({ fileName }) =>
      screenshotRegex.test(fileName)
    );
    const screenshot = screenshotIndex >= 0 && initialInstruction.instructionFiles.splice(screenshotIndex, 1)[0].link;
    const attachments = initialInstruction?.instructionFiles || [];

    const result = {
      ...limbleTask,
      description,
      category,
      asset:
        assets.find((asset) => asset.limbleId === limbleTask.assetID) ||
        limbleAssets.find((asset) => asset.assetID === limbleTask.assetID),
      status: limbleStatuses.find((status) => status.statusID === limbleTask.statusID),
      requestor: requestor?.userAccount,
      screenshot,
      attachments,
    };
    setTask(result);
    setLoading(false);
  };

  useEffect(() => {
    if (assets.length > 0 && users.length > 0) {
      handleGetTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, users]);

  if (loading) {
    return <AntdCard loading />;
  }

  const DueTime = () => {
    const dueTime = dayjs(task.due * 1000);
    const currentTime = dayjs();
    if (currentTime > dueTime && styles.overDue) {
      return (
        <span className={styles.overDue}>
          {i18n.date(dueTime, { dateStyle: "medium", timeStyle: "short" })}{" "}
          <Tooltip
            title={t({
              id: "work-requests.work-request.details.due-date.over-due",
              message: "Overdue",
            })}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      );
    }
    return i18n.date(dueTime, { dateStyle: "medium", timeStyle: "short" });
  };

  return (
    <Card>
      <HeaderMenu task={task} />
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>{task.name}</h2>
          <Tag color={statusColor(task.statusID)}>{task.status.name}</Tag>
        </div>
        <div className={styles.table}>
          <Descriptions column={1} bordered>
            <Descriptions.Item
              label={t({ id: "work-requests.work-request.details.request-index.label", message: "Request Index" })}
            >
              # {task.taskID}
            </Descriptions.Item>
            <Descriptions.Item label={t({ id: "work-requests.work-request.details.status.label", message: "Status" })}>
              <Tag color={statusColor(task.statusID)}>{task.status.name}</Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={t({ id: "work-requests.work-request.details.asset-involved.label", message: "Asset Involved" })}
            >
              {task.asset?.id && (
                <Link to={`/assets/${task.asset.id}`} className={styles.requestLink}>
                  {task.asset.name}
                </Link>
              )}
              {!task.asset?.id && task.asset.name && task.asset.name}
              {!task.asset?.id && !task.asset.name && <span className={styles.notDefined}>not defined</span>}
            </Descriptions.Item>
            <Descriptions.Item
              label={t({ id: "work-requests.work-request.details.asset-involved.category", message: "Category" })}
            >
              {task.category}
            </Descriptions.Item>
            <Descriptions.Item
              label={t({ id: "work-requests.work-request.details.due-date.label", message: "Due Date & Time" })}
            >
              <DueTime />
            </Descriptions.Item>
            <Descriptions.Item
              label={t({
                id: "work-requests.work-request.details.submit-date.label",
                message: "Submitted Date & Time",
              })}
            >
              {i18n.date(dayjs(task.createdDate * 1000), { dateStyle: "medium", timeStyle: "short" })}
            </Descriptions.Item>
            <Descriptions.Item
              label={t({ id: "work-requests.work-request.details.requestor.label", message: "Requestor" })}
            >
              {task.requestor?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={t({ id: "work-requests.work-request.details.description.label", message: "Description" })}
              style={{ whiteSpace: "pre-line" }}
            >
              {task.description}
            </Descriptions.Item>
            <Descriptions.Item
              label={t({ id: "work-requests.work-request.details.screenshot.label", message: "Screenshot" })}
            >
              {task.screenshot && <Image className={styles.image} src={task.screenshot} height={200} />}
              {!task.screenshot && <span>---</span>}
            </Descriptions.Item>
            <Descriptions.Item
              label={t({
                id: "work-requests.work-request.details.additional-images.label",
                message: "Additional Images",
              })}
            >
              {task.attachments.length > 0 &&
                task.attachments.map((attachment) => (
                  <Image key={attachment.fileName} className={styles.image} src={attachment.link} height={100} />
                ))}
              {task.attachments.length < 1 && <span>---</span>}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    </Card>
  );
}
