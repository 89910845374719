import React, { useState, useContext, createContext, useMemo } from "react";
import { KnockFeedProvider } from "@knocklabs/react-notification-feed";
import { notification as AntNotification } from "antd";
import { useGetMyUserAccount } from "hooks/apiService";
import { getOrgFromSubdomain } from "utils/auth-utils";
import NotificationToaster from "components/NotificationToaster";

const NotificationsContext = createContext([]);

const NotificationsProvider = ({ children }) => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const { myUserAccount } = useGetMyUserAccount();

  const { knockToken, auth0Id } = myUserAccount || {};
  const loading = !knockToken || !auth0Id;

  const closeDrawer = () => {
    setDrawerVisibility(false);
  };
  const openDrawer = () => {
    setDrawerVisibility(true);
  };
  const toggleDrawer = () => {
    setDrawerVisibility(!drawerVisibility);
  };

  AntNotification.config({
    duration: 3,
    maxCount: 3,
  });

  const values = useMemo(
    () => ({
      drawerVisibility,
      closeDrawer,
      openDrawer,
      toggleDrawer,
      loading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawerVisibility, loading]
  );

  const orgSlug = getOrgFromSubdomain();

  if (loading) {
    return <NotificationsContext.Provider value={values}>{children}</NotificationsContext.Provider>;
  }
  return (
    <KnockFeedProvider
      apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY}
      feedId={process.env.REACT_APP_KNOCK_FEED_ID}
      defaultFeedOptions={{ tenant: orgSlug }}
      userToken={knockToken}
      userId={auth0Id}
    >
      <NotificationToaster />
      <NotificationsContext.Provider value={values}>{children}</NotificationsContext.Provider>
    </KnockFeedProvider>
  );
};

const useNotifications = () => useContext(NotificationsContext);

export { useNotifications, NotificationsProvider };
