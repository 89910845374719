/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { ReactComponent as TrendUp } from "assets/trend-up.svg";
import styles from "./ChartDisplays.module.less";

import LineChartPreview from "../LineChartPreview";

const getTrend = (data) => ((data[data.length - 1] / data[0] - 1) * 100).toFixed(1);

export const NumberDisplay = ({ value, unit }) => (
  <div className={styles.numberDisplay}>
    <div className={styles.number}>{value}</div>
    <div className={styles.unit}>{unit}</div>
  </div>
);

export const TrendDisplay = ({ percentage, containerStyle }) => (
  <div className={styles.trendContainer} style={containerStyle}>
    <TrendUp style={percentage < 0 ? { transform: "scale(1,-1)" } : {}} />
    {`${percentage} %`}
  </div>
);

export const SingleChartDisplay = ({ data, onPanelClick }) => (
  <div className={styles.singleChartContainer} onClick={() => onPanelClick(data)}>
    <div className={styles.rowSeparated}>
      <h2 className={styles.subtitle}>{data.title}</h2>
      <TrendDisplay percentage={getTrend(data.data)} />
    </div>
    <div className={styles.chartContainer}>
      <div style={{ flex: 1, display: "flex", alignItems: "center", maxWidth: "50%" }}>
        <NumberDisplay value={data?.data?.[data.data.length - 1]} unit={data.unit} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "50%",
          height: "90%",
        }}
      >
        <LineChartPreview data={data} />
      </div>
    </div>
  </div>
);

const DoubleChartHalf = ({ data, value, onPanelClick }) => (
  <div className={styles.doubleChartContainer} onClick={() => onPanelClick(data)}>
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 4,
          justifyContent: "space-between",
          paddingBottom: 8,
        }}
      >
        <h2 className={styles.subtitle} style={{ textWrap: "wrap" }}>
          {data.title}
        </h2>
        <TrendDisplay percentage={getTrend(data.data)} />
      </div>
      <NumberDisplay value={value} unit={data.unit} />
    </div>

    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        height: "40%",
      }}
    >
      <LineChartPreview data={data} />
    </div>
  </div>
);

export const DoubleChartDisplay = ({ chart1, chart2, onPanelClick }) => {
  const { data: data1 } = chart1;
  const { data: data2 } = chart2;

  const value1 = data1.data[data1.data.length - 1];
  const value2 = data2.data[data2.data.length - 1];

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", gap: 12 }}>
      <DoubleChartHalf data={data1} value={value1} onPanelClick={onPanelClick} />
      <DoubleChartHalf data={data2} value={value2} onPanelClick={onPanelClick} />
    </div>
  );
};

export const BigChartDisplay = ({ data, onPanelClick }) => (
  <div className={styles.singleChartContainer} onClick={() => onPanelClick(data)}>
    <div className={styles.rowSeparated}>
      <h2 className={styles.subtitle}>{data.title}</h2>
      <TrendDisplay percentage={getTrend(data.data)} />
    </div>
    <div className={`${styles.chartContainer} ${styles.vertical}`}>
      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <NumberDisplay value={data.data[data.data.length - 1]} unit={data.unit} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <LineChartPreview data={data} />
      </div>
    </div>
  </div>
);
