import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_DASHBOARD } from "graphql/apiService";

export default function useCreateDashboard() {
  const { currentOrganization } = useUser();

  const [createDashboardMutation, { loading, error }] = useMutation(CREATE_DASHBOARD, {
    variables: { organizationSlug: currentOrganization },
  });

  const createDashboard = async ({ input }) => {
    const res = await createDashboardMutation({ variables: { input } });
    return res.data.createDashboard;
  };

  return [createDashboard, { loading, error }];
}
