import React from "react";
import { t } from "@lingui/macro";
import { Button, Dropdown, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as SortIcon } from "assets/sort-icon.svg";

import styles from "./DashboardToolbar.module.less";

function DashboardToolbar({ searchString, setSearchString, sortDirection, setSortDirection }) {
  const sortOptions = [
    {
      value: "LAST_UPDATED_DESCENDING",
      label: t({ id: "dashboards.sort-options.last-updated-descending", message: "Last Updated: New to Old" }),
    },
    {
      value: "LAST_UPDATED_ASCENDING",
      label: t({ id: "dashboards.sort-options.last-updated-ascending", message: "Last Updated: Old to New" }),
    },
    { value: "NAME_ASCENDING", label: t({ id: "dashboards.sort-options.name-ascending", message: "Name: A-Z" }) },
    { value: "NAME_DESCENDING", label: t({ id: "dashboards.sort-options.name-descending", message: "Name: Z-A" }) },
  ];
  const sortMenuItems = sortOptions.map((o) => ({
    label: o.label,
    key: o.value,
  }));

  return (
    <div className={styles.toolbar}>
      <Input
        value={searchString}
        placeholder="Search Name or Tag"
        suffix={<SearchOutlined />}
        allowClear
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
      />
      <Dropdown
        menu={{
          items: sortMenuItems,
          selectedKeys: [sortDirection],
          onClick: ({ key }) => setSortDirection(key),
        }}
        trigger={["click"]}
      >
        <Button shape="circle" type="text" className={styles.sortButton}>
          <SortIcon />
        </Button>
      </Dropdown>
    </div>
  );
}

export default DashboardToolbar;
