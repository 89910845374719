import { useUser } from "context/UserContext";
import { GET_ALARMS } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useAlarms() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_ALARMS, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });
  const alarms = data?.getAlarms || [];

  return { alarms, loading, error };
}
