import { useUser } from "context/UserContext";
import { GET_ROLES } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useRoles() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_ROLES, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const roles = data?.getRoles || [];
  return { roles, loading, error };
}
