import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

import { useUser } from "context/UserContext";
import useCustomMessage from "hooks/useCustomMessage";
import { useSetUserRoles } from "hooks/apiService";

import { ReactComponent as LeftArrow } from "assets/left-arrow.svg";

import { Avatar as AvatarAntD } from "antd";
import Avatar from "components/Avatar";
import RoleSelector from "../RoleSelector";

import styles from "./UserRoles.module.less";

function UserRoles({ selectedUser, userRoles }) {
  const { message } = useCustomMessage();
  const [searchParams] = useSearchParams();
  const organizationSlug = searchParams.get("organizationSlug");
  const [setUserRoles, { loading: rolesUpdating }] = useSetUserRoles(organizationSlug);
  const { hasPermissions } = useUser();

  const authorizationType = userRoles?.length === 1 && !userRoles[0].module ? "SIMPLE" : "ADVANCED";

  const handleUpdateRoles = async (roleSlugs) => {
    try {
      await setUserRoles({ userId: selectedUser.auth0Id, roleSlugs });
      message.success(
        t({
          id: "roles-admin.roles-update-success",
          message: `Successfully updated the roles for ${selectedUser?.name}`,
        })
      );
    } catch (err) {
      message.error(
        t({
          id: "roles-admin.roles-update-fail",
          message: `Failed to update the roles of ${selectedUser?.name}`,
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <Link to={`/roles-admin/members${organizationSlug ? `?organizationSlug=${organizationSlug}` : ``}`}>
          <div className={styles.backContainer}>
            <LeftArrow />
            <Trans id="roles-admin.user-roles.back">
              <span>Back to Organization</span>
            </Trans>
          </div>
        </Link>

        <div className={styles.topContainer}>
          <div className={styles.userDisplay}>
            {selectedUser.pictureUrl && <AvatarAntD size={54} src={selectedUser.pictureUrl} />}
            {!selectedUser.pictureUrl && <Avatar size={54} name={selectedUser.name} showName={false} />}
            <div className={styles.userInfo}>
              <h2 className={styles.name}>{selectedUser.name}</h2>
              <span className={styles.username}>{selectedUser.email}</span>
            </div>
          </div>
        </div>
        <RoleSelector
          loading={rolesUpdating}
          initialMode={authorizationType}
          initialSimpleRoleSlug={authorizationType === "SIMPLE" ? userRoles?.[0]?.slug : undefined}
          initialAdvancedRoles={userRoles}
          onRolesUpdate={handleUpdateRoles}
          disabled={!hasPermissions(["grant:all_roles"])}
        />
      </div>
    </div>
  );
}

export default UserRoles;
