import React from "react";
import { isCustomerApm, isServiceApm } from "utils/auth-utils";
import SystemOverviewService from "./SystemOverviewService";
import SystemOverviewCustomer from "./SystemOverviewCustomer";

import styles from "./SystemOverview.module.less";

const isService = isServiceApm();
const isCustomer = isCustomerApm();
function SystemOverview() {
  if (isService) {
    return <SystemOverviewService styles={styles} />;
  }
  if (isCustomer) {
    return <SystemOverviewCustomer styles={styles} />;
  }
}

export default SystemOverview;
