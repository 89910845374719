import React from "react";
import { Form, Button, Input } from "antd";
import { t, Trans } from "@lingui/macro";

import { useUpdateReportSuggestions } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";
import { ReactComponent as BotIcon } from "assets/ai-icon.svg";

import styles from "./TitleAndSummary.module.less";

export default function TitleAndSummary({ form, reportId }) {
  const { message } = useCustomMessage();
  const [getSuggestions, { loading: suggestionsLoading }] = useUpdateReportSuggestions();

  const generateSuggestions = async () => {
    form.setFields([
      {
        name: "title",
        errors: [],
      },
      {
        name: "summary",
        errors: [],
      },
    ]);
    try {
      const res = await getSuggestions({ id: reportId });
      form.setFieldsValue({
        title: res?.suggestedTitle,
        summary: res?.suggestedSummary,
      });
    } catch (err) {
      message.error(
        t({
          id: "incident-report-form.suggestions-error",
          message: "Error getting suggestions",
        })
      );
    }
  };

  return (
    <>
      <Button
        onClick={generateSuggestions}
        loading={suggestionsLoading}
        className={styles.suggestionsButton}
        icon={<BotIcon width={22} />}
      >
        <Trans id="incident-report-form.generate-suggestions-prompt">Auto-generate by APM Bot</Trans>
      </Button>
      <Form.Item
        name="title"
        labelCol={{ span: 24 }}
        label={t({
          id: "incident-report-form.title",
          message: "Incident Title",
        })}
        required
        rules={[
          {
            required: true,
            message: t({
              id: "incident-report-form.rules.title",
              message: "Please enter the incident title",
            }),
          },
        ]}
      >
        {suggestionsLoading ? (
          <div className={styles.suggestionPlaceholder}>
            <Trans id="incident-report-form.generate-title-placeholder">
              Hang tight for a few seconds as we auto-generate the incident title for you
            </Trans>
          </div>
        ) : (
          <Input
            placeholder={t({
              id: "incident-report-form.placeholder-title",
              message: "Enter Incident Title",
            })}
            allowClear
          />
        )}
      </Form.Item>
      <Form.Item
        name="summary"
        labelCol={{ span: 24 }}
        label={t({
          id: "incident-report-form.summary",
          message: "Incident Summary",
        })}
      >
        {suggestionsLoading ? (
          <div className={styles.suggestionPlaceholder} style={{ height: "145px" }}>
            <Trans id="incident-report-form.generate-summary-placeholder">
              Hang tight for a few seconds as we auto-generate the incident summary for you
            </Trans>
          </div>
        ) : (
          <Input.TextArea
            rows={6}
            maxLength={1400}
            showCount
            allowClear
            placeholder={t({
              id: "incident-report-form.summary.placeholder",
              message: "Enter Incident Summary",
            })}
          />
        )}
      </Form.Item>
    </>
  );
}
