import React, { useEffect } from "react";

import { useLayout } from "context/GlobalLayoutContext";

import { Drawer as AntDrawer } from "antd";
import Menu from "components/Menu";

function Drawer() {
  const { drawerVisible, setDrawerVisible } = useLayout();
  useEffect(
    () => () => {
      setDrawerVisible(false);
    },
    [setDrawerVisible]
  );

  return (
    <AntDrawer placement="left" closable onClose={() => setDrawerVisible(false)} open={drawerVisible}>
      <Menu />
    </AntDrawer>
  );
}

export default Drawer;
