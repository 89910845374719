import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Avatar as AvatarAntD } from "antd";
import Avatar from "components/Avatar";

import { statusMessages as statusMessagesFn } from "../../helpers/annotation.helpers";

import styles from "./AnnotationMessage.module.less";

export default function AnnotationMessage({ message }) {
  const [timestamp, setTimestamp] = useState(dayjs(message.createdAt).fromNow());
  const { author, type } = message;

  const statusMessages = statusMessagesFn();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(dayjs(message.createdAt).fromNow());
    }, 1000);
    return () => clearInterval(interval);
  }, [message.createdAt]);

  if (type === "TEXT")
    return (
      <div className={styles.annotationMessageContainer}>
        <div>
          {author?.pictureUrl && <AvatarAntD size="small" src={author?.pictureUrl} />}
          {!author?.pictureUrl && <Avatar size="small" name={author?.name} showName={false} />}
        </div>
        <div className={styles.authorContentContainer}>
          <div className={styles.authorTimestamp}>
            <span className={styles.authorName}>{author?.name}</span>
            <span className={styles.timestamp}>{timestamp}</span>
          </div>
          <div className={styles.content}>{message.interpolatedContent}</div>
        </div>
      </div>
    );

  return (
    <div className={styles.annotationStatusContainer}>
      <div>
        {author?.pictureUrl && <AvatarAntD size="small" src={author?.pictureUrl} />}
        {!author?.pictureUrl && <Avatar size="small" name={author?.name} showName={false} />}
      </div>
      <div className={styles.authorStatusTimestamp}>
        <span className={styles.authorName}>{author?.name}</span>
        <span className={styles.statusMessage}>{statusMessages[type]}</span>
        <span className={styles.timestamp}>{timestamp}</span>
      </div>
    </div>
  );
}
