import React, { useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { useLayout } from "context/GlobalLayoutContext";

import { Layout } from "antd";

import Menu from "components/Menu";

import styles from "./Sidebar.module.less";
import SidebarToggle from "../SidebarToggle";

const { Sider } = Layout;

function SideBar() {
  const { sidebarCollapsed, setSidebarCollapsed } = useLayout();

  const onCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    if (isSmallScreen) {
      setSidebarCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen]);

  return (
    <Sider
      className={styles.sidebar}
      collapsible
      collapsed={sidebarCollapsed}
      onCollapse={onCollapse}
      theme="light"
      trigger={null}
      width={256}
      collapsedWidth={72}
    >
      <Menu />
      {!isSmallScreen && (
        <SidebarToggle
          collapsed={sidebarCollapsed}
          toggleCollapsed={() => setSidebarCollapsed((collapsed) => !collapsed)}
        />
      )}
    </Sider>
  );
}

export default SideBar;
