/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { List, Button, Checkbox, Divider, Input, Select } from "antd";
import dayjs from "dayjs";

import { ExclamationCircleOutlined, CheckOutlined, ReloadOutlined } from "@ant-design/icons";
import { ReactComponent as PlcAlarm } from "assets/plc-alarm-icon.svg";
import { ReactComponent as ApmAlarm } from "assets/apm-alarm-icon.svg";
import { ReactComponent as AlarmUnread } from "assets/alarm-unread.svg";
import { ReactComponent as TagIcon } from "assets/tag.svg";
import { ReactComponent as TagIconFilled } from "assets/tag-filled.svg";

import { capitalizeFirstLetter } from "utils/string-utils";

import styles from "./AlarmList.module.less";

const options = {
  sources: ["PLC"],
  statuses: ["BOOKMARK"],
  severities: ["URGENT", "HIGH", "MEDIUM", "LOW"],
};

const getDisplayName = (option) => {
  if (option === "PLC") return "PLC alarm";
  if (option === "APM") return "APM alarm";
  return capitalizeFirstLetter(option);
};

const optionIcons = {
  PLC: <PlcAlarm />,
  APM: <ApmAlarm />,
  UNACKNOWLEDGED: <AlarmUnread />,
  ACKNOWLEDGED: <CheckOutlined />,
  SAVED: <TagIcon />,
  LOW: <ExclamationCircleOutlined className={styles.severityLowColor} />,
  MEDIUM: <ExclamationCircleOutlined className={styles.severityMediumColor} />,
  HIGH: <ExclamationCircleOutlined className={styles.severityHighColor} />,
  URGENT: <ExclamationCircleOutlined className={styles.severityCriticalColor} />,
  ACKNOWLEDGED_ALARM: <ExclamationCircleOutlined className={styles.acknowledgedColor} />,
};

const sortOptions = {
  TIME_DESCENDING: "Newest to Oldest",
  TIME_ASCENDING: "Oldest to Newest",
  NAME_DESCENDING: "From Z to A",
  NAME_ASCENDING: "From A to Z",
};

const initialCounters = {
  PLC: 0,
  APM: 0,
  UNACKNOWLEDGED: 0,
  ACKNOWLEDGED: 0,
  BOOKMARK: 0,
  LOW: 0,
  MEDIUM: 0,
  HIGH: 0,
  URGENT: 0,
  NORMAL: 0,
};

const MINUTES = 60000;
const triggeredAlarms = [
  {
    timeTriggered: new Date(new Date().getTime() - MINUTES * 360).toISOString(),
    alarm: {
      plcAlarmInfo: { test: true },
      id: 1,
      priority: "URGENT",
      name: "Makeup Water Tank Pressure High High",
      saved: false,
    },
  },
  {
    timeTriggered: new Date(new Date().getTime() - MINUTES * 60).toISOString(),
    alarm: {
      plcAlarmInfo: { test: true },
      id: 2,
      priority: "MEDIUM",
      name: "Makeup Water Tank Level Low Low",
      saved: false,
    },
  },
  {
    timeTriggered: new Date(new Date().getTime() - MINUTES * 180).toISOString(),
    alarm: {
      plcAlarmInfo: { test: true },
      id: 2,
      priority: "LOW",
      name: "V-371 Level Low Low",
      saved: false,
    },
  },
];

export default function AlarmList() {
  const [selectedOptions, setSelectedOptions] = useState({
    sources: options.sources,
    statuses: options.statuses,
    severities: options.severities,
  });

  const [searchString, setSearchString] = useState("");
  const [listData, setListData] = useState([]);
  const [counters, setCounters] = useState(initialCounters);
  const [sortDirection, setSortDirection] = useState("TIME_DESCENDING");

  const compareAlarms = (a, b) => {
    switch (sortDirection) {
      case "TIME_ASCENDING":
        return dayjs(a.timeTriggered).diff(dayjs(b.timeTriggered));

      case "TIME_DESCENDING":
        return dayjs(b.timeTriggered).diff(dayjs(a.timeTriggered));

      case "NAME_ASCENDING":
        return a.name.localeCompare(b.name);

      case "NAME_DESCENDING":
        return b.name.localeCompare(a.name);

      default:
        return 0;
    }
  };

  // TODO: Get Alarms for product APM
  const loading = false;

  useEffect(() => {
    const newCounters = { ...initialCounters };

    triggeredAlarms.forEach((a) => {
      const type = a.alarm?.plcAlarmInfo ? "PLC" : "APM";
      const acknowledged = !!a.acknowledgedBy;
      const { saved } = a;
      const severity = a.alarm?.priority;

      newCounters[type]++;
      if (acknowledged) newCounters.ACKNOWLEDGED++;
      if (!acknowledged) newCounters.UNACKNOWLEDGED++;
      if (saved) newCounters.SAVED++;
      newCounters[severity]++;
    });

    const filteredList = triggeredAlarms
      .map((a) => ({
        id: a.id,
        timeTriggered: a.timeTriggered,
        name: a.alarm?.name,
        severity: a.alarm?.priority,
        acknowledged: !!a.acknowledgedBy,
        saved: a.saved,
        type: a.alarm?.plcAlarmInfo ? "PLC" : "APM",
      }))
      .filter(
        (a) =>
          selectedOptions.severities.includes(a.severity) && a.name.toLowerCase().includes(searchString.toLowerCase())
      )
      .sort(compareAlarms);

    setCounters(newCounters);
    setListData(filteredList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredAlarms, selectedOptions, searchString, sortDirection]);

  const handleChange = (type, value, checked) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [type]: checked ? [...prevState[type], value] : prevState[type].filter((item) => item !== value),
    }));
  };

  const handleCheckAllChange = (type, checked) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [type]: checked ? options[type] : [],
    }));
  };

  const isSelectedAll = (type) => selectedOptions[type].length === options[type].length;
  const isSelectedSome = (type) =>
    selectedOptions[type].length > 0 && selectedOptions[type].length < options[type].length;

  const renderCheckboxes = (type, label) => (
    <>
      <div onClick={() => handleCheckAllChange(type, !isSelectedAll(type))}>
        <div className={styles.title}>{`ALL ${label.toUpperCase()}`}</div>
        <div>
          <Checkbox indeterminate={isSelectedSome(type)} checked={isSelectedAll(type)} />
        </div>
      </div>
      {options[type].map((option) => (
        <div key={option} onClick={() => handleChange(type, option, !selectedOptions[type].includes(option))}>
          <div className={styles.optionContentLeft}>
            <div className={styles.icon}>{optionIcons[option]}</div>
            <div>{getDisplayName(option)}</div>
          </div>
          <div className={styles.checkboxContainer}>
            <div>{counters[option]}</div>
            <Checkbox checked={selectedOptions[type].includes(option)} />
          </div>
        </div>
      ))}
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        {renderCheckboxes("sources", "Alarm Sources")}
        <Divider className={styles.divider} />
        {renderCheckboxes("statuses", "Statuses")}
        <Divider className={styles.divider} />
        {renderCheckboxes("severities", "Severities")}
      </div>
      <div className={styles.alarmsContainer}>
        <div className={styles.searchBar}>
          <Input
            value={searchString}
            placeholder="Search"
            className={styles.searchInput}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <Button shape="circle" icon={<ReloadOutlined />} />
          {/* <Button shape="circle" icon={<ReloadOutlined />} onClick={() => refetch()} /> */}
          <Select
            className={styles.select}
            value={sortDirection}
            onChange={(newSort) => setSortDirection(newSort)}
            options={Object.entries(sortOptions).map(([sortOption, label]) => ({
              value: sortOption,
              label,
            }))}
          />
        </div>
        <List
          dataSource={listData}
          className={styles.list}
          loading={loading}
          renderItem={(item) => (
            <div className={`${styles.alarmContainer} ${item.acknowledged ? styles.acknowledged : ""}`}>
              <div className={styles.alarmContent}>
                <div className={styles.alarmContentLeft}>
                  <div className={styles.icon}>
                    {optionIcons[item.acknowledged ? "ACKNOWLEDGED_ALARM" : item.severity]}
                  </div>
                  <div
                    className={
                      styles[
                        `${
                          item.acknowledged
                            ? "acknowledgedBackground"
                            : `severity${capitalizeFirstLetter(item.severity)}Background`
                        }`
                      ]
                    }
                    style={{
                      height: "100%",
                      width: 10,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                  {item.name}
                </div>
                <div className={styles.alarmContentRight}>
                  <div className={styles.icon}>{optionIcons[item.type]}</div>
                  <div className={styles.alarmTime}>
                    {item.timeTriggered ? dayjs(item.timeTriggered).format("MM-DD-YYYY HH:mm:ss") : "not triggered"}
                  </div>
                  <Button
                    type="text"
                    size="small"
                    className={styles.icon}
                    style={{ cursor: "pointer", color: item.saved ? "#008b8a" : "#00000073" }}
                  >
                    {item.saved ? <TagIconFilled /> : <TagIcon />}
                  </Button>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}
