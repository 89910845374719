import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_INCIDENT_REPORT, GET_INCIDENT_REPORTS_BY_ORGANIZATION } from "graphql/apiService";

export default function useCreateIncidentReport() {
  const { currentOrganization } = useUser();

  const [createIncidentReportMutation, { loading, error }] = useMutation(CREATE_INCIDENT_REPORT, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: [
      { query: GET_INCIDENT_REPORTS_BY_ORGANIZATION, variables: { organizationSlug: currentOrganization } },
    ],
  });

  const createIncidentReport = async ({ input }) => {
    const res = await createIncidentReportMutation({ variables: { input } });
    return res.data.createIncidentReport;
  };

  return [createIncidentReport, { loading, error }];
}
