import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_USER_ORGANIZATION_PROFILE } from "graphql/apiService";

export default function useDeleteUserOrganizationProfile(organizationSlug) {
  const { currentOrganization } = useUser();

  const [deleteUserOrganizationProfileMutation, { loading, error }] = useMutation(DELETE_USER_ORGANIZATION_PROFILE, {
    variables: { organizationSlug: organizationSlug || currentOrganization },
    refetchQueries: ["GetUserOrganizationProfiles"],
  });

  const deleteUserOrganizationProfile = async ({ auth0Id }) => {
    const res = await deleteUserOrganizationProfileMutation({ variables: { auth0Id } });
    return res.data.deleteUserOrganizationProfile;
  };

  return [deleteUserOrganizationProfile, { loading, error }];
}
