import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Lottie from "lottie-react";
import { t, Trans } from "@lingui/macro";

import { Modal, Form, Input, Spin } from "antd";
import { EnterOutlined } from "@ant-design/icons";
import useCustomMessage from "hooks/useCustomMessage";

import animationData from "assets/document-processing.json";
import { useCreateHydrogenQuestion } from "hooks/apiService";

import styles from "./H2ToolsModal.module.less";

export default function H2ToolsModal({ visibility, close }) {
  const { message } = useCustomMessage();
  const [askedQuestion, setAskedQuestion] = useState(null);
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  const [createHydrogenQuestion, { loading, data }] = useCreateHydrogenQuestion();

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => inputRef.current.focus(), 100);
    }
  }, [inputRef, visibility]);

  const handleQuestionSubmission = async (formData) => {
    try {
      const { question } = formData;
      setAskedQuestion(question);
      await createHydrogenQuestion({ question });
      form.resetFields();
    } catch (err) {
      message.error(
        t({
          id: "h2-tools.submit-question-error",
          message: "Failed to get a response for your hydrogen question",
        })
      );
    }
  };

  const handleContainerClick = (e) => {
    if (loading || data?.createHydrogenQuestion) {
      return;
    }
    if (e.target.tagName !== "INPUT") {
      close();
    }
  };

  const clearErrors = () => {
    form.setFields([
      {
        name: "question",
        errors: [],
      },
    ]);
  };

  const handleValidationError = (e) => {
    if (e.errorFields?.[0]?.errors?.[0]) {
      message.error(e.errorFields?.[0]?.errors?.[0]);
    }
  };

  const Suffix = () =>
    loading ? (
      <Spin />
    ) : (
      <div className={styles.suffixContainer}>
        <Trans id="h2-tools.form.input-enter">Enter</Trans>
        <EnterOutlined />
      </div>
    );

  return (
    <Modal
      centered
      open={visibility}
      onCancel={close}
      width="min(100%, 1000px)"
      className={styles.modal}
      bodyStyle={{ background: "transparent" }}
      footer={null}
      closable={false}
    >
      <div aria-hidden onClick={handleContainerClick}>
        <Form
          form={form}
          onFinish={handleQuestionSubmission}
          onValuesChange={clearErrors}
          onFinishFailed={handleValidationError}
          validateTrigger="onSubmit"
        >
          <Form.Item
            name="question"
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "h2-tools.form.question-required",
                  message: "Question cannot be blank",
                }),
              },
            ]}
          >
            <Input
              size="large"
              className={styles.input}
              autoComplete="off"
              ref={inputRef}
              placeholder={t({
                id: "h2-tools.form.question-placeholder",
                message: "Ask the h2tools knowledge base...",
              })}
              suffix={<Suffix />}
            />
          </Form.Item>
          <div
            className={styles.bottomContainer}
            style={loading || data?.createHydrogenQuestion ? {} : { visibility: "hidden" }}
          >
            {loading ? (
              <div className={styles.animationContainer}>
                <Lottie animationData={animationData} loop />
              </div>
            ) : (
              <>
                <div className={styles.responseTitle}>{askedQuestion}</div>
                <div className={styles.answerContainer}>
                  <ReactMarkdown>{data?.createHydrogenQuestion}</ReactMarkdown>
                </div>
              </>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
}
