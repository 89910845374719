import React from "react";
import dayjs from "dayjs";
import ResponsivePlot from "components/ResponsivePlot";

export default function LineChartPreview({ data }) {
  const dates = Array.from({ length: data.data.length }, (_, i) =>
    dayjs().subtract(i, "days").format("YYYY-MM-DD HH:mm:ss")
  ).reverse();

  return (
    <ResponsivePlot
      data={[
        {
          x: dates,
          y: data.data,
          mode: "lines",
          fill: "tozeroy",
          type: "scatter",
          line: {
            color: "#008b8a",
          },
          fillcolor: "#e4f0e4",
        },
      ]}
      layout={{
        xaxis: {
          showgrid: false,
          zeroline: false,
          showline: false,
          showticklabels: false,
        },
        yaxis: {
          showgrid: false,
          zeroline: false,
          showline: false,
          showticklabels: false,
        },
        margin: {
          l: 2,
          r: 2,
          b: 0,
          t: 0,
          pad: 2,
        },
      }}
      config={{ displayModeBar: false, responsive: true }}
    />
  );
}
