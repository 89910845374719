/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import PlotlyBase from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import { SizeMe } from "react-sizeme";

import styles from "./PieChart.module.less";

const Plotly = createPlotlyComponent(PlotlyBase);

export default function PieChart({ data, title, className }) {
  const defaultData = [
    {
      type: "pie",
      textinfo: "label+percent",
      textposition: "inside",
      ...data,
    },
  ];

  const layout = {
    showlegend: false,
    margin: {
      t: 15,
      r: 15,
      b: 0,
      l: 15,
    },
  };

  const config = {
    displaylogo: false,
  };

  const paddingHorizontal = 0;
  const paddingVertical = 20;

  return (
    <div className={`${styles.container} ${className && className}`}>
      {title && <div className={styles.title}>{title}</div>}
      <SizeMe monitorHeight>
        {({ size: containerSize }) => (
          <div className={styles.plot}>
            <Plotly
              data={defaultData}
              layout={{
                ...layout,
                width: containerSize.width - paddingHorizontal,
                height: containerSize.height - paddingVertical,
              }}
              config={config}
            />
          </div>
        )}
      </SizeMe>
    </div>
  );
}
