import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useUser } from "context/UserContext";
import { GET_ALARM } from "graphql/apiService";

export default function useGetAlarm() {
  const { currentOrganization } = useUser();
  const [data, setData] = useState(null);
  const [getAlarmQuery, { loading, error }] = useLazyQuery(GET_ALARM, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const getAlarm = async ({ id }) => {
    const res = await getAlarmQuery({
      variables: {
        id,
      },
    });
    setData(res.data?.getAlarm);
    return res.data?.getAlarm;
  };

  return [getAlarm, { data, loading, error }];
}
