import React, { useRef, useEffect } from "react";
import { Modal, Card, Button } from "antd";
import { t, Trans } from "@lingui/macro";

import SignatureCanvas from "react-signature-canvas";
import styles from "./SignatureModal.module.less";

function SignatureModal({ open, setOpen, onSignatureSave, currentSignatureURL }) {
  const canvasRef = useRef(null);

  const onCancel = () => {
    canvasRef.current.clear();
    setOpen(false);
  };

  const ModalFooter = () => (
    <div className={styles.footer}>
      <div>
        <Button
          key="clear"
          onClick={() => {
            canvasRef.current.clear();
          }}
        >
          <Trans id="signature-modal.clear">Clear</Trans>
        </Button>
      </div>
      <div>
        <Button key="cancel" onClick={onCancel}>
          <Trans id="signature-modal.cancel">Cancel</Trans>
        </Button>
        <Button
          key="ok"
          onClick={() => {
            const dataURL = canvasRef.current.toDataURL();
            const isEmpty = canvasRef.current.isEmpty();
            onSignatureSave({ dataURL, isEmpty });
            onCancel();
          }}
          type="primary"
        >
          <Trans id="signature-modal.ok">Save</Trans>
        </Button>
      </div>
    </div>
  );

  useEffect(() => {
    if (open && currentSignatureURL && canvasRef?.current) {
      canvasRef.current.fromDataURL(currentSignatureURL);
    }
  }, [currentSignatureURL, open]);

  return (
    <Modal
      title={t({ id: "signature-modal.title", message: "Add Signature" })}
      open={open}
      onCancel={onCancel}
      width="min(600px, 100%)"
      centered
      footer={<ModalFooter />}
    >
      <Card bodyStyle={{ padding: 0, width: "550px", height: "180px" }}>
        <SignatureCanvas canvasProps={{ width: 550, height: 180 }} ref={canvasRef} clearOnResize={false} />
      </Card>
    </Modal>
  );
}

export default SignatureModal;
