import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import styles from "./BrowserWarningModal.module.less";

function BrowserWarningModal({ visible, setVisible }) {
  const message = (
    <Trans id="browser-warning.text">
      You are using an unsupported browser, some features may not work as intended.
    </Trans>
  );

  return (
    <Modal
      title={
        <div className={styles.titleContainer}>
          <ExclamationCircleOutlined className={styles.icon} />{" "}
          <Trans id="browser-warning.title">
            <span className={styles.title}>Warning</span>
          </Trans>
        </div>
      }
      open={visible}
      cancelButtonProps={{ style: { display: "none" } }}
      centered
      onCancel={() => setVisible(false)}
      onOk={() => {
        setVisible(false);
      }}
    >
      <div className={styles.description}>{message}</div>
    </Modal>
  );
}

export default BrowserWarningModal;
