import { useUser } from "context/UserContext";
import { GET_ABLY_TOKEN_REQUEST } from "graphql/apiService";
import { useLazyQuery } from "@apollo/client";

export default function useGetAblyTokenRequest() {
  const { currentOrganization } = useUser();

  const [getAblyTokenRequestQuery, { data, loading, error }] = useLazyQuery(GET_ABLY_TOKEN_REQUEST, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const tokenRequest = data?.getAblyTokenRequest;

  const getAblyTokenRequest = async () => {
    const res = await getAblyTokenRequestQuery();
    return res.data?.getAblyTokenRequest;
  };

  return [getAblyTokenRequest, { tokenRequest, loading, error }];
}
