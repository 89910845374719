import { useUser } from "context/UserContext";
import { GET_ANNOTATION } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useAnnotation({ id }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_ANNOTATION, {
    variables: {
      organizationSlug: currentOrganization,
      id,
    },
  });

  const annotation = data?.getAnnotation;

  return { annotation, loading, error };
}
