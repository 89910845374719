import React from "react";
import { t } from "@lingui/macro";
import { typeOptions as typeOptionsFn } from "containers/AssetMap/helpers/mapping";

import AssetProperty from "../AssetProperty";

import styles from "./Metadata.module.less";

const getSignal = (signal) => (signal ? "YES" : "NO");

export default function Metadata({ asset }) {
  const typeOptions = typeOptionsFn();
  return (
    <div className={styles.metadataContainer}>
      <div className={styles.metadata}>
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.asset-slug", message: "Asset Id" })}
          value={asset.slug || "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.asset-type", message: "Asset Type" })}
          value={typeOptions.find((o) => o.value === asset?.type)?.name}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.process", message: "Process" })}
          value={asset.process || "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.description", message: "Description" })}
          value={asset.description || "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.pidNumber", message: "P&ID Number" })}
          value={asset.pidNumber || "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.pidDescription", message: "P&ID Description" })}
          value={asset.pidDescription || "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.skidNumber", message: "Skid Number" })}
          value={asset.skidNumber || "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.signal", message: "Signal" })}
          value={asset.signal !== undefined ? getSignal(asset.signal) : "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.serial-number", message: "Serial number" })}
          value={asset.serialNumber || "---"}
        />
        <AssetProperty
          property={t({ id: "asset-map.asset.meta.part-numberl", message: "Part number" })}
          value={asset.partNumber || "---"}
        />
      </div>
    </div>
  );
}
