export const getOrgFromSubdomain = () => {
  const domain = process.env.REACT_APP_DOMAIN || "localhost";
  const parts = window.location.hostname.split(`.${domain}`);
  if (parts.length > 1 && parts[0] !== "www") {
    return parts[0];
  }
  return null;
};

export const getNewOrgUrl = (newOrg) => {
  const org = getOrgFromSubdomain();
  return window.location.origin.replace(org, newOrg);
};

export const getNewOrgUrlForOrgSwitch = (newOrg) => {
  const newUrl = getNewOrgUrl(newOrg);
  return `${newUrl}/login?orgSwitch=true`;
};

export const handleLogout = async (auth0Logout) => {
  localStorage.removeItem("currentOrgId");
  await auth0Logout({ logoutParams: { returnTo: `${window.location.origin}/login?grafanaLogout=true` } });
};

export const isProductApm = () => {
  const org = getOrgFromSubdomain();
  return org === "customer" || org === "service";
};

export const isCustomerApm = () => {
  const org = getOrgFromSubdomain();
  return org === "customer";
};

export const isServiceApm = () => {
  const org = getOrgFromSubdomain();
  return org === "service";
};
