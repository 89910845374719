import React, { useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

import {
  useEquipment,
  useDataPartnersReceiving,
  useSetSharedEquipmentMeasures,
  useSharedEquipmentRecipients,
} from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Button, Card as AntdCard, Select, Steps, Table } from "antd";
import { ArrowLeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import Card from "components/Card";
import Note from "components/Note";

import MeasureSelection from "./components/MeasureSelection";
import TableColumns from "./components/TableColumns";
import { listStrings, pluralize } from "./helpers/pluralize.helpers";

import styles from "./AssetShare.module.less";

export default function AssetShare() {
  const { assetId } = useParams();
  const navigate = useNavigate();
  const { message } = useCustomMessage();

  const { equipment: asset, loading } = useEquipment({ id: assetId });
  const { dataPartners } = useDataPartnersReceiving();
  const { sharedEquipmentRecipients } = useSharedEquipmentRecipients({ equipmentId: assetId });
  const [shareEquipmentMeasures, { loading: shareLoading }] = useSetSharedEquipmentMeasures({ equipmentId: assetId });

  const availableOrganizations = useMemo(
    () =>
      dataPartners
        .filter((grant) => grant.status === "ACTIVE")
        .map(({ principal }) => principal.slug)
        .filter((orgSlug) => !sharedEquipmentRecipients.includes(orgSlug)),
    [dataPartners, sharedEquipmentRecipients]
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [shareAllMeasures, setShareAllMeasures] = useState(false);

  const handleOrganizationSelection = (selection) => {
    setSelectedOrganizations(availableOrganizations.filter((orgSlug) => selection.includes(orgSlug)));
  };

  const handleNext = () => {
    if (currentStep <= 1) {
      setCurrentStep(currentStep + 1);
    }
  };
  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleCancel = () => {
    navigate(`/assets/${assetId}`);
  };

  const handleShare = async () => {
    try {
      await shareEquipmentMeasures({
        measureIds: selectedMeasures.map((m) => m.id),
        receivingOrganizationSlugs: selectedOrganizations,
        equipmentId: assetId,
        allMeasures: shareAllMeasures,
      });
      message.success(
        t({
          id: "asset-map.asset.sharing.share-success",
          message: "Successfully shared the selected measures",
        })
      );
      navigate(`/assets/${assetId}`);
    } catch (err) {
      message.error(
        t({
          id: "asset-map.asset.sharing.share-fail",
          message: "Failed to share the selected measures",
        })
      );
    }
  };

  const measures = useMemo(() => asset?.measures?.slice().sort((a, b) => a.slug.localeCompare(b.slug)) || [], [asset]);

  const tableColumns = TableColumns();

  const stepsContent = [
    {
      title: t({
        id: "asset-map.asset.sharing.step-indicator.organizations",
        message: "Select Organizations",
      }),
      content: (
        <div className={styles.stepOrganizations}>
          <p className={styles.paragraph}>
            <Trans id="asset-map.asset.sharing.step.organizations.paragraph">
              Select the organizations you would like to share with
            </Trans>
            <span className={styles.required}>*</span>
          </p>
          <Select
            mode="multiple"
            showArrow
            allowClear
            className={styles.organizationSelect}
            options={availableOrganizations.map((orgSlug) => ({ value: orgSlug, label: orgSlug }))}
            value={selectedOrganizations}
            onChange={handleOrganizationSelection}
          />
          <Note icon={<ExclamationCircleOutlined style={{ color: "#FAAD14" }} />}>
            <Trans id="asset-map.asset.sharing.step.organizations.note">
              Only organizations with an <Link to="/data-partners">active data partnership</Link> can be selected.
            </Trans>
          </Note>
          <div className={styles.organizationNext}>
            <Button onClick={handleCancel}>
              <Trans id="asset-map.asset.sharing.step.organizations.cancel-button">Cancel</Trans>
            </Button>
            <Button type="primary" onClick={handleNext} disabled={selectedOrganizations.length < 1}>
              <Trans id="asset-map.asset.sharing.step.organizations.next-button">Next</Trans>
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: t({
        id: "asset-map.asset.sharing.step-indicator.measures",
        message: "Select Measures",
      }),
      content: (
        <div className={styles.stepMeasures}>
          <p className={styles.paragraph}>
            <Trans id="asset-map.asset.sharing.step.measures.paragraph">Select measures you would like to share</Trans>{" "}
            <span className={styles.required}>*</span>
          </p>
          <MeasureSelection
            measures={measures}
            selectedMeasures={selectedMeasures}
            setSelectedMeasures={setSelectedMeasures}
            shareAllMeasures={shareAllMeasures}
            setShareAllMeasures={setShareAllMeasures}
          />
        </div>
      ),
    },
    {
      title: t({
        id: "equipment-management.equipment.sharing.step-indicator.summary",
        message: "Share",
      }),
      content: (
        <div className={styles.stepSummary}>
          <p className={styles.paragraph}>
            <Trans id="equipment-management.equipment.sharing.step.summary.paragraph.sharing-with">
              You are sharing with:
            </Trans>
            <span className={styles.sharingCompanies}>{selectedOrganizations.join("; ")}</span>
          </p>
          <p className={styles.paragraph}>
            <Trans id="equipment-management.equipment.sharing.step.summary.paragraph.shared-measures">
              Shared Measures{shareAllMeasures ? ":" : ""}
            </Trans>

            {shareAllMeasures && (
              <Trans id="equipment-management.equipment.sharing.step.summary.paragraph.all">
                <span className={styles.allMeasuresText}>all</span>
              </Trans>
            )}
          </p>
          {shareAllMeasures || (
            <Table
              columns={tableColumns}
              dataSource={selectedMeasures?.map((measure) => ({ ...measure, key: measure.id }))}
              pagination={{
                showSizeChanger: true,
              }}
            />
          )}
        </div>
      ),
    },
  ];

  const stepDrawer = [
    null,
    <div
      className={`${styles.drawer} ${(selectedMeasures.length > 0 || shareAllMeasures) && styles.open}`}
      key="step-drawer-1"
    >
      <p className={styles.paragraph}>
        {shareAllMeasures ? (
          <Trans id="equipment-management.equipment.sharing.step.measures.drawer.entire-equipment">
            Selected all {asset.name} measures
          </Trans>
        ) : (
          <Trans id="equipment-management.equipment.sharing.step.measures.drawer.selected-measure">
            Selected {pluralize(selectedMeasures.length, "measure")}
          </Trans>
        )}
      </p>
      <div className={styles.buttonContainer}>
        <Button onClick={handlePrevious}>Previous</Button>
        <Button type="primary" onClick={handleNext}>
          <Trans id="equipment-management.equipment.sharing.step.summary.drawer.next-button">Next</Trans>
        </Button>
      </div>
    </div>,
    <div className={`${styles.drawer} ${styles.open}`} key="step-drawer-2">
      {shareAllMeasures ? (
        <p className={styles.paragraph}>
          <Trans id="equipment-management.equipment.sharing.step.summary-all">
            Please Click “Share” to share all {asset.name} measures with
          </Trans>{" "}
          {listStrings(selectedOrganizations)}
        </p>
      ) : (
        <p className={styles.paragraph}>
          <Trans id="equipment-management.equipment.sharing.step.summary-selected">
            Please Click “Share” to share the selected measures with
          </Trans>{" "}
          {listStrings(selectedOrganizations)}
        </p>
      )}
      <div className={styles.buttonContainer}>
        <Button onClick={handlePrevious}>Previous</Button>
        <Button type="primary" loading={shareLoading} onClick={handleShare}>
          <Trans id="equipment-management.equipment.sharing.step.summary.drawer.share-button">Share</Trans>
        </Button>
      </div>
    </div>,
  ];

  if (loading) {
    return <AntdCard loading />;
  }

  return (
    <>
      <Card>
        <div className={styles.backActionContainer}>
          {currentStep >= 1 && (
            <Button icon={<ArrowLeftOutlined />} onClick={handlePrevious}>
              <Trans id="equipment-management.equipment.sharing.back-button">Back</Trans>
            </Button>
          )}
          {currentStep < 1 && (
            <Link to={{ pathname: `/assets/${assetId}` }}>
              <Button icon={<ArrowLeftOutlined />}>
                <Trans id="equipment-management.equipment.sharing.back-button">Back</Trans>
              </Button>
            </Link>
          )}
        </div>
        <div className={styles.container}>
          <h2>Share “{asset.name}“</h2>
          <div className={styles.stepContainer}>
            <Steps
              current={currentStep}
              className={styles.stepIndicator}
              items={stepsContent.map((step) => ({ title: step.title }))}
            />
            {stepsContent[currentStep].content}
          </div>
        </div>
      </Card>
      {stepDrawer[currentStep]}
    </>
  );
}
