import React from "react";
import { t } from "@lingui/macro";

import { ReactComponent as FolderIcon } from "assets/folder-icon.svg";
import CompressorMapIcon from "assets/compressor-map-icon.svg";
import { ReactComponent as ElectrolyzerIcon } from "assets/assetTypes/electrolyzer.svg";
import { ReactComponent as CompressorIcon } from "assets/assetTypes/compressor.svg";
import { ReactComponent as HeadExchangerIcon } from "assets/assetTypes/heat-exchanger.svg";
import { ReactComponent as AssetIcon } from "assets/assetTypes/asset.svg";

export const typeOptions = () => [
  {
    name: t({
      id: "asset-map.asset.mapping.unknown",
      message: "Unknown",
    }),
    value: "UNKNOWN",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.plant",
      message: "Plant",
    }),
    value: "PLANT",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.compressor",
      message: "Compressor",
    }),
    value: "COMPRESSOR",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.heat-exchanger",
      message: "Heat Exchanger",
    }),
    value: "HEAT_EXCHANGER",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.stack",
      message: "Stack",
    }),
    value: "STACK",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.separator",
      message: "Separator",
    }),
    value: "SEPARATOR",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.gas-sampling-system",
      message: "Gas Sampling System",
    }),
    value: "GAS_SAMPLING_SYSTEM",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.polisher",
      message: "Polisher",
    }),
    value: "POLISHER",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.pump",
      message: "Pump",
    }),
    value: "PUMP",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.tank",
      message: "Tank",
    }),
    value: "TANK",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.gas-cooler",
      message: "Gas Cooler",
    }),
    value: "GAS_COOLER",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.dry-cooler",
      message: "Dry Cooler",
    }),
    value: "DRY_COOLER",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.ro-di",
      message: "RO/DI",
    }),
    value: "RO_DI",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.ventilation",
      message: "Ventilation",
    }),
    value: "VENTILATION",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.power-supply",
      message: "Power Supply",
    }),
    value: "POWER_SUPPLY",
  },
  {
    name: t({
      id: "asset-map.asset.mapping.bop",
      message: "BOP",
    }),
    value: "BOP",
  },
];

export const icons = {
  UNKNOWN: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  PLANT: {
    map: CompressorMapIcon,
    react: <ElectrolyzerIcon style={{ color: "#008b8a" }} />,
  },
  COMPRESSOR: {
    map: CompressorMapIcon,
    react: <CompressorIcon style={{ color: "#008b8a" }} />,
  },
  HEAT_EXCHANGER: {
    map: CompressorMapIcon,
    react: <HeadExchangerIcon style={{ color: "#008b8a" }} />,
  },
  STACK: {
    map: CompressorMapIcon,
    react: <ElectrolyzerIcon style={{ color: "#008b8a" }} />,
  },
  SEPARATOR: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  GAS_SAMPLING_SYSTEM: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  POLISHER: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  PUMP: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  TANK: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  GAS_COOLER: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  DRY_COOLER: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  RO_DI: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  VENTILATION: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  POWER_SUPPLY: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  BOP: {
    map: CompressorMapIcon,
    react: <AssetIcon style={{ color: "#008b8a" }} />,
  },
  FOLDER: {
    react: <FolderIcon style={{ color: "#008b8a" }} />,
  },
};
