import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { SET_USER_ROLES } from "graphql/apiService";

export default function useSetUserRoles(organizationSlug) {
  const { currentOrganization } = useUser();

  const [setUserRoleMutation, { loading, error }] = useMutation(SET_USER_ROLES, {
    variables: {
      organizationSlug: organizationSlug || currentOrganization,
    },
    refetchQueries: ["GetUserRoles"],
  });

  const setUserRoles = async ({ userId, roleSlugs }) => {
    const res = await setUserRoleMutation({ variables: { userId, roleSlugs } });
    return res.data.setUserRoles;
  };

  return [setUserRoles, { loading, error }];
}
