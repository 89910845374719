import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import _ from "lodash";
import { t } from "@lingui/macro";

import { useGetTelemetryData } from "hooks/databaseService";
import useCustomMessage from "hooks/useCustomMessage";

import { useLazyQuery } from "@apollo/client";
import { useUser } from "context/UserContext";

import { GET_EVENTS } from "graphql/databaseService";
import { intervalToAbsoluteRange } from "utils/time-utils";
import {
  equalTraces,
  generateEventFilters,
  generateMeasureFilters,
  generateThresholds,
  generateTraces,
} from "./helpers/panel.helpers";

import Plot from "../Plot";
import populateOmittedMeasures from "./populateOmittedMeasures";

const isBetween = require("dayjs/plugin/isBetween");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

export default function Panel({
  panel,
  pollInterval = 0,
  refetch,
  annotations = [],
  showResolvedAnnotations,
  onLoadingChange,
  time,
  setStartTime,
  setEndTime,
  setTimeMode,
  className,
  style,
  showLegend = false,
  rangeSlider = false,
  legendConfig,
  width,
  height,
  onClick,
  onClickAnnotation,
  onRelayout: onRelayoutParent,
  onZoomChange,
}) {
  /**
   * refetch is used to init a refetch of data, that would be used when we have
   * the refresher on panel, the value of refetch just need to change, so
   * switching from true to false would initiate the refetch.
   * pollInterval, if 0 it will not poll for new data, but if we pass in 5000
   * (pollInterval takes milliseconds) it will fetch for new data every 5 seconds.
   * The onLoadingChange function will get passed the panelID and the current
   * state of loading as a callback function to the parent component.
   */

  const { currentOrganization: organizationSlug } = useUser();

  const [panelTime, setPanelTime] = useState({});
  const [traces, setTraces] = useState([]);
  const [thresholds, setThresholds] = useState([]);
  const [lastFetchTo, setLastFetchTo] = useState(undefined);
  const [refetching, setRefetching] = useState(0);
  const [zoomRangeStart, setZoomRangeStart] = useState(undefined);
  const [zoomRangeEnd, setZoomRangeEnd] = useState(undefined);
  const [prevTime, setPrevTime] = useState(undefined);

  const [getTelemetryData, { loading, error }] = useGetTelemetryData();
  const { message } = useCustomMessage();

  const { customAxes = [] } = panel;
  useEffect(() => {
    if (error) {
      message.error({
        key: "telemetry-no-data",
        content: t({
          id: "panel.errors.no-data",
          message: "Failed to fetch data for panel",
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (onZoomChange) {
      onZoomChange(zoomRangeStart, zoomRangeEnd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRangeStart, zoomRangeEnd]);

  const [getEventData, { data: eventData }] = useLazyQuery(GET_EVENTS, {
    context: {
      clientName: "database-service",
    },
    variables: {
      organizationSlug,
      eventFilters: generateEventFilters({ panel }),
      from: panelTime.from,
      to: panelTime.to,
    },
  });

  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(eventData?.events);
  }, [eventData?.events]);

  const fetchZoomData = useMemo(
    () =>
      _.debounce(async ({ start, end }) => {
        const measureFilters = generateMeasureFilters({ panel });
        if (!measureFilters?.length) {
          return;
        }
        const data = await getTelemetryData({
          measureFilters,
          from: start,
          to: end,
        });

        if (data?.time?.length) {
          const zoomTraces = generateTraces({ telemetries: data, panel });
          spliceZoomTraces({ zoomTraces, zoomStart: start, zoomEnd: end });
        }
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panel]
  );

  const timeSetting = useMemo(() => {
    if (time) {
      return time.type === "REALTIME"
        ? intervalToAbsoluteRange(time.interval)
        : { from: dayjs(time.from).toISOString(), to: dayjs(time.to).toISOString() };
    }
    return panel.time.type === "REALTIME"
      ? intervalToAbsoluteRange(panel.time?.interval)
      : {
          from: panel.time.from,
          to: panel.time.to,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time?.interval, time?.from, time?.to, time?.type]);

  const onRelayout = (e) => {
    if (e["xaxis.range"]) {
      const zoomStart = dayjs(e["xaxis.range"][0]).toISOString();
      const zoomEnd = dayjs(e["xaxis.range"][1]).toISOString();
      setZoomRangeStart(zoomStart);
      setZoomRangeEnd(zoomEnd);
      fetchZoomData({ start: zoomStart, end: zoomEnd });
    } else if (e["xaxis.range[0]"]) {
      const zoomStart = dayjs(e["xaxis.range[0]"]).toISOString();
      const zoomEnd = dayjs(e["xaxis.range[1]"]).toISOString();
      setZoomRangeStart(zoomStart);
      setZoomRangeEnd(zoomEnd);
      fetchZoomData({ start: zoomStart, end: zoomEnd });
    }
    if (e["xaxis.autorange"]) {
      const timeDiff = dayjs(timeSetting.to).diff(timeSetting.from);
      const endRange = traces.length > 0 ? dayjs(traces[0].x.slice(-1)[0]).toISOString() : timeSetting.from;
      const startRange = dayjs(endRange).subtract(timeDiff, "ms").toISOString();
      setZoomRangeStart(startRange);
      setZoomRangeEnd(endRange);
    }
    if (onRelayoutParent) {
      onRelayoutParent(e);
    }
  };

  const fetchNewData = async ({ from, to }) => {
    const measureFilters = generateMeasureFilters({ panel });
    if (!measureFilters?.length) {
      return;
    }
    let telemetryData = await getTelemetryData({
      measureFilters,
      from,
      to,
    });
    if (telemetryData) {
      telemetryData = populateOmittedMeasures({
        telemetryData,
        measureFilters,
      });
      handleNewData(telemetryData);
    }
  };

  const handleGetEventData = (options) => {
    if (!panel?.events?.length) {
      return;
    }
    getEventData(options);
  };

  useEffect(() => {
    if (panelTime?.from !== prevTime?.from || panelTime?.to !== prevTime?.to) {
      handleGetEventData();
      fetchNewData({ from: panelTime.from, to: panelTime.to });
      setPrevTime(panelTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelTime?.from, panelTime?.to]);

  useEffect(() => {
    if (panelTime.from && panelTime.to) {
      handleGetEventData();
      fetchNewData({ from: panelTime.from, to: panelTime.to });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panel.measures]);

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange({ loading, panelID: panel.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setThresholds(generateThresholds(panel));
  }, [panel]);

  const handleNewData = useCallback(
    (newData) => {
      const newTraces = generateTraces({ telemetries: newData, panel });
      const match =
        !!traces.length &&
        equalTraces(traces.length > 0 ? traces[0].x : [], newTraces.length > 0 ? newTraces[0].x : []);
      if (!lastFetchTo || newTraces.length !== traces.length) {
        setTraces(newTraces);
      } else if (!match) {
        const currentTimeWindow = intervalToAbsoluteRange(time.interval);
        setTraces((oldTraces) =>
          oldTraces.map((oldTrace) => {
            // Filter out data outside of the current time window
            const filteredX = oldTrace.x.filter((x) => dayjs(x).isSameOrAfter(dayjs(currentTimeWindow.from)));
            const filteredY = oldTrace.y.slice(oldTrace.x.length - filteredX.length);

            return {
              ...oldTrace,
              x: [
                ...filteredX,
                ...(newTraces.find((newTrace) => newTrace.measure?.id === oldTrace.measure?.id)?.x || []),
              ],
              y: [
                ...filteredY,
                ...(newTraces.find((newTrace) => newTrace.measure?.id === oldTrace.measure?.id)?.y || []),
              ],
            };
          })
        );
        if (
          dayjs(zoomRangeEnd) >= dayjs(traces.length > 0 ? traces[0].x[traces[0].x.length - 1] : new Date()) &&
          pollInterval
        ) {
          const newRange = intervalToAbsoluteRange(time?.interval || panel.time.interval);
          const startRange = dayjs(newRange.to).subtract(dayjs(zoomRangeEnd).diff(zoomRangeStart), "ms").toISOString();
          setZoomRangeStart(startRange);
          setZoomRangeEnd(dayjs(newRange.to).toISOString());
        }
      }
      setLastFetchTo(newData.time.slice(-1)[0]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastFetchTo, zoomRangeStart, zoomRangeEnd, panel.measures, traces?.length]
  );

  useEffect(() => {
    if (refetching > 0 && (panel.time.type === "REALTIME" || time?.type === "REALTIME")) {
      fetchNewData({ from: lastFetchTo || timeSetting.from, to: new Date().toISOString() });
      handleGetEventData({
        variables: {
          from: timeSetting.from,
          to: new Date().toISOString(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetching]);

  const spliceZoomTraces = ({ zoomTraces, zoomStart, zoomEnd }) => {
    setTraces((oldTraces) =>
      oldTraces.map((oldTrace) => {
        const newX = [];
        const newY = [];

        const zoomStartValue = dayjs(zoomStart).valueOf();
        const zoomEndValue = dayjs(zoomEnd).valueOf();

        const zoomTrace = zoomTraces.find((trace) => trace.measure.id === oldTrace.measure.id);

        let splicedZoomData = false;
        oldTrace.x.forEach((timeString, index) => {
          const timeValue = dayjs(timeString).valueOf();
          if (timeValue < zoomStartValue || timeValue > zoomEndValue) {
            newX.push(timeString);
            newY.push(oldTrace.y[index]);
          } else if (!splicedZoomData) {
            if (zoomTrace) {
              newX.push(...zoomTrace.x);
              newY.push(...zoomTrace.y);
            }
            splicedZoomData = true;
          }
        });

        return {
          ...oldTrace,
          x: newX,
          y: newY,
        };
      })
    );
  };

  useEffect(() => {
    setLastFetchTo(undefined);
    setPanelTime(timeSetting);
    setZoomRangeStart(dayjs(timeSetting.from).toISOString());
    setZoomRangeEnd(dayjs(timeSetting.to).toISOString());
  }, [timeSetting]);

  useEffect(() => {
    setRefetching((current) => current + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    let interval;
    if (pollInterval > 0) {
      interval = setInterval(() => {
        setRefetching((current) => current + 1);
      }, pollInterval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [pollInterval]);

  return useMemo(
    () => (
      <Plot
        className={className}
        name={panel.name}
        traces={traces}
        thresholds={thresholds}
        annotations={annotations.filter((a) => dayjs(a.time).isBetween(zoomRangeStart, zoomRangeEnd))}
        showResolvedAnnotations={showResolvedAnnotations}
        events={events}
        time={panelTime}
        zoomRange={{
          from: zoomRangeStart,
          to: zoomRangeEnd,
        }}
        style={style}
        showLegend={showLegend}
        rangeSlider={rangeSlider}
        legendConfig={legendConfig}
        width={width}
        height={height}
        onClick={onClick}
        onClickAnnotation={onClickAnnotation}
        onRelayout={onRelayout}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        setTimeMode={setTimeMode}
        customAxes={customAxes}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      traces,
      thresholds,
      annotations,
      events,
      panelTime,
      zoomRangeStart,
      zoomRangeEnd,
      width,
      height,
      className,
      showResolvedAnnotations,
      showLegend,
    ]
  );
}
