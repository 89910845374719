import React from "react";
import { Link } from "react-router-dom";
import { UnorderedListOutlined } from "@ant-design/icons";
import grafanaLogoUrl from "assets/grafana-logo.svg";
import supersetLogoUrl from "assets/superset-logo.svg";
import { ReactComponent as QuickboardIcon } from "assets/dashboard.svg";
import { useUser } from "context/UserContext";

import { Menu } from "antd";

import styles from "./SideMenu.module.less";

function SideMenu({ dashboardMode }) {
  const { features } = useUser();

  const menuItems = [
    { key: "all", name: "All", icon: UnorderedListOutlined, color: "#333333" },
    { key: "grafana", name: "Grafana", icon: () => <img src={grafanaLogoUrl} alt="grafana" /> },
    features.includes("superset") && {
      key: "superset",
      name: "Superset",
      icon: () => <img src={supersetLogoUrl} alt="superset" />,
    },
    { key: "quickboard", name: "Quickboard", icon: QuickboardIcon, color: "#6AB8FD" },
  ]
    .filter(Boolean)
    .map(({ name, key, color, icon: Icon, ...rest }) => ({
      ...rest,
      key,
      label: (
        <Link to={{ pathname: `/dashboards?mode=${key}` }}>
          <div className={styles.titleContainer}>{name}</div>{" "}
        </Link>
      ),
      icon: (
        <div className={styles.iconContainer} style={{ color }}>
          <Icon className={styles.icon} />
        </div>
      ),
      className: `${styles.menuItem} ${key === dashboardMode ? styles.selected : ""}`,
    }));

  return (
    <Menu
      className={`${styles.container}`}
      key="Menu"
      mode="inline"
      inlineIndent={0}
      selectedKeys={[dashboardMode]}
      style={{
        width: "100%",
      }}
      items={menuItems}
    />
  );
}

export default SideMenu;
