import React from "react";
import { Card } from "antd";

import { useParams } from "react-router-dom";
import { useEquipment } from "hooks/apiService";
import { getOrgFromSubdomain } from "utils/auth-utils";

import AssetDisplay from "../AssetDisplay";
import { findSubTree } from "../helpers/nestedObject";

const org = getOrgFromSubdomain();

function Asset({ assetList, assetIdMap, assetTree }) {
  const { assetId } = useParams();
  const subTree = findSubTree(assetTree, assetId);

  const { equipment: asset, loading } = useEquipment({ id: assetId });

  if (loading) {
    return <Card loading />;
  }

  return (
    asset && (
      <AssetDisplay
        asset={asset}
        assetIdMap={assetIdMap}
        assetTree={subTree ? [subTree] : null}
        assetList={assetList}
        isShared={org !== asset?.organizationSlug}
      />
    )
  );
}

export default Asset;
