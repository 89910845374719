/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import { t, Trans } from "@lingui/macro";
import { useLazyQuery } from "@apollo/client";

import usePanelTime from "hooks/usePanelTime";
import { useEquipmentList, useGetMeasuresByEquipment } from "hooks/apiService";
import useAssetTree from "hooks/useAssetTree";
import { useUser } from "context/UserContext";

import { ANNOTATION_TYPES } from "config/plotConfig";
import { GET_EVENT_SLUGS } from "graphql/databaseService";

import { ReactComponent as TrendIcon } from "assets/trend-icon.svg";
import { ReactComponent as EventIcon } from "assets/event-icon.svg";
import { ArrowLeftOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, Table, Tabs, Skeleton, TreeSelect, Tooltip } from "antd";

import PanelPreview from "components/PanelPreview";
import PanelSettings from "components/PanelSettings";
import Card from "../Card";

import styles from "./PanelForm.module.less";

export default function PanelForm({ isNew = false, panel, panelLoading, onSave }) {
  const { panelId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { assetTree } = useAssetTree();

  const titleInput = useRef(null);
  const { equipmentList: availableEquipments } = useEquipmentList();
  const [getMeasuresByEquipment] = useGetMeasuresByEquipment();

  const { currentOrganization: organizationSlug } = useUser();
  const [getEventSlugsByEquipment] = useLazyQuery(GET_EVENT_SLUGS, {
    context: {
      clientName: "database-service",
    },
    variables: {
      organizationSlug,
    },
  });

  const [panelEquipment, setPanelEquipment] = useState({});
  const [measures, setMeasures] = useState({});
  const [events, setEvents] = useState({});
  const [loadingSources, setLoadingSources] = useState({});
  const [selectedMeasures, setSelectedMeasures] = useState({});
  const [selectedEvents, setSelectedEvents] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [customAxes, setCustomAxes] = useState([]);

  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    timeInterval,
    setTimeInterval,
    timeMode,
    setTimeMode,
    pollInterval,
    setPollInterval,
  } = usePanelTime({ panel });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const currentMeasuresList = useMemo(() => Object.values(selectedMeasures).flat(), [selectedMeasures]);
  const currentEventList = useMemo(() => Object.values(selectedEvents).flat(), [selectedEvents]);

  const fullMeasureList = useMemo(
    () =>
      Object.values(measures)
        .flat()
        .filter((m) => currentMeasuresList.some((mId) => mId === m.id)),
    [measures, currentMeasuresList]
  );

  const fullEventList = useMemo(
    () =>
      Object.values(events)
        .flat()
        .filter((e) => currentEventList.some((eventSlug) => eventSlug === `${e.equipmentSlug}-${e.slug}`)),
    [events, currentEventList]
  );

  const previewPanel =
    fullMeasureList?.length || currentEventList?.length
      ? {
          measures: fullMeasureList,
          events: fullEventList.map((event) => ({ equipmentSlug: event.equipmentSlug, type: event.slug })),
          type: "LINE_CHART",
          time: {
            type: timeMode,
            interval: timeInterval,
            from: startTime,
            to: endTime,
          },
          customAxes,
        }
      : null;

  const previewProps = {
    previewPanel,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    timeInterval,
    setTimeInterval,
    timeMode,
    setTimeMode,
  };

  const initializeForm = ({ name }) => {
    form.setFieldsValue({
      name,
    });
  };

  const initializeTime = ({ time }) => {
    const { from, to, type, interval } = time;
    setTimeMode(type);
    setStartTime(dayjs(from));
    setEndTime(dayjs(to));
    setTimeInterval(interval);
  };

  const handleAxesSettingsChange = (axesData) => {
    setCustomAxes(axesData);
  };

  useEffect(() => {
    if (panel) {
      initializeForm(panel);
      initializeTime(panel);
      setCustomAxes(panel.customAxes);

      const allEquipment = panel?.measures.map((m) => m.equipment);
      const distinctEquipment = allEquipment.reduce((map, equipment) => ({ ...map, [equipment.id]: equipment }), {});

      setPanelEquipment(distinctEquipment);

      const selectedMeasuresResult = {};
      panel.measures.forEach((m) => {
        const {
          equipment: { id: equipmentId },
          id: measureId,
        } = m;
        if (!selectedMeasuresResult[equipmentId]) {
          selectedMeasuresResult[equipmentId] = [];
        }
        selectedMeasuresResult[equipmentId].push(measureId);
      });
      setSelectedMeasures(selectedMeasuresResult);

      const selectedEventsResult = {};
      panel.events.forEach((event) => {
        const { equipmentSlug } = event;
        if (!selectedEventsResult[equipmentSlug]) {
          selectedEventsResult[equipmentSlug] = [];
        }
        selectedEventsResult[equipmentSlug].push(`${equipmentSlug}-${event.type}`);
      });
      setSelectedEvents(selectedEventsResult);
    } else {
      initializeForm({
        name: t({
          id: "panels.panel.placeholder.new-panel",
          message: "New Panel",
        }),
      });
    }
  }, [panel]);

  useEffect(() => {
    if (Object.keys(panelEquipment).length > 0) {
      handleGetSources();
    }
  }, [panelEquipment]);

  const handleEditCancel = useCallback(() => {
    if (isNew) {
      navigate(-1);
    } else {
      navigate(`/panels/${panelId}`, {
        replace: true,
      });
    }
  }, [form]);

  const getEventSlugs = ({ equipmentSlug }) =>
    getEventSlugsByEquipment({
      variables: {
        eventFilters: [
          {
            equipmentSlug,
          },
        ],
      },
    });

  const handleGetSources = async () => {
    const measuresResult = {};
    const eventsResult = {};
    for await (const eq of Object.values(panelEquipment)) {
      const { id: equipmentId, slug: equipmentSlug, name: equipmentName } = eq;
      if (!measures[equipmentId] || !events[equipmentSlug]) {
        setLoadingSources((source) => ({ ...source, [equipmentId]: true }));
      }
      if (!measures[equipmentId]) {
        measuresResult[equipmentId] = await getMeasuresByEquipment({ equipmentId });
        measuresResult[equipmentId] = measuresResult[equipmentId]
          .slice()
          .sort((a, b) => a.slug.localeCompare(b.slug, "en", { numeric: true }))
          .map((m) => ({
            ...m,
            equipment: { slug: equipmentSlug, id: equipmentId, name: equipmentName },
            typeName: "MEASURE",
          }));
        setLoadingSources((source) => ({ ...source, [equipmentId]: false }));
      }
      if (!events[equipmentSlug]) {
        const {
          data: { eventSlugs },
        } = await getEventSlugs({ equipmentSlug });

        eventsResult[equipmentSlug] = eventSlugs.map((slug) => ({
          equipmentSlug,
          slug,
          description: ANNOTATION_TYPES[slug].label,
          typeName: "EVENT",
        }));
        setLoadingSources((source) => ({ ...source, [equipmentId]: false }));
      }
    }

    setMeasures((e) => ({ ...e, ...{ ...measuresResult } }));
    setEvents((e) => ({ ...e, ...{ ...eventsResult } }));
  };

  const handleSave = async () => {
    const time = {
      type: timeMode,
      interval: timeInterval,
      from: startTime,
      to: endTime,
    };
    if (onSave) {
      setSaveLoading(true);
      await onSave({
        measureIds: Object.values(selectedMeasures).flat(),
        events: fullEventList.map((event) => ({
          equipmentSlug: event.equipmentSlug,
          type: event.slug,
        })),
        formValues: form.getFieldValue(),
        customAxes: customAxes.map(({ id, name, unit, position, colorHex, measureIds, logarithmic, reversed }) => ({
          id,
          name,
          unit,
          position,
          colorHex,
          measureIds,
          logarithmic,
          reversed,
        })),
        time,
        pollInterval,
      });
      setSaveLoading(false);
    }
  };

  const rowSelection = ({ equipmentId, equipmentSlug }) => ({
    selectedRowKeys: [...(selectedMeasures[equipmentId] || []), ...(selectedEvents[equipmentSlug] || [])],
    onChange: (_, newSelectedRows) => {
      setSelectedMeasures((e) => ({
        ...e,
        [equipmentId]: newSelectedRows.filter((row) => row.typeName === "MEASURE").map((row) => row.id),
      }));
      setSelectedEvents((e) => ({
        ...e,
        [equipmentSlug]: newSelectedRows
          .filter((row) => row.typeName === "EVENT")
          .map((row) => `${equipmentSlug}-${row.slug}`),
      }));
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t({
            id: "panels.panel.edit.measures.search-index",
            message: "Search",
          })} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            <Trans id="panels.panel.edit.measures.search-button">Search</Trans>
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            <Trans id="panels.panel.edit.measures.reset-button">Reset</Trans>
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (open) => {
      if (open) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#fb7951",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: " ",
      key: "typeName",
      dataIndex: "typeName",
      width: "20px",
      render: (typeName) => (
        <>
          {typeName === "MEASURE" && (
            <Tooltip
              title={t({ id: "panels.panel.edit.measures.filter.measure.tooltip", message: "This is a Measure" })}
            >
              <TrendIcon style={{ color: "#0F8B8A" }} />
            </Tooltip>
          )}
          {typeName === "EVENT" && (
            <Tooltip title={t({ id: "panels.panel.edit.measures.filter.event.tooltip", message: "This is an Event" })}>
              <EventIcon style={{ color: "#1890FF" }} />
            </Tooltip>
          )}
        </>
      ),
      filters: [
        {
          text: (
            <div className={styles.tableFilters}>
              <TrendIcon style={{ color: "#0F8B8A" }} />
              <span>
                {t({
                  id: "panels.panel.edit.measures.filter-measures",
                  message: "Measures",
                })}
              </span>
            </div>
          ),
          value: "MEASURE",
        },
        {
          text: (
            <div className={styles.tableFilters}>
              <EventIcon style={{ color: "#1890FF" }} />
              <span>
                {t({
                  id: "panels.panel.edit.measures.filter-events",
                  message: "Events",
                })}
              </span>
            </div>
          ),
          value: "EVENT",
        },
      ],
      onFilter: (value, record) => record.typeName.indexOf(value) === 0,
      filterMultiple: false,
    },
    {
      title: t({
        id: "panels.panel.edit.measures.column-source",
        message: "Source",
      }),
      key: "source",
      dataIndex: "slug",
      width: "auto",
      sorter: (a, b) => a.slug.localeCompare(b.slug, "en", { numeric: true }),
      ...getColumnSearchProps("slug"),
    },
    {
      title: t({
        id: "panels.panel.edit.measures.column-description",
        message: "Description",
      }),
      key: "description",
      dataIndex: "description",
      width: "50%",
      responsive: ["xl"],
      sorter: (a, b) => {
        if (a.description) {
          return b.description ? a.description.localeCompare(b.description) : -1;
        }
        return a.description ? b.description.localeCompare(a.description) : 1;
      },
      ...getColumnSearchProps("description"),
      render: (text) =>
        text ? (
          <span>{text}</span>
        ) : (
          <span className={styles.measurePlaceholder}>
            <Trans id="panels.panel.edit.measures.description-not-set">not set</Trans>
          </span>
        ),
    },
  ];

  if (!isNew && panelLoading) {
    return (
      <Card>
        <Skeleton active />
      </Card>
    );
  }

  const handleTreeSelectChange = (selectedEquipment) => {
    const equipmentResult = {};
    selectedEquipment
      // using filter to prevent selection of stacks
      .filter(({ value: id }) => availableEquipments.find((e) => e.id === id))
      .forEach(({ value: equipmentId }) => {
        let eq;
        if (panelEquipment[equipmentId]) {
          eq = panelEquipment[equipmentId];
        } else {
          eq = { ...availableEquipments.find((e) => e.id === equipmentId), measures: [] };
        }
        equipmentResult[equipmentId] = eq;
      });
    setPanelEquipment(equipmentResult);
  };

  const handleTreeDeselect = (targetKey) => {
    setSelectedMeasures((e) => {
      const { [targetKey]: _v, ...rest } = e;
      return rest;
    });
    setSelectedEvents((e) => {
      const { [targetKey]: _v, ...rest } = e;
      return rest;
    });
  };

  const handleTabChange = (targetKey) => {
    setPanelEquipment((e) => {
      const { [targetKey]: _v, ...rest } = e;
      return rest;
    });
    setSelectedMeasures((e) => {
      const { [targetKey]: _v, ...rest } = e;
      return rest;
    });
    setSelectedEvents((e) => {
      const { [targetKey]: _v, ...rest } = e;
      return rest;
    });
  };

  return (
    <Card className={styles.container}>
      <div className={styles.backActionContainer}>
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Trans id="panels.panel.back-button">Back</Trans>
        </Button>
        <div className={styles.actionButtons}>
          <Button onClick={handleEditCancel}>
            <Trans id="panels.panel.cancel-button">Cancel</Trans>
          </Button>
          <Button type="primary" htmlType="submit" onClick={form.submit} loading={saveLoading}>
            <Trans id="panels.panel.save-button">Save</Trans>
          </Button>
        </div>
      </div>
      <Form form={form} onFinish={handleSave}>
        <div className={styles.titleActionContainer}>
          <div className={styles.editTitleContainer}>
            <Button type="text" className={styles.editTitleButton} onClick={() => titleInput.current.focus()}>
              <EditOutlined />
            </Button>
            <Form.Item name="name" className={styles.titleFormItem}>
              <Input
                className={styles.editTitle}
                ref={titleInput}
                placeholder={t({
                  id: "panels.panel.placeholder.new-panel",
                  message: "New Panel",
                })}
              />
            </Form.Item>
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.tableSection}>
            <h5 style={{ paddingBottom: 14, fontWeight: 500, fontSize: 17 }}>
              <Trans id="panels.panel.measure-title">Equipment Selection</Trans>
            </h5>
            <p style={{ paddingBottom: 8 }}>
              <Trans id="panels.panel.measure-description-text">
                Please select the equipment and its measures & events you want to show on the panel.
              </Trans>
            </p>
            <TreeSelect
              style={{
                width: "100%",
              }}
              value={Object.entries(panelEquipment).map(([_equipmentId, eq]) => ({
                value: eq.id,
                label: eq.name,
              }))}
              onChange={handleTreeSelectChange}
              onDeselect={handleTreeDeselect}
              treeNodeFilterProp="title"
              placeholder={t({
                id: "panel-form.equipment-select.placeholder",
                message: "Type or Select Equipment",
              })}
              allowClear
              treeDefaultExpandAll
              treeCheckable
              treeCheckStrictly
              showCheckedStrategy={TreeSelect.SHOW_ALL}
              treeData={assetTree.filter((asset) => !!asset.slug || asset.children?.length)}
            />

            <Card className={styles.measuresCard}>
              <Tabs
                className={styles.tabs}
                hideAdd
                type="editable-card"
                onEdit={handleTabChange}
                items={Object.values(panelEquipment).map((eq) => ({
                  key: eq.id,
                  label: eq.name,
                  children: (
                    <Table
                      loading={loadingSources[eq.id]}
                      rowSelection={rowSelection({ equipmentId: eq.id, equipmentSlug: eq.slug })}
                      columns={columns}
                      dataSource={[
                        ...(measures[eq.id]?.map((measure) => ({
                          ...measure,
                          key: measure.id,
                        })) || []),
                        ...(events[eq.slug]?.map((event) => ({
                          ...event,
                          key: `${eq.slug}-${event.slug}`,
                        })) || []),
                      ]}
                      size="small"
                      pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 15,
                      }}
                    />
                  ),
                }))}
              />
            </Card>
          </div>
          <div className={styles.previewSection}>
            <div className={styles.settingsButtonContainer}>
              {previewPanel && (
                <PanelSettings
                  panel={previewPanel}
                  panelEquipment={panelEquipment}
                  onSave={handleAxesSettingsChange}
                  previewProps={previewProps}
                />
              )}
            </div>
            <PanelPreview
              {...{
                previewPanel,
                startTime,
                setStartTime,
                endTime,
                setEndTime,
                timeInterval,
                setTimeInterval,
                timeMode,
                pollInterval,
                setPollInterval,
                setTimeMode,
              }}
            />
          </div>
        </div>
      </Form>
    </Card>
  );
}
