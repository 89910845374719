/* eslint-disable import/prefer-default-export */
import { t } from "@lingui/macro";

export const dataPartnerStatus = () => ({
  ACTIVE: {
    name: t({
      id: "data-partners.status.active",
      message: "Active",
    }),
    color: "green",
  },
  PENDING: {
    name: t({
      id: "data-partners.status.pending",
      message: "Pending Approval",
    }),
    color: "gold",
  },
  REVOKED: {
    name: t({
      id: "data-partners.status.denied",
      message: "Denied",
    }),
    color: "red",
  },
});
