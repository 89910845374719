import { i18n } from "@lingui/core";
import { en, es, pt } from "make-plural/plurals";

export const locales = {
  en: "English",
  es: "Spanish",
  pt: "Portuguese",
};
export const defaultLocale = "en";

i18n.loadLocaleData({
  en: { plurals: en },
  es: { plurals: es },
  pt: { plurals: pt },
});

// https://github.com/lingui/js-lingui/issues/1194
// Activate the default locale with an empty set of messages, to prevent error message on first render
i18n.load(defaultLocale, {});
i18n.activate(defaultLocale);

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function activateLocale(locale) {
  const { messages } = await import(`locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
