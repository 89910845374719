import React from "react";
import { t, Trans } from "@lingui/macro";

import { i18n } from "@lingui/core";
import { Button, Tag } from "antd";

import { Link, useNavigate } from "react-router-dom";

import { statuses as statusesFn } from "../../helpers/enums";

import styles from "./TableColumns.module.less";

export default function TableColumns({ data, handleIncidentReportArchive, deletingReportId, hasPermissions }) {
  const navigate = useNavigate();
  const tagStyles = ["draft", "review", "done"];

  const statuses = statusesFn();

  const displayTitle = ({ title, localIndex }) =>
    title || t({ id: "incident-report.default-title", message: `Incident Report #${localIndex}` });

  return [
    {
      title: t({
        id: "incident-reports.table.index",
        message: "Index",
      }),
      key: "localIndex",
      dataIndex: "localIndex",
      className: styles.titleColumn,
      width: 100,
      sorter: (a, b) => a.localIndex - b.localIndex,
      render: (index, record) => (
        <Link to={`${record.id}${record.status === 0 ? "/edit" : ""}`}>
          <div className={styles.titleContainer}># {index}</div>
        </Link>
      ),
    },
    {
      title: t({
        id: "incident-reports.table.title",
        message: "Title",
      }),
      key: "title",
      dataIndex: "title",
      className: styles.titleColumn,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (title, record) => (
        <Link to={`${record.id}${record.status === 0 ? "/edit" : ""}`}>
          <div className={styles.titleContainer}>{displayTitle({ title, localIndex: record.localIndex })}</div>
        </Link>
      ),
    },
    {
      title: t({
        id: "incident-reports.table.status",
        message: "Progress Status",
      }),
      key: "status",
      dataIndex: "status",
      width: "10rem",
      sorter: (a, b) => a.status - b.status,

      render: (_, record) => <Tag className={styles[tagStyles[record.status]]}>{statuses[record.status].name}</Tag>,
    },
    {
      title: t({
        id: "incident-reports.table.severity",
        message: "Severity",
      }),
      key: "severity",
      dataIndex: "severity",
      width: "auto",
      filters: [...new Set(data.map((item) => item.severity))].map((severity) => ({ value: severity, text: severity })),
      responsive: ["xl"],
      onFilter: (selectedSeverity, record) => record.severity === selectedSeverity,
      sorter: (a, b) => a.severity.localeCompare(b.severity),
    },
    {
      title: t({
        id: "incident-reports.table.incident-date",
        message: "Incident Date & Time",
      }),
      key: "incidentDate",
      dataIndex: "incidentDate",
      width: "auto",
      responsive: ["md"],
      sorter: (a, b) => new Date(a.incidentDate) - new Date(b.incidentDate),
      render: (incidentDate, _) => (
        <span>{i18n.date(new Date(incidentDate), { dateStyle: "medium", timeStyle: "short" })}</span>
      ),
    },
    {
      title: t({
        id: "incident-reports.table.last-updated",
        message: "Last Updated",
      }),
      key: "updatedAt",
      dataIndex: "updatedAt",
      width: "auto",
      responsive: ["xl"],
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (updatedAt, _) => (
        <span>{i18n.date(new Date(updatedAt), { dateStyle: "medium", timeStyle: "short" })}</span>
      ),
    },
    {
      title: t({
        id: "equipment-management.tabs.attachments.column-action",
        message: "Action",
      }),
      key: "action",
      width: "18%",
      render: (_, record) => (
        <div className={styles.actionsContainer}>
          {record.status === 0 ? (
            hasPermissions(["update:incident_reports"]) && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`${record.id}/edit`);
                }}
              >
                <Trans id="incident-reports.table.actions.edit">Edit</Trans>
              </Button>
            )
          ) : (
            <Button
              type="link"
              onClick={() => {
                navigate(`${record.id}`);
              }}
            >
              <Trans id="incident-reports.table.actions.view">View</Trans>
            </Button>
          )}
          {hasPermissions(["delete:incident_reports"]) && (
            <Button
              type="link"
              onClick={() => {
                handleIncidentReportArchive({
                  id: record.id,
                  reportTitle: displayTitle({ title: record.title, localIndex: record.localIndex }),
                });
              }}
              loading={deletingReportId === record.id}
            >
              <Trans id="incident-reports.table.actions.archive">Archive</Trans>
            </Button>
          )}
        </div>
      ),
    },
  ];
}
