import { useUser } from "context/UserContext";
import { GET_DASHBOARD } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useDashboard({ id }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_DASHBOARD, {
    variables: {
      organizationSlug: currentOrganization,
      id,
    },
  });

  const dashboard = data?.getDashboard;

  return { dashboard, loading, error };
}
