import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { SET_SHARED_EQUIPMENT_MEASURES } from "graphql/apiService";

export default function useSetSharedEquipmentMeasures() {
  const { currentOrganization } = useUser();

  const [setSharedMeasuresMutation, { loading, error }] = useMutation(SET_SHARED_EQUIPMENT_MEASURES, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetSharedEquipmentMeasures"],
  });

  const setSharedEquipmentMeasures = async ({ receivingOrganizationSlugs, equipmentId, measureIds, allMeasures }) => {
    const res = await setSharedMeasuresMutation({
      variables: {
        receivingOrganizationSlugs,
        equipmentId,
        measureIds,
        allMeasures,
      },
    });
    return res.data.setSharedEquipmentMeasures;
  };

  return [setSharedEquipmentMeasures, { loading, error }];
}
