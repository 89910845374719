import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { APPROVE_ORGANIZATION_DATA_PARTNER_REQUEST } from "graphql/apiService";

export default function useApproveDataPartnerRequest() {
  const { currentOrganization } = useUser();

  const [approveDataPartnerRequestMutation, { loading, error }] = useMutation(
    APPROVE_ORGANIZATION_DATA_PARTNER_REQUEST,
    {
      variables: { organizationSlug: currentOrganization },
    }
  );

  const approveDataPartnerRequest = async ({ id }) => {
    const res = await approveDataPartnerRequestMutation({ variables: { id } });
    return res.data.approveOrganizationDataPartnerRequest;
  };

  return [approveDataPartnerRequest, { loading, error }];
}
