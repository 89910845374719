/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { t, Trans, plural } from "@lingui/macro";

import useTableSearch from "hooks/useTableSearch";

import styles from "./TableColumns.module.less";

export default function TableColumns({ onDelete, onEdit }) {
  const { getColumnSearchProps } = useTableSearch();

  return [
    {
      title: t({
        id: "equipment-management.tabs.shared-with.column-organization",
        message: "Organization",
      }),
      key: "name",
      dataIndex: "name",
      width: "auto",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps({ dataIndex: "name" }),
    },
    {
      title: t({
        id: "equipment-management.tabs.shared-with.column-measures",
        message: "Measures",
      }),
      key: "measures",
      width: "40%",
      render: (_, record) => (
        <span>
          {record.allMeasures ? (
            <Trans id="equipment-management.tabs.shared-with.measures-shared-text-all">all</Trans>
          ) : (
            <Trans id="equipment-management.tabs.shared-with.measures-shared-text">
              {record.measures.length}{" "}
              {plural(record.measures.length, {
                one: "measure",
                other: "measures",
              })}{" "}
              shared
            </Trans>
          )}
        </span>
      ),
    },
    {
      title: t({
        id: "equipment-management.tabs.shared-with.column-action",
        message: "Action",
      }),
      key: "action",
      width: "15%",
      render: (_, record) => (
        <>
          <span
            className={styles.recordButton}
            onClick={() => {
              onEdit({ receivingOrganizationSlug: record.key });
            }}
          >
            <Trans id="equipment-management.tabs.shared-with.edit-button">Edit</Trans>
          </span>{" "}
          <span
            className={styles.recordButton}
            onClick={() => {
              onDelete({ receivingOrganizationSlug: record.key });
            }}
          >
            <Trans id="equipment-management.tabs.shared-with.delete-button">Delete</Trans>
          </span>
        </>
      ),
    },
  ];
}
