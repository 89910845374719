import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import dayjs from "dayjs";

import { CheckCircleOutlined } from "@ant-design/icons";

import { Avatar as AvatarAntD, Tooltip } from "antd";
import Avatar from "components/Avatar";

import styles from "./AnnotationItem.module.less";

export default function AnnotationItem({ annotation, setActiveAnnotation }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [timestamp, setTimestamp] = useState(dayjs(annotation.createdAt).fromNow());
  const { author, status, title, time } = annotation;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(dayjs(annotation.createdAt).fromNow());
    }, 1000);
    return () => clearInterval(interval);
  }, [annotation.createdAt]);

  const handleClickAnnotation = () => {
    setActiveAnnotation(annotation);
    searchParams.set("annotationId", annotation.id);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div
      className={`${styles.annotation} ${status === "RESOLVED" && styles.resolved}`}
      onClick={handleClickAnnotation}
      aria-hidden
    >
      <div className={styles.annotationHeaderContentTitle}>
        <div className={styles.annotationContent}>
          <div className={styles.annotationTitle}>{title}</div>
          <div className={styles.annotationTime}>{dayjs(time).format("MM-DD-YYYY HH:mm:ss")}</div>
          <div className={styles.authorTimestampContainer}>
            <div>
              {author?.pictureUrl && <AvatarAntD size="small" src={author?.pictureUrl} />}
              {!author?.pictureUrl && <Avatar size="small" name={author?.name} showName={false} />}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className={styles.authorName}>{author?.name}</span>
              <span className={styles.timestamp}>
                <Trans id="panels.panel.annotation.created-at">created {timestamp}</Trans>
              </span>
            </div>
          </div>
        </div>
        {status === "RESOLVED" && (
          <Tooltip
            title={t({
              id: "panel.annotation.title.resolved-tooltip",
              message: "This annotation is resolved.",
            })}
          >
            <CheckCircleOutlined className={styles.checkIcon} />
          </Tooltip>
        )}
      </div>
    </div>
  );
}
