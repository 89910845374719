import _ from "lodash";
import dayjs from "dayjs";

export function incidentReportToFormData(incidentReport) {
  const incidentReportData = _.pick(incidentReport, [
    "severity",
    "authorName",
    "supervisorName",
    "peopleInvolved",
    "witnesses",
    "partsInvolved",
    "workOrderNb",
    "incidentLocation",
    "createdAt",
    "incidentDate",
    "discoveryFactor",
    "incidentDescription",
    "cause",
    "activities",
    "employeeSignature",
    "employeeSignatureDate",
    "supervisorSignature",
    "supervisorSignatureDate",
    "summary",
    "title",
  ]);

  const {
    incidentDate,
    employeeSignatureDate,
    supervisorSignatureDate,
    createdAt,
    title,
    summary,
    suggestedTitle,
    suggestedSummary,
    incidentLocation,
    peopleInvolved,
    witnesses,
    partsInvolved,
  } = incidentReport;

  return {
    ...incidentReportData,
    incidentDate: incidentDate ? dayjs(incidentDate) : undefined,
    createdAt: createdAt ? dayjs(createdAt) : undefined,
    incidentLocation: incidentLocation?.formattedAddress,
    employeeSignatureDate: employeeSignatureDate ? dayjs(employeeSignatureDate) : undefined,
    supervisorSignatureDate: supervisorSignatureDate ? dayjs(supervisorSignatureDate) : undefined,
    title: title?.length ? title : suggestedTitle,
    summary: summary?.length ? summary : suggestedSummary,
    peopleInvolved: peopleInvolved.map((p) => ({ label: p.label, value: p.value, key: p.value })),
    witnesses: witnesses.map((w) => ({ label: w.label, value: w.value, key: w.value })),
    partsInvolved: partsInvolved.map((p) => ({ label: p.label, value: p.value })),
  };
}

export function formDataToIncidentReport(formData) {
  const {
    incidentDate,
    employeeSignatureDate,
    supervisorSignatureDate,
    workOrderNb,
    partsInvolved,
    peopleInvolved,
    witnesses,
  } = formData;
  return {
    ...formData,
    incidentDate: incidentDate?.toISOString(),
    employeeSignatureDate: employeeSignatureDate?.toISOString(),
    supervisorSignatureDate: supervisorSignatureDate?.toISOString(),
    workOrderNb: workOrderNb ? parseInt(workOrderNb, 10) : undefined,
    createdAt: undefined,
    partsInvolved: partsInvolved?.map((p) => ({ label: p.label, value: p.value })),
    peopleInvolved: peopleInvolved?.map((p) => ({ label: p.label, value: p.value })),
    witnesses: witnesses?.map((w) => ({ label: w.label, value: w.value })),
  };
}
