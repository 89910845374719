import SHA256 from "sha.js/sha256";
import seedrandom from "seedrandom";

export default function generateData(value, length, seed) {
  const hash = new SHA256().update(seed).digest("hex");
  const seedValue = parseInt(hash, 16);

  const rng = seedrandom(seedValue.toString());

  const newValues = Array.from({ length: length - 1 }, () => {
    const variation = value * 0.02;
    const newValue = value + (rng() * 2 * variation - variation);
    return Number(newValue.toPrecision(4));
  });

  newValues.push(value);

  return newValues;
}
