import { useUser } from "context/UserContext";
import { GET_ANNOTATION } from "graphql/apiService";
import { useLazyQuery } from "@apollo/client";

export default function useGetAnnotation() {
  const { currentOrganization } = useUser();

  const [getAnnotationQuery, { data, loading, error }] = useLazyQuery(GET_ANNOTATION, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const annotation = data?.getAnnotation;

  const getAnnotation = async ({ id }) => {
    const res = await getAnnotationQuery({
      variables: {
        id,
      },
    });
    return res.data?.getAnnotation;
  };

  return [getAnnotation, { annotation, loading, error }];
}
