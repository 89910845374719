import React, { useState, useEffect } from "react";
import { t } from "@lingui/macro";
import { Tabs } from "antd";
import { useUser } from "context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import { isProductApm } from "utils/auth-utils";

import Panels from "../Panels";
import Measures from "../Measures";
import SharingTab from "../SharingTab";
import AttachmentBox from "../AttachmentBox";
import Metadata from "../Metadata";

import styles from "./TabView.module.less";

const isProduct = isProductApm();

export default function TabView({ asset, isShared, hasData, parent }) {
  const { hasPermissions } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("tab") || (hasData ? "measures" : "details");
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setActiveKey(searchParams.get("tab") || (hasData ? "measures" : "details"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleTabChange = (key) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const tabItems = [
    {
      key: "details",
      label: t({
        id: "asset-map.asset.tabs.details",
        message: "Details",
      }),
      children: <Metadata asset={asset} isShared={isShared} parent={parent} />,
    },
    hasData && {
      key: "measures",
      label: t({
        id: "asset-map.asset.tabs.measures",
        message: "Measures",
      }),
      children: <Measures asset={asset} isShared={isShared} />,
    },
    hasPermissions(["read:dashboards"]) &&
      hasData && {
        key: "panels",
        label: t({
          id: "asset-map.asset.tabs.graphs",
          message: "Panels",
        }),
        children: <Panels asset={asset} />,
      },
    !isShared && {
      key: "files",
      label: t({
        id: "asset-map.asset.tabs.files",
        message: "Files",
      }),
      children: <AttachmentBox className={styles.box} asset={asset} />,
    },
    !isProduct &&
      !isShared &&
      hasPermissions(["read:telemetry_data_shares"]) && {
        key: "sharing",
        label: t({
          id: "asset-maps.asset.tabs.shared-with",
          message: "Shared With",
        }),
        children: <SharingTab asset={asset} />,
      },
  ].filter(Boolean);

  return (
    <div className={styles.container}>
      <Tabs activeKey={activeKey} onChange={handleTabChange} type="card" className={styles.tabs} items={tabItems} />
    </div>
  );
}
