/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import dayjs from "dayjs";

import useAssetTree from "hooks/useAssetTree";
import { useWorkOrderRequest } from "context/WorkOrderRequestContext";
import { useUser } from "context/UserContext";

import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as OpenIcon } from "assets/open-icon.svg";

import { Button, Select, Table, Tag, Tooltip, TreeSelect } from "antd";
import Card from "components/Card";

import { isServiceApm } from "utils/auth-utils";
import { toKebabCase } from "utils/string-utils";

import statusColor from "../helpers/statusColor";
import styles from "../WorkRequests.module.less";

const isService = isServiceApm();

const tabs = ["Work Requests", isService && "Work Orders"].filter(Boolean);

export default function WorkRequests() {
  const { assetTree, loading: loadingAssets } = useAssetTree();

  const { openWorkOrderRequest } = useWorkOrderRequest();
  const { hasPermissions } = useUser();

  const [tasks, setTasks] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedAsset, setSelectedAsset] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("work-requests");

  const limbleAssets = [];
  const limbleStatuses = [];
  const limbleTasks = [];
  const assets = [];

  const Toolbar = () => (
    <div className={styles.toolbar}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${toKebabCase(tab) === page ? styles.selected : ""}`}
            onClick={() => setPage(toKebabCase(tab))}
          >
            {tab}
          </div>
        ))}
      </div>
      <div>
        {hasPermissions(["create:work_orders"]) && (
          <Button type="primary" onClick={openWorkOrderRequest} icon={<PlusOutlined className={styles.shiftIcon} />}>
            {page === "work-requests" ? "New Work Request" : "New Work Order"}
          </Button>
        )}
      </div>
    </div>
  );

  const handleGetTasks = async () => {
    setStatuses(limbleStatuses);
    const result = limbleTasks.reverse().map((task) => ({
      ...task,
      asset:
        assets.find((asset) => asset.limbleId === task.assetID) ||
        limbleAssets.find((asset) => asset.assetID === task.assetID),
      status: limbleStatuses.find((status) => status.statusID === task.statusID),
    }));
    setTasks(result);
    setLoading(false);
  };

  useEffect(() => {
    if (assets.length > 0) {
      handleGetTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  const tableColumns = [
    {
      key: "taskID",
      dataIndex: "taskID",
      title: t({ id: "work-requests.overview.table.column.index", message: "Index" }),
      render: (_, record) => (
        <Link to={`/work-requests/${record.taskID}`} className={styles.requestLink}>
          # {record.taskID}
        </Link>
      ),
      width: 80,
      sorter: (a, b) => a.taskID - b.taskID,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "status",
      dataIndex: "statusID",
      title: t({ id: "work-requests.overview.table.column.status", message: "Status" }),
      width: 160,
      render: (_, record) => <Tag color={statusColor(record.statusID)}>{record.status.name}</Tag>,
    },
    {
      key: "asset",
      dataIndex: "asset",
      title: t({ id: "work-requests.overview.table.column.asset", message: "Asset" }),
      render: (_, record) => {
        if (record.asset?.id) {
          return (
            <Link to={`/assets/${record.asset.id}`} className={styles.requestLink}>
              {record.asset.name}
            </Link>
          );
        }
        return record.asset?.name ? record.asset.name : <span className={styles.notDefined}>not defined</span>;
      },
      width: 240,
    },
    {
      key: "due",
      dataIndex: "due",
      title: t({ id: "work-requests.overview.table.column.due-date", message: "Due Date & Time" }),
      render: (_, record) => {
        const dueTime = dayjs(record.due * 1000);
        const currentTime = dayjs();
        if (currentTime > dueTime && styles.overDue) {
          return (
            <span className={styles.overDue}>
              {i18n.date(dueTime, { dateStyle: "medium", timeStyle: "short" })}{" "}
              <Tooltip title={t({ id: "work-requests.overview.table.column.due-date.over-due", message: "Overdue" })}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          );
        }
        return i18n.date(dueTime, { dateStyle: "medium", timeStyle: "short" });
      },
      width: 220,
      sorter: (a, b) => a.due - b.due,
    },
    {
      key: "createdDate",
      dataIndex: "createdDate",
      title: t({ id: "work-requests.overview.table.column.submit-date", message: "Submitted Date & Time" }),
      render: (_, record) => i18n.date(dayjs(record.createdDate * 1000), { dateStyle: "medium", timeStyle: "short" }),
      width: 220,
      sorter: (a, b) => a.createdDate - b.createdDate,
    },
    {
      key: "action",
      title: t({ id: "work-requests.overview.table.column.action", message: "Action" }),
      render: (_, record) => (
        <div className={styles.links}>
          <Link to={`/work-requests/${record.taskID}`}>
            <Trans id="work-requests.overview.table.column.action.view">View</Trans>
          </Link>
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href={`https://app.limblecmms.com/task/${record.taskID}/${record.locationID}`}
          >
            <OpenIcon /> <Trans id="work-requests.overview.table.column.action.cmms">CMMS</Trans>
          </a>
        </div>
      ),
      width: 180,
    },
  ];

  const filteredTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          (selectedStatus === undefined ? true : task.statusID === selectedStatus) &&
          (selectedAsset === undefined ? true : task.assetID === selectedAsset.limbleId)
      ),
    [tasks, selectedAsset, selectedStatus]
  );

  const resetFilters = () => {
    setSelectedAsset(undefined);
    setSelectedStatus(undefined);
  };

  return (
    <div className={styles.container}>
      <div style={{ paddingBottom: 12 }}>
        <Toolbar />
      </div>
      <Card className={styles.content}>
        <div className={styles.filters}>
          <div>
            <span>
              <Trans id="work-requests.overview.table.filters">Filters</Trans>
            </span>
            <TreeSelect
              className={styles.treeSelect}
              onSelect={(_, node) => {
                if (node.type !== "FOLDER") {
                  setSelectedAsset(node);
                }
                return false;
              }}
              onClear={() => setSelectedAsset(undefined)}
              treeNodeFilterProp="title"
              placeholder={t({
                id: "work-requests.overview.table.filter.asset.placeholder",
                message: "Filter by Asset",
              })}
              treeData={assetTree}
              treeIcon
              loading={loadingAssets}
              disabled={loadingAssets}
              showArrow
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              showSearch
              allowClear
            />
            <Select
              className={styles.statusSelector}
              options={statuses.map((status) => ({ label: status.name, value: status.statusID }))}
              placeholder={t({
                id: "work-requests.overview.table.filter.status.placeholder",
                message: "Filter by Status",
              })}
              onChange={setSelectedStatus}
              value={selectedStatus}
              allowClear
            />
          </div>
          <div>
            <Button onClick={resetFilters}>
              <Trans id="work-requests.overview.table.reset">Reset</Trans>
            </Button>
          </div>
        </div>
        <Table
          dataSource={filteredTasks}
          columns={tableColumns}
          rowKey={(record) => record.taskID}
          loading={loading}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
          }}
        />
      </Card>
    </div>
  );
}
