/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Highlighter from "react-highlight-words";
import { t, Trans } from "@lingui/macro";
import { filesize } from "filesize";
import { Button } from "antd";

import FileIcon from "components/FileIcon";

import useTableSearch from "hooks/useTableSearch";
import styles from "./AttachmentsTableColumns.module.less";

export default function AttachmentsTableColumns({ onDelete, onDownload, data, downloadingFileId, deletingFileId }) {
  const { getColumnSearchProps, searchText } = useTableSearch();

  return [
    {
      title: t({
        id: "asset-map.asset.attachments.columns.filename",
        message: "Filename",
      }),
      key: "fileName",
      dataIndex: "fileName",
      width: "auto",
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      ...getColumnSearchProps({ dataIndex: "fileName" }),
      render: (fileName, record) => (
        <a href={record.s3Url} target="_blank" rel="noreferrer" className={styles.nameLink}>
          <div className={styles.nameContainer}>
            <FileIcon contentType={record.contentType} className={styles.fileIcon} />
            <Highlighter
              className={styles.text}
              highlightStyle={{
                backgroundColor: "#fb7951",
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={fileName}
            />
          </div>
        </a>
      ),
    },
    {
      title: t({
        id: "asset-map.asset.attachments.columns.type",
        message: "Type",
      }),
      key: "type",
      dataIndex: "type",
      width: "auto",
      filters: [...new Set(data.map((item) => item.type))].map((type) => ({ value: type, text: type })),
      responsive: ["xl"],
      onFilter: (selectedType, record) => record.type === selectedType,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: t({
        id: "asset-map.asset.attachments.columns.size",
        message: "Size",
      }),
      key: "size",
      dataIndex: "contentLength",
      width: "auto",
      responsive: ["md"],
      sorter: (a, b) => a.contentLength - b.contentLength,
      render: (size, _) => <div>{filesize(size)}</div>,
    },
    {
      title: t({
        id: "asset-map.asset.attachments.columns.actions",
        message: "Action",
      }),
      key: "action",
      width: "18%",
      render: (_, record) => (
        <div className={styles.actionsContainer}>
          <Button
            type="link"
            onClick={() => {
              onDownload({
                fileUrl: record.s3Url,
                fileName: record.fileName,
                contentType: record.contentType,
                id: record.id,
              });
            }}
            loading={downloadingFileId === record.id}
          >
            <Trans id="asset-map.asset.attachments.columns.actions.download-button">Download</Trans>
          </Button>
          <Button
            type="link"
            onClick={() => {
              onDelete({ id: record.key, fileName: record.fileName });
            }}
            loading={deletingFileId === record.id}
          >
            <Trans id="asset-map.asset.attachments.columns.actions.delete-button">Delete</Trans>
          </Button>
        </div>
      ),
    },
  ];
}
