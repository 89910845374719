import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_DASHBOARD, GET_DASHBOARDS_BY_ORGANIZATION } from "graphql/apiService";

export default function useDeleteDashboard() {
  const { currentOrganization } = useUser();

  const [deleteDashboardMutation, { loading, error }] = useMutation(DELETE_DASHBOARD, {
    variables: { organizationSlug: currentOrganization },
    update: (cache, { data: { deleteDashboard: deletedDashboard } }) => {
      const { getDashboardsByOrganization: dashboards } = cache.readQuery({
        query: GET_DASHBOARDS_BY_ORGANIZATION,
        variables: {
          organizationSlug: currentOrganization,
        },
      });
      cache.writeQuery({
        query: GET_DASHBOARDS_BY_ORGANIZATION,
        variables: {
          organizationSlug: currentOrganization,
        },
        data: {
          getDashboardsByOrganization: dashboards.filter((db) => db.id !== deletedDashboard.id),
        },
      });
    },
  });

  const deleteDashboard = async ({ id }) => {
    const res = await deleteDashboardMutation({ variables: { id } });
    return res.data?.deleteDashboard;
  };

  return [deleteDashboard, { loading, error }];
}
