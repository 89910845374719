import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_HYDROGEN_QUESTION } from "graphql/apiService";

export default function useCreateHydrogenQuestion() {
  const { currentOrganization } = useUser();

  const [createHydrogenQuestionMutation, { loading, data, error }] = useMutation(CREATE_HYDROGEN_QUESTION, {
    variables: { organizationSlug: currentOrganization },
  });

  const createHydrogenQuestion = async ({ question }) => {
    const res = await createHydrogenQuestionMutation({ variables: { question } });
    return res.data.createHydrogenQuestion;
  };

  return [createHydrogenQuestion, { loading, error, data }];
}
