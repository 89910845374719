import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { ReactComponent as OpenIcon } from "assets/open-icon.svg";

import { Button } from "antd";

import styles from "./HeaderMenu.module.less";

export default function HeaderMenu({ task }) {
  return (
    <div className={styles.header}>
      <Link to={-1}>
        <Button type="primary" ghost>
          <ArrowLeftOutlined />
          <Trans id="work-requests.work-request.header.back-button">Back</Trans>
        </Button>
      </Link>
      <div className={styles.headerRight}>
        <a target="_blank" rel="noreferrer" href={`https://app.limblecmms.com/task/${task.taskID}/${task.locationID}`}>
          <Button className={styles.headerButton} type="primary" icon={<OpenIcon className={styles.shiftIcon} />}>
            <Trans id="work-requests.work-request.header.open-button">Open in CMMS</Trans>
          </Button>
        </a>
      </div>
    </div>
  );
}
