import React, { useState, useEffect, useMemo } from "react";
import { Modal, Card } from "antd";
import { t } from "@lingui/macro";
import _ from "lodash";

import MeasureSelection from "containers/AssetMap/components/MeasureSelection";

function ShareEditModal({
  open,
  setOpen,
  equipmentMeasures,
  sharedMeasures,
  isSharingAllMeasures,
  onUpdate,
  updating,
}) {
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [shareAllMeasures, setShareAllMeasures] = useState(false);

  useEffect(() => {
    setSelectedMeasures(sharedMeasures);
  }, [sharedMeasures]);

  useEffect(() => {
    setShareAllMeasures(isSharingAllMeasures);
  }, [isSharingAllMeasures]);

  const onModalCancel = () => {
    setOpen(false);
    setSelectedMeasures(sharedMeasures);
    setShareAllMeasures(isSharingAllMeasures);
  };

  const dataWasModified = useMemo(
    () =>
      isSharingAllMeasures !== shareAllMeasures ||
      (!shareAllMeasures &&
        !_.isEqual(
          sharedMeasures
            .map((m) => m.id)
            .slice()
            .sort(),
          selectedMeasures
            .map((m) => m.id)
            .slice()
            .sort()
        )),
    [isSharingAllMeasures, selectedMeasures, shareAllMeasures, sharedMeasures]
  );

  return (
    <Modal
      title={t({ id: "equipment-management.tabs.shared-with.share-edit-modal.title", message: "Select Measures" })}
      open={open}
      onCancel={onModalCancel}
      okText={t({ id: "equipment-management.tabs.shared-with.share-edit-modal.ok-button", message: "Update Measures" })}
      onOk={() => onUpdate({ selectedIds: selectedMeasures.map((m) => m.id), shareAllMeasures })}
      okButtonProps={{ disabled: !dataWasModified || (!shareAllMeasures && selectedMeasures.length === 0) }}
      confirmLoading={updating}
      bodyStyle={{ padding: 0 }}
      width={900}
    >
      <Card>
        <MeasureSelection
          measures={equipmentMeasures}
          selectedMeasures={selectedMeasures}
          setSelectedMeasures={setSelectedMeasures}
          shareAllMeasures={shareAllMeasures}
          setShareAllMeasures={setShareAllMeasures}
        />
      </Card>
    </Modal>
  );
}

export default ShareEditModal;
