import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import captureException from "utils/capture-sentry-exception";
import { Card } from "antd";
import { t } from "@lingui/macro";

import { useIncidentReport } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import IncidentReportSteps from "../IncidentReportSteps";
import IncidentReportView from "../IncidentReportView";

function IncidentReport({ mode }) {
  const { id } = useParams();
  const { incidentReport, loading, error } = useIncidentReport({ id });
  const { message } = useCustomMessage();

  useEffect(() => {
    if (error) {
      captureException(error);
      message.error(
        t({
          id: "incident-report.errors.get-incident-report",
          message: "Failed to get incident report",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (loading && !incidentReport) {
    return <Card loading />;
  }

  return mode === "view" ? (
    <IncidentReportView incidentReport={incidentReport} />
  ) : (
    <IncidentReportSteps incidentReport={incidentReport} />
  );
}

export default IncidentReport;
