import React from "react";
import ResponsivePlot from "components/ResponsivePlot";
import { useMediaQuery } from "react-responsive";

export default function BarChart({ x, y, color }) {
  const isBigScreen = useMediaQuery({ minWidth: 1900 });

  return (
    <ResponsivePlot
      data={[
        {
          type: "bar",
          hole: 0.4,
          orientation: "h",
          x,
          y,
          marker: { color },
          hovertemplate: "%{x}<extra></extra>",
          showlegend: false,
        },
      ]}
      layout={{
        margin: {
          l: isBigScreen ? 210 : 178,
          r: 20,
          b: 20,
          t: 20,
          pad: 4,
        },
        yaxis: { autorange: "reversed" },
        legend: { orientation: "h", side: "bot" },
        font: {
          size: isBigScreen ? 14 : 12,
        },
      }}
      config={{
        modeBarButtons: [[]],
        displaylogo: false,
        responsive: true,
      }}
    />
  );
}
