import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_USER_ORGANIZATION_PROFILES } from "graphql/apiService";

export default function useCreateUserOrganizationProfiles() {
  const { currentOrganization } = useUser();

  const [createUserOrganizationProfilesMutation, { loading, error }] = useMutation(CREATE_USER_ORGANIZATION_PROFILES, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetUserOrganizationProfiles"],
  });

  const createUserOrganizationProfiles = async ({ invitees, organizationSlug }) => {
    const res = await createUserOrganizationProfilesMutation({ variables: { invitees, organizationSlug } });
    return res.data.createUserOrganizationProfiles;
  };

  return [createUserOrganizationProfiles, { loading, error }];
}
