import { useUser } from "context/UserContext";
import { GET_DASHBOARDS_BY_ORGANIZATION } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useDashboards() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_DASHBOARDS_BY_ORGANIZATION, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const dashboards = data?.getDashboardsByOrganization || [];

  return { dashboards, loading, error };
}
