import React from "react";
import { ReactComponent as EH2Logo } from "assets/eh2-square.svg";

import styles from "./CircleLogo.module.less";

export default function CircleLogo({ size = 32 }) {
  return (
    <div className={styles.circle} style={{ width: size, height: size }}>
      <EH2Logo className={styles.logo} />
    </div>
  );
}
