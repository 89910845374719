import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useIncidentReports, useDeleteIncidentReport } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";
import { useUser } from "context/UserContext";

import { Button, Table, App } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";

import TableColumns from "./components/TableColumns";
import { severities as severitiesFn, statuses as statusesFn } from "./helpers/enums";

import styles from "./IncidentReports.module.less";

function IncidentReports() {
  const { incidentReports, loading } = useIncidentReports();
  const [deletingReportId, setDeletingReportId] = useState();
  const [deleteIncidentReport] = useDeleteIncidentReport();
  const { modal } = App.useApp();
  const { message } = useCustomMessage();
  const { hasPermissions } = useUser();

  const severities = severitiesFn();
  const statuses = statusesFn();

  const tableData = incidentReports.map((report) => ({
    ...report,
    key: report.id,
    status: statuses.findIndex((statusObject) => statusObject.value === report.status),
    severity: report.severity
      ? severities.find((severityObject) => severityObject.value === report.severity).name
      : t({
          id: "incident-reports.severity.not-set",
          message: "Not set",
        }),
  }));

  const showArchiveConfirmModal = ({ id, reportTitle }) => {
    modal.confirm({
      title: t({
        id: "incident-reports.confirm-archive.title",
        message: `Are you sure you want to archive ${reportTitle}?`,
      }),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t({
        id: "incident-reports.confirm-archive.button",
        message: "Archive",
      }),
      onOk: () => {
        handleIncidentReportArchive({ id });
      },
      onCancel() {},
    });
  };

  const handleIncidentReportArchive = async ({ id }) => {
    try {
      setDeletingReportId(id);
      await deleteIncidentReport({ id });
    } catch (err) {
      message.error(
        t({
          id: "incident-reports.archive-error",
          message: `Failed to archive incident report`,
        })
      );
    } finally {
      setDeletingReportId(null);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.title}>
          <Trans id="incident-reports.title">Incident Registry & Report</Trans>
        </div>
        {hasPermissions(["create:incident_reports"]) && (
          <Button className={styles.addButton} type="primary">
            <Link to="new">
              <PlusOutlined className={styles.plusIcon} />
              <Trans id="incident-reports.new">New Incident Report</Trans>
            </Link>
          </Button>
        )}
      </div>
      <Table
        columns={TableColumns({
          data: tableData,
          handleIncidentReportArchive: showArchiveConfirmModal,
          deletingReportId,
          hasPermissions,
        })}
        loading={loading}
        dataSource={tableData}
        pagination={{
          showSizeChanger: true,
        }}
      />
    </div>
  );
}

export default IncidentReports;
