import { useUser } from "context/UserContext";
import { GET_MY_FAVORITES } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useMyFavorites() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_MY_FAVORITES, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const favorites = data?.getMyFavorites || [];
  return { favorites, loading, error };
}
