/* eslint-disable import/prefer-default-export */
import configJson from "./auth0_config.json";

export const getConfig = () => {
  const env = process.env.REACT_APP_ENV;
  if (env === "prod" || env === "production") {
    return configJson.production;
  }
  if (process.env.REACT_APP_CLIENT_ID_OVERRIDE) {
    return { ...configJson.staging, clientId: process.env.REACT_APP_CLIENT_ID_OVERRIDE };
  }
  return configJson.staging;
};
