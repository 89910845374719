import { gql } from "@apollo/client";

export const GET_TELEMETRY_DATA = gql`
  query GetTelemetryData($organizationSlug: String!, $from: Date!, $to: Date, $measureFilters: [MeasureFilter!]!) {
    rawTelemetryData(organizationSlug: $organizationSlug, from: $from, to: $to, measureFilters: $measureFilters) {
      equipmentId
      measureId
      time
      value
    }
  }
`;

export const GET_COMPRESSED_TELEMETRY_DATA = gql`
  query GetCompressedTelemetryData(
    $organizationSlug: String!
    $from: Date!
    $to: Date
    $measureFilters: [MeasureFilter!]!
  ) {
    compressedTelemetryData(
      organizationSlug: $organizationSlug
      from: $from
      to: $to
      measureFilters: $measureFilters
    ) {
      measures {
        slug
        equipmentSlug
        values
      }
      time
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents($organizationSlug: String!, $from: Date!, $to: Date!, $eventFilters: [EventFilter!]!) {
    events(organizationSlug: $organizationSlug, from: $from, to: $to, eventFilters: $eventFilters) {
      type
      time
      equipmentSlug
    }
  }
`;
export const GET_EVENT_SLUGS = gql`
  query GetEventSlugs($organizationSlug: String!, $eventFilters: [EventFilter!]!) {
    eventSlugs(organizationSlug: $organizationSlug, eventFilters: $eventFilters)
  }
`;
