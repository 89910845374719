import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { MARK_EQUIPMENT_ATTACHMENT_UPLOADED } from "graphql/apiService";

export default function useMarkEquipmentAttachment() {
  const { currentOrganization } = useUser();

  const [markUploadedMutation, { loading, error }] = useMutation(MARK_EQUIPMENT_ATTACHMENT_UPLOADED, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetEquipmentAttachments"],
  });

  const markEquipmentAttachmentAsUploaded = async ({ id }) => {
    const res = await markUploadedMutation({ variables: { id } });
    return res.data.markEquipmentAttachmentAsUploaded;
  };

  return [markEquipmentAttachmentAsUploaded, { loading, error }];
}
