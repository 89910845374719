import { getOrgFromSubdomain } from "utils/auth-utils";

const org = getOrgFromSubdomain();

export function generateDashboardURL({ id, shortPath = false }) {
  const suffix = `/superset/dashboard/p/${id}/`;
  return shortPath ? suffix : `${getSupersetUrl()}${suffix}`;
}

export function getSupersetUrl() {
  return process.env.REACT_APP_DOMAIN
    ? `https://${org}.embedded-superset.${process.env.REACT_APP_DOMAIN}`
    : "http://localhost:8088";
}

export function extractDashboardId(url) {
  const regex = /\/p\/([a-zA-Z0-9-_]+)\/?/;
  const idMatch = url.match(regex);
  if (!idMatch) return null;
  return idMatch[1];
}
