import React, { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { t, Trans } from "@lingui/macro";
import { useChannel } from "ably/react";

import {
  useAnnotation,
  useGetAnnotation,
  useCreateAnnotationMessage,
  useMarkAnnotationAsActive,
  useMarkAnnotationAsResolved,
} from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { getOrgFromSubdomain } from "utils/auth-utils";

import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Form, Tag, Tooltip } from "antd";
import AnnotationMessage from "../AnnotationMessage";

import { singleEquipment } from "../../helpers/annotation.helpers";

import styles from "./ExistingAnnotation.module.less";

dayjs.extend(relativeTime);

const orgSlug = getOrgFromSubdomain();

export default function ExistingAnnotation({ id, users, panel, onClose }) {
  const { annotation: originalAnnotation } = useAnnotation({ id });
  const [subscriptionAnnotation, setSubscriptionAnnotation] = useState();

  const [getAnnotation] = useGetAnnotation();
  const [messageForm] = Form.useForm();
  const { message } = useCustomMessage();

  const annotation = subscriptionAnnotation || originalAnnotation;

  const { _channel } = useChannel(`${orgSlug}:annotations:${id}`, async (_ablyMessage) => {
    const newAnnotation = await getAnnotation({ id });
    setSubscriptionAnnotation(newAnnotation);
  });

  const [currentMessage, setCurrentMessage] = useState("");
  const usersMap = users.reduce(
    (result, user) => ({
      [user.auth0Id]: user.name,
      ...result,
    }),
    {}
  );
  const [createAnnotationMessage, { loading: loadingMessage }] = useCreateAnnotationMessage();
  const [markAnnotationAsResolved, { loading: loadingResolved }] = useMarkAnnotationAsResolved();
  const [markAnnotationAsActive, { loading: loadingActive }] = useMarkAnnotationAsActive();

  const handleSaveAnnotationMessage = async () => {
    try {
      await createAnnotationMessage({ annotationId: id, content: currentMessage });
      messageForm.resetFields();
    } catch (error) {
      message.error(
        t({
          id: "panel.annotation.errors.failed-annotation-message-creation",
          message: "Failed to create annotation message",
        })
      );
    }
  };
  const handleResolveAnnotation = async () => {
    try {
      await markAnnotationAsResolved({ id });
      onClose();
    } catch (error) {
      message.error(
        t({
          id: "panel.annotation.errors.failed-annotation-resolve",
          message: "Failed to resolve annotation",
        })
      );
    }
  };
  const handleActivateAnnotation = async () => {
    try {
      await markAnnotationAsActive({ id });
    } catch (error) {
      message.error(
        t({
          id: "panel.annotation.errors.failed-annotation-resolve",
          message: "Failed to resolve annotation",
        })
      );
    }
  };

  return (
    <>
      <div className={styles.annotationHeaderContentContainer}>
        <div className={styles.annotationHeader}>
          <div className={styles.annotationHeaderTitleContainer}>
            {annotation?.status === "RESOLVED" && (
              <Tooltip
                title={t({
                  id: "panel.annotation.title.resolved-tooltip",
                  message: "This annotation is resolved.",
                })}
              >
                <CheckCircleOutlined className={styles.checkIcon} />
              </Tooltip>
            )}
            <h5 className={styles.annotationHeaderTitle}>{annotation?.title}</h5>
          </div>
          <span className={styles.closeAnnotation} onClick={onClose} aria-hidden>
            <CloseOutlined />
          </span>
        </div>
      </div>
      <div className={styles.annotationTimestamp}>
        {annotation?.time ? dayjs(annotation.time).format("MM-DD-YYYY HH:mm:ss") : ""}
      </div>
      {annotation?.measures.length > 0 && (
        <div className={styles.annotationContent}>
          <p className={styles.annotationParagraph}>
            <Trans id="panel.annotation.measures.paragraph">Measures:</Trans>
          </p>
          <div className={styles.annotationMeasures}>
            {annotation?.measures.map((measure) => (
              <Tag key={measure.id}>
                {measure.description || measure.slug}
                {!singleEquipment({ measures: panel.measures }) ? ` (${measure.equipment.name})` : ""}
              </Tag>
            ))}
          </div>
        </div>
      )}
      <div className={styles.scrollContainer}>
        <div className={styles.annotationMessagesContainer}>
          {annotation?.messages
            .map((messageData) => <AnnotationMessage key={messageData.id} message={messageData} />)
            .reverse()}
          <AnnotationMessage
            message={{
              type: "CREATE",
              author: annotation?.author,
              createdAt: annotation?.createdAt,
            }}
          />
        </div>
      </div>

      <Form className={styles.messageForm} form={messageForm} layout="vertical" onFinish={handleSaveAnnotationMessage}>
        <Form.Item
          name="content"
          className={styles.annotationMessageInput}
          label={t({
            id: "panel.annotation.message.form.response-message.label",
            message: "Add Response",
          })}
          required
          rules={[
            {
              required: true,
              message: t({
                id: "panel.annotation.form.rules.reply-message",
                message: "You need to write a message to reply",
              }),
            },
          ]}
        >
          <MentionsInput
            className="mentions"
            value={currentMessage}
            onChange={(ev, newValue) => setCurrentMessage(newValue)}
            placeholder={t({
              id: "panel.annotation.form.reply-message.placeholder",
              message: "Mention people using '@'",
            })}
            a11ySuggestionsListLabel={t({
              id: "panel.annotation.form.mention-suggestion-list-label",
              message: "Suggested mentions",
            })}
            allowSuggestionsAboveCursor
            allowSpaceInQuery
          >
            <Mention
              appendSpaceOnAdd
              className={styles.mentionHighlight}
              markup="(user:__id__)"
              trigger="@"
              data={users.map(({ auth0Id, name }) => ({ id: auth0Id, display: name }))}
              displayTransform={(userId) => `@${usersMap[userId]}`}
              renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                <div className={`user ${focused ? "focused" : ""}`}>{highlightedDisplay}</div>
              )}
            />
          </MentionsInput>
        </Form.Item>
        <Form.Item>
          <div className={styles.actionButtons}>
            <Button type="primary" htmlType="submit" loading={loadingMessage}>
              <Trans id="panel.annotation.message.form.button.send">Send</Trans>
            </Button>
            {annotation?.status === "ACTIVE" && (
              <Button onClick={handleResolveAnnotation} loading={loadingResolved}>
                <Trans id="panel.annotation.message.form.button.resolve">Resolve</Trans>
              </Button>
            )}
            {annotation?.status === "RESOLVED" && (
              <Button onClick={handleActivateAnnotation} loading={loadingActive}>
                <Trans id="panel.annotation.message.form.button.restore">Restore</Trans>
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
