import React, { useState } from "react";
import { t, Trans } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";

import { useUser } from "context/UserContext";
import { useCreateDataPartnerRequest } from "hooks/apiService/dataPartner";

import { Button, Input, message, Modal } from "antd";

import styles from "./InviteModal.module.less";

export default function InviteModal({ visible, setVisible }) {
  const { currentOrganization } = useUser();

  const [createOrganizationDataPartnerRequest, { loading }] = useCreateDataPartnerRequest();

  const [organizationCode, setOrganizationCode] = useState("");

  const handleInviteModalOk = async () => {
    try {
      await createOrganizationDataPartnerRequest({ receivingOrganizationSlug: organizationCode });
    } catch (error) {
      if (error.message === "Access grant already exists.") {
        message.warn(
          t({
            id: "data-partners.errors.already-exists",
            message: "Data Partner already exists",
          })
        );
      } else {
        captureException(error);
        message.error(
          t({
            id: "data-partners.errors.invite-error",
            message: "Failed to invite data partner",
          })
        );
      }
    } finally {
      setVisible(false);
      setOrganizationCode("");
    }
  };
  const handleInviteModalCancel = () => {
    setVisible(false);
  };
  const handleCodeChange = (e) => {
    setOrganizationCode(e.target.value);
  };

  return (
    <Modal
      open={visible}
      centered
      title={t({
        id: "data-partners.modal.title",
        message: "Invite New Data Partner",
      })}
      onOk={handleInviteModalOk}
      onCancel={handleInviteModalCancel}
      footer={[
        <Button key="Cancel" onClick={handleInviteModalCancel}>
          <Trans id="data-partners.modal.footer.cancel">Cancel</Trans>
        </Button>,
        <Button
          key="Invite"
          type="primary"
          loading={loading}
          disabled={organizationCode.length === 0}
          onClick={handleInviteModalOk}
        >
          <Trans id="data-partners.modal.footer.invite">Invite</Trans>
        </Button>,
      ]}
    >
      <p>
        <Trans id="data-partners.modal.paragraph-1">
          You are inviting a new organization to receive shared data from
        </Trans>{" "}
        <span className={styles.organizationName}>{currentOrganization.toUpperCase()}</span>
        <Trans id="data-partners.modal.paragraph-2">
          . After the partner organization approve your request, you will be able to choose which data to share with
          them.
        </Trans>
      </p>
      <div className={styles.organizationCodeContainer}>
        <p>
          <Trans id="data-partners.modal.enter-organization-code">Please enter Organization Code:</Trans>
        </p>
        <Input value={organizationCode} onChange={handleCodeChange} />
      </div>
      <p className={styles.note}>
        <Trans id="data-partners.modal.note">
          Note: Please ask the data admin at the partner organization for the organization code.
        </Trans>
      </p>
    </Modal>
  );
}
