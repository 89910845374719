import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_INCIDENT_REPORT } from "graphql/apiService";

export default function useDeleteIncidentReport() {
  const { currentOrganization } = useUser();

  const [deleteIncidentReportMutation, { loading, error }] = useMutation(DELETE_INCIDENT_REPORT, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetIncidentReportsByOrganization"],
  });

  const deleteIncidentReport = async ({ id }) => {
    const res = await deleteIncidentReportMutation({ variables: { id } });
    return res.data?.deleteIncidentReport;
  };

  return [deleteIncidentReport, { loading, error }];
}
