import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";

import useTableSearch from "hooks/useTableSearch";
import { typeOptions as typeOptionsFn } from "../../helpers/mapping";

import styles from "./TableColumns.module.less";

export default function TableColumns({ onAssetMove, onAssetDelete }) {
  const { getColumnSearchProps, searchText, searchedColumn } = useTableSearch();
  const typeOptions = typeOptionsFn();

  const dropdown = (asset) => {
    const dropdownMenuItems = [
      {
        key: "move",
        label: "Move",
        onClick: () => onAssetMove(asset.id),
      },
      {
        key: "edit",
        label: <Link to={`/assets/${asset.id}/edit`}>Edit</Link>,
      },
      {
        key: "delete",
        label: "Delete",
        danger: true,
        onClick: () => onAssetDelete(asset.id),
      },
    ];
    return (
      <div className={styles.dropdownButtonContainer}>
        <Dropdown menu={{ items: dropdownMenuItems }} trigger={["click"]} placement="bottomRight">
          <MoreOutlined className={styles.icon} />
        </Dropdown>
      </div>
    );
  };

  return [
    {
      key: "name",
      dataIndex: "name",
      title: t({ id: "asset-map.list-view.column.asset-name", message: "Asset Name" }),
      render: (_, record) => {
        const searchString = searchedColumn === "name" ? searchText : "";
        let index = record.name.indexOf(searchString);
        index = index < 0 ? 0 : index;
        return (
          <Link to={`/assets/${record.id}`} className={styles.center}>
            <div className={styles.iconContainer}>{record.icon}</div>
            <div>
              {record.name.substring(0, index)}
              <span style={{ backgroundColor: "#fb7951" }}>
                {record.name.substring(index, index + searchString.length)}
              </span>
              {record.name.substring(index + searchString.length)}
            </div>
          </Link>
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps({
        dataIndex: "name",
        customRender: true,
      }),
    },
    {
      key: "type",
      dataIndex: "type",
      title: t({ id: "asset-map.list-view.column.asset-type", message: "Asset Type" }),
      render: (_, { type }) => typeOptions.find((o) => o.value === type)?.name,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      key: "action",
      title: t({ id: "asset-map.list-view.column.action", message: "Action" }),
      width: 100,
      render: (_, record) => dropdown(record),
      sorter: (a, b) => {
        if (!a.location?.formattedAddress && !b.location?.formattedAddress) {
          return 0;
        }
        if (!a.location?.formattedAddress && b.location?.formattedAddress) {
          return 1;
        }
        return a.location?.formattedAddress.localeCompare(b.location?.formattedAddress);
      },
    },
  ];
}
