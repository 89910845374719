/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import PlotlyBase from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";

import { SizeMe } from "react-sizeme";

import styles from "./LineChart.module.less";

const Plotly = createPlotlyComponent(PlotlyBase);

export default function LineChart({ data, layout, title, className }) {
  const defaultData = data.map((entry) => ({
    type: "scatter",
    mode: "lines",
    hoverinfo: "name+x+y",
    ...entry,
  }));

  const layoutData = {
    ...layout,
    hovermode: "x unified",
    showlegend: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1,
    },
    margin: {
      t: 0,
      r: 50,
      b: 35,
      l: 50,
      pad: 5,
    },
    yaxis: {
      ...layout?.yaxis,
      side: "left",
      showgrid: false,
    },
    yaxis2: {
      ...layout?.yaxis2,
      side: "right",
      overlaying: "y",
      showgrid: false,
    },
    xaxis: {
      showgrid: false,
    },
  };

  const config = {
    modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
    doubleClick: "autosize",
    displaylogo: false,
  };

  const paddingHorizontal = 0;
  const paddingVertical = 20;

  return (
    <div className={`${styles.container} ${className && className}`}>
      {title && <div className={styles.title}>{title}</div>}
      <SizeMe monitorHeight>
        {({ size: containerSize }) => (
          <div className={styles.plot}>
            <Plotly
              data={defaultData}
              layout={{
                ...layoutData,
                width: containerSize.width - paddingHorizontal,
                height: containerSize.height - paddingVertical,
              }}
              config={config}
            />
          </div>
        )}
      </SizeMe>
    </div>
  );
}
