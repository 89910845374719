import React from "react";
import { Modal, Card } from "antd";
import styles from "./StacksModal.module.less";

const StacksModal = ({ data, open, setOpen }) => (
  <Modal
    open={open}
    onCancel={() => setOpen(false)}
    footer={null}
    centered
    width="90vw"
    title={<div className={styles.titleContainer}>Stack Voltage & Runhours</div>}
    className={styles.modal}
  >
    <div className={styles.contentContainer}>
      <div className={styles.cards}>
        {data.map((stack, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={index} title={`Stack ${index + 1} (IDXXXX)`} className={styles.card}>
            <div className={styles.cardContent}>
              <div className={styles.cardItem}>
                <span>Avg Cell V</span>
                <span className={styles.cardValue}>{stack.avg}</span>
              </div>
              <div className={styles.cardItem}>
                <span>Min V</span>
                <span className={styles.cardValue}>{stack.min}</span>
              </div>
              <div className={styles.cardItem}>
                <span>Max V</span>
                <span className={styles.cardValue}>{stack.max}</span>
              </div>
              <div className={styles.cardItem}>
                <span>Runhours</span>
                <span className={styles.cardValue}>{stack.runtime}</span>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  </Modal>
);

export default StacksModal;
