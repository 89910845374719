import React from "react";
import { Button, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./Message.module.less";

export default function Message({ content, closeMessage }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{content}</div>
      <Divider type="vertical" className={styles.divider} />
      {closeMessage && (
        <Button type="text" className={styles.button} onClick={closeMessage}>
          <CloseOutlined />
        </Button>
      )}
    </div>
  );
}
