import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_ANNOTATION_MESSAGE, GET_ANNOTATION } from "graphql/apiService";

export default function useCreateAnnotationMessage() {
  const { currentOrganization } = useUser();

  const [createAnnotationMessageMutation, { loading, error }] = useMutation(CREATE_ANNOTATION_MESSAGE, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ({ data: { createAnnotationMessage: createdMessage } }) => [
      {
        query: GET_ANNOTATION,
        variables: {
          organizationSlug: currentOrganization,
          id: createdMessage.annotationId,
        },
      },
    ],
  });

  const createAnnotationMessage = async ({ annotationId, content }) => {
    const res = await createAnnotationMessageMutation({
      variables: {
        input: {
          annotationId,
          content,
        },
      },
    });
    return res.data.createAnnotationMessage;
  };

  return [createAnnotationMessage, { loading, error }];
}
