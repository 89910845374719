import { useUser } from "context/UserContext";
import { GET_EQUIPMENT } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useEquipment({ id }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_EQUIPMENT, {
    variables: {
      organizationSlug: currentOrganization,
      id,
    },
  });

  const equipment = data?.getEquipment;
  return { equipment, loading, error };
}
