import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Radio, App, Tag } from "antd";
import { UnorderedListOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { t, Trans } from "@lingui/macro";

import { useDeleteEquipment } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";
import captureException from "utils/capture-sentry-exception";

import AssetDetails from "../AssetDetails";
import AssetList from "../AssetList";
import Header from "../components/Header";

import { icons } from "../helpers/mapping";

import styles from "./AssetDisplay.module.less";

function AssetDisplay({
  isFolder,
  isShared,
  asset,
  assetList,
  assetIdMap,
  subAssets: providedSubAssets,
  allowedModes,
  assetTree,
}) {
  const initialMode = isFolder ? "LIST" : localStorage.getItem("assetDisplayMode") || "DETAILS";

  const { assetId } = useParams();
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const { message } = useCustomMessage();
  const [deleteAsset] = useDeleteEquipment();
  const [searchParams] = useSearchParams();
  const measureId = searchParams.get("measureId");

  const [mode, setMode] = useState(initialMode);
  const subAssets = providedSubAssets || assetList.filter((a) => a.parentId === assetId);

  const modes = allowedModes || ["LIST", "DETAILS"];

  const iconType = asset?.type || "UNKNOWN";
  const iconReact = icons[iconType].react;

  const sharedBy = isShared ? asset?.organizationSlug : null;

  useEffect(() => {
    if (measureId) {
      handleModeChange("DETAILS");
    }
  }, [measureId]);

  const content = () => {
    switch (mode) {
      case "LIST":
        return (
          <AssetList
            asset={asset}
            isShared={isShared}
            subAssets={subAssets}
            onAssetDelete={(id) => deleteModal(id, false)}
          />
        );
      case "DETAILS":
        return <AssetDetails asset={asset} assetIdMap={assetIdMap} />;
      default:
        return null;
    }
  };

  const modeIcon = (m) => {
    switch (m) {
      case "LIST":
        return <UnorderedListOutlined />;
      case "DETAILS":
        return <InfoCircleOutlined />;
      default:
        return null;
    }
  };

  const deleteModal = (id, navigateToAssets) => {
    modal.warning({
      title: t({
        id: "asset-map.asset.delete-modal.title",
        message: "Delete Asset",
      }),
      content: t({
        id: "asset-map.asset.delete-modal.content",
        message: "Are you sure you want to delete this Asset?",
      }),
      closable: true,
      maskClosable: true,
      onOk() {
        handleDelete(id, navigateToAssets);
      },
      okText: t({
        id: "asset-map.asset.delete-modal.confirm-button",
        message: "Delete",
      }),
      okType: "danger",
    });
  };

  const handleDelete = async (id, navigateToAssets) => {
    try {
      await deleteAsset({
        id,
      });
      if (navigateToAssets) {
        navigate("/assets");
      }
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "asset-map.asset.errors.delete-asset",
          message: "Failed to delete asset",
        })
      );
    }
  };

  const handleModeChange = (newMode) => {
    localStorage.setItem("assetDisplayMode", newMode);
    setMode(newMode);
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <Header isFolder={isFolder} assetTree={assetTree} onDelete={() => deleteModal(asset.id, true)} />
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {iconReact} <h2>{asset?.name}</h2>
            {sharedBy && (
              <Tag className={styles.tag}>
                <span className={styles.sharedBy}>
                  <Trans id="asset-map.asset.shared-by">Shared by</Trans>
                </span>{" "}
                {sharedBy}
              </Tag>
            )}
          </div>
          {modes.length > 1 && (
            <div className={styles.switchButtons}>
              <Radio.Group onChange={(e) => handleModeChange(e.target.value)} value={mode}>
                {modes.map((m) => (
                  <Radio.Button key={m} value={m}>
                    {modeIcon(m)}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
          )}
        </div>
        {content()}
      </div>
    </div>
  );
}

export default AssetDisplay;
