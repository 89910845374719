import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import captureException from "utils/capture-sentry-exception";
import { t, Trans } from "@lingui/macro";

import { useUser } from "context/UserContext";
import { useCreatePanel, useGenerateMeasures } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Button, Input, Table, App, Checkbox } from "antd";
import { PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";

import TableColumns from "./components/TableColumns";

import styles from "./Measures.module.less";

export default function Measures({ asset, isShared }) {
  const { currentOrganization, hasPermissions } = useUser();

  const [measures, setMeasures] = useState([]);
  const [selectedMeasureIds, setSelectedMeasureIds] = useState([]);
  const { modal } = App.useApp();
  const { message } = useCustomMessage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const measureId = searchParams.get("measureId");

  const [panelLoading, setPanelLoading] = useState(false);

  const [createPanel] = useCreatePanel();
  const [generateMeasures, { loading: measuresLoading }] = useGenerateMeasures();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const sortedMeasures = asset?.measures
      ?.slice()
      .sort((a, b) => a.slug.localeCompare(b.slug, "en", { numeric: true }));
    setMeasures(sortedMeasures);

    if (measureId && sortedMeasures) {
      const measureIndex = sortedMeasures.findIndex((measure) => measure.id === measureId);
      if (measureIndex >= 0) {
        const measurePage = Math.floor(measureIndex / pageSize) + 1;
        setCurrentPage(measurePage);
        if (!selectedMeasureIds.includes(measureId)) {
          setSelectedMeasureIds([...selectedMeasureIds, measureId]);
        }

        setTimeout(() => {
          const rowElement = document.querySelector(`[data-row-key='${measureId}']`);
          if (rowElement) {
            rowElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, currentOrganization, measureId, pageSize]);

  const handleCreatePanel = async ({ name, type }) => {
    if (selectedMeasureIds.length === 0) return;
    try {
      const panelInput = {
        name,
        type,
        time: {
          type: "REALTIME",
          interval: "3h",
        },
        measureIds: selectedMeasureIds,
      };
      const panel = await createPanel({ input: panelInput });
      navigate(`/panels/${panel.id}`);
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "asset-map.asset.tabs.measures.errors.create-panel",
          message: "Failed to save panel",
        })
      );
    }
  };

  const createPanelModal = () => {
    if (selectedMeasureIds.length < 1) {
      message.error(
        t({
          id: "asset-map.asset.tabs.measures.errors.create-panel.no-measures",
          message: "No measures selected",
        })
      );
    } else {
      let panelName;
      modal.info({
        title: t({
          id: "asset-map.asset.tabs.measures.create-modal.title",
          message: "Create Panel",
        }),
        content: (
          <div style={{ marginTop: "2rem" }}>
            <Input
              onChange={(e) => {
                panelName = e.target.value;
              }}
              size="large"
              placeholder={t({
                id: "asset-map.asset.tabs.measures.create-modal.content.input-placeholder",
                message: "Type panel name here",
              })}
            />
          </div>
        ),
        onOk: async () => {
          setPanelLoading(true);
          await handleCreatePanel({ name: panelName, type: "LINE_CHART" });
        },
        okText: t({
          id: "asset-map.asset.tabs.measures.create-modal.confirm-button",
          message: "Create",
        }),
        closable: true,
        maskClosable: true,
        confirmLoading: panelLoading,
      });
    }
  };

  const toggleSelectAll = () => {
    setSelectedMeasureIds((ids) => (ids.length === measures.length ? [] : measures.map((m) => m.id)));
  };

  const headerCheckbox = (
    <div className={styles.headerCheckboxContainer}>
      <Checkbox
        checked={selectedMeasureIds.length}
        indeterminate={selectedMeasureIds.length > 0 && selectedMeasureIds.length < measures.length}
        onChange={toggleSelectAll}
      />
      <div className={styles.numberContainer}>{selectedMeasureIds.length || ""}</div>
    </div>
  );

  const rowSelection = {
    selectedRowKeys: selectedMeasureIds,
    onChange: (selectedRowIds) => {
      setSelectedMeasureIds(selectedRowIds);
    },
    columnTitle: headerCheckbox,
  };

  return (
    <>
      <Table
        rowSelection={hasPermissions(["update:measures"]) ? rowSelection : undefined}
        columns={TableColumns({ setMeasures, isShared, hasPermissions })}
        dataSource={measures?.map((measure) => ({ ...measure, key: measure.id }))}
        className={styles.table}
        pagination={{
          current: currentPage,
          pageSize,
          showSizeChanger: true,
          onChange: (page, size) => {
            setCurrentPage(page);
            setPageSize(size);
          },
        }}
      />
      <div className={styles.bottomMenu}>
        <Button
          className={styles.bottomMenuButton}
          style={{
            color: measuresLoading ? "#ababab" : "#008b8a",
            visibility: isShared || !hasPermissions(["update:equipment"]) ? "hidden" : "visible",
          }}
          onClick={() => {
            generateMeasures({ equipmentId: asset.id });
          }}
          disabled={measuresLoading}
        >
          <ReloadOutlined
            className={styles.bottomMenuButtonIcon}
            style={{ color: measuresLoading ? "#ababab" : "#008b8a" }}
          />
          <Trans id="asset-map.asset.tabs.measures.refresh-measures">Measures</Trans>
        </Button>
        <div>
          {hasPermissions(["create:panels"]) && (
            <Button className={styles.bottomMenuButton} onClick={createPanelModal}>
              <PlusCircleOutlined className={styles.bottomMenuButtonIcon} />
              <Trans id="asset-map.asset.tabs.measures.add-graph">Graph</Trans>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
