import { useUser } from "context/UserContext";
import { GET_ANNOTATIONS_BY_PANEL } from "graphql/apiService";
import useQuery from "../../useQuery";

export default function useAnnotations({ panelId }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_ANNOTATIONS_BY_PANEL, {
    variables: {
      organizationSlug: currentOrganization,
      input: {
        panelId,
      },
    },
  });

  const annotations = data?.getAnnotationsByPanel;

  return { annotations, loading, error };
}
