import React from "react";
import { Tooltip, Button } from "antd";
import { t } from "@lingui/macro";
import { ReactComponent as GrafanaLogo } from "assets/grafana-logo.svg";
import styles from "./GrafanaButton.module.less";

const domain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";

function GrafanaButton({ dashboardUrl, text = t({ id: "grafana-button.text", message: "Open in Grafana" }) }) {
  const url = dashboardUrl || `https://grafana.${domain}`;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Tooltip title={text} placement="topRight">
        <Button className={styles.grafanaButton}>
          <GrafanaLogo />
        </Button>
      </Tooltip>
    </a>
  );
}

export default GrafanaButton;
