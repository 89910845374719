import React, { useEffect } from "react";
import _ from "lodash";
import { Modal, Table, Radio } from "antd";
import { t } from "@lingui/macro";

import { getMeasuresByEquipment } from "../helper/axesForm.helper";
import { computeRowSpan } from "../helper/table.helper";

function MeasuresModal({ open, setOpen, formData, panel, measureAssignments, setMeasureAssignments }) {
  const savedMeasureAssignments = panel.customAxes
    ? panel.customAxes
        .map((axis, index) => axis.measureIds.map((mId) => ({ measureId: mId, assignedAxis: index })))
        .flat()
        .reduce((measureMap, m) => ({ ...measureMap, [m.measureId]: m.assignedAxis }), {})
    : {};

  const measuresByEquipment = getMeasuresByEquipment(panel);

  const allMeasures = Object.values(measuresByEquipment)
    .map((eq) => eq.measures)
    .flat()
    .map((m) => m.id);

  const initialAssignments = allMeasures.reduce(
    (assignmentMap, mId) => ({
      ...assignmentMap,
      [mId]:
        savedMeasureAssignments[mId] && savedMeasureAssignments[mId] < formData.length
          ? savedMeasureAssignments[mId]
          : 0,
    }),
    {}
  );

  useEffect(() => {
    if (_.isEmpty(measureAssignments) && panel && formData?.length) {
      setMeasureAssignments(initialAssignments);
    }
  }, [panel, formData, measureAssignments, initialAssignments, setMeasureAssignments]);

  useEffect(() => {
    if (panel && formData?.length === 1) {
      setMeasureAssignments(initialAssignments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.length]);

  const tableData = Object.values(measuresByEquipment)
    .map((eq) => eq.measures)
    .flat()
    .sort((a, b) => {
      const assetNameCompare = a.equipment.name.localeCompare(b.equipment.name);
      if (assetNameCompare !== 0) return assetNameCompare;
      return a.slug.localeCompare(b.slug);
    })
    .map((m) => ({
      key: m.id,
      measureId: m.id,
      assetName: m.equipment.name,
      assetId: m.equipment.id,
      measureName: m.slug,
      assignedAxis: measureAssignments[m.id],
    }));

  const tableDataWithRowSpan = computeRowSpan(tableData);

  const tableColumns = [
    {
      title: t({ id: "panel-settings-measures-modal.table.asset", message: "Asset" }),
      dataIndex: "assetName",
      key: "asset",
      render: (assetName, record) => ({
        children: assetName,
        props: { rowSpan: record.rowSpan },
      }),
    },
    {
      title: t({ id: "panel-settings-measures-modal.table.measure", message: "Measure" }),
      dataIndex: "measureName",
      key: "measure",
    },
    ...formData.map((axis, index) => ({
      title: axis.name,
      key: axis.name,
      render: (text, record) => (
        <Radio
          checked={record.assignedAxis === index}
          onChange={(e) => {
            if (e.target.checked) {
              setMeasureAssignments({ ...measureAssignments, [record.measureId]: index });
            }
          }}
        />
      ),
    })),
  ];

  return (
    <Modal
      title={t({ id: "panel-settings.measures-modal.title", message: "Choose the desired axis for each measure" })}
      open={open}
      onCancel={() => setOpen(false)}
      onOk={() => {
        setOpen(false);
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      styles={{ content: { padding: 0 }, header: { padding: 14 }, footer: { padding: 14 } }}
      width={900}
    >
      <Table
        columns={tableColumns}
        dataSource={tableDataWithRowSpan}
        pagination={
          allMeasures?.length <= 20
            ? false
            : {
                defaultPageSize: 20,
              }
        }
      />
    </Modal>
  );
}

export default MeasuresModal;
