import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import LocalizedApp from "containers/LocalizedApp/LocalizedApp";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "dev") {
  Sentry.init({
    release: process.env.REACT_APP_BUILD_VERSION,
    dsn: "https://cc7165798e534b50bdaa967691372c09@o922355.ingest.sentry.io/5869354",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<LocalizedApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
