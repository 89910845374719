import { useAuth0 } from "@auth0/auth0-react";

import { getConfig } from "config/limble/limble_config";
import { getOrgFromSubdomain } from "utils/auth-utils";

const proxyUrl = process.env.REACT_APP_THIRDPARTY_PROXY_URL || "https://api.v1.betterh2.com";
const auth0ApiUrl = `${proxyUrl}/thirdparty/auth0`;
const limbleApiUrl = `${proxyUrl}/thirdparty/limble`;

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

function useThirdPartyProxy() {
  const { user, getAccessTokenSilently } = useAuth0();
  const currentOrganization = getOrgFromSubdomain();

  const getMoreAssets = async (cursor = 0) => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const LIMIT = 10000;

    let moreAssets = [];
    const response = await fetch(
      `${limbleApiUrl}/${currentOrganization}/assets${cursor > 0 ? `?cursor=${cursor}&limit=${LIMIT}` : ""}`,
      requestParameters
    );
    const assets = await response.json();
    if (assets.length === LIMIT) {
      moreAssets = await getMoreAssets(assets[assets.length - 1].assetID);
    }
    return [...assets, ...moreAssets];
  };

  const getAssets = async () => getMoreAssets();

  const getLocations = async () => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(`${limbleApiUrl}/${currentOrganization}/locations`, requestParameters);
    return response.json();
  };

  const getTags = async () => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(`${limbleApiUrl}/${currentOrganization}/tags`, requestParameters);
    return response.json();
  };

  const getPriorities = async () => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(`${limbleApiUrl}/${currentOrganization}/priorities`, requestParameters);
    return response.json();
  };

  const getStatuses = async () => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(`${limbleApiUrl}/${currentOrganization}/statuses`, requestParameters);
    return response.json();
  };

  const createTask = async ({ locationID, name, assetID, due, description }) => {
    const accessToken = await getAccessTokenSilently();

    const config = getConfig();

    const requestParameters = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        locationID,
        name,
        assetID,
        templateID: config.templateID,
        type: config.taskType,
        due,
        description,
        requestName: user?.name,
        requestEmail: user?.email,
      }),
    };
    const response = await fetch(`${limbleApiUrl}/${currentOrganization}/tasks`, requestParameters);
    return response.json();
  };

  const getTasks = async () => {
    const accessToken = await getAccessTokenSilently();

    const requestParameters = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(`${limbleApiUrl}/${currentOrganization}/tasks`, requestParameters);
    return response.json();
  };

  const getTaskInstructions = async ({ taskID }) => {
    const accessToken = await getAccessTokenSilently();

    const requestParameters = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(
      `${limbleApiUrl}/${currentOrganization}/tasks/${taskID}/instructions`,
      requestParameters
    );
    return response.json();
  };

  const createInstruction = async ({ taskID, instruction }) => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        instruction,
        type: 9,
        parentInstructionID: 0,
      }),
    };
    const response = await fetch(
      `${limbleApiUrl}/${currentOrganization}/tasks/${taskID}/instructions`,
      requestParameters
    );
    return response.json();
  };

  const uploadFilesToInstruction = async ({ taskID, instructionID, files }) => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const maxAllowedSize = 5 * 1024 * 1024;
    return Promise.all(
      files
        .filter((file) => file)
        // eslint-disable-next-line array-callback-return
        .map(async (file) => {
          try {
            if (file instanceof File) {
              if (file.size > maxAllowedSize)
                return {
                  ...file,
                  success: false,
                };
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = async () => {
                await fetch(
                  `${limbleApiUrl}/${currentOrganization}/tasks/${taskID}/instructions/${instructionID}/image`,
                  {
                    ...requestParameters,
                    body: JSON.stringify({
                      fileBase64: reader.result,
                      filename: file.name,
                    }),
                  }
                );
              };
              reader.onerror = () => ({
                ...file,
                success: false,
              });
              return {
                ...file,
                success: true,
              };
            }
            await fetch(`${limbleApiUrl}/${currentOrganization}/tasks/${taskID}/instructions/${instructionID}/image`, {
              ...requestParameters,
              body: JSON.stringify({
                fileBase64: file,
                filename: `Auto capture ${taskID}.png`,
              }),
            });
            return {
              file: "auto capture",
              success: true,
            };
          } catch (error) {
            return {
              ...file,
              success: false,
            };
          }
        })
    );
  };

  const getInvitations = async (organizationAuth0Id) => {
    const accessToken = await getAccessTokenSilently();

    const requestParameters = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return fetch(`${auth0ApiUrl}/organizations/${organizationAuth0Id || user.org_id}/invitations`, requestParameters)
      .then(handleErrors)
      .then((request) => request.json());
  };

  const revokeInvitation = async (invitationId, organizationAuth0Id) => {
    const accessToken = await getAccessTokenSilently();

    const requestParameters = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await fetch(
      `${auth0ApiUrl}/organizations/${organizationAuth0Id || user.org_id}/invitations/${invitationId}`,
      requestParameters
    ).then(handleErrors);
  };

  const inviteUsers = async (invitees, organizationAuth0Id) => {
    const accessToken = await getAccessTokenSilently();
    const requestData = { invitees, send_invitation_email: true };
    const requestParameters = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    await fetch(
      `${auth0ApiUrl}/organizations/${organizationAuth0Id || user.org_id}/invitations`,
      requestParameters
    ).then(handleErrors);
  };

  return {
    getTaskInstructions,
    getAssets,
    getInvitations,
    getLocations,
    getPriorities,
    getStatuses,
    getTags,
    getTasks,
    createTask,
    createInstruction,
    inviteUsers,
    uploadFilesToInstruction,
    revokeInvitation,
  };
}

export default useThirdPartyProxy;
