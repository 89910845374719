import React from "react";
import dayjs from "dayjs";

import ResponsivePlot from "components/ResponsivePlot";

export default function OperationsModeBarChart({ data }) {
  const dates = Array.from({ length: 7 }, (_, i) => dayjs().subtract(i, "days").format("MMM D")).reverse();
  const y1 = data.map((d) => d[0]);
  const y2 = data.map((d) => d[1]);
  const y3 = data.map((d) => 24 - d[0] - d[1]);

  const trace1 = {
    x: dates,
    y: y1,
    name: "Electrolyzing",
    type: "bar",
    marker: { color: "#008b8a" },
  };

  const trace2 = {
    x: dates,
    y: y2,
    name: "Standby",
    type: "bar",
    marker: { color: "#222" },
  };
  const trace3 = {
    x: dates,
    y: y3,
    name: "Off/Other",
    type: "bar",
    marker: { color: "#C7DDCC" },
  };

  return (
    <ResponsivePlot
      data={[trace1, trace2, trace3]}
      layout={{
        yaxis: { title: "Hours", tickformat: ".1f", tickvals: [6, 12, 18, 24] },
        barmode: "stack",
        legend: {
          traceorder: "normal",
          orientation: "h",
          y: 1.1,
          x: 0.5,
          xanchor: "center",
          yanchor: "bottom",
        },
        margin: {
          l: 50,
          r: 18,
          b: 50,
          t: 5,
          pad: 4,
        },
      }}
      config={{
        modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
        displaylogo: false,
      }}
    />
  );
}
