import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { t, Trans } from "@lingui/macro";
import { useDeleteUserOrganizationProfile } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Button, Dropdown, App } from "antd";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import styles from "./UserActions.module.less";

function UserActions({ userID, username }) {
  const [searchParams] = useSearchParams();
  const organizationSlug = searchParams.get("organizationSlug");

  const [removeUser] = useDeleteUserOrganizationProfile(organizationSlug);
  const { modal } = App.useApp();
  const { message } = useCustomMessage();

  const actionItems = [
    {
      text: t({
        id: "roles-admin.user-actions.details",
        message: `View Details`,
      }),
      action: () =>
        navigate(`/roles-admin/members/${userID}${organizationSlug ? `?organizationSlug=${organizationSlug}` : ``}`),
    },
    {
      text: t({
        id: "roles-admin.user-actions.remove",
        message: `Remove`,
      }),
      action: () => {
        showConfirmModal();
      },
      danger: true,
    },
  ];

  const actionMenuItems = actionItems.map((item) => ({
    key: item.text,
    onClick: item.action,
    label: item.text,
    danger: item.danger,
  }));

  const showConfirmModal = () => {
    modal.confirm({
      title: t({
        id: "roles-admin.user-actions.remove.confirm-message",
        message: `Are you sure you want to remove the user ${username} from your organization?`,
      }),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk: async () => {
        try {
          await removeUser({ auth0Id: userID });
          message.success(
            t({
              id: "messages.success.remove-user",
              message: `Successfully removed ${username} from your organization`,
            })
          );
        } catch (err) {
          message.error(
            t({
              id: "messages.error.remove-user",
              message: `Failed to remove ${username} from your organization`,
            })
          );
        }
      },
    });
  };

  const navigate = useNavigate();

  const smallScreen = useMediaQuery({
    query: "(max-width: 1199px)",
  });

  return smallScreen ? (
    <Dropdown menu={{ items: actionMenuItems }} trigger={["click"]}>
      <Button onClick={(event) => event.stopPropagation()}>
        <Trans id="roles-admin.user-actions.actions">
          Actions <DownOutlined />
        </Trans>
      </Button>
    </Dropdown>
  ) : (
    <div className={styles.actions}>
      {actionItems.map((item) => (
        <Button key={item.text} type="link" className={styles.actionLink} onClick={item.action}>
          {item.text}
        </Button>
      ))}
    </div>
  );
}

export default UserActions;
