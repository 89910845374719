import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_REPORT_SUGGESTIONS } from "graphql/apiService";

export default function useUpdateReportSuggestions() {
  const { currentOrganization } = useUser();

  const [updateSuggestionsMutation, { loading, error }] = useMutation(UPDATE_REPORT_SUGGESTIONS, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateReportSuggestions = async ({ id }) => {
    const res = await updateSuggestionsMutation({ variables: { id } });
    return res?.data?.updateSuggestions;
  };

  return [updateReportSuggestions, { loading, error }];
}
