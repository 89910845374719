import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { t, Trans } from "@lingui/macro";

import { useApolloClient } from "@apollo/client";
import { useUser } from "context/UserContext";

import {
  useGetOrganizationProfile,
  useUpdateOrganizationProfile,
  useGetMyUserAccount,
  useUpdateMyUserAccount,
  useUpdateMyUserAccountPicture,
  useUploadUserAccountPictureSuccess,
  useUpdateMyNotificationPreferences,
} from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import {
  Tabs,
  Card,
  Modal,
  Slider,
  Input,
  Form,
  Button,
  Spin,
  Avatar as AvatarAntD,
  App,
  Checkbox,
  Tooltip,
} from "antd";
import { EditTwoTone, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import Avatar from "components/Avatar";
import LanguageSelector from "components/LanguageSelector";
import ImageUpload from "components/ImageUpload";

import styles from "./Account.module.less";

function Account() {
  const navigate = useNavigate();
  const apollo = useApolloClient();

  const { currentOrganization, user } = useUser();

  const { myUserAccount, loading: userLoading } = useGetMyUserAccount();
  const [updateMyUserAccount, { loading: loadingUpdateUserAccount }] = useUpdateMyUserAccount();
  const [updateMyUserAccountPicture] = useUpdateMyUserAccountPicture();
  const [uploadUserAccountPictureSuccess] = useUploadUserAccountPictureSuccess();
  const { modal } = App.useApp();
  const { message } = useCustomMessage();

  const { organizationProfile } = useGetOrganizationProfile();
  const [updateOrganizationProfile] = useUpdateOrganizationProfile();

  const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
  const [potentialPictureURL, setPotentialPictureURL] = useState("");
  const imageInputRef = useRef(null);

  const startingTab = new URLSearchParams(useLocation().search).get("tab");

  const userInfo = [
    {
      propertyName: t({ id: "account.user-info.email", message: "Email" }),
      property: "email",
      value: user?.email || "...",
      editable: false,
    },
    {
      propertyName: t({ id: "account.user-info.full-name", message: "Full name" }),
      property: "name",
      value: myUserAccount?.name,
      editable: true,
      validationRules: [
        {
          required: true,
          message: t({
            id: "account.user-info.name.validation-message",
            message: "Name cannot be empty",
          }),
        },
      ],
    },
  ];

  const updateUser = async (values) => {
    try {
      await updateMyUserAccount(values);
      message.success(
        t({ id: "messages.success.user-account-update", message: "Successfully updated your user account" })
      );
    } catch {
      message.error(t({ id: "messages.error.user-account-update", message: "Could not update user account" }));
    }
  };

  const [userSettingsForm] = Form.useForm();
  const [updateMyNotificationPreferences, { loading: loadingUpdateNotificationPreferences }] =
    useUpdateMyNotificationPreferences();

  const initialUserSettings = () => {
    userSettingsForm.setFieldsValue({
      notifications: [...(myUserAccount.notificationPreferences?.email ? ["email"] : [])],
    });
  };

  useEffect(() => {
    if (myUserAccount) {
      initialUserSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUserAccount]);

  const updateSettings = async (values) => {
    try {
      await updateMyNotificationPreferences({
        notificationPreferences: {
          email: values.notifications.includes("email"),
        },
      });
      message.success(
        t({ id: "messages.success.user-settings-update", message: "Successfully updated your user settings" })
      );
    } catch {
      message.error(t({ id: "messages.error.user-settings-update", message: "Could not update user settings" }));
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    // Only Allow files up to 5MB
    const maxAllowedSize = 5 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      modal.error({
        title: t({ id: "account.image-size-error-title", message: "Image file size is too large" }),
        content: t({
          id: "account.image-size-error-content",
          message: "Please upload a .jpg, .png, .jpeg file less than 5MB.",
        }),
      });
      return;
    }
    // Allow User to Crop Picture
    setPotentialPictureURL(URL.createObjectURL(file));
    setAvatarModalIsOpen(true);
    e.target.value = null;
  };

  // After Cropping is complete, save avatar picture
  const onCompleteAvatarEdit = async () => {
    setAvatarModalIsOpen(false);
    if (avatarEditorRef.current) {
      const canvas = avatarEditorRef.current.getImageScaledToCanvas().toDataURL("image/jpeg", 0.7);
      const dataUrl = await fetch(canvas);
      const blob = await dataUrl.blob();
      await saveProfilePic(blob);
    }
  };

  const saveProfilePic = async (blob) => {
    if (blob === null) {
      return;
    }
    try {
      // Get Pre-signed URL to upload the document
      const onUploadFinish = message.loading("Uploading new profile picture...");
      const { uploadUrl } = await updateMyUserAccountPicture();
      await fetch(uploadUrl, {
        method: "PUT",
        body: blob,
      });
      await uploadUserAccountPictureSuccess();
      onUploadFinish();
      await apollo.refetchQueries({
        updateCache(cache) {
          cache.modify({
            fields: {
              getMyUserAccount(value, { INVALIDATE }) {
                return INVALIDATE;
              },
            },
          });
        },
      });
      message.success(
        t({ id: "messages.success.profile-pic-update", message: "Successfully updated your profile picture" })
      );
    } catch (err) {
      message.error(t({ id: "messages.error.profile-pic-update", message: "Could not update profile picture" }));
    }
  };

  const ImageEditModal = ({ avatarEditorRef }) => {
    const [zoom, setZoom] = useState(1);
    return (
      <Modal
        title={t({ id: "account.crop-avatar", message: "Crop Avatar" })}
        open={avatarModalIsOpen}
        onOk={onCompleteAvatarEdit}
        onCancel={() => setAvatarModalIsOpen(false)}
        maskClosable={false}
      >
        <div className={styles.avatarEditorContainer}>
          <AvatarEditor
            ref={avatarEditorRef}
            image={potentialPictureURL}
            width={250}
            height={250}
            borderRadius={999}
            border={50}
            color={[255, 255, 255, 0.6]}
            backgroundColor="white"
            scale={zoom}
            rotate={0}
          />
        </div>
        <span>
          <Trans id="account.crop-avatar.zoom">Zoom:</Trans>
        </span>
        <Slider
          min={1}
          max={3}
          step={0.1}
          onChange={(val) => setZoom(val)}
          tooltip={{
            open: false,
          }}
        />
      </Modal>
    );
  };

  const avatarEditorRef = useRef(null);

  const uploadOrganizationLogo = async (file) => {
    try {
      const organizationLogo = await updateOrganizationProfile();
      await fetch(organizationLogo.uploadUrl, {
        method: "PUT",
        body: file,
      });

      // Need to invalidate to update the logo in the sidebar
      await apollo.refetchQueries({
        updateCache(cache) {
          cache.modify({
            fields: {
              getOrganizationProfile(value, { INVALIDATE }) {
                return INVALIDATE;
              },
            },
          });
        },
      });
      message.success(
        t({
          id: "account.organization.logo.upload-success",
          message: "New organization logo has been successfully uploaded",
        })
      );
    } catch {
      message.error(
        t({
          id: "account.errors.organization.logo.upload-error",
          message: "Failed to upload organization logo",
        })
      );
    }
  };
  return (
    <>
      <ImageEditModal avatarEditorRef={avatarEditorRef} />
      <div className={styles.grid}>
        <Card className={styles.userCard} title={<div />}>
          <div className={styles.cardContent}>
            <div className={styles.pictureContainer}>
              <div aria-hidden className={styles.editIconContainer} onClick={() => imageInputRef.current.click()}>
                <input
                  type="file"
                  name="image"
                  id="image"
                  accept=".png,.jpg,.jpeg"
                  style={{ display: "none" }}
                  ref={imageInputRef}
                  onChange={handleProfilePicChange}
                />
                <EditTwoTone twoToneColor="#FF7950" />
              </div>

              {userLoading && (
                <AvatarAntD className={`${styles.avatar} ${styles.avatarLoading}`} icon={<LoadingOutlined />} />
              )}
              {!userLoading && myUserAccount?.pictureUrl && (
                <AvatarAntD className={styles.avatar} src={myUserAccount?.pictureUrl} />
              )}
              {!userLoading && !myUserAccount?.pictureUrl && (
                <Avatar className={styles.avatarPlaceholder} name={myUserAccount?.name} showName={false} />
              )}
            </div>
            <span className={styles.username}>{user?.email || "..."}</span>
          </div>
        </Card>
        <Card className={styles.profileInfo}>
          <Tabs
            defaultActiveKey={startingTab}
            onTabClick={(key, _event) => {
              navigate(`/account?tab=${key}`);
            }}
            items={[
              {
                key: "general-information",
                label: t({ id: "account.tabs.general-information", message: "General Information" }),
                children: (
                  <div className={styles.container}>
                    {userLoading && <Spin />}
                    {!userLoading && (
                      <Form className={styles.form} onFinish={updateUser}>
                        {userInfo.map((item) => (
                          <div key={item.propertyName} className={styles.profileItem}>
                            <span className={styles.property}>{item.propertyName}</span>
                            {item.editable && (
                              <Form.Item
                                className={styles.property}
                                name={item.property}
                                initialValue={item.value}
                                rules={item.validationRules}
                              >
                                <Input className={styles.input} />
                              </Form.Item>
                            )}
                            {!item.editable && <span className={styles.value}>{item.value}</span>}
                          </div>
                        ))}
                        <Form.Item>
                          <Button type="primary" htmlType="submit" loading={loadingUpdateUserAccount}>
                            <Trans id="account.form.button.update">Update</Trans>
                          </Button>
                        </Form.Item>
                      </Form>
                    )}
                  </div>
                ),
              },
              {
                key: "organization-information",
                label: t({ id: "account.tabs.organization", message: "Organization" }),
                children: (
                  <div className={styles.container}>
                    <div className={styles.profileItem}>
                      <span className={styles.property}>
                        <Trans id="account.organization.id.property-name">ID</Trans>
                      </span>
                      <span className={styles.value}>{currentOrganization}</span>
                    </div>
                    <div className={styles.settingsItem}>
                      <h5>
                        <Trans id="account.organization.logo.property-name">Logo</Trans>
                      </h5>
                      <ImageUpload
                        className={styles.organizationImage}
                        src={organizationProfile?.pictureUrl}
                        imageCallback={uploadOrganizationLogo}
                        okText={t({ id: "account.organization.logo.modal-ok-text", message: "Upload" })}
                        circle
                        scaled
                        quality={0.7}
                      />
                    </div>
                  </div>
                ),
              },
              {
                key: "settings-and-privacy",
                label: t({ id: "account.tabs.settings-and-privacy", message: "Settings & Privacy" }),
                children: (
                  <div className={styles.container}>
                    <div className={styles.settingsItem}>
                      <h5>
                        <Trans id="account.language">Language</Trans>
                      </h5>
                      <LanguageSelector />
                    </div>
                    <div className={styles.settingsItem}>
                      <Form className={styles.form} form={userSettingsForm} onFinish={updateSettings}>
                        <h3 className={styles.formSection}>
                          <Trans id="account.settings.notifications.title">Notification Preferences</Trans>
                        </h3>
                        <Form.Item className={styles.property} name="notifications">
                          <Checkbox.Group className={styles.profileItem}>
                            <span className={styles.property}>
                              <Trans id="account.settings.notifications.email.label">Email</Trans>{" "}
                              <Tooltip
                                title={t({
                                  id: "account.settings.notifications.email.tooltip",
                                  message: "We notify you by email when you are not using the app",
                                })}
                              >
                                <InfoCircleOutlined className={styles.infoIcon} />
                              </Tooltip>
                            </span>
                            <Checkbox value="email" />
                          </Checkbox.Group>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" loading={loadingUpdateNotificationPreferences}>
                            <Trans id="account.settings.notifications.button.update">Update</Trans>
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </Card>
      </div>
    </>
  );
}

export default Account;
