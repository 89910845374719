import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Spin } from "antd";
import { t } from "@lingui/macro";

const needReturnTo = (location) => {
  const { pathname } = location;
  return pathname !== "/login" && pathname !== "/" && pathname !== "/home";
};

function PrivateRoute() {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const returnToParam = needReturnTo(location)
    ? `?returnTo=${encodeURIComponent(`${location.pathname}${location.search}`)}`
    : "";
  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
        <Spin
          size="large"
          tip={t({
            id: "spin.loading",
            message: `Loading...`,
          })}
        />
      </div>
    );
  }
  if (isAuthenticated) {
    // Remove orgSwitch param once authenticated
    if (window.location.href.includes("orgSwitch=true")) {
      const newUrl = new URL(window.location);
      newUrl.searchParams.delete("orgSwitch");
      window.history.pushState(null, "", newUrl.toString());
    }
    return <Outlet />;
  }
  return <Navigate to={`/login${returnToParam}`} />;
}

export default PrivateRoute;
