/* eslint-disable no-restricted-syntax */
export const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export const findNestedObj = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export const arrayToTree = (array, childField, parentField, parent = null) =>
  array
    .filter((item) => item[parentField] === parent)
    .map((child) => ({
      ...child,
      children: arrayToTree(array, childField, parentField, child[childField]),
    }));

export const treeToArray = (array = [], childField = "subAssets") =>
  array.concat(...array.map((child) => treeToArray(child[childField], childField))).map((childItem) => {
    const child = { ...childItem };
    delete child[childField];
    return child;
  });

export const findAncestors = (assetId, assetIdMap) => {
  let currentAsset = assetIdMap.get(assetId);
  const ancestors = [currentAsset];

  while (currentAsset && currentAsset.parentId) {
    const parent = assetIdMap.get(currentAsset.parentId);
    if (parent) {
      ancestors.unshift(parent);
      currentAsset = parent;
    } else {
      break;
    }
  }

  return ancestors;
};

export const findSubTree = (nodes, key) => {
  const helper = (currentNodes) => {
    for (const node of currentNodes) {
      if (node.key === key) {
        return node;
      }
      if (node.children) {
        const result = helper(node.children);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };
  return helper(nodes);
};

export const addMeasuresToTree = (assetTree = []) =>
  assetTree.map((asset) => {
    const newAsset = { ...asset };
    if (newAsset.measures && Array.isArray(newAsset.measures)) {
      const measuresCopy = [...newAsset.measures];
      const measureNodes = measuresCopy
        .sort((a, b) => a.slug.localeCompare(b.slug, "en", { numeric: true }))
        .map((measure) => ({
          key: measure.id,
          title: measure.slug,
          value: measure.id,
          parentAssetId: measure.equipmentId,
        }));

      newAsset.children = [...(newAsset.children || []), ...measureNodes];
    }

    if (newAsset.children && newAsset.children.length > 0) {
      newAsset.children = addMeasuresToTree(newAsset.children);
    }

    return newAsset;
  });
