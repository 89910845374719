import { useMutation } from "@apollo/client";
import { GET_ORGANIZATION_PROFILES, SYNC_ORGANIZATION_PROFILES } from "graphql/apiService";

export default function useUpdateOrganizationProfile() {
  const [syncOrganizationProfilesMutation, { loading, error }] = useMutation(SYNC_ORGANIZATION_PROFILES, {
    update: (cache, { data: { syncOrganizationProfiles: organizationProfiles } }) => {
      cache.writeQuery({
        query: GET_ORGANIZATION_PROFILES,
        data: {
          getOrganizationProfiles: organizationProfiles,
        },
      });
    },
  });

  const syncOrganizationProfiles = async () => {
    const res = await syncOrganizationProfilesMutation();
    return res.data.syncOrganizationProfiles;
  };

  return [syncOrganizationProfiles, { loading, error }];
}
