import React, { useEffect, useState } from "react";
import captureException from "utils/capture-sentry-exception";
import { t, Trans } from "@lingui/macro";

import { useComments, useCreateComment, useUpdateThread } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Button, List, Drawer, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import MentionsInput from "components/MentionsInput";
import Comment from "../Comment";

import styles from "../CustomDashboardComments.module.less";

function Thread({ users, showCurrentThread, handleCloseThread, onToggleComments, thread, avatarBackground }) {
  const { id: threadId, status } = thread;

  const [updateThread] = useUpdateThread();
  const { message } = useCustomMessage();
  const { comments, commentLoading, error: getCommentError } = useComments({ threadId });
  const [createComment] = useCreateComment({ threadId });

  const [commentData, setCommentData] = useState([]);
  const [input, setInput] = useState("");
  const [resolved, setResolved] = useState(status === "RESOLVED");

  useEffect(() => {
    if (getCommentError) {
      captureException(getCommentError);
      message.error(
        t({
          id: "thread.errors.comment",
          message: "Failed to get comments",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCommentError]);

  useEffect(() => {
    if (comments) {
      setCommentData(comments);
    }
  }, [comments]);

  const returnButton = () => (
    <Button className={styles.closeButton} shape="circle" type="text" onClick={handleCloseThread}>
      <ArrowLeftOutlined />
    </Button>
  );

  const updateThreadStatus = async (newStatus) => {
    await updateThread({ input: { id: threadId, status: newStatus } });
    message.success(
      t({
        id: "thread.success.update,",
        message: "Successfully updated thread",
      })
    );
  };

  const handleResolve = () => {
    setResolved(true);
    updateThreadStatus("RESOLVED");
  };

  const handleUnresolve = () => {
    setResolved(false);
    updateThreadStatus("UNRESOLVED");
  };

  const handleSubmitComment = async () => {
    if (input !== "") {
      const commentInput = { content: input };
      try {
        await createComment({
          input: commentInput,
        });
        setInput("");
      } catch (err) {
        captureException(err);
        message.error(
          t({
            id: "thread.error.comment-add",
            message: "Failed to add comment",
          })
        );
      }
    }
  };

  return (
    <Drawer
      title={returnButton()}
      placement="right"
      onClose={onToggleComments}
      open={showCurrentThread}
      getContainer={false}
      width={318}
    >
      <div className={styles.commentContainer}>
        <div className={styles.comments}>
          {commentLoading ? (
            <div className={styles.spinContainer}>
              <Spin />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={commentData}
              renderItem={(comment) => (
                <List.Item key={comment.id}>
                  <Comment comment={comment} thread={thread} avatarBackground={avatarBackground} />
                </List.Item>
              )}
            />
          )}
        </div>
        <div className={styles.input}>
          {!resolved && <MentionsInput users={users} input={input} setInput={setInput} />}
          <div className={styles.inputButton}>
            {resolved ? (
              <Button onClick={handleUnresolve}>
                <Trans id="threads.input.unresolve">Unresolve</Trans>
              </Button>
            ) : (
              <>
                <Button onClick={() => handleSubmitComment()} type="primary">
                  <Trans id="threads.input.comment">Comment</Trans>
                </Button>
                <Button onClick={handleResolve}>
                  <Trans id="threads.input.resolve">Resolve</Trans>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Thread;
