import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

import {
  useCreateUserOrganizationProfiles,
  useGetOrganizationProfile,
  useUserOrganizationProfiles,
} from "hooks/apiService";
import useThirdPartyProxy from "hooks/useThirdPartyProxy";

import useCustomMessage from "hooks/useCustomMessage";
import { useUser } from "context/UserContext";

import { Select, Form } from "antd";

import RoleSelector from "../RoleSelector";
import BackButton from "../BackButton";

import styles from "./InviteUsers.module.less";

function InviteUsers() {
  const { message } = useCustomMessage();
  const [form] = Form.useForm();

  const { currentOrganization } = useUser();

  const [searchParams] = useSearchParams();
  const organizationSlug = searchParams.get("organizationSlug");

  const { organizationProfile } = useGetOrganizationProfile(organizationSlug);
  const { userOrganizationProfiles } = useUserOrganizationProfiles(organizationSlug);
  const { getInvitations } = useThirdPartyProxy();

  const [createUserOrganizationProfiles] = useCreateUserOrganizationProfiles();
  const [actionLoading, setActionLoading] = useState(false);
  const [invitations, setInvitations] = useState([]);

  const fetchInvitations = async () => {
    try {
      const res = await getInvitations(organizationProfile.auth0Id);
      setInvitations(res);
    } catch (error) {
      setInvitations([]);
    }
  };

  useEffect(() => {
    if (organizationProfile) {
      fetchInvitations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationProfile]);

  const handleSendInvitations = async (roleSlugs) => {
    try {
      await form.validateFields(["emails"]);
    } catch (err) {
      return;
    }
    try {
      setActionLoading(true);
      const emails = form.getFieldValue("emails");
      const invitees = emails.map((email) => ({
        email,
        roleSlugs,
      }));
      await createUserOrganizationProfiles({ invitees, organizationSlug: organizationSlug || currentOrganization });
      form.resetFields();
      message.success(
        t({
          id: "messages.success.send-invitations",
          message: "Successfully sent invitations to the selected email addresses",
        })
      );
    } catch {
      message.error(
        t({
          id: "messages.error.send-invitations",
          message: "Failed to send invitations to the selected email addresses",
        })
      );
    } finally {
      setActionLoading(false);
    }
  };

  const emailValidator = (rule, values, callback) => {
    const emailRegex = /^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
    const now = new Date();
    const existingEmails = userOrganizationProfiles?.map((userProfile) => userProfile.userAccount.email);
    const invitedEmails = invitations
      .filter((invitation) => new Date(invitation.expires_at) > now)
      .map((invitation) => invitation.invitee.email);

    const invalidEmails = values?.filter((value) => !value.match(emailRegex)) || [];
    const existingEmailsInInput = values?.filter((value) => existingEmails.includes(value)) || [];
    const alreadyInvited = values?.filter((value) => invitedEmails.includes(value)) || [];

    if (invalidEmails.length > 0) {
      if (invalidEmails.length === 1) {
        callback(`${invalidEmails.join("")} is not a valid email`);
      } else {
        callback(`${invalidEmails.slice(0, -1).join(", ")} and ${invalidEmails.slice(-1)} are not valid emails`);
      }
      return;
    }

    if (existingEmailsInInput.length > 0) {
      if (existingEmailsInInput.length === 1) {
        callback(`There is already an organization member with the email ${existingEmailsInInput.join("")}`);
      } else {
        callback(
          `There are already organization members with the emails ${existingEmailsInInput
            .slice(0, -1)
            .join(", ")} and ${existingEmailsInInput.slice(-1)}`
        );
      }
      return;
    }

    if (alreadyInvited.length > 0) {
      if (alreadyInvited.length === 1) {
        callback(`There is already an existing invitation for the email ${alreadyInvited.join("")}.`);
      } else {
        callback(
          `There are already existing invitations for the emails ${alreadyInvited
            .slice(0, -1)
            .join(", ")} and ${alreadyInvited.slice(-1)}.`
        );
      }
      return;
    }
    callback();
  };

  return (
    <div className={styles.container}>
      <BackButton
        link={`/roles-admin/members${organizationSlug ? `?organizationSlug=${organizationSlug}` : ``}`}
        text={t({ id: "roles-admin.invite-users.back", message: "Back to Organization" })}
      />
      <h1>
        <Trans id="roles-admin.invite-users.title">Add Members To Organization</Trans>
      </h1>
      <span>
        <Trans id="roles-admin.invite-users.prompt">
          Enter the email(s) of the user(s) you want to add as members to the{" "}
          <span style={{ fontWeight: "bold" }}>
            {organizationProfile?.name} ({organizationSlug || currentOrganization})
          </span>{" "}
          organization and select their role(s).
        </Trans>
      </span>
      <div className={styles.content}>
        <Form form={form} className={styles.form}>
          <Form.Item
            name="emails"
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "forms.validation.emails-required",
                  message: "Email addresses are required",
                }),
              },
              {
                validator: emailValidator,
              },
            ]}
          >
            <Select
              mode="tags"
              open={false}
              className={styles.select}
              placeholder={t({
                id: "roles-admin.invite-users.emails-placeholder",
                message: "Enter email addresses",
              })}
            />
          </Form.Item>
        </Form>

        <RoleSelector
          onRolesUpdate={handleSendInvitations}
          loading={actionLoading}
          buttonDisabled={false}
          actionText={<Trans id="roles-admin.invite-users.send">Send Invitation(s)</Trans>}
        />
      </div>
    </div>
  );
}

export default InviteUsers;
