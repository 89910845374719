import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useKnockFeed } from "@knocklabs/react-notification-feed";

import { useUser } from "context/UserContext";
import { useLayout } from "context/GlobalLayoutContext";

import { Menu as AntMenu, App } from "antd";
import NotificationsIcon from "components/NotificationsIcon";
import { ReactComponent as OpenLink } from "assets/open-link.svg";

import { menuItems as menuItemsFn } from "config/SideBarItems";
import showNoAccessModal from "utils/showNoAccessModal";

import styles from "./Menu.module.less";

function Menu() {
  const location = useLocation();
  const { features, currentOrganization, hasPermissions, grafanaOrgId } = useUser();
  const { sidebarCollapsed } = useLayout();
  const { modal } = App.useApp();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const knockFeed = useKnockFeed();

  if (knockFeed) {
    knockFeed.feedClient.store.subscribe((state) => {
      const unreadCount = state.metadata.unread_count;
      setUnreadNotificationsCount(unreadCount);
    });
  }

  const [fullyOpen, setFullyOpen] = useState(false);

  const menuItems = menuItemsFn();

  const env = process.env.REACT_APP_ENV || "dev";
  useEffect(() => {
    if (!sidebarCollapsed) {
      setTimeout(() => {
        setFullyOpen(true);
      }, 200);
    } else {
      setFullyOpen(false);
    }
  }, [sidebarCollapsed]);

  const selectedKey = location.pathname.replace(/^\/|\/$/g, "").split("/")[0];

  const currentSelectedSubmenu = menuItems
    .filter((group) => group.subitems)
    .filter((group) => group?.subitems.find((member) => member.key === selectedKey))
    .map((group) => group.key)[0];

  const getDefaultOpenKeys = () => {
    const savedKeys = JSON.parse(window.localStorage.getItem("openKeys"));
    if (!savedKeys) {
      return menuItems.filter((group) => group.subitems).map((group) => group.key);
    }
    if (!savedKeys.includes(currentSelectedSubmenu)) {
      return [...savedKeys, currentSelectedSubmenu];
    }
    return savedKeys;
  };

  const [openKeys, setOpenKeys] = useState(getDefaultOpenKeys());

  const onOpenChange = (newOpenKeys) => {
    if (!sidebarCollapsed) {
      setOpenKeys(newOpenKeys);
      window.localStorage.setItem("openKeys", JSON.stringify(newOpenKeys));
    }
  };

  const getMenuItems = (items) =>
    items.flatMap((item) => {
      const {
        color,
        icon: Icon,
        key,
        link,
        externalLink,
        name,
        subitems,
        requiredFeature,
        permissions,
        globalPermissions,
        locked,
        restrictedToOrgs,
        disabledInOrgs,
        restrictedToEnvs,
      } = item;
      if (requiredFeature && !features?.includes(requiredFeature)) {
        return null;
      }
      if (permissions && !hasPermissions(permissions)) {
        return null;
      }
      if (globalPermissions && !hasPermissions(globalPermissions, { global: true })) {
        return null;
      }
      if (disabledInOrgs?.length && disabledInOrgs?.includes(currentOrganization)) {
        return null;
      }

      if (!subitems) {
        const isUnavailable =
          locked ||
          (restrictedToOrgs?.length && !restrictedToOrgs?.includes(currentOrganization)) ||
          (restrictedToEnvs?.length && !restrictedToEnvs?.includes(env));

        let externalLinkItem;
        if (!isUnavailable && externalLink) {
          const url = key === "grafana" ? `${externalLink}?orgId=${grafanaOrgId}` : externalLink;
          externalLinkItem = (
            <a target="_blank" href={url} rel="noreferrer">
              <div className={`${styles.titleContainer} ${styles.linkContainer}`}>
                <div>{name} </div> <OpenLink width={20} fill="#323232" />
              </div>
            </a>
          );
        }
        return {
          key,
          icon: (
            <div className={styles.iconContainer} style={{ color }}>
              {key === "notifications" ? (
                <NotificationsIcon className={styles.icon} count={unreadNotificationsCount} />
              ) : (
                <Icon className={styles.icon} />
              )}
            </div>
          ),
          onClick: externalLinkItem
            ? undefined
            : () => {
                if (isUnavailable) {
                  showNoAccessModal(modal, { name, locked, restrictedToOrgs, source: "MainMenu" });
                }
              },
          label: externalLinkItem || (
            <>
              {!isUnavailable && <Link to={{ pathname: link }} />}
              <div className={styles.titleContainer}>
                {name}
                {key === "notifications" && unreadNotificationsCount > 0 && (
                  <div className={styles.notificationsBadge}>{unreadNotificationsCount}</div>
                )}
              </div>
            </>
          ),
          className: `${styles.menuItem} ${key === selectedKey ? styles.selected : ""}`,
        };
      }
      if (!getMenuItems(subitems).filter((i) => i !== null)?.length) {
        return null;
      }
      if (sidebarCollapsed) {
        return [
          {
            type: "divider",
          },
          ...getMenuItems(subitems),
        ];
      }
      return {
        key,
        label: name,
        children: getMenuItems(subitems),
      };
    });

  return (
    <AntMenu
      className={`${styles.container} ${fullyOpen && styles.fullyOpen}`}
      key="Menu"
      mode="inline"
      inlineIndent={0}
      selectedKeys={[selectedKey]}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        width: "100%",
      }}
      items={getMenuItems(menuItems)}
    />
  );
}

export default Menu;
