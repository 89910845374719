import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { t, Trans } from "@lingui/macro";

import { Modal, Slider, Spin, App } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import defaultPlaceholder from "assets/image-placeholder.png";

import styles from "./ImageUpload.module.less";

export default function ImageUpload({
  src,
  alt,
  imageCallback,
  style,
  className,
  circle,
  placeholder,
  okText,
  scaled,
  quality = 1,
  iconSize,
  loading = false,
}) {
  const cropComponentRef = useRef(null);
  const { modal } = App.useApp();

  const [image, setImage] = useState(undefined);
  const [potentialImage, setPotentialImage] = useState(undefined);
  const [cropModal, setCropModal] = useState(false);

  const handleOk = async () => {
    let canvas;
    if (scaled) {
      canvas = cropComponentRef.current.getImageScaledToCanvas().toDataURL("image/jpeg", quality);
    } else {
      canvas = cropComponentRef.current.getImage().toDataURL("image/jpeg", quality);
    }
    setImage(canvas);
    setCropModal(false);
    if (imageCallback) {
      const dataUrl = await fetch(canvas);
      const blob = await dataUrl.blob();
      await imageCallback(blob);
    }
  };

  const handleCancel = () => {
    setPotentialImage(undefined);
    setCropModal(false);
  };

  const ImageCropModal = () => {
    const [zoom, setZoom] = useState(1);
    return (
      <Modal
        className={styles.imageCropModal}
        title={t({ id: "image-crop.modal-title", message: "Crop Image" })}
        open={cropModal}
        onOk={handleOk}
        okText={okText}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <div className={styles.avatarEditorContainer}>
          <AvatarEditor
            ref={cropComponentRef}
            image={potentialImage}
            width={circle ? 320 : 480}
            height={320}
            border={50}
            borderRadius={circle && 999}
            color={[255, 255, 255, 0.6]}
            backgroundColor="white"
            scale={zoom}
            rotate={0}
          />
        </div>
        <span>
          <Trans id="image-crop.zoom">Zoom:</Trans>
        </span>
        <Slider
          min={1}
          max={3}
          step={0.1}
          onChange={(val) => setZoom(val)}
          tooltip={{
            open: false,
          }}
        />
      </Modal>
    );
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const maxAllowedSize = 5 * 1024 * 1024;
      if (e.target.files[0].size > maxAllowedSize) {
        modal.error({
          // eslint-disable-next-line no-undef
          title: t({ id: "image-crop.image-size-error-title", message: "Image file size is too large" }),
          content: t({
            id: "image-crop.image-size-error-content",
            message: "Please upload a .jpg, .png, .jpeg file less than 5MB.",
          }),
        });
        return;
      }
      setPotentialImage(URL.createObjectURL(e.target.files[0]));
      setCropModal(true);
      e.target.value = null;
    }
  };

  return (
    <div className={`${styles.container} ${className || ""}`} style={style}>
      <img
        className={styles.image}
        style={circle && { borderRadius: "100%" }}
        src={image || src || placeholder || defaultPlaceholder}
        alt={alt || ""}
      />
      {loading && <Spin className={styles.spin} />}
      <div className={styles.uploadContainer} style={iconSize && { fontSize: iconSize }}>
        <UploadOutlined />
      </div>
      <input className={styles.input} type="file" accept="image/jpeg, image/png" onChange={onImageChange} />
      <ImageCropModal />
    </div>
  );
}
