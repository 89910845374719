import React from "react";
import { useSearchParams } from "react-router-dom";
import { ChannelProvider } from "ably/react";
import mixpanel from "mixpanel-browser";

import { getOrgFromSubdomain } from "utils/auth-utils";

import ExistingAnnotation from "../ExistingAnnotation";
import NewAnnotation from "../NewAnnotation";
import AnnotationList from "../AnnotationList";

const orgSlug = getOrgFromSubdomain();

export default function AnnotationPane({
  annotations,
  listView = false,
  setListView = () => {},
  activeAnnotation,
  setActiveAnnotation,
  setAnnotations,
  panel,
  users,
  startTime,
  endTime,
  zoomStartTime,
  zoomEndTime,
  timeMode,
  timeInterval,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleAnnotationClose = () => {
    setActiveAnnotation(undefined);
    searchParams.delete("annotationId");
    setSearchParams(searchParams, { replace: true });
    setAnnotations((a) => a.filter((an) => !an.new));
    mixpanel.track("Annotation Closed", {
      "Panel ID": panel?.id,
      ...(activeAnnotation?.id && {
        "Annotation ID": activeAnnotation?.id,
      }),
    });
  };

  const handleAnnotationsClose = () => {
    setListView(false);
  };

  if (!activeAnnotation && listView)
    return (
      <AnnotationList
        annotations={annotations}
        onClose={handleAnnotationsClose}
        setActiveAnnotation={setActiveAnnotation}
      />
    );

  if (activeAnnotation?.new)
    return (
      <NewAnnotation
        annotation={activeAnnotation}
        setActiveAnnotation={setActiveAnnotation}
        setAnnotations={setAnnotations}
        startTime={startTime}
        endTime={endTime}
        zoomStartTime={zoomStartTime}
        zoomEndTime={zoomEndTime}
        timeMode={timeMode}
        timeInterval={timeInterval}
        panel={panel}
        users={users}
        onClose={handleAnnotationClose}
      />
    );

  return (
    activeAnnotation?.id && (
      <ChannelProvider channelName={`${orgSlug}:annotations:${activeAnnotation.id}`}>
        <ExistingAnnotation id={activeAnnotation.id} panel={panel} users={users} onClose={handleAnnotationClose} />
      </ChannelProvider>
    )
  );
}
