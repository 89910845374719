import React, { useState, useEffect } from "react";
import { StarOutlined, StarFilled } from "@ant-design/icons";

import { t } from "@lingui/macro";

import useCustomMessage from "hooks/useCustomMessage";

import styles from "./FavoriteButton.module.less";

export default function FavoriteButton({ isFavorite = false, createFavorite, deleteFavorite }) {
  const { message } = useCustomMessage();
  const [displayFavorite, setDisplayFavorite] = useState(isFavorite);

  useEffect(() => {
    setDisplayFavorite(isFavorite);
  }, [isFavorite]);

  const handleFavoriteToggle = async () => {
    if (!displayFavorite) {
      setDisplayFavorite(true);
      try {
        await createFavorite();
      } catch (err) {
        message.error(
          t({
            id: "favorite-buttons.fail-add",
            message: "Failed to add favorite",
          })
        );
        setDisplayFavorite(isFavorite);
      }
    } else {
      try {
        setDisplayFavorite(false);
        await deleteFavorite();
      } catch (err) {
        message.error(
          t({
            id: "favorite-buttons.fail-remove",
            message: "Failed to remove favorite",
          })
        );
        setDisplayFavorite(isFavorite);
      }
    }
  };
  return isFavorite ? (
    <StarFilled className={`${styles.favoriteButton} ${styles.favoriteActive}`} onClick={handleFavoriteToggle} />
  ) : (
    <StarOutlined className={`${styles.favoriteButton} ${styles.favoriteInactive}`} onClick={handleFavoriteToggle} />
  );
}
