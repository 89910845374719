import React from "react";
import { getSupersetUrl } from "utils/superset-utils";

import styles from "./SupersetPage.module.less";

export default function SupersetPage({ supersetPath, iframeKey }) {
  const supersetUrl = getSupersetUrl();

  return (
    <iframe
      key={iframeKey}
      title="dashboard"
      className={styles.iframe}
      src={`${supersetUrl}${supersetPath}`}
      frameBorder={0}
      height="100%"
      width="100%"
    />
  );
}
