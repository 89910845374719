import React, { useState, useEffect } from "react";
import { Modal, Steps, Button, Input, Form } from "antd";

import { Trans, t } from "@lingui/macro";
import mixpanel from "mixpanel-browser";
import { useApolloClient } from "@apollo/client";

import { useGetMyUserAccount, useUpdateMyUserAccount, useUpdateMyUserAccountPicture } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { SmileOutlined } from "@ant-design/icons";
import { ReactComponent as EH2Logo } from "assets/eh2-square.svg";
import CircleLogo from "components/CircleLogo";
import userIcon from "assets/user-icon.svg";

import ImageUpload from "components/ImageUpload";

import styles from "./OnboardingModal.module.less";

const { Step } = Steps;

export default function OnboardingModal() {
  const { message } = useCustomMessage();

  const [currentStep, setCurrentStep] = useState(null);
  const [visible, setVisible] = useState(false);
  const [uploadingPicture, setUploadingPicture] = useState(false);

  const [updateMyUserAccount, { loading: loadingUpdateUserAccount }] = useUpdateMyUserAccount();
  const [updateMyUserAccountPicture] = useUpdateMyUserAccountPicture();
  const { myUserAccount } = useGetMyUserAccount();
  const apollo = useApolloClient();

  const [form] = Form.useForm();

  useEffect(() => {
    if (myUserAccount && myUserAccount.name === myUserAccount.email) {
      setVisible(true);
      mixpanel.track("Onboarding Started");
    }
  }, [myUserAccount]);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };
  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const Welcome = () => (
    <div className={styles.welcomeContainer}>
      <EH2Logo className={styles.logo} />
      <div>
        <div className={styles.welcomeTitle}>
          <Trans id="onboarding-modal.welcome.title">
            Welcome to <span className={styles.titleHighlight}>EH2 APM</span>
          </Trans>
        </div>
        <div className={styles.subtitle}>
          <Trans id="onboarding-modal.welcome.subtitle">
            It&apos;s great to have you with us! To help us optimize your experience, tell us more about you.
          </Trans>
        </div>
      </div>
      <Button type="primary" onClick={() => setCurrentStep(0)}>
        <Trans id="onboarding-modal.welcome.button">Start My Journey</Trans>
      </Button>
    </div>
  );

  const setUserName = async () => {
    const validName = await validateName();
    if (!validName) return;
    try {
      await updateMyUserAccount({ name: form.getFieldValue("name") });
      next();
    } catch {
      message.error(t({ id: "onboarding-modal.name-set.error", message: "Error setting account name" }));
    }
  };

  const validateName = async () => {
    try {
      await form.validateFields(["name"]);
      return true;
    } catch (err) {
      return false;
    }
  };

  const EnterName = () => (
    <div className={styles.contentContainer}>
      <div className={styles.buttonsContainer}>
        <Button type="text" className={styles.backButton} onClick={() => setCurrentStep(null)}>
          <Trans id="onboarding-modal.button.back">Go Back</Trans>
        </Button>
        <Button type="primary" onClick={setUserName} loading={loadingUpdateUserAccount}>
          <Trans id="onboarding-modal.button.next">Next</Trans>
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.mainSection}>
          <h2 className={styles.contentTitle}>
            <Trans id="onboarding-modal.name-prompt">First, Enter Your Name</Trans>
          </h2>
          <div className={styles.inputContainer}>
            <Form.Item
              name="name"
              className={styles.titleFormItem}
              required
              rules={[
                {
                  required: true,
                  message: t({
                    id: "onboarding-modal.form.name.required-message",
                    message: "Name is required",
                  }),
                },
              ]}
            >
              <Input className={styles.nameInput} />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );

  const handleUploadPicture = async (file) => {
    setUploadingPicture(true);
    try {
      if (file === null) {
        return;
      }
      const { uploadUrl } = await updateMyUserAccountPicture();

      await fetch(uploadUrl, {
        method: "PUT",
        body: file,
      });
      await apollo.refetchQueries({
        updateCache(cache) {
          cache.modify({
            fields: {
              getMyUserAccount(value, { INVALIDATE }) {
                return INVALIDATE;
              },
            },
          });
        },
      });
      message.success(
        t({
          id: "onboarding-modal.picture.upload-success",
          message: "Profile picture has been successfully uploaded",
        })
      );
    } catch {
      message.error(
        t({
          id: "onboarding-modal.picture.upload-fail",
          message: "Failed to upload profile picture",
        })
      );
    } finally {
      setUploadingPicture(false);
    }
  };

  const UploadPicture = () => (
    <div className={styles.contentContainer}>
      <div className={styles.buttonsContainer}>
        <Button type="text" className={styles.backButton} onClick={prev}>
          <Trans id="onboarding-modal.button.back">Go Back</Trans>
        </Button>
        <div className={styles.buttonsRightContainer}>
          <Button type="text" onClick={next} className={styles.skipButton}>
            <Trans id="onboarding-modal.button.skip">Skip</Trans>
          </Button>
          <Button type="primary" onClick={next}>
            <Trans id="onboarding-modal.button.next">Next</Trans>
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.mainSection}>
          <h2 className={styles.contentTitle}>
            <Trans id="onboarding-modal.picture-prompt">Upload Your Profile Image</Trans>
          </h2>
          <div className={styles.uploadSection}>
            <div className={styles.profileImageContainer}>
              <ImageUpload
                src={myUserAccount?.pictureUrl}
                placeholder={userIcon}
                imageCallback={handleUploadPicture}
                loading={uploadingPicture}
                okText={t({ id: "onboarding-modal.ok-text", message: "Upload" })}
                circle
                scaled
                quality={0.7}
                iconSize="2rem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const handleFinishOnboarding = () => {
    mixpanel.track("Onboarding Finished");
    setVisible(false);
  };

  const Finished = () => (
    <div className={styles.contentContainer}>
      <div className={styles.buttonsContainer}>
        <Button type="text" className={styles.backButton} onClick={prev}>
          <Trans id="onboarding-modal.button.back">Go Back</Trans>
        </Button>
        <Button type="primary" htmlType="submit" onClick={handleFinishOnboarding}>
          <Trans id="onboarding-modal.button.finished">Start My Exploration</Trans>
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.centeredSection}>
          <SmileOutlined className={styles.smileIcon} />
          <h2 className={styles.contentTitle}>
            <Trans id="onboarding-modal.finished-title">Let&apos;s Get Started!</Trans>
          </h2>
          <p className={styles.contentSubtitle}>
            <Trans id="onboarding-modal.finished-subtitle">Start building your Hydrogen project now.</Trans>
          </p>
        </div>
      </div>
    </div>
  );

  const stepItems = [
    {
      title: t({ id: "onboarding-modal.steps.name", message: "Enter Name" }),
      content: <EnterName />,
    },
    {
      title: t({
        id: "onboarding-modal.steps.profile-picture",
        message: "Upload Profile Image",
      }),
      content: <UploadPicture />,
    },
    {
      title: t({ id: "onboarding-modal.steps.get-started", message: "Get Started" }),
      content: <Finished />,
    },
  ];

  return (
    <Modal
      open={visible}
      centered
      closable={false}
      footer={null}
      bodyStyle={{ padding: 0 }}
      className={styles.modal}
      width={720}
    >
      <Form form={form}>
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <div className={styles.leftContent}>
              <div className={styles.titleContainer}>
                <CircleLogo size={26} />
                <Trans id="onboarding-modal.header">EH2 APM</Trans>
              </div>
              <div className={styles.stepsContainer}>
                <Steps direction="vertical" current={currentStep}>
                  {stepItems.map((step) => (
                    <Step key={step.title} title={step.title} />
                  ))}
                </Steps>
              </div>
            </div>
          </div>
          <div className={styles.rightSection}>
            {currentStep !== null ? stepItems[currentStep].content : <Welcome />}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
