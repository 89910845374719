/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import { t, Trans } from "@lingui/macro";
import Highlighter from "react-highlight-words";

import useTableSearch from "hooks/useTableSearch";

import styles from "./TableColumns.module.less";

export default function TableColumns() {
  const { getColumnSearchProps, searchText } = useTableSearch();

  return [
    {
      title: t({
        id: "asset-map.asset.sharing.measure-table.column-measure",
        message: "Measure",
      }),
      key: "source",
      dataIndex: "slug",
      width: "auto",
      sorter: (a, b) => a.slug.localeCompare(b.slug),
      ...getColumnSearchProps({ dataIndex: "slug" }),
    },
    {
      title: t({
        id: "asset-map.asset.sharing.measure-table.column-description",
        message: "Description",
      }),
      key: "description",
      dataIndex: "description",
      width: "60%",
      sorter: (a, b) => {
        if (a.description) {
          return b.description ? a.description.localeCompare(b.description) : -1;
        }
        return a.description ? b.description.localeCompare(a.description) : 1;
      },
      ...getColumnSearchProps({ dataIndex: "description", customRender: true }),
      render: (text) =>
        text ? (
          <Highlighter
            className={styles.recordText}
            highlightStyle={{
              backgroundColor: "#fb7951",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text}
          />
        ) : (
          <span className={styles.measurePlaceholder}>
            <Trans id="asset-map.asset.sharing.measure-table.description-not-set">not set</Trans>
          </span>
        ),
    },
    {
      title: t({
        id: "asset-map.asset.sharing.measure-table.column-unit",
        message: "Unit",
      }),
      key: "unit",
      dataIndex: "unit",
      width: "10%",
      render: (text) =>
        text ? (
          <span className={styles.recordText}>{text}</span>
        ) : (
          <span className={styles.measurePlaceholder}>
            <Trans id="asset-map.asset.sharing.measure-table.unit-not-set">not set</Trans>
          </span>
        ),
    },
  ];
}
