import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_TRIGGERED_ALARM } from "graphql/apiService";

export default function useUpdateTriggeredAlarm() {
  const { currentOrganization } = useUser();

  const [updateAlarmMutation, { loading, error }] = useMutation(UPDATE_TRIGGERED_ALARM, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateTriggeredAlarm = async ({ id, acknowledged, saved }) => {
    const res = await updateAlarmMutation({
      variables: {
        id,
        acknowledged,
        saved,
      },
    });
    return res.data.updateTriggeredAlarm;
  };

  return [updateTriggeredAlarm, { loading, error }];
}
