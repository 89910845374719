import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_MY_USER_ACCOUNT, GET_MY_USER_ACCOUNT } from "graphql/apiService";

export default function useUpdateMyUserAccount() {
  const { currentOrganization } = useUser();

  const [updateMyUserAccountMutation, { loading, error }] = useMutation(UPDATE_MY_USER_ACCOUNT, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: [GET_MY_USER_ACCOUNT],
  });

  const updateMyUserAccount = async ({ name }) => {
    const res = await updateMyUserAccountMutation({ variables: { name } });
    return res.data.updateMyUserAccount;
  };

  return [updateMyUserAccount, { loading, error }];
}
