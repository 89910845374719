import React from "react";
import { t } from "@lingui/macro";
import { modules as modulesFn } from "config/modules";
import lodash from "lodash";

import { Table, Select, Button } from "antd";

import styles from "./AdvancedRoleSelector.module.less";

const roleLevel = (role) => {
  switch (role) {
    case "None":
      return 0;
    case "Viewer":
      return 1;
    case "Editor":
      return 2;
    case "Admin":
      return 3;
    default:
      return 4;
  }
};

export default function AdvancedRoleSelector({
  initialRoles,
  onRolesUpdate,
  actionText,
  loading,
  buttonDisabled,
  selectedRoles,
  setSelectedRoles,
  initialMode,
  roles,
  disabled,
}) {
  const modules = modulesFn();

  const rolesByModule = Object.entries(
    roles.reduce((res, role) => {
      res[role.module] = res[role.module] || [];
      res[role.module].push(role);
      return res;
    }, {})
  ).map(([module, moduleRoles]) => ({ module, roles: moduleRoles }));

  const rolesChanged =
    initialMode === "SIMPLE" ||
    !lodash.isEqual(
      initialRoles.map((role) => role.slug).sort(),
      Object.values(selectedRoles)
        .filter((roleSlug) => roleSlug !== "none")
        .sort()
    );

  const handleRoleSelect = (value, module) => {
    setSelectedRoles((prevState) => ({
      ...prevState,
      [module]: value,
    }));
  };

  const handleSaveRoles = () => {
    onRolesUpdate(Object.values(selectedRoles).filter((roleSlug) => roleSlug !== "none"));
  };

  const tableColumns = [
    {
      title: t({ id: "roles-admin.advanced-roles.column.module", message: "Module" }),
      dataIndex: "module",
      key: "module",
      width: "70%",
      sorter: (a, b) => a.module.localeCompare(b.module),
      render: (module) => modules[module]?.name,
    },
    {
      title: t({ id: "roles-admin.advanced-roles.column.role", message: "Role" }),
      dataIndex: "role",
      key: "role",
      width: "auto",
      render: (_, record) => (
        <Select
          value={selectedRoles?.[record.module]}
          onChange={(value) => handleRoleSelect(value, record.module)}
          disabled={disabled}
          className={styles.select}
          options={[
            { label: "None", value: "none" },
            ...record.roles.map((role) => ({ label: role.name, value: role.slug })),
          ]}
          filterSort={(roleA, roleB) => roleLevel(roleA.label) - roleLevel(roleB.label)}
        />
      ),
    },
  ];
  return (
    <div className={styles.container}>
      <Table columns={tableColumns} dataSource={rolesByModule} rowKey="module" pagination={false} />
      {!disabled && (
        <Button
          disabled={buttonDisabled !== undefined ? buttonDisabled : !rolesChanged}
          onClick={handleSaveRoles}
          type="primary"
          className={styles.updateButton}
          loading={loading}
        >
          {actionText}
        </Button>
      )}
    </div>
  );
}
