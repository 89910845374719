import React from "react";

import styles from "./Members.module.less";
import UsersTable from "../UsersTable";

function Members() {
  return (
    <div className={styles.tableContainer}>
      <UsersTable />
    </div>
  );
}

export default Members;
