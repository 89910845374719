/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const MIN_EQUIPMENT = gql`
  fragment MinEquipment on Equipment {
    id
    name
    slug
    parentId
    organizationSlug
    type
    createdAt
    updatedAt
  }
`;

export const BASIC_EQUIPMENT = gql`
  fragment BasicEquipment on Equipment {
    id
    name
    slug
    parentId
    organizationSlug
    type
    limbleId
    contacts
    dateOfProduction
    description
    process
    pidNumber
    pidDescription
    skidNumber
    signal
    manufacturer
    modelNumber
    serialNumber
    createdAt
    updatedAt
  }
`;

export const BASIC_MEASURE = gql`
  fragment BasicMeasure on Measure {
    id
    organizationSlug
    equipmentId
    slug
    description
    unit
    thresholdMin
    thresholdMax
    createdAt
    updatedAt
  }
`;

export const FULL_MEASURE = gql`
  ${BASIC_MEASURE}
  ${BASIC_EQUIPMENT}
  fragment FullMeasure on Measure {
    ...BasicMeasure
    equipment {
      ...BasicEquipment
    }
  }
`;

export const FULL_EVENT = gql`
  fragment FullEvent on Event {
    equipmentSlug
    type
  }
`;

export const FULL_PANEL = gql`
  ${FULL_MEASURE}
  ${FULL_EVENT}
  fragment FullPanel on Panel {
    id
    type
    name
    showLegend
    time {
      type
      from
      to
      interval
    }
    pollInterval
    measures {
      ...FullMeasure
    }
    dashboard {
      id
      name
    }
    customAxes {
      id
      name
      unit
      position
      measureIds
      colorHex
      logarithmic
      reversed
    }
    events {
      ...FullEvent
    }
  }
`;

export const EQUIPMENT_AND_MEASURES = gql`
  ${BASIC_EQUIPMENT}
  ${BASIC_MEASURE}
  fragment EquipmentAndMeasures on Equipment {
    ...BasicEquipment
    measures {
      ...BasicMeasure
    }
  }
`;

export const FULL_EQUIPMENT = gql`
  ${FULL_PANEL}
  ${BASIC_EQUIPMENT}
  ${BASIC_MEASURE}
  fragment FullEquipment on Equipment {
    ...BasicEquipment
    measures {
      ...BasicMeasure
    }
    panels {
      ...FullPanel
    }
  }
`;

export const BASIC_DASHBOARD = gql`
  fragment BasicDashboard on Dashboard {
    id
    type
    name
    grafanaId
    externalId
    isFavorite
    tags
    time {
      type
      from
      to
      interval
    }
    pollInterval
    timeOverride
    createdAt
    updatedAt
    createdBy
  }
`;

export const FULL_DASHBOARD = gql`
  ${BASIC_DASHBOARD}
  ${FULL_PANEL}
  fragment FullDashboard on Dashboard {
    ...BasicDashboard
    layouts
    panels {
      ...FullPanel
    }
  }
`;

export const FULL_ORGANIZATION_DATA_PARTNER = gql`
  fragment FullOrganizationDataPartner on AccessGrant {
    id
    principal {
      id
      slug
      type
    }
    access {
      slug
      type
    }
    resource {
      id
      organizationSlug
      slug
      type
    }
    status
    createdAt
    createdBy
    updatedAt
  }
`;

export const FULL_USER_ACCOUNT = gql`
  fragment FullUserAccount on UserAccount {
    auth0Id
    email
    name
    pictureUrl
    createdAt
    updatedAt
  }
`;

export const FULL_USER_PROFILE = gql`
  ${FULL_USER_ACCOUNT}
  fragment FullUserProfile on UserOrganizationProfile {
    id
    auth0Id
    organizationSlug
    recentModules
    userAccount {
      ...FullUserAccount
    }
  }
`;

export const FULL_ORGANIZATION_PROFILE = gql`
  fragment FullOrganizationProfile on OrganizationProfile {
    auth0Id
    name
    organizationSlug
    pictureUrl
    grafanaId
    createdAt
    updatedAt
  }
`;

export const BASIC_ANNOTATION_MESSAGE = gql`
  ${FULL_USER_ACCOUNT}
  fragment BasicAnnotationMessage on AnnotationMessage {
    id
    organizationSlug
    annotationId
    content
    interpolatedContent
    type
    createdBy
    author {
      ...FullUserAccount
    }
    createdAt
    updatedAt
  }
`;

export const BASIC_ANNOTATION = gql`
  ${FULL_USER_ACCOUNT}
  fragment BasicAnnotation on Annotation {
    id
    organizationSlug
    panelId
    dashboardId
    status
    time
    timeRange {
      from
      to
    }
    title
    createdBy
    author {
      ...FullUserAccount
    }
    createdAt
    updatedAt
  }
`;

export const FULL_ANNOTATION = gql`
  ${BASIC_ANNOTATION}
  ${BASIC_ANNOTATION_MESSAGE}
  ${FULL_MEASURE}
  fragment FullAnnotation on Annotation {
    ...BasicAnnotation
    measures {
      ...FullMeasure
    }
    messages {
      ...BasicAnnotationMessage
    }
  }
`;

export const FULL_INCIDENT_REPORT = gql`
  fragment FullIncidentReport on IncidentReport {
    id
    organizationSlug
    localIndex
    severity
    authorName
    supervisorName
    peopleInvolved {
      label
      value
    }
    witnesses {
      label
      value
    }
    partsInvolved {
      label
      value
    }
    workOrderNb
    incidentLocation {
      formattedAddress
      lat
      lng
    }
    incidentDate
    discoveryFactor
    incidentDescription
    cause
    damagesOrInjuries
    activities
    createdAt
    updatedAt
    status
    employeeSignature
    employeeSignatureDate
    supervisorSignature
    supervisorSignatureDate
    summary
    title
    suggestedTitle
    suggestedSummary
    stepNumber
  }
`;

export const BASIC_COMMENT = gql`
  ${FULL_USER_ACCOUNT}
  fragment BasicComment on Comment {
    id
    author {
      ...FullUserAccount
    }
    content
    createdAt
    createdBy
  }
`;

export const BASIC_THREAD_READ_STATE = gql`
  fragment BasicThreadReadState on ThreadReadState {
    id
    status
  }
`;

export const BASIC_THREAD = gql`
  ${BASIC_COMMENT}
  ${BASIC_THREAD_READ_STATE}
  ${FULL_USER_ACCOUNT}
  fragment BasicThread on Thread {
    id
    author {
      ...FullUserAccount
    }
    content
    status
    createdAt
    updatedAt
    createdBy
    atUsers
    comments {
      ...BasicComment
    }
    readStates {
      ...BasicThreadReadState
    }
  }
`;

export const BASIC_ALARM = gql`
  fragment BasicAlarm on Alarm {
    id
    name
    description
    organizationSlug
    priority
    muted
    reportMissingData
    status
    lastTimeTriggered
    createdAt
    createdBy
    updatedAt
    plcAlarmInfo {
      type
      status
      threshold
      timeseriesMeasure {
        slug
        description
      }
    }
  }
`;

export const BASIC_ALARM_RULE = gql`
  fragment BasicAlarmRule on AlarmRule {
    id
    alarmId
    organizationSlug
    measureId
    thresholdValue
    formula
    duration
    mode
    updatedAt
    createdAt
  }
`;
export const FULL_ALARM_RULE = gql`
  ${BASIC_ALARM_RULE}
  ${BASIC_MEASURE}
  fragment FullAlarmRule on AlarmRule {
    ...BasicAlarmRule
    measure {
      ...BasicMeasure
    }
  }
`;

export const ALARM_RULE_WITH_FULL_MEASURES = gql`
  ${BASIC_ALARM_RULE}
  ${FULL_MEASURE}
  fragment AlarmRuleWithFullMeasures on AlarmRule {
    ...BasicAlarmRule
    measure {
      ...FullMeasure
    }
  }
`;

export const FULL_ALARM = gql`
  ${BASIC_ALARM}
  ${BASIC_ALARM_RULE}
  ${FULL_USER_ACCOUNT}
  fragment FullAlarm on Alarm {
    ...BasicAlarm
    alarmRules {
      ...BasicAlarmRule
    }
    notifyUsers {
      ...FullUserAccount
    }
    plcAlarmInfo {
      type
      status
      threshold
      timeseriesMeasure {
        id
        slug
        description
        equipment {
          id
          name
          slug
          description
        }
      }
    }
  }
`;

export const BASIC_TRIGGERED_ALARM = gql`
  fragment BasicTriggeredAlarm on TriggeredAlarm {
    id
    organizationSlug
    timeTriggered
    timeResolved
    acknowledgedBy
    saved
    alarmId
  }
`;

export const FULL_TRIGGERED_ALARM = gql`
  ${BASIC_TRIGGERED_ALARM}
  ${BASIC_ALARM}
  fragment FullTriggeredAlarm on TriggeredAlarm {
    ...BasicTriggeredAlarm
    alarm {
      ...BasicAlarm
    }
  }
`;
