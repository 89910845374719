/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

import captureException from "utils/capture-sentry-exception";
import useThirdPartyProxy from "hooks/useThirdPartyProxy";
import { useGetOrganizationProfile } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";
import { useUser } from "context/UserContext";

import { Table, Badge, Button, App } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import styles from "./Invitations.module.less";

function Invitations() {
  const { modal } = App.useApp();
  const { message } = useCustomMessage();
  const { hasPermissions } = useUser();
  const { getInvitations, revokeInvitation } = useThirdPartyProxy();
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const organizationSlug = searchParams.get("organizationSlug");
  const { organizationProfile, loading: loadingOrganizationProfile } = useGetOrganizationProfile(organizationSlug);

  const fetchInvitations = async () => {
    setLoading(true);
    try {
      const res = await getInvitations(organizationProfile.auth0Id);
      setInvitations(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      captureException(error);
      message.error(
        t({
          id: "roles-admin.errors.get-invitations",
          message: "Failed to get organization invitations",
        })
      );
    }
  };

  const handleRevokeInvitation = async ({ invitationId }) => {
    try {
      await revokeInvitation(invitationId, organizationProfile.auth0Id);
      await fetchInvitations();
      message.success(t({ id: "roles-admin.success.revoke-invitation", message: "Successfully revoked invitation" }));
    } catch (err) {
      captureException(err);
      message.error(t({ id: "roles-admin.errors.revoke-invitation", message: "Failed to revoke invitation" }));
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationProfile]);

  const ConfirmModalTitle = ({ name }) => (
    <>
      <span>
        {t({
          id: "roles-admin.invitations.confirm-revoke",
          message: "Are you sure you want to revoke the invitation for ",
        })}
      </span>
      <span style={{ fontWeight: "bold" }}>{name}</span>
    </>
  );

  const showConfirmModal = ({ invitationId, name }) => {
    modal.confirm({
      title: <ConfirmModalTitle name={name} />,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: t({ id: "roles-admin.invitations.modal.revoke", message: "Revoke" }),
      onOk: async () => handleRevokeInvitation({ invitationId }),
    });
  };

  const columns = [
    {
      title: t({ id: "roles-admin.invitations.table.email", message: "Email" }),
      dataIndex: "inviteeEmail",
      key: "inviteeEmail",
      render: (email, _record) => <span className={styles.email}>{email}</span>,
    },
    {
      title: t({ id: "roles-admin.invitations.table.status", message: "Status" }),
      key: "status",
      render: (_, record) => {
        const expired = new Date(record.expires_at) < new Date();
        return expired ? (
          <Badge
            status="error"
            text={t({
              id: "roles-admin.invitations.status.expired",
              message: "Expired",
            })}
          />
        ) : (
          <Badge
            status="success"
            text={t({
              id: "roles-admin.invitations.status.pending",
              message: "Pending",
            })}
          />
        );
      },
    },
    {
      title: t({ id: "roles-admin.invitations.table.created-at", message: "Created At" }),
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["lg"],
      render: (_, record) => (
        <div className={styles.dateContainer}>
          {i18n.date(new Date(record.created_at), { dateStyle: "medium", timeStyle: "short" })}
        </div>
      ),
    },
    {
      title: t({ id: "roles-admin.invitations.table.expires-at", message: "Expires At" }),
      dataIndex: "expires_at",
      key: "expires_at",
      responsive: ["sm"],
      render: (_, record) => (
        <div className={styles.dateContainer}>
          {i18n.date(new Date(record.expires_at), { dateStyle: "medium", timeStyle: "short" })}
        </div>
      ),
    },
    {
      title: t({ id: "roles-admin.invitations.table.created-by", message: "Created By" }),
      dataIndex: "inviterName",
      key: "inviterName",
      responsive: ["xl"],
    },
    hasPermissions(["delete:invitations"]) && {
      title: t({ id: "roles-admin.invitations.table.action", message: "Action" }),
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          className={styles.actionLink}
          onClick={() => {
            showConfirmModal({ invitationId: record.key, name: record.inviteeEmail });
          }}
        >
          <Trans id="roles-admin.invitations.table.revoke">Revoke</Trans>
        </Button>
      ),
    },
  ].filter(Boolean);

  const tableData = invitations.map((invitation) => {
    const {
      created_at,
      expires_at,
      id,
      invitee: { email: inviteeEmail },
      inviter: { name: inviterName },
    } = invitation;
    return { created_at, expires_at, inviteeEmail, inviterName, key: id };
  });

  return <Table loading={loading || loadingOrganizationProfile} dataSource={tableData} columns={columns} />;
}

export default Invitations;
