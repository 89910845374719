import { useUser } from "context/UserContext";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPRESSED_TELEMETRY_DATA } from "graphql/databaseService";

export default function useGetTelemetryData() {
  const { currentOrganization } = useUser();

  const [getTelemetryDataQuery, { loading, data, error }] = useLazyQuery(GET_COMPRESSED_TELEMETRY_DATA, {
    context: {
      clientName: "database-service",
    },
    variables: {
      organizationSlug: currentOrganization,
    },
  });
  const getTelemetryData = async ({ measureFilters, from, to }) => {
    const res = await getTelemetryDataQuery({ variables: { measureFilters, from, to } });
    return res?.data?.compressedTelemetryData;
  };

  return [getTelemetryData, { loading, data, error }];
}
