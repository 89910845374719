import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

import { useUser } from "context/UserContext";
import {
  useCreateAlarm,
  useEquipmentAndMeasuresList,
  useGetAlarm,
  useUserOrganizationProfiles,
  useUpdateAlarm,
} from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";
import { arrayToTree } from "containers/AssetMap/helpers/nestedObject";

import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";

import {
  Avatar as AvatarAntD,
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Skeleton,
  Steps,
  Switch,
  TreeSelect,
} from "antd";
import Avatar from "components/Avatar";

import {
  FORMULAS,
  durations as durationsFn,
  priorities as prioritiesFn,
  modes as modesFn,
} from "../helpers/AlarmRules.helpers";

import styles from "./AlarmRule.module.less";

export default function AlarmRule() {
  const [dataSourcesForm] = Form.useForm();
  const [notifyUsersForm] = Form.useForm();
  const [detailsForm] = Form.useForm();

  const navigate = useNavigate();
  const { message } = useCustomMessage();
  const { alarmId } = useParams();
  const { user, currentOrganization } = useUser();

  const [getAlarm, { data: alarm, loading: loadingAlarm }] = useGetAlarm();
  const [createAlarm] = useCreateAlarm();
  const [updateAlarm] = useUpdateAlarm();

  const { equipmentList: assets, loading: loadingAssets } = useEquipmentAndMeasuresList();
  const { userOrganizationProfiles, loading: loadingUsers } = useUserOrganizationProfiles();

  const durations = durationsFn();
  const priorities = prioritiesFn();
  const modes = modesFn();

  useEffect(() => {
    if (alarmId) {
      getAlarm({ id: alarmId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmId]);

  const assetTree = arrayToTree(
    [
      ...assets
        .filter(({ organizationSlug }) => organizationSlug === currentOrganization)
        .map((equipment) => ({
          ...equipment,
          selectable: !!equipment.slug,
          key: equipment.id,
          value: equipment.id,
          label: equipment.name,
          parentId: equipment.parentId,
          measures: equipment.measures ? [...equipment.measures].sort((a, b) => a.slug.localeCompare(b.slug)) : [],
        })),
    ],
    "id",
    "parentId"
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [dataSources, setDataSources] = useState({});

  useEffect(() => {
    if (alarm && assets) {
      detailsForm.setFieldsValue({
        name: alarm.name,
        description: alarm.description,
        priority: alarm.priority,
        reportMissingData: alarm.reportMissingData,
      });
      const newDataSources = {};
      alarm.alarmRules.forEach((alarmRule, i) => {
        newDataSources[i] = {
          source: alarmRule.measure.equipmentId,
          conditionValue: alarmRule.measure.id,
          conditionComparison: alarmRule.formula,
          conditionThreshold: alarmRule.thresholdValue,
          conditionMode: alarmRule.mode,
          conditionDuration: alarmRule.duration,
          measure: alarmRule.measure,
          measures: assets.find((asset) => asset.id === alarmRule.measure.equipmentId)?.measures,
        };
      });
      dataSourcesForm.setFieldsValue({
        dataSources: Object.values(newDataSources).map((dataSource) => dataSource),
      });
      notifyUsersForm.setFieldsValue({
        notifyUsers: alarm.notifyUsers?.map((userAccount) => userAccount.auth0Id),
      });
      setDataSources(newDataSources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarm, assets]);

  const handleDataSourceSelect = (key, node) => {
    setDataSources((prev) => ({ ...prev, [key]: node }));
  };
  const handleDataSourceRemove = (name, key, remove) => {
    setDataSources((prev) => ({ ...prev, [key]: undefined }));
    remove(name);
  };
  const handleValueSelect = (key, option) => {
    setDataSources((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        measure: option,
      },
    }));
  };

  const [edited, setEdited] = useState(false);
  const handleFirstChange = () => {
    setEdited(true);
  };

  const loading = loadingAlarm || loadingAssets || loadingUsers;

  const steps = [
    {
      key: "alarm-conditions",
      title: t({ id: "alarms-rules.rule.steps.alarm-conditions.title", message: "Alarm Conditions" }),
      content: (
        <Form form={dataSourcesForm} layout="vertical" onValuesChange={handleFirstChange}>
          {loading && <Skeleton active />}
          {!loading && (
            <Form.List name="dataSources" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <div className={styles.dataSourcesContainer}>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div className={styles.dataSourceContainer} key={key}>
                      {index > 0 && <div className={styles.dataSourceDivider}>and</div>}
                      <div className={styles.dataSource}>
                        <div className={styles.dataSourceConditions}>
                          <Form.Item
                            {...restField}
                            name={[name, "source"]}
                            label={t({
                              id: "alarms-rules.rule.steps.alarm-conditions.data-source-selection.label",
                              message: "Select Datasource",
                            })}
                            required
                            rules={[
                              {
                                required: true,
                                message: t({
                                  id: "alarms-rules.rule.steps.alarm-conditions.data-source-selection.required-message",
                                  message: "Please select source",
                                }),
                              },
                            ]}
                          >
                            <TreeSelect
                              placeholder={t({
                                id: "alarms-rules.rule.steps.alarm-conditions.data-source-selection.placeholder",
                                message: "Type or Select",
                              })}
                              treeData={assetTree}
                              treeDataSimpleMode={{ id: "id", pId: "parentId", rootPId: null }}
                              treeLine
                              loading={loadingAssets}
                              filterTreeNode={(input, treeNode) =>
                                treeNode.label.toLowerCase().includes(input.toLowerCase())
                              }
                              showSearch
                              onSelect={(_, node) => handleDataSourceSelect(key, node)}
                            />
                          </Form.Item>
                          <div className={styles.condition}>
                            <Form.Item
                              className={styles.conditionValue}
                              {...restField}
                              name={[name, "conditionValue"]}
                              label={t({
                                id: "alarms-rules.rule.steps.alarm-conditions.trigger.label",
                                message: "Trigger Alarm When",
                              })}
                              required
                              rules={[
                                {
                                  required: true,
                                  message: t({
                                    id: "alarms-rules.rule.steps.alarm-conditions.trigger.required-message",
                                    message: "Please select value",
                                  }),
                                },
                              ]}
                            >
                              <Select
                                placeholder="Value"
                                options={
                                  dataSources[key]?.measures?.map((measure) => ({
                                    ...measure,
                                    value: measure.id,
                                    label: measure.description ? measure.description : measure.slug,
                                  })) || []
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                disabled={!dataSources[key]}
                                onSelect={(_, option) => handleValueSelect(key, option)}
                              />
                            </Form.Item>
                            <Form.Item
                              className={styles.conditionExtraSmall}
                              {...restField}
                              name={[name, "conditionComparison"]}
                              label=" "
                              initialValue={FORMULAS[0].value}
                            >
                              <Select options={FORMULAS} disabled={!dataSources[key]?.measure} />
                            </Form.Item>
                            <Form.Item
                              className={styles.conditionThreshold}
                              {...restField}
                              name={[name, "conditionThreshold"]}
                              label=" "
                              required={false}
                              rules={[
                                {
                                  required: true,
                                  message: t({
                                    id: "alarms-rules.rule.steps.alarm-conditions.threshold.required-message",
                                    message: "Please set threshold",
                                  }),
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder={t({
                                  id: "alarms-rules.rule.steps.alarm-conditions.threshold.placeholder",
                                  message: "Threshold",
                                })}
                                disabled={!dataSources[key]?.measure}
                                {...{
                                  addonAfter: dataSources[key]?.measure?.unit,
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              className={styles.conditionMedium}
                              {...restField}
                              name={[name, "conditionMode"]}
                              label=" "
                              initialValue={modes[0].value}
                            >
                              <Select options={modes} disabled={!dataSources[key]?.measure} />
                            </Form.Item>
                            <span className={styles.conditionText}>
                              <Trans id="alarms-rules.rule.steps.alarm-conditions.per">for</Trans>
                            </span>
                            <Form.Item
                              className={styles.conditionSmall}
                              {...restField}
                              name={[name, "conditionDuration"]}
                              label=" "
                              initialValue={durations[0].value}
                            >
                              <Select options={durations} disabled={!dataSources[key]?.measure} />
                            </Form.Item>
                          </div>
                        </div>
                        <Button
                          onClick={() => handleDataSourceRemove(name, key, remove)}
                          icon={<MinusOutlined />}
                          style={{ visibility: fields.length === 1 ? "hidden" : "visible" }}
                        />
                      </div>
                    </div>
                  ))}
                  <Button onClick={add} icon={<PlusOutlined />} />
                </div>
              )}
            </Form.List>
          )}
        </Form>
      ),
    },
    {
      key: "notify-users",
      title: t({ id: "alarms-rules.rule.steps.notifications.title", message: "Notify Users" }),
      content: (
        <Form form={notifyUsersForm} layout="vertical">
          <Form.Item
            name="notifyUsers"
            label={t({
              id: "alarms-rules.rule.steps.notifications.users.label",
              message: "Select Users to notify",
            })}
            rules={[
              {
                required: true,
                message: t({
                  id: "alarms-rules.rule.steps.notifications.users.required-message",
                  message: "Please select users to notify",
                }),
              },
            ]}
            initialValue={[user.sub]}
          >
            <Select
              className={styles.notifyUsersSelect}
              options={userOrganizationProfiles
                .map((userProfile) => {
                  const { userAccount, auth0Id } = userProfile;
                  const { name } = userAccount;
                  return {
                    ...userAccount,
                    value: auth0Id,
                    label: (
                      <>
                        {userAccount.pictureUrl && (
                          <>
                            <AvatarAntD
                              className={styles.notifyUsersAvatar}
                              size="small"
                              src={userAccount.pictureUrl}
                            />{" "}
                            <span>{name}</span>
                          </>
                        )}
                        {!userAccount.pictureUrl && <Avatar size="small" name={name} />}
                      </>
                    ),
                  };
                })
                .sort((a, b) => a.name.localeCompare(b.name))}
              mode="multiple"
              placeholder={t({
                id: "alarms-rules.rule.steps.notifications.users.placeholder",
                message: "Type or Select",
              })}
              loading={loadingUsers}
              filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
              showSearch
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      key: "add-details",
      title: t({ id: "alarms-rules.rule.steps.details.title", message: "Add Details" }),
      content: (
        <Form form={detailsForm} layout="vertical">
          <div className={styles.sideBySide}>
            <Form.Item
              name="name"
              label={t({
                id: "alarms-rules.rule.steps.details.name.label",
                message: "Alarm Name",
              })}
              rules={[
                {
                  required: true,
                  message: t({
                    id: "alarms-rules.rule.steps.details.name.required-message",
                    message: "Please select users to notify",
                  }),
                },
              ]}
            >
              <Input
                placeholder={t({
                  id: "alarms-rules.rule.steps.details.name.placeholder",
                  message: "Enter name",
                })}
              />
            </Form.Item>
            <Form.Item
              name="priority"
              label={t({
                id: "alarms-rules.rule.steps.details.priority.label",
                message: "Alarm Priority",
              })}
              rules={[
                {
                  required: true,
                  message: t({
                    id: "alarms-rules.rule.steps.details.priority.required-message",
                    message: "Please set a priority",
                  }),
                },
              ]}
            >
              <Select
                placeholder={t({
                  id: "alarms-rules.rule.steps.details.priority.placeholder",
                  message: "Select priority",
                })}
                options={Object.entries(priorities).map(([key, value]) => ({ value: key, label: value.name }))}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="reportMissingData"
            label={t({
              id: "alarms-rules.rule.steps.details.missing-data.label",
              message: "Report on missing data",
            })}
            valuePropName="checked"
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
          <Form.Item
            name="procedures"
            label={t({
              id: "alarms-rules.rule.steps.details.procedures.label",
              message: "Operating Procedures (Optional)",
            })}
          >
            <Input
              placeholder={t({
                id: "alarms-rules.rule.steps.details.procedures.placeholder",
                message: "Add URL to the Operating Procedures",
              })}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={t({
              id: "alarms-rules.rule.steps.details.description.label",
              message: "Description (Optional)",
            })}
          >
            <Input.TextArea
              placeholder={t({
                id: "alarms-rules.rule.steps.details.description.placeholder",
                message: "Write an alarm description",
              })}
              autoSize={{ minRows: 3, maxRows: 6 }}
              maxLength={500}
              showCount
            />
          </Form.Item>
        </Form>
      ),
    },
  ];

  const handleSave = async () => {
    const dataSourcesValues = await dataSourcesForm.getFieldsValue();
    const dataSourceValues = dataSourcesValues.dataSources.map((dataSource) => ({
      source: dataSource.source,
      measure: dataSource.conditionValue,
      conditionComparison: dataSource.conditionComparison,
      conditionDuration: dataSource.conditionDuration,
      conditionMode: dataSource.conditionMode,
      conditionThreshold: dataSource.conditionThreshold,
    }));
    const notifyUsersValues = await notifyUsersForm.getFieldsValue();
    const notifyAuth0Ids = notifyUsersValues.notifyUsers.map((auth0Id) => auth0Id);
    const detailsValues = await detailsForm.getFieldsValue();
    try {
      if (!alarmId) {
        await createAlarm({
          name: detailsValues.name,
          priority: detailsValues.priority,
          description: detailsValues.description,
          reportMissingData: detailsValues.reportMissingData,
          alarmRules: dataSourceValues.map((dataSource) => ({
            measureId: dataSource.measure,
            duration: dataSource.conditionDuration,
            formula: dataSource.conditionComparison,
            mode: dataSource.conditionMode,
            thresholdValue: dataSource.conditionThreshold,
          })),
          notifyAuth0Ids,
        });
      } else {
        await updateAlarm({
          id: alarmId,
          name: detailsValues.name,
          priority: detailsValues.priority,
          description: detailsValues.description,
          reportMissingData: detailsValues.reportMissingData,
          alarmRules: dataSourceValues.map((dataSource) => ({
            measureId: dataSource.measure,
            duration: dataSource.conditionDuration,
            formula: dataSource.conditionComparison,
            mode: dataSource.conditionMode,
            thresholdValue: dataSource.conditionThreshold,
          })),
          notifyAuth0Ids,
        });
      }
    } catch (error) {
      message.error(t({ id: "alarms-rules.rule.errors.save-failed", message: "Failed to save alarm" }));
    }
  };
  const handleNextSave = async () => {
    if (currentStep === steps.length - 1) {
      try {
        await dataSourcesForm.validateFields();
        await notifyUsersForm.validateFields();
        await detailsForm.validateFields();
        await handleSave();
        navigate("/alarm-rules");
      } catch (error) {
        message.error(
          t({ id: "alarms-rules.rule.errors.required-fields", message: "Please fill all the required fields" })
        );
      }
      return;
    }
    if (currentStep === 0) {
      await dataSourcesForm.validateFields();
    } else if (currentStep === 1) {
      await notifyUsersForm.validateFields();
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handleSaveDraft = async () => {
    await handleSave();
    navigate("/alarm-rules");
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const nextSaveText =
    currentStep === steps.length - 1
      ? t({ id: "alarms-rules.rule.finish-button", message: "Finish" })
      : t({ id: "alarms-rules.rule.next-button", message: "Next" });

  const saveDraftText = alarmId
    ? t({ id: "alarms-rules.rule.save-changes-button", message: "Save Changes" })
    : t({ id: "alarms-rules.rule.save-draft-button", message: "Save as Draft" });

  const titleText =
    alarmId && !loadingAlarm
      ? alarm?.name || t({ id: "alarms-rules.rule.title-empty", message: "Alarm Rule" })
      : t({ id: "alarms-rules.rule.title-new", message: "New Alarm Rule" });

  return (
    <Card>
      <div className={styles.backContainer}>
        <Button icon={<ArrowLeftOutlined className={styles.backIcon} />} onClick={() => navigate(-1)}>
          <Trans id="alarms-rules.rule.back-button">Back</Trans>
        </Button>
      </div>

      <div className={styles.content}>
        <h2>{titleText}</h2>
        <Steps className={styles.steps} current={currentStep} items={steps} />
        <Divider />
        {steps.map((step, index) => (
          <div
            key={step.key}
            className={styles.stepContent}
            style={{ display: currentStep === index ? "block" : "none" }}
          >
            {step.content}
          </div>
        ))}
        <Divider />
        <div className={styles.controls}>
          {currentStep > 0 && <Button onClick={handlePrevious}>Previous</Button>}
          <Button type="primary" ghost onClick={handleSaveDraft} disabled={!alarmId && !edited}>
            {saveDraftText}
          </Button>
          <Button type="primary" onClick={handleNextSave}>
            {nextSaveText}
          </Button>
        </div>
      </div>
    </Card>
  );
}
