import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_ORGANIZATION_PROFILE } from "graphql/apiService";

export default function useUpdateOrganizationProfile() {
  const { currentOrganization } = useUser();

  const [updateOrganizationProfilePictureMutation, { loading, error }] = useMutation(UPDATE_ORGANIZATION_PROFILE, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateOrganizationProfilePicture = async () => {
    const res = await updateOrganizationProfilePictureMutation();
    return res.data.updateOrganizationProfilePicture;
  };

  return [updateOrganizationProfilePicture, { loading, error }];
}
