import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/macro";

import { getNewOrgUrl, getOrgFromSubdomain, isProductApm } from "utils/auth-utils";

import { Button } from "antd";
import eH2LogoSquare from "assets/eh2-square.svg";
import LoadingScreen from "components/LoadingScreen";
import GlobalFooter from "components/GlobalFooter";
import ErrorModal from "./components/ErrorModal";

import styles from "./Authentication.module.less";

const org = getOrgFromSubdomain();
const proxyUrl = process.env.REACT_APP_THIRDPARTY_PROXY_URL || "https://api.v1.betterh2.com";
const grafanaDomain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";
const isProduct = isProductApm();

const titleText = isProduct ? (
  <>
    EH2<sup style={{ fontSize: 40 }}>®</sup>APM
  </>
) : (
  "Pioneer APM"
);
const env = process.env.REACT_APP_ENV || "staging";
const orgProfileUrl = `https://${process.env.REACT_APP_PUBLIC_ASSETS_CLOUDFRONT}/${env}/${org}/org-profile/profile-picture.jpg`;

function BackgroundImageLogo({ src, placeholder, className }) {
  const [imgSrc, setImgSrc] = useState(placeholder);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImgSrc(src);
    img.onerror = () => setImgSrc(placeholder);
    img.src = src;
  }, [src, placeholder]);

  return <div className={className} style={{ backgroundImage: `url(${imgSrc})` }} />;
}

function Authentication() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const auth0Error = searchParams.get("error");
  const invitation = searchParams.get("invitation");
  const auth0ErrorDescription = searchParams.get("error_description");
  const orgSwitch = searchParams.get("orgSwitch") === "true";
  const grafanaLogout = searchParams.get("grafanaLogout") === "true";
  const returnTo = searchParams.get("returnTo");
  const auth0LoginSucceeded = searchParams.get("code") && searchParams.get("state");

  // Deal with organization invitations
  const handleInvitation = () => {
    const organizationId = searchParams.get("organization");
    const redirectUrl = getNewOrgUrl(searchParams.get("organization_name"));
    loginWithRedirect({
      authorizationParams: {
        organization: organizationId,
        invitation,
        redirect_uri: redirectUrl,
      },
    });
  };

  const showError = (type) => {
    setErrorType(type);
    setErrorModalVisible(true);
  };

  const cleanGrafanaLogoutUrl = () => {
    if (grafanaLogout) {
      const newUrl = new URL(window.location);
      newUrl.searchParams.delete("grafanaLogout");
      window.history.pushState(null, "", newUrl.toString());
    }
  };

  const auth0Login = async () => {
    if (!org) {
      showError("ORG_NOT_PRESENT");
      return;
    }
    setLoading(true);
    const data = await fetch(`${proxyUrl}/thirdparty/auth0/organizations/${org}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusMessage, { cause: res });
        }
        return res.json();
      })
      .catch((error) => {
        if (error.cause?.status === 404) {
          showError("ORG_NOT_FOUND");
        } else {
          showError("GET_ORG_FAIL");
        }
        setLoading(false);
      });
    if (data) {
      try {
        localStorage.setItem("currentOrgId", data.id);
        const returnToParam = returnTo ? `?returnTo=${encodeURIComponent(returnTo)}` : "";
        await loginWithRedirect({
          authorizationParams: {
            organization: data.id,
            redirect_uri: `${window.location.origin}/login${returnToParam}`,
          },
        });
      } catch (err) {
        setLoading(false);
        showError("AUTH0");
      }
    }
  };

  useEffect(() => {
    setLoading(false);
    cleanGrafanaLogoutUrl();
    if (auth0Error) {
      showError("AUTH0");
    } else if (invitation) {
      handleInvitation();
    } else if (org === "login") {
      const redirectUrl = getNewOrgUrl(searchParams.get("organization_name"));
      window.location.replace(`${redirectUrl}/login`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgSwitch && !isAuthenticated) {
      auth0Login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSwitch, isAuthenticated]);

  if (orgSwitch) {
    return (
      <LoadingScreen
        message={<Trans id="authentication.login-button-org-switch">Switching Organization...</Trans>}
        keyId="orgSwitch"
      />
    );
  }

  if (isAuthenticated) {
    const path = returnTo || "/home";
    return <Navigate to={path} />;
  }

  if (auth0LoginSucceeded) {
    return (
      <LoadingScreen
        message={<Trans id="authentication.login.succeeded">Login Successful, Redirecting...</Trans>}
        keyId="loginSuccessful"
      />
    );
  }

  return (
    <>
      <ErrorModal
        visible={errorModalVisible}
        type={errorType}
        auth0Error={auth0Error}
        auth0ErrorDescription={auth0ErrorDescription}
        setVisible={setErrorModalVisible}
        org={org}
      />
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.title}>{titleText}</div>

          <BackgroundImageLogo src={orgProfileUrl} placeholder={eH2LogoSquare} className={styles.logoContainer} />

          <div className={styles.textContainer}>
            <div className={styles.subtitle}>Welcome!</div>
            <div className={styles.prompt}>Log In to Get Started</div>
          </div>
          <Button className={styles.button} type="primary" loading={loading} onClick={auth0Login}>
            <Trans id="authentication.login-button">Log In</Trans>
          </Button>
        </div>
        <GlobalFooter containerStyle={{ background: "transparent" }} textPrimary="white" textSecondary="white" />
        {grafanaLogout && (
          <iframe
            title="grafanaLogout"
            src={`https://grafana.${grafanaDomain}/session-logout`}
            className={styles.hiddenIframe}
          />
        )}
      </div>
    </>
  );
}

export default Authentication;
