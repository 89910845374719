import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_THREAD } from "graphql/apiService";

export default function useUpdateThread() {
  const { currentOrganization } = useUser();

  const [updateThreadMutation, { loading, error }] = useMutation(UPDATE_THREAD, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetThreadsByDashboard"],
  });

  const updateThread = async ({ input }) => {
    const res = await updateThreadMutation({ variables: { input } });
    return res.data.updateThread;
  };

  return [updateThread, { loading, error }];
}
