/* eslint-disable import/prefer-default-export */
import { t } from "@lingui/macro";

export const types = () => ({
  GRAFANA: {
    label: t({
      id: "dashboards.mapping.grafana",
      message: "Grafana",
    }),
  },
  SUPERSET: {
    label: t({
      id: "dashboards.mapping.superset",
      message: "Superset",
    }),
    requiredFeature: "superset",
  },
  CUSTOM: {
    label: t({
      id: "dashboards.mapping.custom",
      message: "Custom",
    }),
  },
});
