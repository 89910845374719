import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

import { Form, Divider, Button, Card, Steps, Result } from "antd";
import { ArrowLeftOutlined, SmileOutlined } from "@ant-design/icons";

import { useMediaQuery } from "react-responsive";
import captureException from "utils/capture-sentry-exception";

import {
  useCreateIncidentReport,
  useUpdateIncidentReport,
  useMarkIncidentReportAsWithSafetyTeam,
  useUserOrganizationProfiles,
  useGetMyUserAccount,
} from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import {
  BasicInfo,
  IncidentDescription,
  RootCause,
  ActivitiesPerformed,
  TitleAndSummary,
  NamesAndSignatures,
} from "../formSteps";

import { formDataToIncidentReport, incidentReportToFormData } from "../../helpers/dataConversion";

import styles from "./IncidentReportSteps.module.less";

function IncidentReportSteps({ incidentReport }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [finished, setFinished] = useState(false);
  const [locationCoordinates, setLocationCoordinates] = useState({});
  const [currentReportId, setCurrentReportId] = useState(null);
  const [savingDraft, setSavingDraft] = useState(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { message } = useCustomMessage();

  const [createIncidentReport] = useCreateIncidentReport();
  const [updateIncidentReport] = useUpdateIncidentReport();
  const [markIncidentReport] = useMarkIncidentReportAsWithSafetyTeam();
  const { myUserAccount } = useGetMyUserAccount();
  const { userOrganizationProfiles } = useUserOrganizationProfiles();

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 991px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 1799px)",
  });

  const initializeForm = () => {
    setCurrentStep(incidentReport.stepNumber);
    const formData = incidentReportToFormData(incidentReport);
    form.setFieldsValue(formData);
  };

  useEffect(() => {
    if (incidentReport) {
      initializeForm();
      setCurrentReportId(incidentReport.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };
  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const displayTitle = ({ title, localIndex }) =>
    title || t({ id: "incident-report.default-title", message: `Incident Report #${localIndex}` });

  const onValuesChange = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }
      // Clear error messages of fields
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ]);
    });
  };

  const validateFields = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleNext = async () => {
    const formIsValid = await validateFields();
    if (formIsValid) {
      handleSaveDraft({ showMessage: false, step: currentStep + 1, showLoading: false });
      next();
    }
  };

  const handleSaveDraft = async ({ showMessage = true, step, showLoading = true }) => {
    if (showLoading) setSavingDraft(true);
    const incidentReportData = formDataToIncidentReport(form.getFieldValue());
    const incidentLocation = { ...locationCoordinates, formattedAddress: incidentReportData.incidentLocation };
    try {
      if (currentReportId) {
        const updatedReport = await updateIncidentReport({
          input: { ...incidentReportData, id: currentReportId, incidentLocation, stepNumber: step || currentStep },
        });
        const { suggestedTitle, suggestedSummary } = updatedReport;
        if (suggestedTitle && !incidentReportData.title && currentStep >= 3) {
          form.setFieldsValue({
            title: suggestedTitle,
          });
        }
        if (suggestedSummary && !incidentReportData.summary && currentStep >= 3) {
          form.setFieldsValue({
            summary: suggestedSummary,
          });
        }
      } else {
        const { id } = await createIncidentReport({
          input: {
            ...incidentReportData,
            incidentLocation,
            stepNumber: step || currentStep,
            authorName: myUserAccount?.name,
          },
        });
        setCurrentReportId(id);
        window.history.pushState(null, "", `/incident-reports/${id}/edit`);
      }
      if (showMessage) {
        message.success(
          t({
            id: "incident-report-form.save-success",
            message: "Incident Report draft saved successfully",
          })
        );
      }
    } catch (err) {
      captureException(err);
      message.error(
        t({
          id: "incident-report-form.save-error",
          message: "Failed to save incident report",
        })
      );
    } finally {
      setSavingDraft(false);
    }
  };

  const handleSubmit = async () => {
    const formIsValid = await validateFields();
    if (!formIsValid) {
      return;
    }
    const incidentReportData = formDataToIncidentReport(form.getFieldValue());
    let incidentReportId;
    const incidentLocation = { ...locationCoordinates, formattedAddress: incidentReportData.incidentLocation };
    try {
      if (currentReportId) {
        incidentReportId = currentReportId;
        await updateIncidentReport({
          input: { ...incidentReportData, incidentLocation, id: currentReportId },
        });
      } else {
        const { id } = await createIncidentReport({
          input: { ...incidentReportData, incidentLocation },
        });
        incidentReportId = id;
      }
      await markIncidentReport({ id: incidentReportId });
      setFinished(true);
    } catch (err) {
      captureException(err);
      message.error(
        t({
          id: "incident-report-form.submit-error",
          message: "Failed to submit incident report for review",
        })
      );
    }
  };

  const users = useMemo(
    () =>
      (userOrganizationProfiles &&
        userOrganizationProfiles.map(({ userAccount }) => userAccount).sort((a, b) => a.name.localeCompare(b.name))) ||
      [],
    [userOrganizationProfiles]
  );
  const userOptions = users.map((user) => ({
    value: user.auth0Id,
    label: user.name,
  }));

  const onBack = () => {
    navigate("/incident-reports");
  };

  const UnderReview = () => (
    <Result
      icon={<SmileOutlined className={styles.resultIcon} />}
      title={t({
        id: "incident-report.steps.review.title",
        message: "Great, our safety team is reviewing your submission! ",
      })}
      extra={
        <Link to="/incident-reports">
          <Button>
            <Trans id="incident-report.steps.review.view-all-reports">View All Incident Reports</Trans>
          </Button>
        </Link>
      }
    />
  );

  const stepsContent = [
    {
      title: t({
        id: "incident-report.steps.incident-description",
        message: "Incident Description",
      }),
      content: (
        <BasicInfo
          form={form}
          setLocationCoordinates={setLocationCoordinates}
          userOptions={userOptions}
          isSmallScreen={isSmallScreen}
        />
      ),
    },
    {
      title: t({
        id: "incident-report.steps.basic-information",
        message: "Basic Information",
      }),
      content: <IncidentDescription />,
    },
    {
      title: t({
        id: "incident-report.steps.root-cause",
        message: "Root Cause",
      }),
      content: <RootCause />,
    },
    {
      title: t({
        id: "incident-report.steps.activities-performed",
        message: "Activities Performed",
      }),
      content: <ActivitiesPerformed />,
    },
    {
      title: t({
        id: "incident-report.steps.title-&-summary",
        message: "Title & Summary",
      }),
      content: <TitleAndSummary reportId={currentReportId} form={form} />,
    },
    {
      title: t({
        id: "incident-report.steps.sign-&-submit",
        message: "Sign & Submit",
      }),
      content: <NamesAndSignatures form={form} userOptions={userOptions} authorName={myUserAccount?.name} />,
    },
  ];

  return (
    <Card
      title={
        <div className={styles.formHeader}>
          <Button className={styles.backButton} icon={<ArrowLeftOutlined />} onClick={onBack}>
            <Trans id="common.back">Back</Trans>
          </Button>
          <div className={styles.title}>
            {incidentReport ? (
              displayTitle({ title: incidentReport.title, localIndex: incidentReport.localIndex })
            ) : (
              <Trans id="incident-reports.title-new">New Incident Report</Trans>
            )}
          </div>
        </div>
      }
    >
      <div className={styles.container}>
        <Form
          form={form}
          className={styles.form}
          onValuesChange={onValuesChange}
          layout="vertical"
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <Steps
            current={currentStep}
            className={styles.stepIndicator}
            labelPlacement={isLargeScreen ? "horizontal" : "vertical"}
            direction={isSmallScreen ? "vertical" : "horizontal"}
            items={stepsContent.map((step) => ({ title: step.title, status: finished ? "finish" : undefined }))}
          />
          <Divider />
          {finished ? <UnderReview /> : stepsContent[currentStep].content}

          {!finished && (
            <div className={styles.buttonsContainer}>
              <Button onClick={handleSaveDraft} loading={savingDraft}>
                <Trans id="incident-report.button.save-draft">Save Draft</Trans>
              </Button>
              {currentStep > 0 && (
                <Button onClick={prev}>
                  <Trans id="incident-report.button.back">Go Back</Trans>
                </Button>
              )}
              {currentStep < stepsContent.length - 1 ? (
                <Button type="primary" onClick={handleNext}>
                  <Trans id="incident-report.button.next">Next</Trans>
                </Button>
              ) : (
                <Button type="primary" onClick={handleSubmit}>
                  <Trans id="incident-report.button.submit">Submit</Trans>
                </Button>
              )}
            </div>
          )}
        </Form>
      </div>
    </Card>
  );
}

export default IncidentReportSteps;
