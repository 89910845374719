import React from "react";
import { Tag } from "antd";
import { priorities as prioritiesFn } from "../../helpers/AlarmRules.helpers";
import styles from "./PriorityTag.module.less";

export default function PriorityTag({ priority }) {
  const priorities = prioritiesFn();

  return (
    <Tag color={priorities[priority].color} className={styles.tag}>
      {priorities[priority].name}
    </Tag>
  );
}
