import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "context/UserContext";

import { Breadcrumb } from "antd";

import TabView from "./components/TabView";

import { findAncestors } from "../helpers/nestedObject";

import styles from "./AssetDetails.module.less";

export default function AssetDetails({ asset, assetIdMap }) {
  const { currentOrganization } = useUser();

  const hasData = !!asset?.slug;
  const isShared = currentOrganization !== asset?.organizationSlug;
  const parent = asset.parentId ? assetIdMap?.get(asset.parentId) : null;

  return (
    <div className={styles.container}>
      {assetIdMap && assetIdMap.size > 0 && (
        <Breadcrumb
          separator=">"
          items={findAncestors(asset.id, assetIdMap).map((a) => ({
            title: <Link to={`/assets/${a.id}`}>{a.name}</Link>,
          }))}
        />
      )}
      <TabView asset={asset} isShared={isShared} hasData={hasData} parent={parent} />
    </div>
  );
}
