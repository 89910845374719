import React, { useState } from "react";
import { ANNOTATION_TYPES, EVENT_FLIP_RIGHT } from "config/plotConfig";

import styles from "./Event.module.less";

export default function Event({ event, position, size }) {
  const [open, setOpen] = useState(false);

  if (!ANNOTATION_TYPES[event.type]) return null;
  const { Icon, color, label } = ANNOTATION_TYPES[event.type];

  const handleClick = () => {
    setOpen(!open);
  };

  const flipped = size.width - position < EVENT_FLIP_RIGHT;

  return (
    <div
      className={`${styles.container}  ${flipped ? styles.leftSide : ""}`}
      style={{
        top: size.top < 0 ? 0 : size.top,
        left: position - 1,
        borderLeft: `1px dashed ${color}`,
        height: size.height,
        zIndex: open ? 100 : 10,
        ...(flipped && {
          right: size.width - position,
          borderRight: `1px dashed ${color}`,
          left: "auto",
          borderLeft: "none",
        }),
      }}
    >
      <div
        className={styles.eventBox}
        style={{
          display: "flex",

          border: `2px solid ${color}`,
          ...(flipped && {
            flexDirection: "row-reverse",
          }),
        }}
        onClick={handleClick}
        aria-hidden
      >
        <Icon className={styles.eventIcon} />
        <p className={`${styles.eventName} ${open ? styles.eventOpen : ""}`} style={{ color }}>
          {label}
        </p>
      </div>
    </div>
  );
}
