import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Alert } from "antd";
import { SettingOutlined, PlusOutlined } from "@ant-design/icons";
import useCustomMessage from "hooks/useCustomMessage";

import PanelPreview from "components/PanelPreview";
import styles from "./PanelSettings.module.less";
import AxisForm from "./AxisForm";
import MeasuresModal from "./MeasuresModal";
import { getFormData, processFormData, defaultAxisData, generateNewAxisName } from "./helper/axesForm.helper";

function PanelSettings({ panel, previewProps, onSave }) {
  const { message } = useCustomMessage();
  const [open, setOpen] = useState(false);
  const [measuresModalOpen, setMeasuresModalOpen] = useState(false);
  const [measureAssignments, setMeasureAssignments] = useState({});

  const [form] = Form.useForm();
  const axesFormData = Form.useWatch("axes", form);
  const { previewPanel } = previewProps;

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const correctMeasureAssignments = (numberOfAxes) => {
    const res = { ...measureAssignments };
    Object.keys(res).forEach((mId) => {
      if (res[mId] >= numberOfAxes) {
        res[mId] = 0;
      }
    });
    return res;
  };

  useEffect(() => {
    if (axesFormData?.length) {
      const correctedAssignments = correctMeasureAssignments(axesFormData.length);
      setMeasureAssignments(correctedAssignments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axesFormData?.length]);

  const validateSelection = () => {
    if (axesFormData.length > 1 && new Set(Object.values(measureAssignments)).size !== axesFormData.length) {
      message.error("Each axis must have measures assigned");
      return false;
    }
    return true;
  };

  const handleFormSubmit = (formData) => {
    if (validateSelection()) {
      onSave(processFormData(formData.axes, measureAssignments));
      closeModal();
    }
  };

  const handleModalClose = () => {
    form.setFieldsValue(initialFormData);
    setMeasureAssignments({});
    closeModal();
  };

  const initialFormData = getFormData(panel);

  const axesPreviewProps = {
    ...previewProps,
    previewPanel: {
      ...previewPanel,
      customAxes: axesFormData ? processFormData(axesFormData, measureAssignments) : undefined,
    },
  };

  return (
    <div>
      <Button onClick={openModal}>Panel Settings</Button>
      <Modal
        centered
        title={
          <div>
            <SettingOutlined /> Panel Settings
          </div>
        }
        okText="Ok"
        open={open}
        onCancel={handleModalClose}
        width="min(95%, 1800px)"
        onOk={() => form.submit()}
      >
        <MeasuresModal
          open={measuresModalOpen}
          setOpen={setMeasuresModalOpen}
          panel={panel}
          formData={axesFormData || []}
          measureAssignments={measureAssignments}
          setMeasureAssignments={setMeasureAssignments}
        />

        <div className={styles.container}>
          <Form
            name="panel-settings"
            form={form}
            initialValues={initialFormData}
            className={styles.form}
            autoComplete="off"
            onFinish={handleFormSubmit}
            labelAlign="left"
            colon={false}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <Form.List name="axes">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <div className={styles.topContainer}>
                    <div>Axis Settings</div>
                    <div className={styles.buttonsContainer}>
                      {axesFormData?.length > 1 && (
                        <Button onClick={() => setMeasuresModalOpen(true)}>Edit Measures</Button>
                      )}
                      {axesFormData?.length < 4 && (
                        <Button
                          shape="circle"
                          style={{ width: 32 }}
                          onClick={() => {
                            add({
                              ...defaultAxisData,
                              name: generateNewAxisName(axesFormData),
                              position: axesFormData.length % 2 === 0 ? "left" : "right",
                              measures: {},
                            });
                            setMeasuresModalOpen(true);
                          }}
                          block
                          icon={<PlusOutlined />}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.axesList}>
                    {fields.map(({ key, name, ..._restField }) => (
                      <AxisForm
                        key={key}
                        fieldKey={key}
                        fieldName={name}
                        form={form}
                        onRemove={() => {
                          remove(name);
                        }}
                      />
                    ))}
                  </div>
                  <div className={styles.errorList}>
                    {errors.map((error) => (
                      <Alert key={error} type="error" message={error} style={{ borderRadius: 2 }} />
                    ))}
                  </div>
                </>
              )}
            </Form.List>
          </Form>
          <div className={styles.previewContainer}>
            <PanelPreview {...axesPreviewProps} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PanelSettings;
