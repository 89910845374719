import React, { useMemo, useState } from "react";
import { Trans } from "@lingui/macro";
import dayjs from "dayjs";

import { useUser } from "context/UserContext";

import { Empty, Select, Checkbox, Dropdown, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { ReactComponent as SortIcon } from "assets/sort-icon.svg";
import AnnotationItem from "../AnnotationItem";

import styles from "./AnnotationList.module.less";

export default function AnnotationList({ annotations, onClose, setActiveAnnotation }) {
  const { user } = useUser();
  const [showMentionsOnly, setShowMentionsOnly] = useState(false);

  const [statusFilter, setStatusFilter] = useState("all");
  const [sortBy, setSortBy] = useState("annotation time");

  const sortFunction = useMemo(
    () =>
      sortBy === "annotation time"
        ? (a, b) => dayjs(b.time).diff(dayjs(a.time))
        : (a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)),
    [sortBy]
  );

  const filteredAnnotations = useMemo(
    () =>
      annotations
        .filter((annotation) => {
          if (statusFilter === "resolved" && annotation.status !== "RESOLVED") return false;
          if (statusFilter === "unresolved" && annotation.status === "RESOLVED") return false;
          if (showMentionsOnly)
            return annotation.messages.some((message) => message.content?.indexOf(`(user:${user.sub})`) > -1);
          return true;
        })
        .sort(sortFunction),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMentionsOnly, annotations, user?.sub, statusFilter, sortFunction]
  );

  const filterOptions = [
    {
      label: "All Statuses",
      value: "all",
    },
    {
      label: "Resolved",
      value: "resolved",
    },
    {
      label: "Unresolved",
      value: "unresolved",
    },
  ];

  const sortMenuItems = [
    {
      label: "Sort By",
      type: "group",
      children: [
        {
          label: "Annotation Time",
          key: "annotation time",
        },
        {
          label: "Created Time",
          key: "created time",
        },
      ],
    },
  ];

  return (
    <div className={styles.annotationListContainer}>
      <div className={styles.annotationListHeader}>
        <h5>
          <Trans id="panels.panel.annotations.all-annotations">All Annotations</Trans>
        </h5>
        <span className={styles.closeAnnotation} onClick={onClose} aria-hidden>
          <CloseOutlined />
        </span>
      </div>
      <div className={styles.annotationListFilters}>
        <Select options={filterOptions} value={statusFilter} onChange={(newStatus) => setStatusFilter(newStatus)} />
        <div className={styles.sortContainer}>
          <Checkbox checked={showMentionsOnly} onChange={(e) => setShowMentionsOnly(e.target.checked)}>
            @Me
          </Checkbox>
          <Dropdown
            menu={{
              items: sortMenuItems,
              selectedKeys: [sortBy],
              onClick: ({ key }) => setSortBy(key),
            }}
            trigger={["click"]}
          >
            <Button shape="circle" type="text" className={styles.sortButton}>
              <SortIcon />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className={styles.annotationList}>
        {filteredAnnotations.length === 0 && (
          <div className={styles.noAnnotations}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
        {filteredAnnotations.map((annotation) => (
          <AnnotationItem key={annotation.id} annotation={annotation} setActiveAnnotation={setActiveAnnotation} />
        ))}
      </div>
    </div>
  );
}
