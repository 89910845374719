/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { Card, Layout } from "antd";
import { request, gql } from "graphql-request";
import PublicHeader from "components/PublicHeader";
import GlobalFooter from "components/GlobalFooter";
import styles from "./CMSDocument.module.less";

const { Content } = Layout;

const url =
  process.env.REACT_APP_HYGRAPH_URL ||
  "https://api-us-east-1-shared-usea1-02.hygraph.com/v2/cllfwd9fu5i1401uj4u2z6rdm/master";

function CMSDocument({ slug }) {
  const query = gql`
  {
    document(where: { slug: "${slug}" }) {
      body {
        html
      }
    }
  }
`;

  useEffect(() => {
    window.scrollTo(0, 0);
    request(url, query).then((data) => {
      setDocumentHTML(data.document.body.html);
    });
  }, [query]);

  const [documentHTML, setDocumentHTML] = useState("");
  return (
    <>
      <PublicHeader />
      <Layout className={styles.layout}>
        <Content className={styles.content}>
          <Card className={styles.card}>
            <div className={styles.tos} dangerouslySetInnerHTML={{ __html: documentHTML }} />
          </Card>
        </Content>
      </Layout>
      <GlobalFooter />
    </>
  );
}

export default CMSDocument;
