import React, { useState, useMemo, useEffect } from "react";
import { Trans } from "@lingui/macro";

import { useRoles } from "hooks/apiService";
import { Radio } from "antd";

import AdvancedRoleSelector from "../AdvancedRoleSelector";
import SimpleRoleSelector from "../SimpleRoleSelector";

import styles from "./RoleSelector.module.less";

export default function RoleSelector({
  loading = false,
  initialMode = "SIMPLE",
  initialSimpleRoleSlug = "role_viewer",
  initialAdvancedRoles = [],
  onRolesUpdate,
  buttonDisabled,
  actionText = <Trans id="roles-admin.user-roles.save">Save Changes</Trans>,
  disabled = false,
}) {
  const [selectedMode, setSelectedMode] = useState(initialMode);
  const [selectedAdvancedRoles, setSelectedAdvancedRoles] = useState({});
  const [selectedSimpleRole, setSelectedSimpleRole] = useState(initialSimpleRoleSlug);

  const { roles } = useRoles();
  const simpleRoles = useMemo(
    () => [...roles].filter((role) => !role.module).sort((a, b) => b.level - a.level),
    [roles]
  );
  const advancedRoles = useMemo(() => roles.filter((role) => !!role.module), [roles]);

  const initialAdvancedRolesMap = useMemo(
    () =>
      advancedRoles.reduce((result, role) => {
        const userRoleSlug = initialAdvancedRoles.find((userRole) => userRole.module === role.module)?.slug;
        if (!userRoleSlug) {
          return {
            [role.module]: "none",
            ...result,
          };
        }
        return {
          [role.module]: userRoleSlug,
          ...result,
        };
      }, {}),
    [advancedRoles, initialAdvancedRoles]
  );

  const handleModeChange = (e) => {
    setSelectedMode(e.target.value);
  };

  useEffect(() => {
    if (advancedRoles?.length) {
      setSelectedAdvancedRoles(initialAdvancedRolesMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedRoles, JSON.stringify(initialAdvancedRoles)]);

  useEffect(() => {
    if (simpleRoles?.length) {
      setSelectedSimpleRole(initialSimpleRoleSlug);
    }
  }, [simpleRoles, initialSimpleRoleSlug]);

  return (
    <div>
      <Radio.Group onChange={handleModeChange} value={selectedMode} disabled={disabled}>
        <Radio value="SIMPLE">Simple</Radio>
        <Radio value="ADVANCED">Advanced</Radio>
      </Radio.Group>
      {selectedMode === "SIMPLE" ? (
        <div className={styles.roleContainer}>
          <SimpleRoleSelector
            roles={simpleRoles}
            initialRoleSlug={initialSimpleRoleSlug}
            selectedRole={selectedSimpleRole}
            setSelectedRole={setSelectedSimpleRole}
            onRolesUpdate={onRolesUpdate}
            initialMode={initialMode}
            loading={loading}
            actionText={actionText}
            buttonDisabled={buttonDisabled}
            disabled={disabled}
          />
        </div>
      ) : (
        <AdvancedRoleSelector
          roles={advancedRoles}
          initialRoles={initialAdvancedRoles}
          selectedRoles={selectedAdvancedRoles}
          setSelectedRoles={setSelectedAdvancedRoles}
          onRolesUpdate={onRolesUpdate}
          loading={loading}
          actionText={actionText}
          buttonDisabled={buttonDisabled}
          initialMode={initialMode}
          disabled={disabled}
        />
      )}
    </div>
  );
}
