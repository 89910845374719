import React, { useEffect } from "react";
import dayjs from "dayjs";
import { t, Trans } from "@lingui/macro";

import { Button, DatePicker, Select, Switch, Tooltip } from "antd";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { ReactComponent as RefreshIcon } from "assets/refresh-icon.svg";

import { intervalToAbsoluteRange } from "utils/time-utils";
import styles from "./TimeSelectorAdvanced.module.less";

const { RangePicker } = DatePicker;
const { Option } = Select;

const intervals = {
  "5m": { getText: () => t({ id: "time-selector.5m", message: "Last 5 minutes" }) },
  "15m": { getText: () => t({ id: "time-selector.15m", message: "Last 15 minutes" }) },
  "1h": { getText: () => t({ id: "time-selector.1h", message: "Last hour" }) },
  "3h": { getText: () => t({ id: "time-selector.3h", message: "Last 3 hours" }) },
  "24h": { getText: () => t({ id: "time-selector.24h", message: "Last 24 hours" }) },
  "3d": { getText: () => t({ id: "time-selector.3d", message: "Last 3 days" }) },
  "7d": { getText: () => t({ id: "time-selector.7d", message: "Last 7 days" }) },
  "30d": { getText: () => t({ id: "time-selector.30d", message: "Last 30 days" }) },
  "90d": { getText: () => t({ id: "time-selector.90d", message: "Last 90 days" }) },
};

function TimeSelectorAdvanced({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  timeInterval,
  setTimeInterval,
  timeMode,
  setTimeMode = () => {},
  pollInterval,
  setPollInterval,
  setRefetch,
  disabled = false,
  containerStyle = {},
  size = "medium",
}) {
  useEffect(() => {
    if (timeMode === "REALTIME") {
      const time = intervalToAbsoluteRange(timeInterval);
      setStartTime(dayjs(time.from));
      setEndTime(dayjs(time.to));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInterval]);
  const onDateRangeChange = ([newStart, newEnd]) => {
    setStartTime(newStart);
    setEndTime(newEnd);
  };

  const disabledRange = (current) => current.isAfter(dayjs());

  const handleTimeIntervalChange = (newInterval) => {
    setTimeInterval(newInterval);
  };

  const ModeSwitcher = () => {
    const selectedColor = disabled ? "#878787" : "#008b8a";
    const selectedStyle = { color: selectedColor, borderColor: selectedColor };
    const unselectedColor = disabled ? "#BABABA" : "#262626";
    const unselectedStyle = { color: unselectedColor, borderColor: "#BFBFBF" };
    return (
      <div className={styles.switcher}>
        <Button
          className={styles.buttonLeft}
          style={timeMode === "HISTORICAL" ? selectedStyle : unselectedStyle}
          onClick={() => setTimeMode("HISTORICAL")}
          disabled={disabled}
          size={size}
        >
          <Trans id="time-selector.mode.historical">Historical</Trans>
        </Button>
        <Button
          className={styles.buttonRight}
          style={timeMode === "REALTIME" ? selectedStyle : unselectedStyle}
          onClick={() => setTimeMode("REALTIME")}
          disabled={disabled}
          size={size}
        >
          <Trans id="time-selector.mode.realtime">Realtime</Trans>
        </Button>
      </div>
    );
  };

  const modeString =
    timeMode === "HISTORICAL" ? (
      <Trans id="time-selector.mode.historical">Historical</Trans>
    ) : (
      <Trans id="time-selector.mode.realtime">Realtime</Trans>
    );

  const SmallModeSwitcher = () => {
    const checked = timeMode === "REALTIME";

    const handleModeSwitch = (realtimeSelected) => {
      if (realtimeSelected) {
        setTimeMode("REALTIME");
      } else {
        setTimeMode("HISTORICAL");
      }
    };
    return (
      <Tooltip title={modeString}>
        <Switch
          checkedChildren={<ClockCircleOutlined />}
          unCheckedChildren={<CalendarOutlined />}
          checked={checked}
          onChange={handleModeSwitch}
          className="draggableCancel"
        />
      </Tooltip>
    );
  };

  const IntervalSwitcher = () => {
    const options = [
      {
        label: "Off",
        value: 0,
      },
      {
        label: "5s",
        value: 5000,
      },
      {
        label: "15s",
        value: 15000,
      },
      {
        label: "1m",
        value: 60000,
      },
      {
        label: "5m",
        value: 300000,
      },
      {
        label: "15m",
        value: 900000,
      },
      {
        label: "30m",
        value: 1800000,
      },

      {
        label: "1h",
        value: 3600000,
      },
      {
        label: "2h",
        value: 7200000,
      },
      {
        label: "1d",
        value: 86400000,
      },
    ];

    const handleChange = (value) => {
      setPollInterval(value);
    };
    const handleButtonClick = () => {
      setRefetch((e) => !e);
    };

    return (
      <div className={`${styles.pollIntervalSwitcher} draggableCancel`}>
        <Button className={styles.refreshButton} onClick={handleButtonClick} size={size} disabled={disabled}>
          <RefreshIcon />
        </Button>
        <Select
          className={`${styles.selectButton} ${styles[size]}`}
          options={options}
          defaultValue={{ label: "Off", value: 0 }}
          value={pollInterval}
          onChange={handleChange}
          size={size}
          disabled={disabled}
        />
      </div>
    );
  };

  return (
    <div className={styles.container} style={containerStyle}>
      {size === "medium" ? <ModeSwitcher /> : <SmallModeSwitcher />}
      <div className={styles.timeControls}>
        {timeMode === "HISTORICAL" ? (
          <RangePicker
            className="draggableCancel"
            showTime={{ format: "HH:mm:ss" }}
            onChange={onDateRangeChange}
            value={[startTime, endTime]}
            disabledDate={disabledRange}
            disabled={disabled}
            format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD", "MMMM DD YYYY", "MM/DD/YYYY"]}
            presets={[{ label: "Today", value: [dayjs().startOf("day"), dayjs()] }]}
            placement="bottomRight"
            size={size}
          />
        ) : (
          <>
            <Select
              className={`${styles.intervalPicker} draggableCancel`}
              onChange={handleTimeIntervalChange}
              value={timeInterval}
              disabled={disabled}
              size={size}
            >
              {Object.entries(intervals).map(([intervalKey, { getText }]) => (
                <Option key={intervalKey} value={intervalKey}>
                  {getText()}
                </Option>
              ))}
            </Select>
            <IntervalSwitcher />
          </>
        )}
      </div>
    </div>
  );
}

export default TimeSelectorAdvanced;
