import { useUser } from "context/UserContext";
import { GET_SHARED_EQUIPMENT_MEASURES } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useSharedEquipmentMeasures({ equipmentId }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_SHARED_EQUIPMENT_MEASURES, {
    variables: {
      organizationSlug: currentOrganization,
      equipmentId,
    },
  });

  const sharedEquipmentMeasures = data?.getSharedEquipmentMeasures || [];

  return { sharedEquipmentMeasures, loading, error };
}
