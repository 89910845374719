import React, { useEffect } from "react";

import { I18nProvider, useLingui } from "@lingui/react";
import { i18n as i18nLib } from "@lingui/core";
import App from "containers/App";
import { ConfigProvider, App as AntdApp } from "antd";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import ptPT from "antd/lib/locale/pt_PT";
import { defaultLocale, activateLocale } from "utils/i18n";

const AntDesignLocalizedApp = () => {
  const { i18n } = useLingui();

  const antDesignLocale = () => {
    switch (i18n.locale) {
      case "en":
        return enUS;
      case "es":
        return esES;
      case "pt":
        return ptPT;
      default:
        return enUS;
    }
  };
  return (
    <ConfigProvider
      locale={antDesignLocale()}
      theme={{
        token: {
          colorPrimary: "#008b8a",
          colorPrimaryBg: "#e5f1f1",
          colorWarning: "#f3b416",
          colorError: "#ff0f0f",
          borderRadius: 16,
          borderRadiusSM: 2,
          fontFamily: "Poppins",
          fontWeightStrong: 500,
          colorBgLayout: "#eaeff4",
          colorBgContainerDisabled: "#f5f5f5",
          colorFillAlter: "#fafafa",
          screenLGMax: 1399,
          screenXL: 1400,
          screenXLMin: 1400,
          screenXLMax: 1899,
          screenXXL: 1900,
          screenXXLMin: 1900,
        },
        components: {
          Table: {
            borderRadiusLG: 2,
          },
          Card: {
            borderRadiusLG: 2,
          },
          Drawer: {
            paddingLG: 0,
          },
          Modal: {
            borderRadiusLG: 2,
          },
          Button: {
            borderRadiusSM: 16,
          },
          Tag: {
            borderRadiusSM: 16,
          },
        },
      }}
    >
      <AntdApp>
        <App />
      </AntdApp>
    </ConfigProvider>
  );
};

export default function LocalizedApp() {
  useEffect(() => {
    activateLocale(defaultLocale);
  }, []);

  return (
    <I18nProvider i18n={i18nLib}>
      <AntDesignLocalizedApp />
    </I18nProvider>
  );
}
