import React from "react";

import styles from "./KPI.module.less";

export default function KPI({ number, unit, unitPlaceToFront = false, title }) {
  return (
    <div className={styles.container}>
      <div>
        {title && <p className={styles.title}>{title}</p>}
        <div className={styles.numberContainer}>
          {unit && unitPlaceToFront && <span className={styles.unit}>{unit}</span>}
          <span className={styles.number}>{Number(number).toLocaleString()}</span>
          {unit && !unitPlaceToFront && <span className={styles.unit}>{unit}</span>}
        </div>
      </div>
    </div>
  );
}
