import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_ALARM, GET_ALARMS } from "graphql/apiService";

export default function useDeleteAlarm() {
  const { currentOrganization } = useUser();

  const [deleteAlarmMutation, { loading, error }] = useMutation(DELETE_ALARM, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: [GET_ALARMS],
  });

  const deleteAlarm = async ({ id }) => {
    const res = await deleteAlarmMutation({
      variables: {
        id,
      },
    });
    return res.data.deleteAlarm;
  };

  return [deleteAlarm, { loading, error }];
}
