import { useUser } from "context/UserContext";
import { GET_INCIDENT_REPORTS_BY_ORGANIZATION } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useIncidentReports() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_INCIDENT_REPORTS_BY_ORGANIZATION, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const incidentReports = data?.getIncidentReportsByOrganization || [];

  return { incidentReports, loading, error };
}
