import React, { useMemo } from "react";
import { AblyProvider as BaseAblyProvider } from "ably/react";
// eslint-disable-next-line import/no-unresolved
import * as Ably from "ably";
import { useGetAblyTokenRequest } from "hooks/apiService";

const AblyProvider = ({ children }) => {
  const [getAblyTokenRequest] = useGetAblyTokenRequest();

  const authCallback = async (data, callback) => {
    try {
      const tokenRequest = await getAblyTokenRequest();
      callback(null, tokenRequest);
    } catch (e) {
      callback(e, null);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const client = useMemo(() => new Ably.Realtime({ authCallback }), []);

  return <BaseAblyProvider client={client}>{children}</BaseAblyProvider>;
};

// eslint-disable-next-line import/prefer-default-export
export { AblyProvider };
