import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { NotificationFeed } from "@knocklabs/react-notification-feed";
import { Button, Drawer } from "antd";
import { Trans } from "@lingui/macro";

import { useNotifications } from "context/NotificationsContext";

import styles from "./NotificationDrawer.module.less";

export default function NotificationDrawer() {
  const { drawerVisibility, closeDrawer } = useNotifications();
  const navigate = useNavigate();

  const handleClose = () => {
    closeDrawer();
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={handleClose}
      open={drawerVisibility}
      rootClassName={styles.drawer}
      maskStyle={{ background: "rgba(0, 0, 0, 0)" }}
      bodyStyle={{ overflow: "hidden" }}
    >
      <div className={styles.drawerContent}>
        <div className={styles.feedContainer}>
          <NotificationFeed
            onNotificationClick={(notification) => {
              if (notification.data?.relativeUrl) {
                navigate(notification.data.relativeUrl);
                closeDrawer();
              }
            }}
          />
        </div>

        <div className={styles.footerMenu}>
          <Link to={{ pathname: "/notifications" }}>
            <Button className={styles.footerMenuButton} type="primary" onClick={handleClose}>
              <Trans id="notification-drawer.view-more">View More</Trans>
            </Button>
          </Link>
        </div>
      </div>
    </Drawer>
  );
}
