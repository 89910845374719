import React from "react";
import { t } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";
import { Empty, App } from "antd";

import { useDeletePanel, useUpdatePanel } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import Grid from "components/Grid";
import PanelCard from "components/PanelCard";

import styles from "./Panels.module.less";

export default function Panels({ asset }) {
  const [deletePanel] = useDeletePanel();
  const [updatePanel] = useUpdatePanel();
  const { modal } = App.useApp();
  const { message } = useCustomMessage();

  const panels = asset?.panels || [];

  const handleDeletePanel = async ({ id }) => {
    try {
      await deletePanel({ id });
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "asset-map.asset.tabs.panels.errors.delete-panel",
          message: "Failed to delete panel",
        })
      );
    }
  };

  const deletePanelModal = ({ id }) => {
    modal.warning({
      title: t({
        id: "asset-map.asset.tabs.panels.delete-modal.title",
        message: "Delete Panel",
      }),
      content: t({
        id: "asset-map.asset.tabs.panels.delete-modal.content",
        message: "Are you sure you want to delete this Panel?",
      }),
      closable: true,
      maskClosable: true,
      onOk() {
        handleDeletePanel({ id });
      },
      okText: t({
        id: "asset-map.asset.tabs.panels.delete-modal.confirm-button",
        message: "Delete",
      }),
      okType: "danger",
    });
  };

  return (
    <div className={styles.container}>
      {panels.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {panels.length > 0 && (
        <Grid columns={2} columnGap="1rem" rowGap="1rem" className={styles.gridContainer}>
          {panels.map((panel) => (
            <PanelCard
              key={panel.id}
              panel={panel}
              height={260}
              onPanelRemove={() => {
                deletePanelModal({ id: panel.id });
              }}
              onPanelUpdate={(data) => updatePanel(data)}
            />
          ))}
        </Grid>
      )}
    </div>
  );
}
