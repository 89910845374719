import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_ORGANIZATION_DATA_PARTNER_REQUEST, GET_ORGANIZATION_DATA_PARTNERS_RECEIVING } from "graphql/apiService";

export default function useCreateDataPartnerRequest() {
  const { currentOrganization } = useUser();

  const [createDataPartnerRequestMutation, { loading, error }] = useMutation(CREATE_ORGANIZATION_DATA_PARTNER_REQUEST, {
    variables: { organizationSlug: currentOrganization },
    update: (cache, { data: { createOrganizationDataPartnerRequest: newDataPartner } }) => {
      const { getOrganizationDataPartners: dataPartners } = cache.readQuery({
        query: GET_ORGANIZATION_DATA_PARTNERS_RECEIVING,
        variables: {
          organizationSlug: currentOrganization,
        },
      });
      cache.writeQuery({
        query: GET_ORGANIZATION_DATA_PARTNERS_RECEIVING,
        variables: {
          organizationSlug: currentOrganization,
        },
        data: {
          getOrganizationDataPartners: [...dataPartners, newDataPartner],
        },
      });
    },
  });

  const createOrganizationDataPartnerRequest = async ({ receivingOrganizationSlug }) => {
    const res = await createDataPartnerRequestMutation({ variables: { receivingOrganizationSlug } });
    return res.data.createOrganizationDataPartnerRequest;
  };

  return [createOrganizationDataPartnerRequest, { loading, error }];
}
