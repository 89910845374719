import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { GET_MY_USER_ACCOUNT, UPDATE_MY_NOTIFICATION_PREFERENCES } from "graphql/apiService";

export default function useUpdateMyNotificationPreferences() {
  const { currentOrganization } = useUser();

  const [updateMyNotificationPreferencesMutation, { loading, error }] = useMutation(
    UPDATE_MY_NOTIFICATION_PREFERENCES,
    {
      variables: { organizationSlug: currentOrganization },
      refetchQueries: [GET_MY_USER_ACCOUNT],
    }
  );

  const updateMyNotificationPreferences = async ({ notificationPreferences }) => {
    const res = await updateMyNotificationPreferencesMutation({ variables: { notificationPreferences } });
    return res.data.updateNotificationPreferences;
  };

  return [updateMyNotificationPreferences, { loading, error }];
}
