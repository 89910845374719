import React, { useEffect } from "react";
import { Card } from "antd";
import { t } from "@lingui/macro";

import { useParams } from "react-router-dom";
import useCustomMessage from "hooks/useCustomMessage";
import { useDashboard } from "hooks/apiService";

import captureException from "utils/capture-sentry-exception";
import DashboardInput from "../DashboardInput";

function DashboardEdit() {
  const { id } = useParams();
  const { dashboard, error, loading } = useDashboard({ id });
  const { message } = useCustomMessage();

  useEffect(() => {
    if (error) {
      captureException(error);
      message.error(
        t({
          id: "dashboards.errors.get-dashboard",
          message: "Failed to get dashboard",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  return loading ? <Card loading /> : <DashboardInput mode="edit" dashboard={dashboard} />;
}

export default DashboardEdit;
