import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { MARK_ANNOTATION_AS_ACTIVE } from "graphql/apiService";

export default function useMarkAnnotationAsActive() {
  const { currentOrganization } = useUser();

  const [markAnnotationAsActiveMutation, { loading, error }] = useMutation(MARK_ANNOTATION_AS_ACTIVE, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetAnnotationsByPanel"],
  });

  const markAnnotationAsActive = async ({ id }) => {
    const res = await markAnnotationAsActiveMutation({
      variables: {
        id,
      },
    });
    return res.data.markAnnotationAsActive;
  };

  return [markAnnotationAsActive, { loading, error }];
}
