import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { MARK_INCIDENT_REPORT_AS_WITH_SAFETY_TEAM, GET_INCIDENT_REPORTS_BY_ORGANIZATION } from "graphql/apiService";

export default function useMarkIncidentReportAsWithSafetyTeam() {
  const { currentOrganization } = useUser();

  const [markIncidentReportAsWithSafetyTeamMutation, { loading, error }] = useMutation(
    MARK_INCIDENT_REPORT_AS_WITH_SAFETY_TEAM,
    {
      variables: { organizationSlug: currentOrganization },
      refetchQueries: [
        { query: GET_INCIDENT_REPORTS_BY_ORGANIZATION, variables: { organizationSlug: currentOrganization } },
      ],
    }
  );

  const markIncidentReportAsWithSafetyTeam = async ({ id }) => {
    const res = await markIncidentReportAsWithSafetyTeamMutation({ variables: { id } });
    return res.data?.MarkIncidentReportAsWithSafetyTeam;
  };

  return [markIncidentReportAsWithSafetyTeam, { loading, error }];
}
