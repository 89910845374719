import React from "react";
import { Table, Checkbox } from "antd";
import { Trans, plural } from "@lingui/macro";
import TableColumns from "../../AssetShare/components/TableColumns";

import styles from "./MeasureSelection.module.less";

function MeasureSelection({ measures, shareAllMeasures, setShareAllMeasures, selectedMeasures, setSelectedMeasures }) {
  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedMeasures(selectedRows);
    },
    selectedRowKeys: selectedMeasures.map((measure) => measure.id),
  };

  const tableColumns = TableColumns();

  return (
    <div className={styles.container}>
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={shareAllMeasures}
          onChange={(e) => {
            setShareAllMeasures(e.target.checked);
          }}
        >
          <Trans id="equipment-management.measure-selection.share-all-measures">Share all measures</Trans>
        </Checkbox>
      </div>

      {shareAllMeasures || (
        <>
          {selectedMeasures.length > 0 && (
            <div className={styles.measureSelectedContainer}>
              <p className={styles.paragraph}>
                <Trans id="equipment-management.measure-selection.selected-items">
                  Selected {selectedMeasures.length}{" "}
                  {plural(selectedMeasures.length, {
                    one: "item",
                    other: "items",
                  })}
                </Trans>
              </p>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span className={styles.clearSelectedMeasuresButton} onClick={() => setSelectedMeasures([])}>
                <Trans id="equipment-management.measure-selection.selected-items-clear">Clear</Trans>
              </span>
            </div>
          )}

          <Table
            rowSelection={rowSelection}
            columns={tableColumns}
            dataSource={measures?.map((measure) => ({ ...measure, key: measure.id }))}
            pagination={{
              showSizeChanger: true,
            }}
          />
        </>
      )}
    </div>
  );
}

export default MeasureSelection;
