import { t } from "@lingui/macro";

const fileTypesFn = () => ({
  image: t({
    id: "asset-map.asset.attachments.columns.types.image",
    message: "Image",
  }),
  pdf: t({
    id: "asset-map.asset.attachments.columns.types.pdf",
    message: "PDF",
  }),
  excel: t({
    id: "asset-map.asset.attachments.columns.types.excel",
    message: "Excel Spreadsheet",
  }),
  powerpoint: t({
    id: "asset-map.asset.attachments.columns.types.powerpoint",
    message: "Powerpoint Presentation",
  }),
  word: t({
    id: "asset-map.asset.attachments.columns.types.word",
    message: "Word Document",
  }),
});

// eslint-disable-next-line consistent-return, import/prefer-default-export
export const simpleType = (contentType) => {
  const fileTypes = fileTypesFn();
  if (contentType.includes("image/")) {
    return fileTypes.image;
  }
  if (contentType === "application/pdf") {
    return fileTypes.pdf;
  }
  if (contentType.includes("word")) {
    return fileTypes.word;
  }
  if (contentType.includes("powerpoint") || contentType.includes("presentation")) {
    return fileTypes.powerpoint;
  }
  if (contentType.includes("excel") || contentType.includes("spreadsheet")) {
    return fileTypes.excel;
  }
};
