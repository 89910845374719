import React from "react";
import { isServiceApm } from "utils/auth-utils";
import WorkRequestsProduct from "./WorkRequestsProduct";
import WorkRequests from "./WorkRequests.jsx";

const isProduct = isServiceApm();
function Main() {
  if (isProduct) {
    return <WorkRequestsProduct />;
  }
  return <WorkRequests />;
}

export default Main;
