/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { List, Button, Checkbox, Divider, Input, Select } from "antd";
import dayjs from "dayjs";
import { useTriggeredAlarms, useUpdateTriggeredAlarm } from "hooks/apiService";

import { ExclamationCircleOutlined, CheckOutlined, ReloadOutlined } from "@ant-design/icons";
import { ReactComponent as PlcAlarm } from "assets/plc-alarm-icon.svg";
import { ReactComponent as ApmAlarm } from "assets/apm-alarm-icon.svg";
import { ReactComponent as AlarmUnread } from "assets/alarm-unread.svg";
import { ReactComponent as TagIcon } from "assets/tag.svg";
import { ReactComponent as TagIconFilled } from "assets/tag-filled.svg";

import { intervalToAbsoluteRange } from "utils/time-utils";
import { capitalizeFirstLetter } from "utils/string-utils";

import styles from "./AlarmAcknowledgement.module.less";

const options = {
  sources: ["PLC", "APM"],
  statuses: ["UNACKNOWLEDGED", "ACKNOWLEDGED", "SAVED"],
  severities: ["LOW", "MEDIUM", "HIGH", "CRITICAL"],
};

const getDisplayName = (option) => {
  if (option === "PLC") return "PLC alarm";
  if (option === "APM") return "APM alarm";
  return capitalizeFirstLetter(option);
};

const optionIcons = {
  PLC: <PlcAlarm />,
  APM: <ApmAlarm />,
  UNACKNOWLEDGED: <AlarmUnread />,
  ACKNOWLEDGED: <CheckOutlined />,
  SAVED: <TagIcon />,
  LOW: <ExclamationCircleOutlined className={styles.severityLowColor} />,
  MEDIUM: <ExclamationCircleOutlined className={styles.severityMediumColor} />,
  HIGH: <ExclamationCircleOutlined className={styles.severityHighColor} />,
  CRITICAL: <ExclamationCircleOutlined className={styles.severityCriticalColor} />,
  ACKNOWLEDGED_ALARM: <ExclamationCircleOutlined className={styles.acknowledgedColor} />,
};

const intervals = {
  "1h": "Last hour",
  "3h": "Last 3 hours",
  "24h": "Last 24 hours",
  "3d": "Last 3 days",
  "7d": "Last 7 days",
  "30d": "Last 30 days",
  "90d": "Last 90 days",
};

const initialCounters = {
  PLC: 0,
  APM: 0,
  UNACKNOWLEDGED: 0,
  ACKNOWLEDGED: 0,
  SAVED: 0,
  LOW: 0,
  MEDIUM: 0,
  HIGH: 0,
  CRITICAL: 0,
};

export default function AlarmAcknowledgement() {
  const [selectedOptions, setSelectedOptions] = useState({
    sources: options.sources,
    statuses: options.statuses,
    severities: options.severities,
  });
  const [searchString, setSearchString] = useState("");
  const [selectedInterval, setSelectedInterval] = useState("24h");
  const timeRange = useMemo(() => intervalToAbsoluteRange(selectedInterval), [selectedInterval]);
  const [updateTriggeredAlarm] = useUpdateTriggeredAlarm();
  const [listData, setListData] = useState([]);
  const [counters, setCounters] = useState(initialCounters);

  const { triggeredAlarms, loading, refetch } = useTriggeredAlarms({ from: timeRange.from });

  useEffect(() => {
    const newCounters = { ...initialCounters };
    triggeredAlarms.forEach((a) => {
      const type = a.alarm?.plcAlarmInfo ? "PLC" : "APM";
      const acknowledged = !!a.acknowledgedBy;
      const { saved } = a;
      const severity = a.alarm?.priority;

      newCounters[type]++;
      if (acknowledged) newCounters.ACKNOWLEDGED++;
      if (!acknowledged) newCounters.UNACKNOWLEDGED++;
      if (saved) newCounters.SAVED++;
      newCounters[severity]++;
    });

    const filteredList = triggeredAlarms
      .map((a) => ({
        id: a.id,
        timeTriggered: a.timeTriggered,
        name: a.alarm?.name,
        severity: a.alarm?.priority,
        acknowledged: !!a.acknowledgedBy,
        saved: a.saved,
        type: a.alarm?.plcAlarmInfo ? "PLC" : "APM",
        alarmId: a.alarmId,
      }))
      .filter(
        (a) =>
          selectedOptions.sources.includes(a.type) &&
          selectedOptions.severities.includes(a.severity) &&
          ((a.acknowledged && selectedOptions.statuses.includes("ACKNOWLEDGED")) ||
            (!a.acknowledged && selectedOptions.statuses.includes("UNACKNOWLEDGED")) ||
            (a.saved && selectedOptions.statuses.includes("SAVED"))) &&
          a.name.includes(searchString)
      );

    setCounters(newCounters);
    setListData(filteredList);
  }, [triggeredAlarms, selectedOptions, searchString]);

  const handleChange = (type, value, checked) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [type]: checked ? [...prevState[type], value] : prevState[type].filter((item) => item !== value),
    }));
  };

  const handleCheckAllChange = (type, checked) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [type]: checked ? options[type] : [],
    }));
  };

  const acknowledgeAlarm = async (id) => {
    updateTriggeredAlarm({ id, acknowledged: true });
  };

  const isSelectedAll = (type) => selectedOptions[type].length === options[type].length;
  const isSelectedSome = (type) =>
    selectedOptions[type].length > 0 && selectedOptions[type].length < options[type].length;

  const renderCheckboxes = (type, label) => (
    <>
      <div onClick={() => handleCheckAllChange(type, !isSelectedAll(type))}>
        <div className={styles.title}>{`ALL ${label.toUpperCase()}`}</div>
        <div>
          <Checkbox indeterminate={isSelectedSome(type)} checked={isSelectedAll(type)} />
        </div>
      </div>
      {options[type].map((option) => (
        <div key={option} onClick={() => handleChange(type, option, !selectedOptions[type].includes(option))}>
          <div className={styles.optionContentLeft}>
            <div className={styles.icon}>{optionIcons[option]}</div>
            <div>{getDisplayName(option)}</div>
          </div>
          <div className={styles.checkboxContainer}>
            <div>{counters[option]}</div>
            <Checkbox checked={selectedOptions[type].includes(option)} />
          </div>
        </div>
      ))}
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        {renderCheckboxes("sources", "Alarm Sources")}
        <Divider className={styles.divider} />
        {renderCheckboxes("statuses", "Statuses")}
        <Divider className={styles.divider} />
        {renderCheckboxes("severities", "Severities")}
      </div>
      <div className={styles.alarmsContainer}>
        <div className={styles.searchBar}>
          <Input
            value={searchString}
            placeholder="Search"
            className={styles.searchInput}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <Button shape="circle" icon={<ReloadOutlined />} onClick={() => refetch()} />
          <Select
            className={styles.select}
            value={selectedInterval}
            onChange={(newInterval) => setSelectedInterval(newInterval)}
            options={Object.entries(intervals).map(([interval, label]) => ({
              value: interval,
              label,
            }))}
          />
        </div>
        <List
          dataSource={listData}
          className={styles.list}
          loading={loading}
          renderItem={(item) => (
            <Link to={`/alarm-rules/${item.alarmId}?backTo=alarmAcknowledgement`}>
              <div className={`${styles.alarmContainer} ${item.acknowledged ? styles.acknowledged : ""}`}>
                <div className={styles.alarmContent}>
                  <div className={styles.alarmContentLeft}>
                    <div className={styles.icon}>
                      {optionIcons[item.acknowledged ? "ACKNOWLEDGED_ALARM" : item.severity]}
                    </div>
                    <div
                      className={
                        styles[
                          `${
                            item.acknowledged
                              ? "acknowledgedBackground"
                              : `severity${capitalizeFirstLetter(item.severity)}Background`
                          }`
                        ]
                      }
                      style={{
                        height: "100%",
                        width: 10,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    />
                    <span className={styles.alarmName}>{item.name}</span>
                  </div>
                  <div className={styles.alarmContentRight}>
                    <div className={styles.icon}>{optionIcons[item.type]}</div>
                    <div className={styles.alarmTime}>
                      {item.timeTriggered ? dayjs(item.timeTriggered).format("MM-DD-YYYY HH:mm:ss") : "not triggered"}
                    </div>
                    <Button
                      type="primary"
                      size="small"
                      className={styles.button}
                      disabled={item.acknowledged}
                      onClick={(e) => {
                        e.preventDefault();
                        acknowledgeAlarm(item.id);
                      }}
                    >
                      {item.acknowledged ? "Acknowledged" : "Acknowledge"}
                    </Button>
                    <Button
                      type="text"
                      size="small"
                      className={styles.icon}
                      style={{ cursor: "pointer", color: item.saved ? "#008b8a" : "#00000073" }}
                      onClick={(e) => {
                        e.preventDefault();
                        updateTriggeredAlarm({ id: item.id, saved: !item.saved });
                      }}
                    >
                      {item.saved ? <TagIconFilled /> : <TagIcon />}
                    </Button>
                  </div>
                </div>
              </div>
            </Link>
          )}
        />
      </div>
    </div>
  );
}
