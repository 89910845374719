import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { t } from "@lingui/macro";

import { useUser } from "context/UserContext";
import { useUserOrganizationProfiles } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Avatar as AvatarAntD, Table } from "antd";
import Avatar from "components/Avatar";
import UserActions from "../UserActions";

import styles from "./UsersTable.module.less";

function UsersTable() {
  const [searchParams] = useSearchParams();
  const organizationSlug = searchParams.get("organizationSlug");
  const { hasPermissions } = useUser();

  const { userOrganizationProfiles, error: usersError, loading } = useUserOrganizationProfiles(organizationSlug);
  const { message } = useCustomMessage();

  useEffect(() => {
    if (usersError) {
      message.error(t({ id: "message.errors.get-members", message: "Failed to fetch organization members" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersError]);

  const columns = [
    {
      title: t({ id: "roles-admin.users-table.headers.name", message: "Name" }),
      dataIndex: "name",
      key: "name",
      className: "nameCol",
      render: (name, record) => (
        <Link
          to={`/roles-admin/members/${record.id}${organizationSlug ? `?organizationSlug=${organizationSlug}` : ``}`}
        >
          <div className={styles.userContainer}>
            {record.pictureUrl && (
              <>
                <AvatarAntD src={record.pictureUrl} />
                {name}
              </>
            )}
            {!record.pictureUrl && <Avatar name={name} />}
          </div>
        </Link>
      ),
    },
    {
      title: t({ id: "roles-admin.users-table.headers.email", message: "Email" }),
      dataIndex: "email",
      responsive: ["sm"],
      key: "email",
      render: (email, _record) => <span className={styles.username}>{email}</span>,
    },
    hasPermissions(["delete:user_organization_profiles"]) && {
      title: t({ id: "roles-admin.users-table.headers.actions", message: "Actions" }),
      key: "actions",
      className: styles.actionsCol,
      render: (_text, record) => <UserActions userID={record.id} email={record.email} />,
    },
  ].filter(Boolean);

  const tableData =
    (userOrganizationProfiles &&
      userOrganizationProfiles
        .map(({ userAccount }) => ({
          key: userAccount.auth0Id,
          id: userAccount.auth0Id,
          name: userAccount.name,
          email: userAccount.email,
          pictureUrl: userAccount.pictureUrl,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))) ||
    [];

  return <Table columns={columns} dataSource={tableData} loading={loading} />;
}

export default UsersTable;
