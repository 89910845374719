import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_MEASURE } from "graphql/apiService";

export default function useUpdateMeasure() {
  const { currentOrganization } = useUser();

  const [updateMeasureMutation, { loading, error }] = useMutation(UPDATE_MEASURE, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateMeasure = async ({ input }) => {
    const res = await updateMeasureMutation({
      variables: {
        input,
      },
    });
    return res?.data?.updateMeasure;
  };

  return [updateMeasure, { loading, error }];
}
