import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_COMMENT, GET_COMMENTS_BY_THREAD } from "graphql/apiService";

export default function useCreateComment({ threadId }) {
  const { currentOrganization } = useUser();

  const [createCommentMutation, { loading, error }] = useMutation(CREATE_COMMENT, {
    variables: { organizationSlug: currentOrganization },
    update: (cache, { data: { createComment: newComment } }) => {
      const { getCommentsByThread: comments } = cache.readQuery({
        query: GET_COMMENTS_BY_THREAD,
        variables: {
          organizationSlug: currentOrganization,
          threadId,
        },
      });
      cache.writeQuery({
        query: GET_COMMENTS_BY_THREAD,
        variables: {
          organizationSlug: currentOrganization,
          threadId,
        },
        data: {
          getCommentsByThread: [...comments, newComment],
        },
      });
    },
    refetchQueries: ["GetCommentsByThread"],
  });

  const createComment = async ({ input }) => {
    const res = await createCommentMutation({ variables: { input: { threadId, ...input } } });
    return res.data.createComment;
  };

  return [createComment, { loading, error }];
}
