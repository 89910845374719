import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useUser } from "context/UserContext";
import useCustomMessage from "hooks/useCustomMessage";
import { t } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";

const domain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";

const getHomeDashboardUri = async ({ orgId, token }) => {
  const apiResponse = await fetch(`https://grafana.${domain}/api/dashboards/home?orgId=${orgId}`, {
    credentials: "include",
    headers: {
      X_JWT_Authn: token,
    },
  });
  const url = (await apiResponse?.json())?.redirectUri;
  if (!url) {
    throw new Error("Failed to get Dashboard Uri");
  }
  return url;
};

export default function useGrafana() {
  const { grafanaOrgId } = useUser();
  const { getIdTokenClaims } = useAuth0();
  const { message } = useCustomMessage();

  const [dashboardUri, setDashboardUri] = useState();

  useEffect(() => {
    if (grafanaOrgId) {
      getIdTokenClaims()
        .then(({ __raw: token }) => getHomeDashboardUri({ orgId: grafanaOrgId, token }))
        .then((uri) => {
          setDashboardUri(uri);
        })
        .catch((err) => {
          captureException(err);
          message.error({
            key: "dashboard-failed-get",
            content: t({ id: "messages.error.dashboard-failed-get", message: "Error: Failed to get Dashboard Uri" }),
          });
        });
    }
    return () => message.destroy({ key: "dashboard-failed-get" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grafanaOrgId]);

  return { homeDashboardUri: dashboardUri };
}
