import React from "react";

import styles from "./Note.module.less";

export default function Note({ children, icon, style, className }) {
  return (
    <div className={`${styles.container} ${className && className}`} style={style && style}>
      {icon && icon}
      <p className={styles.text}>{children}</p>
    </div>
  );
}
