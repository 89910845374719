import React, { useEffect } from "react";
import { IntercomProvider as BaseIntercomProvider, useIntercom } from "react-use-intercom";
import { useGetMyUserAccount } from "hooks/apiService";
import { isProductApm } from "utils/auth-utils";

const Intercom = () => {
  const isProduct = isProductApm();

  const { update, boot, shutdown } = useIntercom();
  const { myUserAccount } = useGetMyUserAccount();

  const { auth0Id, email, name, intercomHash } = myUserAccount || {};

  const initializeIntercom = async () => {
    if (myUserAccount && !isProduct) {
      boot();
      update({
        email,
        name,
        userId: auth0Id,
        userHash: intercomHash,
      });
    } else {
      shutdown();
    }
  };
  useEffect(() => {
    initializeIntercom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUserAccount]);
};

const IntercomProvider = ({ children }) => (
  <BaseIntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
    <Intercom />
    {children}
  </BaseIntercomProvider>
);

export { useIntercom, IntercomProvider };
