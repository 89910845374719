import React from "react";
import { App } from "antd";
import Message from "components/Message";

export default function useCustomMessage() {
  const { message } = App.useApp();

  const createCustomMessageFunction = (messageFunction) => (input) => {
    const { key, content, duration } = typeof input === "string" ? { key: input, content: input } : input;

    return messageFunction({
      key,
      content: <Message content={content} closeMessage={() => message.destroy(key)} />,
      duration: typeof duration === "number" ? duration : 3,
    });
  };

  const customMessage = {
    open: createCustomMessageFunction(message.open),
    success: createCustomMessageFunction(message.success),
    error: createCustomMessageFunction(message.error),
    info: createCustomMessageFunction(message.info),
    warning: createCustomMessageFunction(message.warning),
    loading: createCustomMessageFunction(message.loading),
    destroy: message.destroy,
  };
  return { message: customMessage };
}
