import { useUser } from "context/UserContext";
import { GET_USER_ORGANIZATION_PROFILES } from "graphql/apiService";
import useQuery from "../../useQuery";

export default function useUserOrganizationProfiles(organizationSlug) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_USER_ORGANIZATION_PROFILES, {
    variables: {
      organizationSlug: organizationSlug || currentOrganization,
    },
  });

  const userOrganizationProfiles = data?.getUserOrganizationProfiles || [];
  return { userOrganizationProfiles, loading, error };
}
