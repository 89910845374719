/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import captureException from "utils/capture-sentry-exception";
import { t, Trans } from "@lingui/macro";
import Highlighter from "react-highlight-words";

import { useUpdateMeasure } from "hooks/apiService";
import useTableSearch from "hooks/useTableSearch";
import useCustomMessage from "hooks/useCustomMessage";

import { Input, InputNumber } from "antd";

import styles from "./TableColumns.module.less";

export default function TableColumns({ setMeasures, isShared = false, hasPermissions }) {
  const { getColumnSearchProps, searchText } = useTableSearch();
  const [updateMeasure] = useUpdateMeasure();
  const { message } = useCustomMessage();

  const handleSaveMeasure = async ({ measure }) => {
    try {
      setMeasures((records) =>
        records.map((record) => (record.id === measure.id ? { ...record, loading: true } : record))
      );
      await updateMeasure({
        input: {
          id: measure.id,
          description: measure.description,
          unit: measure.unit,
          thresholdMin: measure.thresholdMin,
          thresholdMax: measure.thresholdMax,
        },
      });
      setMeasures((records) =>
        records.map((record) => (record.id === measure.id ? { ...record, loading: false, editing: false } : record))
      );
    } catch (error) {
      captureException(error);
      cancelEdit(measure);
      message.error(
        t({
          id: "equipment-overview.errors.save-measure",
          message: "Failed to save measure",
        })
      );
    }
  };

  const cancelEdit = (record) => {
    setMeasures((measures) => measures.map((measure) => (measure.id === record.id ? { ...measure.origin } : measure)));
  };

  return [
    {
      title: t({
        id: "equipment-management.tabs.measurements.column-measure",
        message: "Measure",
      }),
      key: "source",
      dataIndex: "slug",
      width: "auto",
      sorter: (a, b) => a.slug.localeCompare(b.slug),
      ...getColumnSearchProps({ dataIndex: "slug" }),
    },
    {
      title: t({
        id: "equipment-management.tabs.measurements.column-description",
        message: "Description",
      }),
      key: "description",
      dataIndex: "description",
      width: "30%",
      sorter: (a, b) => {
        if (a.description) {
          return b.description ? a.description.localeCompare(b.description) : -1;
        }
        return a.description ? b.description.localeCompare(a.description) : 1;
      },
      ...getColumnSearchProps({ dataIndex: "description", customRender: true }),
      render: (text, record) => {
        if (record.editing) {
          return (
            <Input
              className={styles.recordInput}
              value={record.description}
              placeholder={t({
                id: "equipment-management.tabs.measurements.placeholder-description",
                message: "Enter type of description here",
              })}
              size="small"
              disabled={record.loading}
              onChange={(e) => {
                setMeasures((records) =>
                  records.map((measure) =>
                    measure.id === record.id ? { ...measure, description: e.target.value } : measure
                  )
                );
              }}
            />
          );
        }
        return text ? (
          <Highlighter
            className={styles.recordText}
            highlightStyle={{
              backgroundColor: "#fb7951",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text}
          />
        ) : (
          <span className={styles.measurePlaceholder}>
            <Trans id="equipment-management.tabs.measurements.description-not-set">not set</Trans>
          </span>
        );
      },
    },
    {
      title: t({
        id: "equipment-management.tabs.measurements.column-unit",
        message: "Unit",
      }),
      key: "unit",
      dataIndex: "unit",
      width: "10%",
      render: (text, record) => {
        if (record.editing) {
          return (
            <Input
              className={styles.recordInput}
              value={record.unit}
              placeholder={t({
                id: "equipment-management.tabs.measurements.placeholder-unit",
                message: "Enter type of unit here",
              })}
              size="small"
              disabled={record.loading}
              onChange={(e) => {
                setMeasures((records) =>
                  records.map((measure) => (measure.id === record.id ? { ...measure, unit: e.target.value } : measure))
                );
              }}
            />
          );
        }
        return text ? (
          <span className={styles.recordText}>{text}</span>
        ) : (
          <span className={styles.measurePlaceholder}>
            <Trans id="equipment-management.tabs.measurements.unit-not-set">not set</Trans>
          </span>
        );
      },
    },
    {
      title: t({
        id: "equipment-management.tabs.measurements.column-min-threshold",
        message: "Min Threshold",
      }),
      key: "thresholdMin",
      dataIndex: "thresholdMin",
      width: "10%",
      render: (text, record) => {
        if (record.editing) {
          return (
            <InputNumber
              className={styles.recordInput}
              value={record.thresholdMin}
              placeholder={t({
                id: "equipment-management.tabs.measurements.placeholder-min-threshold",
                message: "Enter min threshold here",
              })}
              size="small"
              disabled={record.loading}
              onChange={(value) => {
                setMeasures((records) =>
                  records.map((measure) => (measure.id === record.id ? { ...measure, thresholdMin: value } : measure))
                );
              }}
            />
          );
        }
        return text !== null ? (
          <span className={styles.recordText}>{text}</span>
        ) : (
          <span className={styles.measurePlaceholder}>
            <Trans id="equipment-management.tabs.measurements.threshold-not-set">not set</Trans>
          </span>
        );
      },
    },
    {
      title: t({
        id: "equipment-management.tabs.measurements.column-max-threshold",
        message: "Max Threshold",
      }),
      key: "thresholdMax",
      dataIndex: "thresholdMax",
      width: "10%",
      render: (text, record) => {
        if (record.editing) {
          return (
            <InputNumber
              className={styles.recordInput}
              value={record.thresholdMax}
              placeholder={t({
                id: "equipment-management.tabs.measurements.placeholder-max-threshold",
                message: "Enter max threshold here",
              })}
              size="small"
              disabled={record.loading}
              onChange={(value) => {
                setMeasures((records) =>
                  records.map((measure) => (measure.id === record.id ? { ...measure, thresholdMax: value } : measure))
                );
              }}
            />
          );
        }
        return text !== null ? (
          <span className={styles.recordText}>{text}</span>
        ) : (
          <span className={styles.measurePlaceholder}>
            <Trans id="equipment-management.tabs.measurements.threshold-not-set">not set</Trans>
          </span>
        );
      },
    },
    !isShared &&
      hasPermissions(["update:measures"]) && {
        title: t({
          id: "equipment-management.tabs.measurements.column-action",
          message: "Action",
        }),
        key: "action",
        width: "15%",
        render: (_, record) => {
          if (record.editing) {
            return (
              <>
                <span className={styles.recordButton} onClick={() => handleSaveMeasure({ measure: record })}>
                  <Trans id="panels.panel.save-button">Save</Trans>
                </span>
                <span className={styles.recordButton} onClick={() => cancelEdit(record)}>
                  <Trans id="panels.panel.cancel-button">Cancel</Trans>
                </span>
              </>
            );
          }
          return (
            <span
              className={styles.recordButton}
              onClick={() => {
                setMeasures((records) =>
                  records.map((measure) =>
                    measure.id === record.id ? { ...measure, editing: true, origin: measure } : measure
                  )
                );
              }}
            >
              <Trans id="panels.panel.edit-button">Edit</Trans>
            </span>
          );
        },
      },
  ].filter(Boolean);
}
