import Card from "components/Card";
import dayjs from "dayjs";
import React, { useState, useMemo, useCallback } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { Button, Card as AntdCard, Divider, Select } from "antd";
import { Timeline } from "react-svg-timeline";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { ReactComponent as PlcAlarm } from "assets/plc-alarm-icon.svg";
import { ReactComponent as ApmAlarm } from "assets/apm-alarm-icon.svg";

import { useAlarm, useTriggeredAlarms } from "hooks/apiService";

import Panel from "components/Panel";

import PriorityTag from "../components/PriorityTag";
import { FORMULAS, modes, durations } from "../helpers/AlarmRules.helpers";

import styles from "./AlarmDetails.module.less";

const intervals = {
  "1h": "Last hour",
  "3h": "Last 3 hours",
  "24h": "Last 24 hours",
  "3d": "Last 3 days",
  "7d": "Last 7 days",
  "30d": "Last 30 days",
  "90d": "Last 90 days",
};

const laneId = "alarm-triggers";
const lanes = [
  {
    laneId,
    label: "Alarm Triggers",
  },
];

const displayMeasure = (m) => (m.description ? `${m.description}` : m.slug);

const formatAlarmRule = (rule) =>
  `${rule?.measure?.equipment?.name} — ${displayMeasure(rule.measure)} ${
    FORMULAS.find((f) => f.value === rule.formula)?.label
  } ${rule.thresholdValue}\n${modes().find((m) => m.value === rule.mode)?.label} for ${
    durations().find((d) => d.value === rule.duration)?.label
  }`;

const formatConditions = (rules) => {
  if (!rules) return "";
  return `Conditions:\n${rules
    .map((r, i) => `${formatAlarmRule(r)}${i < rules.length - 1 ? "\n----------and----------\n" : ""}`)
    .join("")}`;
};

const RulesDisplay = ({ rules }) => (
  <div>
    {rules.map((rule, index) => (
      <div key={rule.id} className={styles.ruleItemContainer}>
        <div className={styles.ruleItem}>{formatAlarmRule(rule)}</div>
        {index !== rules.length - 1 && <div className={styles.ruleSeparator}>-and-</div>}
      </div>
    ))}
  </div>
);

const dateFormat = (ms) => new Date(ms).toLocaleString();

const MemoizedPanel = React.memo(Panel);

export default function AlarmDetails() {
  const [selectedInterval, setSelectedInterval] = useState("24h");
  const [zoomStart, setZoomStart] = useState(dayjs().add(-24, "hours"));
  const [zoomEnd, setZoomEnd] = useState(dayjs());

  const { alarmId } = useParams();
  const { triggeredAlarms } = useTriggeredAlarms({ alarmId });
  const { alarm } = useAlarm({ id: alarmId });

  const changeLogs = alarm?.changeLogs?.map((log) => ({ ...log, alarmRules: log.alarmRulesSnapshot }))?.reverse();

  const plcChangeLogs = alarm?.plcAlarmInfo?.thresholdChangeLogs?.reverse();

  const getRelevantChangelog = (timestamp, logs) => {
    if (!logs) return null;
    let result = null;
    const targetTime = new Date(timestamp);
    // eslint-disable-next-line no-restricted-syntax
    for (const log of logs) {
      const logTime = new Date(log.createdAt);

      if (logTime <= targetTime) {
        result = log;
        break;
      }
    }
    return result;
  };

  const [searchParams] = useSearchParams();
  const backTo = searchParams.get("backTo");
  const backToLink = backTo === "alarmAcknowledgement" ? "/alarm-acknowledgement" : "/alarm-rules";

  const handleZoomChange = useCallback((newZoomStart, newZoomEnd) => {
    if (newZoomStart && newZoomEnd) {
      setZoomStart(dayjs(newZoomStart));
      setZoomEnd(dayjs(newZoomEnd));
    }
  }, []);

  const panel = useMemo(
    () => ({
      measures: alarm?.plcAlarmInfo
        ? [alarm?.plcAlarmInfo?.timeseriesMeasure]
        : alarm?.alarmRules?.map((rule) => rule.measure),
      events: [],
      type: "LINE_CHART",
      time: {
        type: "REALTIME",
        interval: selectedInterval,
      },
    }),
    [selectedInterval, alarm?.alarmRules, alarm?.plcAlarmInfo]
  );

  const events = triggeredAlarms.map((a) => {
    let alarmData;
    let thresholdInfo;
    if (alarm?.plcAlarmInfo) {
      thresholdInfo = getRelevantChangelog(a.timeTriggered, plcChangeLogs) || alarm;
      if (thresholdInfo.floatValue !== undefined)
        thresholdInfo = { ...thresholdInfo, threshold: thresholdInfo.floatValue };
    } else {
      alarmData = getRelevantChangelog(a.timeTriggered, changeLogs) || alarm;
    }

    const eventPrintInfo = alarm?.plcAlarmInfo
      ? `Threshold: ${thresholdInfo.threshold}`
      : formatConditions(alarmData?.alarmRules);

    return {
      eventId: a.id,
      tooltip: `Triggered: ${dayjs(a.timeTriggered).format("MM-DD-YYYY HH:mm:ss")}\n ${
        a.timeResolved ? `Resolved: ${dayjs(a.timeResolved).format("MM-DD-YYYY HH:mm:ss")}` : ""
      }\n \n${eventPrintInfo}`,
      laneId,
      color: "#ff0f0f",
      startTimeMillis: dayjs(a.timeTriggered).valueOf(),
      endTimeMillis: a.timeResolved ? dayjs(a.timeResolved).valueOf() : dayjs().valueOf(),
    };
  });

  if (!alarm) {
    return <AntdCard loading />;
  }

  return (
    <Card>
      <Link to={backToLink}>
        <Button>
          <ArrowLeftOutlined /> Back
        </Button>
      </Link>
      <div className={styles.upperContainer}>
        <div className={styles.titleContainer}>
          {alarm.name}
          {alarm.plcAlarmInfo ? <PlcAlarm width={24} height={24} /> : <ApmAlarm width={24} height={24} />}
          <PriorityTag priority={alarm.priority} />
        </div>
      </div>
      <div className={styles.detailItem}>
        <div className={styles.detailLabel}>Last Time Triggered</div>
        <div>
          {alarm.lastTimeTriggered ? dayjs(alarm.lastTimeTriggered).format("YYYY-MM-DD HH:mm:ss") : "Not Triggered"}
        </div>
      </div>
      {alarm.description && (
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>Description</div>
          <div>{alarm.description}</div>
        </div>
      )}
      {!!alarm.alarmRules?.length && (
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>Trigger When</div>
          <RulesDisplay rules={alarm.alarmRules} />
        </div>
      )}
      {!!alarm.plcAlarmInfo?.type && (
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>Type</div>
          <div>{alarm.plcAlarmInfo.type}</div>
        </div>
      )}
      {!!alarm.plcAlarmInfo?.threshold && (
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>Threshold</div>
          <div>{alarm.plcAlarmInfo.threshold}</div>
        </div>
      )}
      <Divider />
      <div className={styles.plotContainer}>
        <div className={styles.innerContainer}>
          <Select
            className={styles.select}
            value={selectedInterval}
            onChange={(newInterval) => setSelectedInterval(newInterval)}
            options={Object.entries(intervals).map(([interval, label]) => ({
              value: interval,
              label,
            }))}
          />
          <div className={styles.timelineContainer}>
            <Timeline
              width={1000}
              height={100}
              events={events}
              lanes={lanes}
              dateFormat={dateFormat}
              customRange={[zoomStart.valueOf(), zoomEnd.valueOf()]}
              trimRange={[zoomStart.valueOf(), zoomEnd.valueOf()]}
              enabledInteractions={[]}
            />
          </div>

          <MemoizedPanel
            panel={panel}
            time={{ type: "REALTIME", interval: selectedInterval }}
            height={500}
            width={950}
            showLegend
            legendConfig={{ orientation: "h", y: -0.4 }}
            onZoomChange={handleZoomChange}
          />
        </div>
      </div>
    </Card>
  );
}
