import { modules as modulesFn } from "config/modules";

// eslint-disable-next-line import/prefer-default-export
export const getModuleFromPathname = (pathname) => {
  const modules = modulesFn();
  const module = pathname.split("/")?.[1];

  if (modules[module] && module !== "home") {
    return module;
  }
  return null;
};
