import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPLOAD_USER_ACCOUNT_PICTURE_SUCCESS } from "graphql/apiService";

export default function useUploadUserAccountPictureSuccess() {
  const { currentOrganization } = useUser();

  const [uploadUserAccountPictureSuccessMutation, { loading, error }] = useMutation(
    UPLOAD_USER_ACCOUNT_PICTURE_SUCCESS,
    {
      variables: { organizationSlug: currentOrganization },
    }
  );

  const uploadUserAccountPictureSuccess = async () => {
    const res = await uploadUserAccountPictureSuccessMutation();
    return res.data.uploadUserAccountPictureSuccess;
  };

  return [uploadUserAccountPictureSuccess, { loading, error }];
}
