import React from "react";

import { DatabaseOutlined, AlertOutlined, FileTextOutlined, MessageOutlined } from "@ant-design/icons";

import { ReactComponent as DataPartnersIcon } from "assets/data-partners-icon.svg";
import { ReactComponent as RolesAdminIcon } from "assets/roles-admin-icon.svg";
import { ReactComponent as HomeIcon } from "assets/home.svg";
import { ReactComponent as TubeTrailerIcon } from "assets/tube-trailer.svg";
import { ReactComponent as MobileRefuelerIcon } from "assets/mobile-refueler.svg";
import { ReactComponent as RefuelingStationIcon } from "assets/h2-refueling-station.svg";
import { ReactComponent as ElectrolyzerAndExportIcon } from "assets/electrolyzer-&-h2-export.svg";
import { ReactComponent as WarningIcon } from "assets/warning.svg";
import { ReactComponent as AlarmSettingsIcon } from "assets/alarm-settings.svg";
import { ReactComponent as MonitoringIcon } from "assets/monitoring.svg";
import { ReactComponent as AssetMapIcon } from "assets/asset-map.svg";
import { ReactComponent as CMMSIcon } from "assets/cmms-icon.svg";
import { ReactComponent as BellIcon } from "assets/bell.svg";
import { ReactComponent as BellAlternative } from "assets/bell-2.svg";
import { ReactComponent as UserIcon } from "assets/user-icon-2.svg";
import { ReactComponent as DashboardIcon } from "assets/dashboard.svg";
import { ReactComponent as MonitorDashboardIcon } from "assets/monitor-dashboard.svg";
import { ReactComponent as WrenchIcon } from "assets/wrench.svg";
import grafanaLogoUrl from "assets/grafana-logo.svg";
import supersetLogoUrl from "assets/superset-logo.svg";

import { t } from "@lingui/macro";

const colors = {
  purple: "#7D50FF",
  purple2: "#7B61FF",
  purple3: "#9747FF",
  teal: "#0F8B8A",
  yellow: "#FAAD13",
  blue: "#1890FF",
  blue2: "#2B73B7",
  lightBlue: "#6AB8FD",
  darkBlue: "#0345A7",
  magenta: "#B70BF3",
  turquoise: "#1ABEB4",
  orange: "#FF7950",
  darkRed: "#7C4530",
  olive: "#817E63",
  yellow2: "#E0B509",
  lime: "#A5D146",
  grayGreen: "#425346",
  darkGray: "#424242",
  black: "#000000",
};

const domain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";

// eslint-disable-next-line import/prefer-default-export
export const modules = () => ({
  home: {
    name: t({ id: "modules.home", message: "Home" }),
    link: "/home",
    key: "home",
    icon: HomeIcon,
    color: colors.teal,
  },
  notifications: {
    name: t({ id: "modules.notification-center", message: "Notifications" }),
    link: "/notifications",
    key: "notifications",
    icon: BellIcon,
    color: colors.darkGray,
  },
  assets: {
    name: t({ id: "modules.assets-map", message: "Asset Map" }),
    link: "/assets",
    key: "assets",
    permissions: ["read:equipment"],
    icon: AssetMapIcon,
    color: colors.blue2,
  },
  dashboards: {
    name: "All Dashboards",
    link: "/dashboards",
    key: "dashboards",
    permissions: ["read:dashboards"],
    disabledInOrgs: ["customer"],
    icon: MonitorDashboardIcon,
    color: colors.blue,
  },
  quickboard: {
    name: "Quickboard",
    link: "/quickboard",
    key: "quickboard",
    permissions: ["read:dashboards"],
    disabledInOrgs: ["customer, service"],
    icon: DashboardIcon,
    color: colors.blue,
  },
  "tube-trailer": {
    name: t({ id: "modules.tube-trailer", message: "Tube Trailer" }),
    link: "/tube-trailer",
    key: "tube-trailer",
    icon: TubeTrailerIcon,
    color: colors.blue,
    requiredFeature: "tube-trailer",
    locked: true,
  },
  "mobile-refueler": {
    name: t({ id: "modules.mobile-refueler", message: "Mobile Refueler" }),
    link: "/mobile-refueler",
    key: "mobile-refueler",
    icon: MobileRefuelerIcon,
    color: colors.olive,
    requiredFeature: "mobile-refueler",
    locked: true,
  },
  "h2-refueling-station": {
    name: t({ id: "modules.h2-refueling-station", message: "H2 Refueling Station" }),
    link: "/h2-refueling-station",
    key: "h2-refueling-station",
    icon: RefuelingStationIcon,
    color: colors.purple2,
    requiredFeature: "h2-refueling-station",
    locked: true,
  },
  "work-requests": {
    name: t({ id: "modules.work-requests", message: "Work Requests" }),
    link: "/work-requests",
    key: "work-requests",
    permissions: ["read:work_orders"],
    icon: CMMSIcon,
    color: colors.orange,
  },
  "business-intelligence": {
    name: t({ id: "modules.business-intelligence", message: "Apache Superset" }),
    link: "/business-intelligence/dashboards",
    permissions: ["create:bi_dashboards"],
    key: "business-intelligence",
    icon: () => <img src={supersetLogoUrl} alt="superset" />,
    restrictedToOrgs: ["demo", "eh2"],
    color: colors.darkBlue,
  },
  "electrolyzer-simulation": {
    name: t({ id: "modules.electrolyzer-simulation", message: "Electrolyzer Simulation" }),
    link: "/electrolyzer-simulation",
    key: "electrolyzer-simulation",
    icon: ElectrolyzerAndExportIcon,
    color: colors.yellow2,
    restrictedToOrgs: ["demo"],
  },
  "incident-reports": {
    name: t({ id: "modules.incident-registry-&-report", message: "Incident Registry & Report" }),
    link: "/incident-reports",
    permissions: ["read:incident_reports"],
    disabledInOrgs: ["customer"],
    key: "incident-reports",
    icon: WarningIcon,
    color: colors.yellow,
  },
  "roles-admin": {
    name: t({ id: "modules.user-management", message: "User Management" }),
    link: "/roles-admin/members",
    key: "roles-admin",
    permissions: ["read:all_roles"],
    icon: RolesAdminIcon,
    color: colors.darkBlue,
  },
  "data-partners": {
    name: t({ id: "modules.data-partners", message: "Data Partners" }),
    link: "/data-partners",
    key: "data-partners",
    permissions: ["read:org_data_partners"],
    disabledInOrgs: ["customer", "service"],
    icon: DataPartnersIcon,
    color: colors.olive,
  },
  "alarm-rules": {
    name: t({
      id: "modules.alarm-rules",
      message: "Alarm Rules",
    }),
    link: "/alarm-rules",
    key: "alarm-rules",
    permissions: ["read:alarms"],
    disabledInOrgs: ["customer"],
    icon: AlarmSettingsIcon,
    color: colors.grayGreen,
  },
  "alarm-acknowledgement": {
    name: t({
      id: "modules.alarm-acknowledgement",
      message: "Alarm Acknowledgement",
    }),
    link: "/alarm-acknowledgement",
    key: "alarm-acknowledgement",
    permissions: ["read:alarms"],
    icon: AlertOutlined,
    color: colors.orange,
  },
  "super-admin": {
    name: t({ id: "modules.super-admin", message: "Super Admin Console" }),
    link: "/super-admin",
    key: "super-admin",
    globalPermissions: ["create:organization_profiles"],
    icon: DatabaseOutlined,
    color: colors.grayGreen,
  },
  account: {
    name: t({ id: "modules.account", message: "User account" }),
    link: "/account",
    key: "account",
    icon: UserIcon,
    color: colors.blue,
  },
  "h2-question": {
    name: t({ id: "modules.h2-question", message: "Hydrogen Safety Question" }),
    key: "h2-question",
  },
  grafana: {
    name: t({ id: "modules.grafana", message: "Grafana" }),
    key: "grafana",
    externalLink: `https://grafana.${domain}`,
    icon: () => <img src={grafanaLogoUrl} alt="grafana" />,
    color: colors.teal,
    disabledInOrgs: ["customer"],
  },
  // product apm
  "alarm-analytics": {
    name: t({
      id: "modules.alarm-analytics",
      message: "Alarm Analytics",
    }),
    link: "/alarm-analytics",
    key: "alarm-analytics",
    permissions: ["read:alarms"],
    icon: BellAlternative,
    color: colors.orange,
  },
  "service-support": {
    name: t({ id: "modules.service-support", message: "Service Support" }),
    key: "service-support",
    link: "/service-support",
    icon: WrenchIcon,
    color: colors.purple3,
    restrictedToOrgs: ["customer", "service"],
  },
  "help-center": {
    name: t({ id: "modules.help-center", message: "Help Center" }),
    key: "help-center",
    link: "/help-center",
    icon: MessageOutlined,
    color: colors.lime,
    restrictedToOrgs: ["customer", "service"],
  },
  "system-overview": {
    name: t({ id: "modules.system-overview", message: "System Level Overview" }),
    key: "system-overview",
    link: "/system-overview",
    icon: MonitoringIcon,
    color: colors.blue,
    restrictedToOrgs: ["customer", "service"],
  },
  "document-center": {
    name: t({ id: "modules.document-center", message: "Document Center" }),
    key: "document-center",
    link: "/document-center",
    icon: FileTextOutlined,
    color: colors.teal,
    restrictedToOrgs: ["customer", "service"],
  },
});
