import React from "react";
import { SizeMe } from "react-sizeme";
import PlotlyBase from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";

const Plotly = createPlotlyComponent(PlotlyBase);

export default function ResponsivePlot({ layout, ...props }) {
  return (
    <SizeMe monitorHeight monitorWidth>
      {({ size }) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 35,
          }}
        >
          {size.width && size.height && (
            <Plotly {...props} layout={{ width: size.width - 4, height: size.height - 4, ...layout }} />
          )}
        </div>
      )}
    </SizeMe>
  );
}
