import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { t, Trans } from "@lingui/macro";

import { useUser } from "context/UserContext";

import { Button, Dropdown } from "antd";
import { CloseCircleOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { ReactComponent as DashboardIcon } from "assets/dashboard.svg";
import { ReactComponent as ShareIcon } from "assets/share-icon.svg";
import SearchTree from "components/SearchTree";

import styles from "./Header.module.less";

export default function HeaderMenu({ isShared, isFolder, onDelete, assetTree }) {
  const { assetId } = useParams();
  const isSmallScreen = useMediaQuery({ maxWidth: 1200 });
  const navigate = useNavigate();
  const { hasPermissions } = useUser();

  const burgerMenuItems = isSmallScreen
    ? [
        hasPermissions(["read:dashboards"]) && {
          label: t({ id: "asset-map.asset.header.view-dashboards-button", message: "View Dashboards" }),
          key: "view-dashboards",
          onClick: () => navigate("/dashboards"),
        },
        !isShared &&
          hasPermissions(["update:equipment"]) && {
            label: t({ id: "asset-map.asset.header.edit-button", message: "Edit" }),
            key: "edit",
            onClick: () => navigate(`/assets/${assetId}/edit`),
          },
        !isShared &&
          hasPermissions(["share:telemetry_data"]) && {
            label: t({ id: "asset-map.asset.header.share-button", message: "Share" }),
            key: "share",
            onClick: () => navigate(`/assets/${assetId}/share`),
          },
        !isShared &&
          hasPermissions(["delete:equipment"]) && {
            label: t({ id: "asset-map.asset.header.delete-button", message: "Delete" }),
            key: "delete",
            onClick: onDelete,
            danger: true,
          },
      ].filter(Boolean)
    : [
        !isShared &&
          hasPermissions(["delete:equipment"]) && {
            label: t({ id: "asset-map.asset.header.delete-button", message: "Delete" }),
            key: "delete",
            onClick: onDelete,
            danger: true,
          },
      ].filter(Boolean);

  if (isFolder) {
    return (
      <div className={styles.header}>
        {assetTree && (
          <SearchTree
            data={assetTree}
            onSelect={(node) => {
              if (node.parentAssetId) {
                navigate(`/assets/${node.parentAssetId}?tab=measures&measureId=${node.key}`);
              } else if (node.key === "global") {
                navigate("/assets");
              } else {
                navigate(`/assets/${node.key}`);
              }
            }}
          />
        )}
      </div>
    );
  }
  return (
    <div className={styles.header}>
      {isSmallScreen ? (
        <Link to={-1}>
          <CloseCircleOutlined className={styles.closeButton} />
        </Link>
      ) : null}
      <div className={styles.headerLeft}>
        {assetTree && (
          <SearchTree
            data={assetTree}
            onSelect={(node) => {
              if (node.parentAssetId) {
                navigate(`/assets/${node.parentAssetId}?tab=measures&measureId=${node.key}`);
              } else {
                navigate(`/assets/${node.key}`);
              }
            }}
          />
        )}
      </div>
      <div className={styles.headerRight}>
        {!isSmallScreen && (
          <>
            {hasPermissions(["read:dashboards"]) && (
              <Link to="/dashboards">
                <Button
                  className={styles.headerButton}
                  type="primary"
                  ghost
                  icon={<DashboardIcon className={styles.icon} />}
                >
                  <Trans id="asset-map.asset.header.view-dashboards-button">View Dashboards</Trans>
                </Button>
              </Link>
            )}
            {!isShared && hasPermissions(["update:equipment"]) && (
              <Link to={`/assets/${assetId}/edit`}>
                <Button
                  className={styles.headerButton}
                  type="primary"
                  ghost
                  icon={<EditOutlined className={`${styles.icon} ${styles.shiftIcon}`} />}
                >
                  <Trans id="asset-map.asset.header.edit-button">Edit</Trans>
                </Button>
              </Link>
            )}
            {!isShared && hasPermissions(["share:telemetry_data"]) && (
              <Link to={`/assets/${assetId}/share`}>
                <Button
                  className={styles.headerButton}
                  type="primary"
                  ghost
                  icon={<ShareIcon className={`${styles.icon} ${styles.shiftIcon}`} />}
                >
                  <Trans id="asset-map.asset.header.share-button">Share</Trans>
                </Button>
              </Link>
            )}
          </>
        )}
        {burgerMenuItems.length > 0 && (
          <Dropdown menu={{ items: burgerMenuItems }} placement="bottomRight" trigger={["click"]}>
            <MoreOutlined className={styles.burgerIcon} />
          </Dropdown>
        )}
      </div>
    </div>
  );
}
