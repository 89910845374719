import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";
import dayjs from "dayjs";

import { useUser } from "context/UserContext";
import { useCreateEquipment, useUpdateEquipment, useGetEquipment, useEquipmentSlugs } from "hooks/apiService";

import { Button, Form, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

import useThirdPartyProxy from "hooks/useThirdPartyProxy";
import useCustomMessage from "hooks/useCustomMessage";

import Card from "components/Card";
import AssetInput from "./components/AssetInput";

import styles from "./AssetEdit.module.less";
import { typeOptions as typeOptionsFn } from "../helpers/mapping";

export default function AssetEdit({ isNew = false, assetTree }) {
  const { assetId } = useParams();
  const [searchParams] = useSearchParams();
  const parentIdParam = searchParams.get("parentId");

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { currentOrganization } = useUser();
  const { message } = useCustomMessage();

  const titleInput = useRef(null);
  const [saveLoading, setSaveLoading] = useState(false);

  const [createEquipment] = useCreateEquipment();
  const [updateEquipment] = useUpdateEquipment();
  const [getEquipment, { equipment }] = useGetEquipment();
  const { equipmentSlugs, loading: equipmentSlugsLoading } = useEquipmentSlugs();

  const { getAssets: getLimbleAssets } = useThirdPartyProxy();
  const [limbleAssets, setLimbleAssets] = useState([]);
  const [loadingLimbleAssets, setLoadingLimbleAssets] = useState(false);

  const typeOptions = typeOptionsFn();

  const initializeForm = ({
    name,
    slug,
    type,
    limbleId,
    modelNumber,
    serialNumber,
    manufacturer,
    location,
    dateOfProduction,
    contacts,
    description,
    parentId,
  }) => {
    form.setFieldsValue({
      name,
      slug,
      type,
      limbleId,
      modelNumber,
      serialNumber,
      manufacturer,
      location,
      dateOfProduction: dayjs(new Date(dateOfProduction)),
      contacts,
      description,
      parentId,
    });
  };

  const handleGetEquipment = async () => {
    try {
      await getEquipment({ id: assetId });
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "asset-map.errors.get-asset",
          message: "Failed to get asset",
        })
      );
    }
  };

  useEffect(() => {
    if (assetId) {
      handleGetEquipment();
    } else if (currentOrganization) {
      initializeForm({
        name: t({
          id: "asset-map.asset.edit.new-asset",
          message: "New Asset",
        }),
        dateOfProduction: new Date().toISOString(),
        parentId: parentIdParam,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, currentOrganization]);

  useEffect(() => {
    if (equipment) {
      initializeForm({
        ...equipment,
        location: equipment.location?.formattedAddress,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment]);

  const handleGetLimbleAssets = async () => {
    try {
      setLoadingLimbleAssets(true);
      const data = await getLimbleAssets();
      setLimbleAssets(data);
    } finally {
      setLoadingLimbleAssets(false);
    }
  };

  useEffect(() => {
    handleGetLimbleAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditCancel = useCallback(() => {
    if (isNew) {
      navigate(-1);
    } else {
      navigate(`/assets/${assetId}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const createOrUpdateEquipment = async (data) => {
    try {
      if (isNew) {
        return await createEquipment({ input: data });
      }
      return await updateEquipment({ input: { id: assetId, ...data } });
    } catch (error) {
      captureException(error);
      if (isNew) {
        message.error(
          error.message ||
            t({
              id: "asset-map.asset.edit.errors.create-equipment",
              message: "Failed to create equipment",
            })
        );
      } else {
        message.error(
          error.message ||
            t({
              id: "asset-map.asset.edit.errors.update-equipment",
              message: "Failed to update equipment",
            })
        );
      }
    }
    return undefined;
  };

  const handleSave = async () => {
    setSaveLoading(true);
    const formValues = form.getFieldValue();
    if (formValues.parentId === "global") {
      formValues.parentId = null;
    }
    const response = await createOrUpdateEquipment({
      ...formValues,
      dateOfProduction: formValues.dateOfProduction.toISOString(),
    });
    setSaveLoading(false);
    if (response) {
      navigate(`/assets/${response.id}`, {
        replace: true,
      });
    }
  };

  return (
    <Card className={styles.container}>
      <div className={styles.backActionContainer}>
        <div className={styles.actionButtons}>
          <Button onClick={handleEditCancel}>
            <Trans id="asset-map.asset.edit.cancel-button">Cancel</Trans>
          </Button>
          <Button type="primary" onClick={form.submit} loading={saveLoading}>
            <Trans id="asset-map.asset.edit.save-button">Save</Trans>
          </Button>
        </div>
      </div>
      <Form form={form} className={styles.form} layout="vertical" onFinish={handleSave}>
        <div className={styles.editTitleContainer}>
          <Button
            type="text"
            className={styles.editTitleButton}
            onClick={() => titleInput.current.focus({ cursor: "all" })}
          >
            <EditOutlined />
          </Button>
          <Form.Item name="name" className={styles.titleFormItem}>
            <Input
              className={styles.editTitle}
              bordered={false}
              ref={titleInput}
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.new-asset",
                message: "New Asset",
              })}
            />
          </Form.Item>
        </div>
        <div className={styles.metadataContainer}>
          <div className={styles.metadata}>
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.asset-slug",
                message: "Asset Slug",
              })}
              name="slug"
              disabled={!isNew}
              options={equipmentSlugs}
              loading={equipmentSlugsLoading}
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.asset-id",
                message: "Asset slug derived from raw data",
              })}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.parent",
                message: "Parent",
              })}
              name="parentId"
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.parent",
                message: "Select Parent",
              })}
              treeData={assetTree.filter(({ key }) => key !== "shared")}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.type",
                message: "Asset Type",
              })}
              name="type"
              options={typeOptions}
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.type",
                message: "Type of asset",
              })}
              rules={[
                {
                  required: true,
                  message: t({
                    id: "asset-map.asset.edit.validation.type",
                    message: "Please select the type of the asset",
                  }),
                },
              ]}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.limble-id",
                message: "Limble ID",
              })}
              name="limbleId"
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.limble-id",
                message: "Limble ID of the asset",
              })}
              limbleOptions={limbleAssets}
              loading={loadingLimbleAssets}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.model-number",
                message: "Model number",
              })}
              name="modelNumber"
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.model-number",
                message: "Model number of the asset",
              })}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.serial-number",
                message: "Serial number",
              })}
              name="serialNumber"
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.serial-number",
                message: "Serial number of the asset",
              })}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.manufacturer",
                message: "Manufacturer",
              })}
              name="manufacturer"
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.manufacturer",
                message: "Manufacturer of the asset",
              })}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.date-of-production",
                message: "Date Of Production",
              })}
              name="dateOfProduction"
              date
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.contact",
                message: "Contacts",
              })}
              name="contacts"
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.contact",
                message: "Contact of the vendor",
              })}
              tags={form.getFieldValue().contacts ? form.getFieldValue().contacts : []}
            />
            <AssetInput
              property={t({
                id: "asset-map.asset.edit.property.description",
                message: "Description",
              })}
              name="description"
              placeholder={t({
                id: "asset-map.asset.edit.placeholder.description",
                message: "Write a short description of the asset",
              })}
              textfield
            />
          </div>
        </div>
      </Form>
    </Card>
  );
}
