import React from "react";
import { ReactComponent as DashboardIcon } from "assets/dashboard.svg";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { useCreateFavorite, useDeleteFavorite } from "hooks/apiService";

import FavoriteButton from "components/FavoriteButton";
import { ReactComponent as GrafanaLogo } from "assets/grafana-logo.svg";
import { ReactComponent as SupersetLogo } from "assets/superset-logo.svg";

import styles from "./TableNameCell.module.less";

const icons = {
  CUSTOM: <DashboardIcon style={{ color: "#1890ff" }} />,
  SUPERSET: <SupersetLogo />,
  GRAFANA: <GrafanaLogo />,
};

export default function TableNameCell({ type, record, name, link, searchText }) {
  const [createFavorite] = useCreateFavorite();
  const [deleteFavorite] = useDeleteFavorite();

  return (
    <div className={styles.cellContainer}>
      <div className={styles.favoriteContainer}>
        <FavoriteButton
          isFavorite={record.isFavorite}
          createFavorite={() =>
            createFavorite({
              itemId: record.id,
              itemType: "DASHBOARD",
            })
          }
          deleteFavorite={() =>
            deleteFavorite({
              itemType: "DASHBOARD",
              itemId: record.id,
            })
          }
        />
      </div>
      <Link to={link}>
        <div className={styles.nameContainer}>
          <div className={styles.icon}>{icons[type]}</div>
          <Highlighter
            highlightStyle={{
              backgroundColor: "#fb7951",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={name}
          />
        </div>
      </Link>
    </div>
  );
}
