import { useUser } from "context/UserContext";
import { GET_ORGANIZATION_DATA_PARTNERS_SHARING } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useDataPartnersSharing() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_ORGANIZATION_DATA_PARTNERS_SHARING, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const dataPartners = data?.getOrganizationDataPartners;

  return { dataPartners, loading, error };
}
