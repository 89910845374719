/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { isCustomerApm, isServiceApm } from "utils/auth-utils";
import { toKebabCase } from "utils/string-utils";

import AlarmRules from "containers/AlarmRules";
import AlarmList from "containers/AlarmList";
import styles from "./AlarmAnalytics.module.less";
import AnalyticsDashboard from "./components/AnalyticsDashboard";
import AlarmOverrides from "./components/AlarmOverrides";

const { RangePicker } = DatePicker;

const isCustomer = isCustomerApm();
const isService = isServiceApm();

const tabs = [
  "Alarm Analytics",
  "All Alarms",
  isService && "Downtime Analytics",
  isCustomer && "Alarm Overrides",
  isService && "Alarm Rules",
].filter(Boolean);

export default function AlarmAnalytics() {
  const { page } = useParams();
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(dayjs().subtract(1, "month"));
  const [endTime, setEndTime] = useState(dayjs());

  const onDateRangeChange = ([newStart, newEnd]) => {
    setStartTime(newStart);
    setEndTime(newEnd);
  };

  useEffect(() => {
    if (!page) {
      navigate("/alarm-analytics/alarm-analytics");
    }
  }, [page, navigate]);

  const Toolbar = () => (
    <div className={styles.toolbar}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <Link to={`/alarm-analytics/${toKebabCase(tab)}`} key={tab}>
            <div className={`${styles.tab} ${toKebabCase(tab) === page ? styles.selected : ""}`}>{tab}</div>
          </Link>
        ))}
      </div>
      <div>
        <RangePicker onChange={onDateRangeChange} value={[startTime, endTime]} format={["MM-DD-YYYY"]} />
      </div>
    </div>
  );

  const renderContent = () => {
    switch (page) {
      case "alarm-analytics":
        return <AnalyticsDashboard />;
      case "all-alarms":
        return <AlarmList />;
      case "alarm-overrides":
        return <AlarmOverrides />;
      case "alarm-rules":
        return <AlarmRules />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <Toolbar />
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
}
