import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_ALARM } from "graphql/apiService";

export default function useCreateAlarm() {
  const { currentOrganization } = useUser();

  const [createAlarmMutation, { loading, error }] = useMutation(CREATE_ALARM, {
    variables: { organizationSlug: currentOrganization },
  });

  const createAlarm = async ({ ...input }) => {
    const res = await createAlarmMutation({
      variables: {
        input,
      },
    });
    return res.data.createAlarm;
  };

  return [createAlarm, { loading, error }];
}
