import React from "react";
import { Trans } from "@lingui/macro";
import { Space, Button } from "antd";
import styles from "./ErrorScreen.module.less";

function ErrorScreen() {
  return (
    <div className={styles.errorContainer}>
      <Space direction="vertical" align="center" size="large" className={styles.space}>
        <div className={styles.textContainer}>
          <h1>
            <Trans id="error-screen.title">Error</Trans>
          </h1>
          <span>
            <Trans id="error-screen.message">There was an error that caused the application to crash</Trans>
          </span>
        </div>
        <a href="/">
          <Button>
            <Trans id="error-screen.button-text">Back Home</Trans>
          </Button>
        </a>
      </Space>
    </div>
  );
}

export default ErrorScreen;
