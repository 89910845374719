import { useUser } from "context/UserContext";
import { GET_AVAILABLE_EQUIPMENT_SLUGS } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useEquipmentSlugs() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_AVAILABLE_EQUIPMENT_SLUGS, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const equipmentSlugs = data?.getAvailableEquipmentSlugs || [];

  return { equipmentSlugs, loading, error };
}
