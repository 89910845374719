import { useUser } from "context/UserContext";
import { useLazyQuery } from "@apollo/client";
import { GET_EQUIPMENT } from "graphql/apiService";

export default function useGetEquipment() {
  const { currentOrganization } = useUser();

  const [getEquipmentQuery, { loading, data, error }] = useLazyQuery(GET_EQUIPMENT, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const equipment = data?.getEquipment;

  const getEquipment = async ({ id }) => {
    const res = await getEquipmentQuery({
      variables: {
        id,
      },
    });
    return res.data?.getEquipment;
  };

  return [getEquipment, { equipment, loading, error }];
}
