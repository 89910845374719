import React from "react";
import page403Graphic from "assets/403.svg";
import { Space, Button } from "antd";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import styles from "./Page403.module.less";

function Page403() {
  return (
    <div className={styles.container}>
      <Space direction="vertical" align="center" size="large" className={styles.space}>
        <img src={page403Graphic} alt="403" />
        <div className={styles.textContainer}>
          <h2>403</h2>
          <span>
            <Trans id="403-page.message">
              Sorry, you don&apos;t have access to this page or this page is under construction.
            </Trans>
          </span>
        </div>
        <Link to="/">
          <Button>
            <Trans id="403-page.home">Back Home</Trans>
          </Button>
        </Link>
      </Space>
    </div>
  );
}

export default Page403;
