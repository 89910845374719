import React from "react";
import { t, Trans } from "@lingui/macro";

import { Avatar, Button, Tag } from "antd";

import avatarString from "./avatar";
import { dataPartnerStatus as dataPartnerStatusFn } from "./status";

import styles from "../DataPartners.module.less";

export const columnsSend = ({ handleApprove, handleRevoke, hasPermissions }) => {
  const dataPartnerStatus = dataPartnerStatusFn();
  return [
    {
      title: t({
        id: "data-partners.tabs.columns.organization",
        message: "Organization",
      }),
      dataIndex: "organization",
      key: "organization",
      render: (text) => (
        <div className={styles.organizationAvatarName}>
          <Avatar className={styles.organizationAvatar}>{avatarString(text)}</Avatar>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t({
        id: "data-partners.tabs.columns.status",
        message: "Status",
      }),
      dataIndex: "status",
      key: "status",
      width: "20%",
      filters: [
        {
          text: t({
            id: "data-partners.status.active",
            message: "Active",
          }),
          value: "ACTIVE",
        },
        {
          text: t({
            id: "data-partners.status.pending",
            message: "Pending Approval",
          }),
          value: "PENDING",
        },
        {
          text: t({
            id: "data-partners.status.denied",
            message: "Denied",
          }),
          value: "REVOKED",
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.status === value,
      render: (_, record) => (
        <Tag color={dataPartnerStatus[record.status].color}>{dataPartnerStatus[record.status].name}</Tag>
      ),
    },
    hasPermissions(["approve:org_data_partners"]) && {
      title: t({
        id: "data-partners.tabs.columns.action",
        message: "Action",
      }),
      key: "action",
      width: "20%",
      responsive: ["sm"],
      render: (_, record) => {
        if (record.status === "ACTIVE")
          return (
            <Button size="small" danger onClick={() => handleRevoke({ id: record.id })}>
              <Trans id="data-partners.action.revoke">Revoke</Trans>
            </Button>
          );
        if (record.status === "PENDING")
          return (
            <>
              <Button
                size="small"
                type="primary"
                style={{ marginRight: "0.5rem" }}
                onClick={() => handleApprove({ id: record.id })}
              >
                <Trans id="data-partners.action.approve">Approve</Trans>
              </Button>
              <Button size="small" danger onClick={() => handleRevoke({ id: record.id })}>
                <Trans id="data-partners.action.deny">Deny</Trans>
              </Button>
            </>
          );
        return <span />;
      },
    },
  ].filter(Boolean);
};

export const columnsReceive = ({ handleRevoke, hasPermissions }) => {
  const dataPartnerStatus = dataPartnerStatusFn();
  return [
    {
      title: t({
        id: "data-partners.tabs.columns.organization",
        message: "Organization",
      }),
      dataIndex: "organization",
      key: "organization",
      render: (text) => (
        <div className={styles.organizationAvatarName}>
          <Avatar className={styles.organizationAvatar}>{avatarString(text)}</Avatar>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t({
        id: "data-partners.tabs.columns.status",
        message: "Status",
      }),
      dataIndex: "status",
      key: "status",
      width: "20%",
      filters: [
        {
          text: t({
            id: "data-partners.status.active",
            message: "Active",
          }),
          value: "ACTIVE",
        },
        {
          text: t({
            id: "data-partners.status.pending",
            message: "Pending Approval",
          }),
          value: "PENDING",
        },
        {
          text: t({
            id: "data-partners.status.denied",
            message: "Denied",
          }),
          value: "REVOKED",
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.status === value,
      render: (_, record) => (
        <Tag color={dataPartnerStatus[record.status].color}>{dataPartnerStatus[record.status].name}</Tag>
      ),
    },
    hasPermissions(["revoke:org_data_partners"]) && {
      title: t({
        id: "data-partners.tabs.columns.action",
        message: "Action",
      }),
      key: "action",
      width: "20%",
      responsive: ["sm"],
      render: (_, record) => {
        if (record.status === "ACTIVE" || record.status === "PENDING")
          return (
            <Button size="small" onClick={() => handleRevoke({ id: record.id })}>
              <Trans id="data-partners.action.revoke">Revoke</Trans>
            </Button>
          );
        return <span />;
      },
    },
  ].filter(Boolean);
};
