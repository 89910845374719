import React from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import SideBar from "components/Sidebar";
import GlobalHeader from "components/GlobalHeader";
import GlobalFooter from "components/GlobalFooter";
import { useMediaQuery } from "react-responsive";
import { useLayout } from "context/GlobalLayoutContext";
import Drawer from "components/Drawer";
import QuickMenu from "components/QuickMenu";
import NotificationDrawer from "components/NotificationDrawer";
import styles from "./NavLayout.module.less";

const { Content } = Layout;

function NavLayout(props) {
  const { children, headerContent, hasFooter, fullscreen, sidebar = true } = props;
  const { sidebarCollapsed } = useLayout();

  const isMobile = useMediaQuery({
    query: "(max-width: 575px)",
  });

  const showQuickmenu = process.env.REACT_APP_SHOW_QUICKMENU === "true";

  return (
    <Layout className={styles.layout}>
      {sidebar && isMobile && <Drawer />}
      {sidebar && !isMobile && <SideBar />}
      <div className={styles.sidebarSpacer} style={{ width: sidebarCollapsed ? "72px" : "256px" }} />
      <Layout>
        <div style={{ height: "48px" }} />
        <GlobalHeader isMobile={isMobile} leftContent={headerContent} />
        <Content className={fullscreen ? `${styles.fullscreenContent}` : `${styles.basicContent}`}>{children}</Content>
        {hasFooter && <GlobalFooter />}
      </Layout>
      <NotificationDrawer />
      {showQuickmenu && <QuickMenu />}
    </Layout>
  );
}

NavLayout.propTypes = {
  fullscreen: PropTypes.bool,
  headerContent: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  hasFooter: PropTypes.bool,
};

NavLayout.defaultProps = {
  fullscreen: false,
  headerContent: "",
  hasFooter: true,
};

export default NavLayout;
