import React from "react";
import useGrafana from "hooks/useGrafana";
import styles from "./Overview.module.less";

const domain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";

function Overview() {
  const { homeDashboardUri: dashboardUri } = useGrafana();
  const grafanaUrl = `https://grafana.${domain}${dashboardUri}?kiosk=tv&theme=light`;

  return (
    <div className={styles.container}>
      {dashboardUri && (
        <div className={styles.iframeContainer}>
          <iframe title="dashboard" src={grafanaUrl} frameBorder={0} height="100px" />
        </div>
      )}
    </div>
  );
}

export default Overview;
