import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_ANNOTATION } from "graphql/apiService";

export default function useCreateAnnotation() {
  const { currentOrganization } = useUser();

  const [createAnnotationMutation, { loading, error }] = useMutation(CREATE_ANNOTATION, {
    variables: { organizationSlug: currentOrganization },
  });

  const createAnnotation = async ({ dashboardId, firstMessage, measureIds, panelId, time, timeRange, title }) => {
    const res = await createAnnotationMutation({
      variables: {
        input: {
          dashboardId,
          firstMessage,
          measureIds,
          panelId,
          time,
          timeRange,
          title,
        },
      },
    });
    return res.data.createAnnotation;
  };

  return [createAnnotation, { loading, error }];
}
