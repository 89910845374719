// eslint-disable-next-line import/prefer-default-export

export const pluralize = (count, noun, suffix = "s") => `${count} ${noun}${count !== 1 ? suffix : ""}`;
export const listStrings = (strings) =>
  strings.length > 1
    ? `${strings
        .slice(0, -1)
        .map((string) => `“${string}“`)
        .join(", ")} and “${strings.slice(-1)}“`
    : `“${strings[0]}“`;
