import { useUser } from "context/UserContext";
import { useLazyQuery } from "@apollo/client";
import { GET_MEASURES_BY_EQUIPMENT } from "graphql/apiService";

export default function useGetMeasuresByEquipment() {
  const { currentOrganization } = useUser();

  const [getMeasuresQuery, { loading, error }] = useLazyQuery(GET_MEASURES_BY_EQUIPMENT, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const getMeasuresByEquipment = async ({ equipmentId }) => {
    const res = await getMeasuresQuery({ variables: { equipmentId } });
    return res.data?.getMeasuresByEquipment;
  };

  return [getMeasuresByEquipment, { loading, error }];
}
