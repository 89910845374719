import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_ALARM } from "graphql/apiService";

export default function useUpdateAlarm() {
  const { currentOrganization } = useUser();

  const [updateAlarmMutation, { loading, error }] = useMutation(UPDATE_ALARM, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateAlarm = async ({ id, ...rest }) => {
    const res = await updateAlarmMutation({
      variables: {
        input: {
          id,
          ...rest,
        },
      },
    });
    return res.data.updateAlarm;
  };

  return [updateAlarm, { loading, error }];
}
