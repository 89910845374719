import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import { t } from "@lingui/macro";
import mixpanel from "mixpanel-browser";

import captureException from "utils/capture-sentry-exception";
import { isServiceApm } from "utils/auth-utils";
import { useDashboards } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import Card from "components/Card";

import SidebarToggle from "components/SidebarToggle";
import PageComingSoon from "components/PageComingSoon";
import { Spin, Layout } from "antd";
import DashboardsList from "./components/DashboardsList";
import SideMenu from "./components/SideMenu";

import styles from "./Dashboards.module.less";

const { Content, Sider } = Layout;
const isService = isServiceApm();

function Dashboards() {
  const { dashboards, loading, error } = useDashboards();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { message } = useCustomMessage();

  const isSmallScreen = useMediaQuery({ maxWidth: 1200 });
  const [searchParams] = useSearchParams();
  const dashboardMode = searchParams.get("mode") || "all";

  useEffect(() => {
    if (error) {
      captureException(error);
      message.error(
        t({
          id: "dashboards.errors.get-dashboards",
          message: "Failed to get dashboards",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    mixpanel.track("Dashboards Page Viewed");
  }, []);

  if (loading) {
    return (
      <div className={styles.container}>
        <Spin size="large" />
      </div>
    );
  }

  const contentMarginLeft = sidebarCollapsed ? 84 : 256;
  return (
    <Layout>
      {isService && (
        <Sider
          collapsible
          collapsed={sidebarCollapsed}
          className={styles.sidebar}
          trigger={null}
          theme="light"
          width={256}
          style={{ overflow: "visible" }}
        >
          <SideMenu dashboardMode={dashboardMode} />
          {!isSmallScreen && (
            <SidebarToggle
              collapsed={sidebarCollapsed}
              toggleCollapsed={() => setSidebarCollapsed((collapsed) => !collapsed)}
            />
          )}
        </Sider>
      )}
      <Content className={styles.content} style={{ marginLeft: isService ? contentMarginLeft : 0 }}>
        <Card className={styles.contentContainer}>
          {dashboardMode === "system-overview" ? <PageComingSoon /> : <DashboardsList dashboards={dashboards} />}
        </Card>
      </Content>
    </Layout>
  );
}

export default Dashboards;
