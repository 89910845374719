import { useUser } from "context/UserContext";
import { LIST_EQUIPMENT } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useEquipmentList() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(LIST_EQUIPMENT, {
    fetchPolicy: "cache-and-network",
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const equipmentList = data?.getAllEquipment || [];

  return { equipmentList, loading, error };
}
