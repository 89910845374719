import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Dropdown, Spin, Button, Tooltip, Switch } from "antd";
import { SizeMe } from "react-sizeme";
import { Trans, t } from "@lingui/macro";

import { useAnnotations, useUpdatePanel } from "hooks/apiService";
import usePanelTime from "hooks/usePanelTime";
import { useUser } from "context/UserContext";

import { MoreOutlined, FormOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { ReactComponent as ExpandIcon } from "assets/expand-icon.svg";

import Panel from "components/Panel";
import Card from "components/Card";
import TimeSelectorAdvanced from "components/TimeSelectorAdvanced";

import styles from "./PanelCard.module.less";

const paddingHorizontal = 38;
const paddingVertical = 70;

const PanelCard = forwardRef(
  (
    {
      panel,
      onPanelRemove,
      onPanelUpdate,
      width,
      height,
      draggable,
      dashboardTime,
      dashboardPollInterval,
      dashboardRefetch,
      dashboardId,
      onZoomChange,
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const { showLegend } = panel;
    const legendSwitchTooltip = showLegend ? "Hide Legend" : "Show Legend";

    const {
      startTime,
      setStartTime,
      endTime,
      setEndTime,
      timeInterval,
      setTimeInterval,
      timeMode,
      setTimeMode,
      pollInterval,
      setPollInterval,
      refetch,
      setRefetch,
      zoomStartTime,
      zoomEndTime,
      handleOnRelayout,
    } = usePanelTime({ panel });

    const [updatePanel] = useUpdatePanel();
    const { hasPermissions } = useUser();

    const { annotations } = useAnnotations({ panelId: panel.id });
    const navigate = useNavigate();

    const onLoadingChange = ({ loading: panelLoading }) => {
      setLoading(panelLoading);
    };

    useEffect(() => {
      if (onZoomChange && (!zoomStartTime.isSame(startTime) || !zoomEndTime.isSame(endTime))) {
        onZoomChange({ zoomStartTime, zoomEndTime });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomStartTime, zoomEndTime]);

    useImperativeHandle(ref, () => ({
      async savePanelTime() {
        await updatePanel({
          input: {
            id: panel.id,
            pollInterval,
            time: {
              type: timeMode,
              from: startTime,
              to: endTime,
              interval: timeInterval,
            },
          },
        });
      },
    }));

    const handleClickAnnotation = (e) => {
      const {
        annotation: { id: annotationId },
      } = e;
      navigate({
        pathname: `/panels/${panel?.id}`,
        search: `?annotationId=${annotationId}&dashboardId=${dashboardId}`,
      });
    };

    const handleAddAnnotation = () => {
      navigate({ pathname: `/panels/${panel?.id}`, search: "?addAnnotation=true" });
    };

    const dropdownMenuItems = [
      {
        key: `dropdown-${panel.id}-edit`,
        label: (
          <Link to={{ pathname: `/panels/${panel.id}/edit` }} state={{ panel }}>
            <span>
              <Trans id="panels.panel.dropdown.edit-button">Edit</Trans>
            </span>
          </Link>
        ),
      },
      {
        key: `dropdown-${panel.id}-remove`,
        onClick: onPanelRemove,
        danger: true,
        label: (
          <span>
            <Trans id="panels.panel.dropdown.remove-button">Remove</Trans>
          </span>
        ),
      },
    ];

    return (
      <SizeMe monitorHeight>
        {({ size: containerSize }) => (
          <div className={styles.container}>
            <Card key={panel.id} className={styles.card}>
              {loading && <Spin className={styles.spin} />}
              <div className={`${styles.plotHeader} ${draggable ? "draggableHandle" : ""}`}>
                <div className={styles.infoContainer}>
                  <p className={styles.title}>{panel.name}</p>
                </div>
                <div className={styles.topRightMenu}>
                  {hasPermissions(["create:annotations"]) && (
                    <Tooltip title={t({ id: "panel.tooltip.add-annotation", message: "Add Annotation" })}>
                      {containerSize.width > 600 && (
                        <Button
                          className={`${styles.annotationButton} draggableCancel`}
                          ghost
                          type="primary"
                          onClick={handleAddAnnotation}
                          icon={<FormOutlined />}
                        />
                      )}
                    </Tooltip>
                  )}

                  <Tooltip title={legendSwitchTooltip}>
                    <Switch
                      checkedChildren={<UnorderedListOutlined />}
                      unCheckedChildren={<UnorderedListOutlined />}
                      checked={showLegend}
                      onChange={(checked) => onPanelUpdate({ input: { id: panel.id, showLegend: checked } })}
                    />
                  </Tooltip>

                  {!dashboardTime && (
                    <TimeSelectorAdvanced
                      {...{
                        setStartTime,
                        setEndTime,
                        startTime,
                        endTime,
                        timeInterval,
                        setTimeInterval,
                        timeMode,
                        setTimeMode,
                        pollInterval,
                        setPollInterval,
                        setRefetch,
                        size: "small",
                      }}
                    />
                  )}
                  <Link
                    to={{ pathname: `/panels/${panel.id}`, search: dashboardId ? `?dashboardId=${dashboardId}` : "" }}
                    state={{ panel }}
                  >
                    <span className={`${styles.expandButton} draggableCancel`}>
                      <ExpandIcon />
                    </span>
                  </Link>

                  {hasPermissions(["delete:dashboards", "update:dashboards"], { mode: "some" }) && (
                    <Dropdown menu={{ items: dropdownMenuItems }} trigger={["click"]} placement="bottomRight">
                      <MoreOutlined className={`${styles.icon} draggableCancel`} />
                    </Dropdown>
                  )}
                </div>
              </div>
              <Panel
                panel={panel}
                annotations={annotations}
                onLoadingChange={onLoadingChange}
                width={width || containerSize.width - paddingHorizontal}
                height={height || containerSize.height - paddingVertical}
                showLegend={showLegend}
                legendConfig={{
                  y: -0.3,
                  orientation: "h",
                  yanchor: "top",
                }}
                time={
                  dashboardTime || {
                    from: startTime,
                    to: endTime,
                    interval: timeInterval,
                    type: timeMode,
                  }
                }
                pollInterval={dashboardPollInterval ?? pollInterval}
                refetch={dashboardRefetch || refetch}
                onClickAnnotation={handleClickAnnotation}
                onRelayout={handleOnRelayout}
              />
            </Card>
          </div>
        )}
      </SizeMe>
    );
  }
);

export default PanelCard;
