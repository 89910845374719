import { useUser } from "context/UserContext";
import { GET_THREADS_BY_DASHBOARD } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useThreads({ dashboardId }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_THREADS_BY_DASHBOARD, {
    variables: {
      organizationSlug: currentOrganization,
      dashboardId,
    },
  });

  const threads = data?.getThreadsByDashboard;

  return { threads, loading, error };
}
