import React from "react";
import { NotificationFeed } from "@knocklabs/react-notification-feed";
import bannerBackground from "assets/banner.svg";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "context/NotificationsContext";

import styles from "./Notifications.module.less";

function Notifications() {
  const { loading } = useNotifications();
  const navigate = useNavigate();
  return (
    !loading && (
      <div className={styles.container}>
        <div className={styles.topBanner} style={{ backgroundImage: `url(${bannerBackground})` }} />
        <div className={styles.bannerText}>
          <div className={styles.bannerTitle}>Notifications</div>
        </div>
        <div className={styles.notificationsContainer}>
          <NotificationFeed
            onNotificationClick={(notification) => {
              if (notification.data?.relativeUrl) {
                navigate(notification.data.relativeUrl);
              }
            }}
          />
        </div>
      </div>
    )
  );
}

export default Notifications;
