import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { t } from "@lingui/macro";
import { useLazyQuery } from "@apollo/client";

import { useUser } from "context/UserContext";
import { useGetOrganizationProfile } from "hooks/apiService";
import { GET_ORGANIZATION_PROFILES } from "graphql/apiService";

import { PlusOutlined } from "@ant-design/icons";

import { Tabs, Button, Card as AntdCard, Select } from "antd";
import Card from "components/Card";
import Members from "./components/Members";
import Invitations from "./components/Invitations";
import OrgInitial from "./components/OrgInitial";

import styles from "./RolesAndAdmin.module.less";

function RolesAndAdmin() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const organizationSlug = searchParams.get("organizationSlug");
  const { hasPermissions } = useUser();

  const { organizationProfile, loading } = useGetOrganizationProfile(organizationSlug);
  const [getOrganizationProfiles] = useLazyQuery(GET_ORGANIZATION_PROFILES);
  const [organizationProfiles, setOrganizationProfiles] = useState([]);

  const handleOrganizationSelect = (organization) => {
    setSearchParams({ organizationSlug: organization });
  };

  const isGlobalAdmin = useMemo(
    () => hasPermissions(["read:organization_profiles"], { global: true }),
    [hasPermissions]
  );

  const handleGetOrganizationProfiles = async () => {
    const { data } = await getOrganizationProfiles();
    setOrganizationProfiles(data.getOrganizationProfiles);
  };

  useEffect(() => {
    if (isGlobalAdmin) {
      handleGetOrganizationProfiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalAdmin]);

  if (loading) {
    return <AntdCard loading />;
  }

  return (
    <Card className={styles.card}>
      <div className={styles.topSection}>
        <div className={styles.topContainer}>
          <div className={styles.orgInfo}>
            <OrgInitial org={organizationProfile?.name} />
            {!isGlobalAdmin && <span>{organizationProfile?.name}</span>}
            {isGlobalAdmin && (
              <Select
                className={styles.selectOrg}
                bordered={false}
                options={organizationProfiles.map((organization) => ({
                  value: organization.organizationSlug,
                  label: organization.name,
                }))}
                popupClassName={styles.orgSelectDropdown}
                defaultValue={organizationProfile?.organizationSlug}
                onChange={handleOrganizationSelect}
              />
            )}
          </div>
          {hasPermissions(["invite:users"]) && (
            <Link to={`/roles-admin/add-members${organizationSlug ? `?organizationSlug=${organizationSlug}` : ``}`}>
              <Button type="primary" icon={<PlusOutlined />}>
                {t({ id: "roles-admin.add-members", message: "Add Members" })}
              </Button>
            </Link>
          )}
        </div>
      </div>
      <Tabs
        defaultActiveKey="members"
        activeKey={tab}
        onTabClick={(key, _event) => {
          navigate(`/roles-admin/${key}${organizationSlug ? `?organizationSlug=${organizationSlug}` : ""}`);
        }}
        items={[
          {
            key: "members",
            label: t({ id: "roles-admin.tabs.members", message: "Members" }),
            children: <Members />,
          },
          {
            key: "invitations",
            label: t({ id: "roles-admin.tabs.invitations", message: "Invitations" }),
            children: <Invitations />,
          },
        ]}
      />
    </Card>
  );
}

export default RolesAndAdmin;
