import React from "react";

import { isProductApm } from "utils/auth-utils";
import styles from "./ActiveModule.module.less";

const isProduct = isProductApm();
const title = isProduct ? (
  <div style={{ maxHeight: 48 }}>
    EH2<span style={{ fontSize: "1.2em", verticalAlign: "super" }}>®</span>APM
  </div>
) : (
  "Pioneer APM"
);

export default function ActiveModule({ name } = {}) {
  return (
    <div className={styles.container}>
      <span className={styles.title} style={isProduct ? { lineHeight: 3 } : {}}>
        {title}
      </span>
      <span className={styles.divider}>|</span>
      <span>{name}</span>{" "}
    </div>
  );
}
