import { t } from "@lingui/macro";

export const priorities = () => ({
  LOW: {
    name: t({ id: "alarm-rules.priorities.low", message: "Low" }),
    color: "gold",
  },
  MEDIUM: {
    name: t({ id: "alarm-rules.priorities.medium", message: "Medium" }),
    color: "orange",
  },
  HIGH: {
    name: t({ id: "alarm-rules.priorities.high", message: "High" }),
    color: "volcano",
  },
  CRITICAL: {
    name: t({ id: "alarm-rules.priorities.critical", message: "Critical" }),
    color: "red",
  },
});

export const status = () => ({
  OK: {
    name: t({ id: "alarm-rules.statuses.ok", message: "OK" }),
    color: "green",
  },
  ALARM: {
    name: t({ id: "alarm-rules.statuses.alarm", message: "Alarm" }),
    color: "red",
  },
  NO_DATA: {
    name: t({ id: "alarm-rules.statuses.no-data", message: "No Data" }),
    color: "magenta",
  },
});

export const FORMULAS = [
  {
    value: "GREATER_THAN",
    label: ">",
  },
  {
    value: "GREATER_THAN_OR_EQUAL",
    label: ">=",
  },
  {
    value: "LESS_THAN",
    label: "<",
  },
  {
    value: "LESS_THAN_OR_EQUAL",
    label: "<=",
  },
  {
    value: "EQUAL",
    label: "==",
  },
  {
    value: "NOT_EQUAL",
    label: "!=",
  },
];

export const modes = () => [
  {
    value: "AT_LEAST_ONCE",
    label: t({ id: "alarm-rules.modes.at-least-once", message: "At least once" }),
  },
  {
    value: "ENTIRE_PERIOD",
    label: t({ id: "alarm-rules.modes.entire-period", message: "Entire period" }),
  },
  {
    value: "ON_AVERAGE",
    label: t({ id: "alarm-rules.modes.on-average", message: "On average" }),
  },
];

export const durations = () => [
  {
    value: 60,
    label: t({ id: "alarm-rules.durations.1min", message: "1 min" }),
  },
  {
    value: 300,
    label: t({ id: "alarm-rules.durations.5min", message: "5 min" }),
  },
  {
    value: 600,
    label: t({ id: "alarm-rules.durations.10min", message: "10 min" }),
  },
  {
    value: 1800,
    label: t({ id: "alarm-rules.durations.30min", message: "30 min" }),
  },
  {
    value: 3600,
    label: t({ id: "alarm-rules.durations.1hour", message: "1 hour" }),
  },
];
