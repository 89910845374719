import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LeftArrow } from "assets/left-arrow.svg";
import styles from "./BackButton.module.less";

function BackButton({ link, text }) {
  return (
    <Link to={link}>
      <div className={styles.backContainer}>
        <LeftArrow /> <span>{text}</span>
      </div>
    </Link>
  );
}

export default BackButton;
