import React from "react";
import { Tag as AntdTag } from "antd";

export default function Tag({ label, ...rest }) {
  const color = label === "Official" ? "blue" : undefined;
  return (
    <AntdTag color={color} {...rest}>
      {label}
    </AntdTag>
  );
}
