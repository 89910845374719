import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { GENERATE_MEASURES, GET_EQUIPMENT } from "graphql/apiService";

export default function useGenerateMeasures() {
  const { currentOrganization } = useUser();

  const [generateMeasuresMutation, { loading, error }] = useMutation(GENERATE_MEASURES, {
    variables: { organizationSlug: currentOrganization },
    update: (cache, { data: { generateMeasures: generatedMeasures } }) => {
      if (generatedMeasures.length > 0) {
        const { equipmentId } = generatedMeasures[0];
        const { getEquipment: equipment } = cache.readQuery({
          query: GET_EQUIPMENT,
          variables: {
            organizationSlug: currentOrganization,
            id: equipmentId,
          },
        });
        cache.writeQuery({
          query: GET_EQUIPMENT,
          variables: {
            organizationSlug: currentOrganization,
            id: equipmentId,
          },
          data: {
            getEquipment: { ...equipment, measures: [...equipment.measures, ...generatedMeasures] },
          },
        });
      }
    },
  });

  const generateMeasures = async ({ equipmentId }) => {
    await generateMeasuresMutation({
      variables: {
        equipmentId,
      },
    });
  };

  return [generateMeasures, { loading, error }];
}
