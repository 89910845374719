import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Table, Button, TreeSelect, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { isCustomerApm } from "utils/auth-utils";

import useCustomMessage from "hooks/useCustomMessage";
import { useUpdateEquipment } from "hooks/apiService";
import useAssetTree from "hooks/useAssetTree";
import TableColumns from "./TableColumns";

import { icons } from "../helpers/mapping";
import styles from "./AssetList.module.less";

const isCustomer = isCustomerApm();

function AssetList({ asset, subAssets, isShared, onAssetDelete }) {
  const { message } = useCustomMessage();

  const [updateEquipment] = useUpdateEquipment();
  const { assetTree } = useAssetTree();
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [movingAssetId, setMovingAssetId] = useState();

  const MoveModal = () => {
    const [selectedAssetId, setSelectedAssetId] = useState(undefined);
    return (
      <Modal
        centered
        open={moveModalOpen}
        title={t({ id: "asset-map.asset-list.move-modal-title", message: "Move To" })}
        onCancel={() => setMoveModalOpen(false)}
        onOk={() => {
          moveAsset(movingAssetId, selectedAssetId);
          setMoveModalOpen(false);
        }}
        width="min(100%, 1000px)"
      >
        <div className={styles.modalContent}>
          <Trans id="asset-map.asset-list.move-modal-prompt">Select the location you would like to move to</Trans>
          <TreeSelect
            style={{
              width: "100%",
              margin: "12px 0",
            }}
            treeLine
            value={selectedAssetId}
            onChange={(newId) => setSelectedAssetId(newId)}
            placeholder={t({
              id: "asset-map.asset-list.select-placeholder",
              message: "Select destination",
            })}
            treeDefaultExpandAll
            treeData={assetTree.filter(({ key }) => key !== "shared")}
          />
        </div>
      </Modal>
    );
  };

  const moveAsset = async (assetId, destinationId) => {
    try {
      const newParentId = destinationId === "global" ? null : destinationId;
      await updateEquipment({ input: { id: assetId, parentId: newParentId } });
    } catch (err) {
      message.error(`Error: ${err.message}`);
    }
  };

  const handleAssetMove = (assetId) => {
    setMovingAssetId(assetId);
    setMoveModalOpen(true);
  };

  const columns = TableColumns({ onAssetMove: handleAssetMove, onAssetDelete });
  const data = subAssets.map((a) => ({ key: a.id, icon: icons[a.type]?.react || icons.ASSET.react, ...a }));
  const parentParam = asset?.id ? `?parentId=${asset.id}` : "";
  return (
    <div>
      <MoveModal />
      <Table className={styles.table} columns={columns} dataSource={data} scroll="x" pagination={false} />

      {!isShared && !isCustomer && (
        <Link to={`/assets/new${parentParam}`}>
          <Button className={styles.button} icon={<PlusOutlined />}>
            <Trans id="asset-map.aset-list.add-asset">Add Asset</Trans>
          </Button>
        </Link>
      )}
    </div>
  );
}

export default AssetList;
