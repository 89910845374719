import React, { useState } from "react";

import { ReactComponent as ElectrolyzerAndExportIcon } from "assets/electrolyzer-&-h2-export.svg";

import { InputNumber, Slider } from "antd";
import Card from "components/Card";
import KPI from "./components/KPI";
import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart";

import { getGridPowerUseByCO2Threshold } from "./helpers/electrolyzer-utils";
import carbonIntensity from "./resources/carbonIntensity.json";
import dayAheadPrice from "./resources/dayAheadPrice.json";

import styles from "./H2Export.module.less";

const carbonIntensityTimes = carbonIntensity.times.map((d) => new Date(d).toISOString());
const dayAheadPriceTimes = dayAheadPrice.times.map((d) => new Date(d).toISOString());

const MAX_ALLOWED_CI = 500;

export default function H2Export() {
  const [inputCIThresholdValue, setInputCIThresholdValue] = useState(0);
  const onChange = (newValue) => {
    setInputCIThresholdValue(newValue);
  };

  const {
    gridPowerUse,
    totalGridPowerUse,
    operationalCapacity,
    totalKgCO2e,
    h2CarbonIntensity,
    fuelMix,
    totalSolarPowerUse,
    gridCost,
    gridRevenue,
    totalH2Production,
  } = getGridPowerUseByCO2Threshold(inputCIThresholdValue, carbonIntensity);

  const totalH2ProductionTons = (totalH2Production / 1000).toFixed(1);
  const totalTonsCO2e = (totalKgCO2e / 1000).toFixed(2);
  const h2CarbonIntensityFixed = h2CarbonIntensity.toFixed(2);
  const operationalCapacityRate = (operationalCapacity * 100).toFixed(1);
  const gridRevenueRounded = Math.round(gridRevenue);
  const gridCostRounded = Math.round(gridCost);
  const totalGridPowerFixed = totalGridPowerUse.toFixed(1);
  const marginalCost = (gridCost / totalH2Production).toFixed(2);

  const fuelMixData = {
    values: [...Object.values(fuelMix).map((val) => val.toFixed(2)), Math.round(totalSolarPowerUse)],
    labels: [...Object.keys(fuelMix), "Non-Grid Power"],
  };
  const gridStatusData = [
    {
      // For now, just show the first 72 hours of data, so that it matches the first graph
      x: dayAheadPriceTimes.slice(0, 72),
      y: dayAheadPrice.dayAhead,
      name: "day-ahead electricity price",
      yaxis: "y1",
    },
    {
      x: carbonIntensityTimes,
      y: carbonIntensity.carbon,
      name: "average grid carbon intensity",
      yaxis: "y2",
    },
  ];
  const gridStatusLayout = {
    yaxis: {
      title: "Electricity price (USD/MWh)",
    },
    yaxis2: {
      title: "Grid carbon intensity (kg CO2e/MWh)",
    },
    shapes:
      inputCIThresholdValue !== 0
        ? [
            {
              type: "line",
              xref: "paper",
              yref: "y2",
              x0: 0,
              y0: inputCIThresholdValue,
              x1: 1,
              y1: inputCIThresholdValue,
              line: {
                color: "#ff7f0e",
                width: 2,
                dash: "dash",
              },
            },
          ]
        : [],
  };

  const facilityPowerData = [
    {
      x: carbonIntensityTimes,
      y: carbonIntensity.solar_generation,
      name: "solar generation",
      line: {
        dash: "dash",
        width: 2,
      },
    },
    {
      x: carbonIntensityTimes,
      y: carbonIntensity.solar_to_ez,
      name: "solar to electrolysis",
      stackgroup: "solarTo",
      line: {
        width: 0,
      },
    },
    {
      x: carbonIntensityTimes,
      y: carbonIntensity.battery_charging,
      name: "solar to battery charging",
      stackgroup: "solarTo",
      line: {
        width: 0,
      },
    },
    {
      x: carbonIntensityTimes,
      y: carbonIntensity.solar_to_grid,
      name: "solar to grid",
      stackgroup: "solarTo",
      line: {
        width: 0,
      },
    },
    {
      x: carbonIntensityTimes,
      y: carbonIntensity.battery_to_ez,
      name: "battery to electrolysis",
      stackgroup: "solarTo",
      line: {
        width: 0,
      },
    },
    {
      x: carbonIntensityTimes,
      y: gridPowerUse,
      name: "grid to electrolysis",
      stackgroup: "solarTo",
      line: {
        width: 0,
      },
    },
  ];
  const facilityPowerLayout = {
    yaxis: {
      title: "Power (MW)",
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <ElectrolyzerAndExportIcon className={styles.moduleIcon} />
          <h2 className={styles.title}>Electrolyzer & H2 Production</h2>
        </div>
        <p>
          Operations of a hypothetical 50 MW electrolysis facility that is tied to the grid and has local solar PV
          generation and a 50MW / 400 MWh battery. Grid prices and fuel mix are pulled from{" "}
          <a href="https://gridStatus.io" target="_blank" rel="noreferrer">
            GridStatus.io
          </a>
          . Average carbon intensity calculations are approximate and are based on IPCC life-cycle emission values.
        </p>
        <div className={styles.thresholdContainer}>
          <p className={styles.thresholdText}>Max. allowed Carbon Intensity of the purchased power</p>
          <div className={styles.sliderContainer}>
            <Slider
              className={styles.sliderThreshold}
              min={0}
              max={MAX_ALLOWED_CI}
              step={1}
              onChange={onChange}
              value={inputCIThresholdValue}
            />
            <InputNumber
              className={styles.inputThreshold}
              min={0}
              max={MAX_ALLOWED_CI}
              step={1}
              value={inputCIThresholdValue}
              onChange={onChange}
              addonAfter={<span>kg CO2e / MWh</span>}
            />
          </div>
        </div>
      </div>
      <div className={styles.grid}>
        <Card className={styles.item}>
          <LineChart title="Facility Power" data={facilityPowerData} layout={facilityPowerLayout} />
        </Card>
        <Card className={`${styles.item} ${styles.gridTopRight}`}>
          <KPI title="Electrolysis facility operational capacity" number={operationalCapacityRate} unit="%" />
          <KPI title="H2 Produced" number={totalH2ProductionTons} unit="tons" />
          <KPI title="Total CO2e" number={totalTonsCO2e} unit="tons" />
          <KPI title="H2 Carbon Intensity" number={h2CarbonIntensityFixed} unit="kg CO2e/kg H2" />
        </Card>
        <Card className={styles.item}>
          <LineChart title="Grid Status" data={gridStatusData} layout={gridStatusLayout} />
        </Card>
        <Card className={`${styles.item} ${styles.gridBottomRight}`}>
          <PieChart className={styles.pieChart} data={fuelMixData} title="Power Mix" />
          <KPI title="Grid Revenue" number={gridRevenueRounded} unit="USD" />
          <KPI title="Grid Cost" number={gridCostRounded} unit="USD" />
          <KPI title="Grid Power Use" number={totalGridPowerFixed} unit="MWh" />
          <KPI title="H2 Marginal Cost" number={marginalCost} unit="USD/kg" />
        </Card>
      </div>
    </div>
  );
}
