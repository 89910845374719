import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { Table, Button, Select, Dropdown, Space, Checkbox, Divider } from "antd";
import { PlusOutlined, StarOutlined, DownOutlined } from "@ant-design/icons";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import useTableSearch from "hooks/useTableSearch";
import { useUser } from "context/UserContext";
import { isProductApm } from "utils/auth-utils";

import TableNameCell from "components/TableNameCell";
import DashboardActions from "../DashboardActions";
import Tag from "../Tag";

import { types as typesFn } from "../../helpers/mapping";

import styles from "./DashboardsList.module.less";

const isProduct = isProductApm();

const getFilteredType = (mode) => {
  switch (mode) {
    case "all":
      return "ALL";
    case "grafana":
      return "GRAFANA";
    case "superset":
      return "SUPERSET";
    default:
      return "ALL";
  }
};

function DashboardsList({ dashboards }) {
  const [selectedFilteredType, setSelectedFilteredType] = useState("ALL");
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "all";
  const modeFilteredType = getFilteredType(mode);

  const filteredType = isProduct ? modeFilteredType : selectedFilteredType;

  const [createdByFilter, setCreatedByFilter] = useState("ALL");
  const [sortBy, setSortBy] = useState("LAST_UPDATED_DESCENDING");
  const [tagsDropdownOpen, setTagsDropdownOpen] = useState(false);
  const { hasPermissions, user } = useUser();
  const allTags = [...new Set(dashboards.map((item) => item.tags).flat())];
  const [selectedTags, setSelectedTags] = useState(allTags);

  const onTagsSelect = (checkedTags) => setSelectedTags(checkedTags);
  const allTagsSelected = allTags.length === selectedTags.length;
  const onCheckAllChange = (e) => {
    setSelectedTags(e.target.checked ? allTags : []);
  };
  const indeterminate = selectedTags.length > 0 && selectedTags.length < allTags.length;

  const { getColumnSearchProps, searchText } = useTableSearch();

  const types = typesFn();

  const compareDashboards = (a, b) => {
    switch (sortBy) {
      case "LAST_UPDATED_ASCENDING":
        return dayjs(a.updatedAt).diff(dayjs(b.updatedAt));

      case "LAST_UPDATED_DESCENDING":
        return dayjs(b.updatedAt).diff(dayjs(a.updatedAt));

      case "NAME_ASCENDING":
        return a.name.localeCompare(b.name);

      case "NAME_DESCENDING":
        return b.name.localeCompare(a.name);

      default:
        return 0;
    }
  };

  const tableData = dashboards
    .map((dashboard) => ({
      key: dashboard.id,
      id: dashboard.id,
      name: dashboard.name,
      type: dashboard.type,
      updatedAt: dashboard.updatedAt,
      createdBy: dashboard.createdBy,
      isFavorite: dashboard.isFavorite,
      tags: dashboard.tags,
    }))
    .filter(
      (dashboard) =>
        (filteredType === "ALL" || dashboard.type === filteredType) &&
        (createdByFilter === "ALL" || dashboard.createdBy === user.sub) &&
        (allTagsSelected || selectedTags.some((tag) => dashboard.tags.includes(tag)))
    )
    .sort(compareDashboards);

  const favoriteDashboards = tableData.filter((d) => d.isFavorite);

  const tagsMenuItems = [
    {
      key: "tags",
      label: (
        <div>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={allTagsSelected}>
            Check all
          </Checkbox>
          <Divider style={{ margin: 12 }} />
          <Checkbox.Group
            className={styles.checkboxes}
            value={selectedTags}
            onChange={onTagsSelect}
            options={allTags.map((tag) => ({ value: tag, label: tag }))}
          />
        </div>
      ),
    },
  ];

  const typeOptions = [
    { value: "ALL", label: t({ id: "dashboards.types.all", message: "All Dashboards" }) },
    ...Object.entries(types).map(([type, { label }]) => ({
      value: type,
      label: `${label} Dashboards`,
    })),
  ];

  const sortOptions = [
    {
      value: "LAST_UPDATED_DESCENDING",
      label: t({ id: "dashboards.sort-options.last-updated-descending", message: "Last Updated: New to Old" }),
    },
    {
      value: "LAST_UPDATED_ASCENDING",
      label: t({ id: "dashboards.sort-options.last-updated-ascending", message: "Last Updated: Old to New" }),
    },
    { value: "NAME_ASCENDING", label: t({ id: "dashboards.sort-options.name-ascending", message: "Name: A-Z" }) },
    { value: "NAME_DESCENDING", label: t({ id: "dashboards.sort-options.name-descending", message: "Name: Z-A" }) },
  ];

  const createdByOptions = [
    { value: "ALL", label: t({ id: "dashboards.created-by.all", message: "All Users" }) },
    { value: "ME", label: t({ id: "dashboards.created-by.me", message: "Me" }) },
  ];

  const columns = [
    {
      title: t({ id: "dashboards.table.name", message: "Name" }),
      dataIndex: "name",
      key: "name",
      width: 600,
      className: "nameCol",
      ...getColumnSearchProps({ dataIndex: "name", customRender: true }),
      render: (name, record) => (
        <TableNameCell record={record} name={name} type={record.type} link={record.id} searchText={searchText} />
      ),
    },
    {
      title: t({ id: "dashboard.table.tags", message: "Tags" }),
      dataIndex: "tags",
      width: "18%",
      responsive: ["sm"],
      render: (tags, _record) =>
        tags && (
          <div>
            {tags.map((tag) => (
              <Tag key={tag} label={tag} />
            ))}
          </div>
        ),
      editable: true,
    },
    {
      title: t({ id: "dashboards.table.updated", message: "Last Updated" }),
      dataIndex: "updatedAt",
      key: "updatedAt",
      responsive: ["sm"],
      render: (updatedAt, _record) => (
        <span>{updatedAt && i18n.date(new Date(updatedAt), { dateStyle: "medium", timeStyle: "short" })}</span>
      ),
    },
    hasPermissions(["read:dashboards"]) && {
      title: t({ id: "dashboards.table.action", message: "Action" }),
      key: "action",
      render: (_, record) => <DashboardActions record={record} />,
    },
  ].filter(Boolean);

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        {!isProduct && (
          <div className={styles.toolbarFilter}>
            <Trans id="dashboards.toolbar.type">Type:</Trans>
            <Select
              options={typeOptions}
              defaultValue="ALL"
              className={styles.select}
              value={filteredType}
              onChange={(newType) => setSelectedFilteredType(newType)}
            />
          </div>
        )}

        <div className={styles.toolbarFilter}>
          <Trans id="dashboards.toolbar.sort">Sort By:</Trans>
          <Select
            style={{ minWidth: "24ch" }}
            options={sortOptions}
            className={styles.select}
            value={sortBy}
            onChange={(newSort) => setSortBy(newSort)}
          />
        </div>
        <div className={styles.toolbarFilter}>
          <Trans id="dashboards.toolbar.created-by">Created By:</Trans>
          <Select
            options={createdByOptions}
            className={styles.select}
            value={createdByFilter}
            onChange={(newValue) => setCreatedByFilter(newValue)}
          />
        </div>
        <div className={styles.toolbarFilter}>
          <Trans id="dashboards.toolbar.tags">Tags:</Trans>
          <Dropdown
            menu={{ items: tagsMenuItems }}
            trigger="click"
            open={tagsDropdownOpen}
            onOpenChange={(newVal) => setTagsDropdownOpen(newVal)}
          >
            <Button>
              <Space>
                {allTagsSelected ? "All Tags" : "Custom"}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        {hasPermissions(["create:dashboards"]) && (
          <Button className={styles.addButton} type="primary">
            <Link to="new">
              <PlusOutlined className={styles.plusIcon} />
              <Trans id="dashboards.new">New Dashboard</Trans>
            </Link>
          </Button>
        )}
      </div>
      <div className={styles.header}>
        <Trans id="dashboards.favorites.title">My Favorites</Trans>
      </div>
      {favoriteDashboards?.length ? (
        <Table
          columns={columns}
          dataSource={favoriteDashboards}
          loading={!dashboards}
          pagination={
            tableData?.length <= 10
              ? false
              : {
                  defaultPageSize: 10,
                }
          }
        />
      ) : (
        <div className={styles.messageContainer}>
          <Trans id="dashboards.favorites.empty-message">
            <div>Add your first favorite dashboard to “My Favorites” by clicking</div>
          </Trans>
          <StarOutlined style={{ fontSize: 19 }} />
        </div>
      )}
      {mode !== "favorites" && (
        <>
          <div className={styles.header}>
            <Trans id="dashboards.all.title">All Dashboards</Trans>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            loading={!dashboards}
            pagination={
              tableData?.length <= 20
                ? false
                : {
                    defaultPageSize: 20,
                  }
            }
          />
        </>
      )}
    </div>
  );
}

export default DashboardsList;
