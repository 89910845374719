import { GET_USER_ORGANIZATION_PROFILE } from "graphql/apiService";
import useQuery from "hooks/useQuery";
import { getOrgFromSubdomain } from "utils/auth-utils";

export default function useUserOrganizationProfile({ id, organizationSlug }) {
  const currentOrganization = getOrgFromSubdomain();

  const { data, loading, error } = useQuery(GET_USER_ORGANIZATION_PROFILE, {
    variables: {
      organizationSlug: organizationSlug || currentOrganization,
      auth0Id: id,
    },
  });

  const userOrganizationProfile = data?.getUserOrganizationProfile;
  return { userOrganizationProfile, loading, error };
}
