/* eslint-disable consistent-return */
import React, { useState } from "react";

import { Button, Upload, Table } from "antd";
import { t, Trans } from "@lingui/macro";
import axios from "axios";

import {
  useEquipmentAttachments,
  useCreateEquipmentAttachment,
  useMarkEquipmentAttachmentAsUploaded,
  useDeleteEquipmentAttachment,
} from "hooks/apiService";
import { useUser } from "context/UserContext";
import useCustomMessage from "hooks/useCustomMessage";

import { UploadOutlined } from "@ant-design/icons";

import Card from "components/Card";
import AttachmentsTableColumns from "./AttachmentsTableColumns";

import { simpleType } from "./helper/fileTypes";

import styles from "./AttachmentBox.module.less";

export default function AttachmentBox({ asset, className }) {
  const [uploadingFileId, setUploadingFileId] = useState();
  const [downloadingFileId, setDownloadingFileId] = useState();
  const [deletingFileId, setDeletingFileId] = useState();
  const [uploading, setUploading] = useState(false);

  const { equipmentAttachments, loading: attachmentsLoading } = useEquipmentAttachments({ equipmentId: asset.id });
  const [createEquipmentAttachment] = useCreateEquipmentAttachment();
  const [deleteAttachment] = useDeleteEquipmentAttachment();
  const [markAttachmentAsUploaded] = useMarkEquipmentAttachmentAsUploaded();
  const { message } = useCustomMessage();
  const { hasPermissions } = useUser();

  const showUploadErrorMessage = (file) =>
    message.error(
      t({
        id: "asset-map.asset.attachments.file-upload-error",
        message: `${file.name} file upload failed`,
      })
    );

  const handleAddFile = async (file) => {
    const maxAllowedSize = 50 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      message.error(
        t({
          id: "asset-map.asset.attachments.errors.file-size",
          message: `File (${file.name}) is above the max allowed size (50 MB).`,
        })
      );
      return false;
    }
  };

  const handleAttachmentUpload = async (file) => {
    try {
      const res = await createEquipmentAttachment({
        input: {
          equipmentId: asset.id,
          fileName: file.name,
        },
      });
      setUploadingFileId(res.id);
      return res.uploadUrl;
    } catch (err) {
      showUploadErrorMessage(file);
    }
  };

  const handleUploadStateChange = async (info) => {
    setUploading(info.file.status === "uploading");
    if (info.file.status === "done") {
      await markAttachmentAsUploaded({ id: uploadingFileId });
      message.success(
        t({
          id: "asset-map.asset.attachments.upload-success",
          message: `${info.file.name} uploaded successfully`,
        })
      );
    } else if (info.file.status === "error") {
      showUploadErrorMessage(info.file);
    }
  };

  const handleAttachmentDelete = async ({ id, fileName }) => {
    try {
      setDeletingFileId(id);
      await deleteAttachment({ id });
    } catch (err) {
      message.error(
        t({
          id: "asset-map.asset.attachments.errors.delete",
          message: `Failed to delete ${fileName}`,
        })
      );
    } finally {
      setDeletingFileId(null);
    }
  };

  const uploadFile = async (options) => {
    const { action: uploadUrl, file, onSuccess, onError, onProgress } = options;
    try {
      await axios.request({
        method: "put",
        url: uploadUrl,
        data: file,
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (p) => {
          onProgress({ percent: Math.floor((p.loaded / p.total) * 100) });
        },
      });

      onSuccess();
    } catch (err) {
      onError({ err });
    }
  };

  const downloadFile = async ({ fileUrl, fileName, contentType, id }) => {
    try {
      setDownloadingFileId(id);
      const res = await fetch(fileUrl, {
        method: "GET",
        headers: {
          "Content-Type": contentType,
        },
      });
      const blob = await res.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      message.error(
        t({
          id: "asset-map.asset.attachments.errors.file-download",
          message: "Failed to download the requested file",
        })
      );
    } finally {
      setDownloadingFileId(null);
    }
  };

  const tableData = equipmentAttachments.map((attachment) => ({
    ...attachment,
    key: attachment.id,
    type: simpleType(attachment.contentType),
  }));

  const tableColumns = AttachmentsTableColumns({
    onDelete: handleAttachmentDelete,
    data: tableData,
    onDownload: downloadFile,
    downloadingFileId,
    deletingFileId,
  });

  return (
    <Card className={className && className}>
      <div className={styles.boxHeader}>
        <span className={styles.boxTitle}>
          <Trans id="asset-map.asset.attachments.title">All Files</Trans>
        </span>
        <Upload
          progress
          beforeUpload={handleAddFile}
          maxCount={1}
          action={handleAttachmentUpload}
          customRequest={uploadFile}
          showUploadList={uploading}
          onChange={handleUploadStateChange}
          accept="image/jpeg, image/png, application/pdf, .docx, .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx"
        >
          {!uploading && hasPermissions(["create:equipment_attachments"]) && (
            <Button>
              <UploadOutlined /> <Trans id="asset-map.asset.attachments.upload-button">Upload New File</Trans>
            </Button>
          )}
        </Upload>
      </div>
      <div className={styles.boxContent}>
        <Table
          className={styles.uploadTable}
          columns={tableColumns}
          dataSource={tableData}
          loading={attachmentsLoading}
          pagination={false}
        />
      </div>
    </Card>
  );
}
