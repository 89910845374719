import React, { useState, useContext, createContext, useMemo } from "react";

import WorkOrderRequestDrawer from "components/WorkOrderRequestDrawer";

const WorkOrderRequestContext = createContext([]);

const WorkOrderRequestProvider = ({ children }) => {
  const [workOrderVisibility, setWorkOrderVisibility] = useState(false);
  const [mounted, setMounted] = useState(false);

  const openWorkOrderRequestDrawer = () => {
    setWorkOrderVisibility(true);
  };
  const closeWorkOrderRequestDrawer = () => {
    setWorkOrderVisibility(false);
  };

  const mount = () => {
    setMounted(true);
  };
  const unmount = () => {
    setMounted(false);
  };

  const values = useMemo(
    () => ({
      mount,
      unmount,
      workOrderVisibility,
      openWorkOrderRequest: openWorkOrderRequestDrawer,
      closeWorkOrderRequest: closeWorkOrderRequestDrawer,
    }),
    [workOrderVisibility]
  );

  return (
    <WorkOrderRequestContext.Provider value={values}>
      {!mounted && <WorkOrderRequestDrawer visibility={workOrderVisibility} close={closeWorkOrderRequestDrawer} />}
      {children}
    </WorkOrderRequestContext.Provider>
  );
};

const useWorkOrderRequest = () => useContext(WorkOrderRequestContext);

export { useWorkOrderRequest, WorkOrderRequestProvider };
