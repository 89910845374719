import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { t, Trans } from "@lingui/macro";

import { Button, Checkbox, DatePicker, Drawer, Form, Input, TreeSelect, Upload, Select } from "antd";
import { CloseCircleOutlined, LinkOutlined } from "@ant-design/icons";

import FileIcon from "components/FileIcon";

import useThirdPartyProxy from "hooks/useThirdPartyProxy";
import useCustomMessage from "hooks/useCustomMessage";

import styles from "./WorkOrderRequestDrawer.module.less";

const UploadItem = (el, file, _fileList, { remove }) => {
  const { name, size, type } = file;

  return (
    <div className={styles.uploadItem}>
      <FileIcon className={styles.fileIcon} contentType={type} />
      <div className={styles.fileProperties}>
        <span className={styles.fileName}>{name}</span>
        <span className={styles.fileSize}>{parseFloat(size / 1024).toFixed(2)} kB</span>
      </div>
      <div className={styles.removeButton} onClick={remove} aria-hidden>
        <CloseCircleOutlined />
      </div>
    </div>
  );
};

export default function WorkOrderRequestDrawer({ panelRef, visibility, close }) {
  const [requestForm] = Form.useForm();
  const { getAssets, createInstruction, createTask, uploadFilesToInstruction } = useThirdPartyProxy();
  const { message } = useCustomMessage();
  const navigate = useNavigate();

  const [autoCapture, setAutoCapture] = useState("");
  const [fileUploads, setFileUploads] = useState([]);
  const [assets, setAssets] = useState([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dueTime, setDueTime] = useState();

  const categoryOptions = ["Balance Of Plant (BOP)", "Stack Assembly", "Power Supply/Power System", "Safety", "Other"];

  const renderElementToPng = async (element) => {
    const canvas = await html2canvas(element, { logging: false });
    const data = canvas.toDataURL("image/png");
    setAutoCapture(data);
  };

  const handleAddFile = (file) => {
    const maxAllowedSize = 5 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      message.error(
        t({
          id: "work-order-request.errors.max-allowed-size",
          message: `File (${file.name}) is above the max allowed size (5 MB).`,
        })
      );
    } else {
      setFileUploads((files) => [...files, file]);
    }
    return false;
  };
  const handleRemoveFile = (file) => {
    setFileUploads((files) => files.filter((item) => item !== file));
  };

  const handleGetAssets = async () => {
    try {
      setLoadingAssets(true);
      const data = await getAssets();
      setAssets(data);
    } finally {
      setLoadingAssets(false);
    }
  };

  const handleDueTimeCheck = (e) => {
    setDueTime(e.target.checked);
  };

  useEffect(() => {
    if (visibility && panelRef) {
      const element = panelRef.current;
      renderElementToPng(element);
    }
    if (visibility) {
      handleGetAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility, panelRef]);

  const handleCreateWorkOrderRequest = async (values) => {
    try {
      setLoadingSubmit(true);
      const { assetID, locationID } = assets.find((asset) => asset.assetID === values["asset-id"]);
      const { name, description, url: meta1, category } = values;
      const due = values["issue-due-date"].unix();
      const descriptionText = `${description || ""}<br>Category: <b>${category}</b>`;
      const { taskID } = await createTask({
        locationID,
        name,
        assetID,
        due,
        description: descriptionText,
      });
      const { instructionID } = await createInstruction({
        taskID,
        instruction: t({
          id: "work-order-request.instruction-message",
          message: `Created with EH2 APM at ${meta1} and may contain relevant images.`,
        }),
      });
      await uploadFilesToInstruction({ taskID, instructionID, files: [autoCapture, ...fileUploads] });
      requestForm.resetFields();
      setFileUploads([]);
      message.success(
        t({
          id: "work-order-request.create-success-message",
          message: "Work Order Request has been successfully created",
        })
      );
      close();
      navigate(`/work-requests/${taskID}`);
    } catch (error) {
      message.error(
        t({
          id: "work-order-request.errors.failed-creation",
          message: "Failed to create Work Order Request",
        })
      );
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <Drawer title="Work Order Request" open={visibility} placement="right" onClose={close} width={420}>
      <div className={styles.container}>
        <Form form={requestForm} layout="vertical" onFinish={handleCreateWorkOrderRequest} requiredMark="optional">
          {autoCapture && (
            <Form.Item
              name="auto-capture"
              label={t({
                id: "work-order-request.form.label.auto-captured",
                message: "Auto Captured",
              })}
              required
            >
              <img className={styles.autoCaptureImage} src={autoCapture} alt="auto capture" />
            </Form.Item>
          )}
          <Form.Item name="url" initialValue={window.location.href}>
            <Input prefix={<LinkOutlined />} disabled />
          </Form.Item>
          <Form.Item
            label={t({
              id: "work-order-request.form.label.attachments",
              message: "Attachments",
            })}
            tooltip={t({
              id: "work-order-request.form.tooltip.attachments",
              message: "Upload additional images for the work order",
            })}
          >
            <Upload
              className={styles.uploadContainer}
              itemRender={UploadItem}
              multiple
              progress
              beforeUpload={handleAddFile}
              onRemove={handleRemoveFile}
              fileList={fileUploads}
              accept="image/jpeg, image/png"
            >
              <Button type="primary" ghost>
                <Trans id="work-order-request.form.button.upload-attachments">Upload Additional Images</Trans>
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="name"
            label={t({
              id: "work-order-request.form.label.name",
              message: "Request Name",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "work-order-request.form.rules.name",
                  message: "Please write a short description of the request",
                }),
              },
            ]}
            tooltip={t({
              id: "work-order-request.form.tooltip.name",
              message: "Please write short description of the request",
            })}
          >
            <Input
              placeholder={t({
                id: "work-order-request.form.placeholder.name",
                message: "Write short description of the request",
              })}
              showCount
              maxLength={350}
            />
          </Form.Item>
          <Form.Item
            name="asset-id"
            label={t({
              id: "work-order-request.form.label.asset",
              message: "Asset ID",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "work-order-request.form.rules.asset",
                  message: "Please select at least one related asset",
                }),
              },
            ]}
            tooltip={t({
              id: "work-order-request.form.tooltip.asset",
              message: "Select at least one related asset",
            })}
          >
            <TreeSelect
              treeData={assets}
              treeDataSimpleMode={{
                id: "assetID",
                pId: "parentAssetID",
              }}
              fieldNames={{
                label: "name",
                value: "assetID",
              }}
              showSearch
              treeLine
              allowClear
              showCheckedStrategy="SHOW_PARENT"
              loading={loadingAssets}
              disabled={loadingAssets}
              treeNodeFilterProp="name"
            />
          </Form.Item>
          <Form.Item
            name="category"
            label={t({
              id: "work-order-request.form.label.category",
              message: "What is the category of the issue?",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "work-order-request.form.rules.category",
                  message: "Please select the category of the issue",
                }),
              },
            ]}
          >
            <Select
              placeholder={t({
                id: "work-order-request.form.placeholder.category",
                message: "Select category",
              })}
            >
              {categoryOptions.map((category) => (
                <Select.Option key={category} value={category}>
                  {category}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.dueTimeFormItem}
            name="issue-due-date"
            label={t({
              id: "work-order-request.form.label.due-date",
              message: "Requested Due Date",
            })}
            initialValue={dayjs()}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "work-order-request.form.rules.due-date",
                  message: "Please select requested due date",
                }),
              },
            ]}
            tooltip={t({
              id: "work-order-request.form.tooltip.due-date",
              message: "The time until this work request is due",
            })}
          >
            <DatePicker className={styles.datePicker} showTime={dueTime} />
          </Form.Item>
          <Checkbox className={styles.dueTimeCheckbox} onChange={handleDueTimeCheck} checked={dueTime}>
            <Trans id="work-order-request.form.due-time">Due time?</Trans>
          </Checkbox>
          <Form.Item
            name="description"
            label={t({
              id: "work-order-request.form.label.description",
              message: "Description",
            })}
            tooltip={t({
              id: "work-order-request.form.tooltip.description",
              message: "A brief description about the Task.",
            })}
          >
            <Input.TextArea rows={3} showCount maxLength={1400} />
          </Form.Item>
          <Form.Item>
            <div className={styles.actionButtons}>
              <Button type="primary" htmlType="submit" loading={loadingSubmit}>
                <Trans id="work-order-request.form.button.send-request">Send Request</Trans>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}
