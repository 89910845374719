import React, { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";

import { useWorkOrderRequest } from "context/WorkOrderRequestContext";
import { useH2Tools } from "context/H2ToolsContext";
import showNoAccessModal from "utils/showNoAccessModal";

import { ReactComponent as ToggleBar } from "assets/quickmenu-toggle.svg";
import { ReactComponent as ShiftScheduleIcon } from "assets/shiftschedule-icon.svg";
import { ReactComponent as WeatherIcon } from "assets/weather-icon.svg";
import { ReactComponent as OperationEventIcon } from "assets/fracas-icon.svg";
import { ReactComponent as WorkOrderRequestIcon } from "assets/cmms-icon.svg";
import { ReactComponent as SafetyIcon } from "assets/safety-icon.svg";
import { ReactComponent as H2ToolsIcon } from "assets/electrolyzer-&-h2-export.svg";
import {
  CloseOutlined,
  ContactsOutlined,
  FormOutlined,
  LeftOutlined,
  MailOutlined,
  PlusOutlined,
  QrcodeOutlined,
  RightOutlined,
} from "@ant-design/icons";

import { Tooltip, App } from "antd";
import { isProductApm } from "utils/auth-utils";

import styles from "./QuickMenu.module.less";
import { useUser } from "../../context/UserContext";

const isProduct = isProductApm();

const QuickMenuToggle = ({ toggleMenu, toggled }) => (
  <div className={styles.toggleContainer} onClick={toggleMenu} aria-hidden>
    <ToggleBar />
    {!toggled && <LeftOutlined className={styles.toggleIcon} />}
    {toggled && <RightOutlined className={styles.toggleIcon} />}
  </div>
);

const MenuItems = ({ toggled, toggle }) => {
  const { openWorkOrderRequest } = useWorkOrderRequest();
  const { openH2Tools } = useH2Tools();
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const { currentOrganization } = useUser();

  let items = [
    {
      icon: <WorkOrderRequestIcon className={styles.menuItemIcon} />,
      tooltip: t({ id: "quickmenu.work-request", message: "Work Request" }),
      color: "#FF7950",
      onClick: () => {
        openWorkOrderRequest();
        toggle();
      },
    },
    !isProduct && {
      icon: <SafetyIcon className={styles.menuItemIcon} />,
      tooltip: t({ id: "quickmenu.safety-incident-report", message: "Safety Incident Report" }),
      color: "#FAAD14",
      onClick: () => {
        navigate("/incident-reports/new");
        toggle();
      },
    },
    !isProduct && {
      icon: <H2ToolsIcon className={styles.menuItemIcon} />,
      tooltip: t({ id: "quickmenu.h2-tools", message: "Hydrogen Safety Question" }),
      color: "#0345A7",
      onClick: openH2Tools,
    },
  ].filter(Boolean);
  if (currentOrganization === "demo") {
    items = [
      {
        icon: <PlusOutlined className={styles.menuItemIcon} />,
        tooltip: t({ id: "quickmenu.add", message: "Quick Add" }),
        color: "#F56138",
        submenuItems: [
          {
            icon: <WorkOrderRequestIcon className={styles.menuItemIcon} />,
            tooltip: t({ id: "quickmenu.work-order-request", message: "Work Order Request" }),
            color: "#FF7950",
            onClick: () => {
              openWorkOrderRequest();
              toggle();
            },
          },
          {
            icon: <OperationEventIcon className={styles.menuItemIcon} />,
            tooltip: t({ id: "quickmenu.record-operation-event", message: "Record Operation Event" }),
            color: "#F56138",
            onClick: () =>
              showNoAccessModal(modal, { name: "Record Operation Event", locked: true, source: "QuickMenu" }),
          },
          {
            icon: <SafetyIcon className={styles.menuItemIcon} />,
            tooltip: t({ id: "quickmenu.safety-incident-report", message: "Safety Incident Report" }),
            color: "#F56138",
            onClick: () => {
              navigate("/incident-reports/new");
              toggle();
            },
          },
        ],
      },
      {
        icon: <H2ToolsIcon className={styles.menuItemIcon} />,
        tooltip: t({ id: "quickmenu.h2-tools", message: "Hydrogen Safety Question" }),
        color: "#0345A7",
        onClick: openH2Tools,
      },
      {
        icon: <MailOutlined className={styles.menuItemIcon} />,
        tooltip: t({ id: "quickmenu.message", message: "Message" }),
        color: "#1890FF",
        onClick: () => showNoAccessModal(modal, { name: "Message", locked: true, source: "QuickMenu" }),
      },
      {
        icon: <FormOutlined className={styles.menuItemIcon} />,
        tooltip: t({ id: "quickmenu.quick-note", message: "Quick Note" }),
        color: "#008B8A",
        onClick: () => showNoAccessModal(modal, { name: "Quick Note", locked: true, source: "QuickMenu" }),
      },
      {
        icon: <QrcodeOutlined className={styles.menuItemIcon} />,
        tooltip: t({ id: "quickmenu.scan-asset-tag", message: "Scan Asset Tag" }),
        color: "#FAAD14",
        onClick: () => showNoAccessModal(modal, { name: "Scan Asset Tag", locked: true, source: "QuickMenu" }),
      },
      {
        icon: <ContactsOutlined className={styles.menuItemIcon} />,
        tooltip: t({ id: "quickmenu.emergency-contacts", message: "Emergency Contacts" }),
        color: "#FF0F0F",
        onClick: () => showNoAccessModal(modal, { name: "Emergency Contacts", locked: true, source: "QuickMenu" }),
      },
      {
        icon: <ShiftScheduleIcon className={styles.menuItemIcon} viewBox="0 0 22 22" />,
        tooltip: t({ id: "quickmenu.shift-schedule", message: "Shift Schedule" }),
        color: "#52C41A",
        onClick: () => showNoAccessModal(modal, { name: "Shift Schedule", locked: true, source: "QuickMenu" }),
      },
      {
        icon: <WeatherIcon className={styles.menuItemIcon} viewBox="0 0 20 20" />,
        tooltip: t({ id: "quickmenu.local-weather", message: "Local Weather" }),
        color: "#7D50FF",
        onClick: () => showNoAccessModal(modal, { name: "Local Weather", locked: true, source: "QuickMenu" }),
      },
    ];
  }

  return (
    <div className={styles.menu}>
      {items.map((item) => (
        <MenuItem data={item} key={item.tooltip} toggled={toggled} />
      ))}
    </div>
  );
};

const SubMenu = ({ items, open, children }) => (
  <div className={styles.submenuContainer}>
    {children}
    <div
      className={styles.submenu}
      style={
        open
          ? { opacity: 1, transform: "translate(0, -50%)", pointerEvents: "all" }
          : { opacity: 0, transform: "translate(50px, -50%)", pointerEvents: "none" }
      }
    >
      {items.map((item) => (
        <MenuItem data={item} key={item.tooltip} />
      ))}
    </div>
  </div>
);

const MenuItem = ({ data, toggled }) => {
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const handleOpenSubmenu = () => {
    setOpenSubmenu((i) => !i);
  };

  useEffect(() => {
    if (openSubmenu && !toggled) {
      handleOpenSubmenu();
    }
  }, [toggled, openSubmenu]);

  return data.submenuItems ? (
    <SubMenu items={data.submenuItems} open={openSubmenu && toggled}>
      <Tooltip
        placement="left"
        color="white"
        overlayInnerStyle={{ color: data.color }}
        {...(!openSubmenu && { title: data.tooltip })}
      >
        <button className={styles.menuItem} style={{ color: data.color }} onClick={handleOpenSubmenu} type="button">
          {openSubmenu ? <CloseOutlined className={styles.menuItemIcon} /> : data.icon}
        </button>
      </Tooltip>
    </SubMenu>
  ) : (
    <Tooltip title={data.tooltip} placement="left" color="white" overlayInnerStyle={{ color: data.color }}>
      <button className={styles.menuItem} style={{ color: data.color }} onClick={data.onClick} type="button">
        {data.icon}
      </button>
    </Tooltip>
  );
};

export default function QuickMenu() {
  const [toggled, setToggle] = useState(false);

  const toggleMenu = () => {
    setToggle((current) => !current);
  };

  return (
    <div className={styles.container}>
      <div className={styles.drawer} style={toggled ? { transform: "translateX(0)" } : {}}>
        <QuickMenuToggle toggleMenu={toggleMenu} toggled={toggled} />
        <MenuItems toggled={toggled} toggle={toggleMenu} />
      </div>
    </div>
  );
}
