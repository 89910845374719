import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, Tooltip, Button } from "antd";
import { t } from "@lingui/macro";

import supersetLogoUrl from "assets/superset-logo.svg";
import { getOrgFromSubdomain } from "utils/auth-utils";
import Card from "components/Card";
import SupersetPage from "./components/SupersetPage";
import styles from "./BITools.module.less";

const org = getOrgFromSubdomain();
const domain = process.env.REACT_APP_GRAFANA_DOMAIN || "betterh2.com";

const SupersetButton = () => {
  const url = `https://${org}.superset.${domain}`;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Tooltip title="Open Superset in new tab" placement="topRight">
        <Button shape="circle">
          <img src={supersetLogoUrl} alt="superset" width={26} height={26} />
        </Button>
      </Tooltip>
    </a>
  );
};

export default function BITools() {
  const [iframeKey, setIframeKey] = useState(0);
  const { tab } = useParams();
  const navigate = useNavigate();

  const items = [
    {
      key: "dashboards",
      label: t({ id: "bi-tools.dashboards", message: "Dashboards" }),
      children: <SupersetPage iframeKey={`dashboards-${iframeKey}`} supersetPath="/dashboard/list/" />,
    },
    {
      key: "charts",
      label: t({ id: "bi-tools.charts", message: "Charts" }),
      children: <SupersetPage iframeKey={`charts-${iframeKey}`} supersetPath="/chart/list/" />,
    },
    {
      key: "datasets",
      label: t({ id: "bi-tools.datasets", message: "Datasets" }),
      children: <SupersetPage iframeKey={`datasets-${iframeKey}`} supersetPath="/tablemodelview/list/" />,
    },
    {
      key: "sql",
      label: t({ id: "bi-tools.sql", message: "SQL" }),
      children: <SupersetPage iframeKey={`sql-${iframeKey}`} supersetPath="/superset/sqllab/" />,
    },
    {
      key: "upload-data",
      label: t({ id: "bi-tools.upload-data", message: "Upload Data" }),
      children: <SupersetPage iframeKey={`upload-data-${iframeKey}`} supersetPath="/csvtodatabaseview/form" />,
    },
  ];

  return (
    <Card className={styles.container}>
      <div className={styles.linkButton}>
        <SupersetButton />
      </div>
      <Tabs
        className={styles.tabs}
        activeKey={tab}
        items={items}
        onTabClick={(tabName) => {
          // Reload iframe when changing tab
          navigate(`/business-intelligence/${tabName}`);
          setIframeKey(iframeKey + 1);
        }}
      />
    </Card>
  );
}
