import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "@lingui/macro";
import mixpanel from "mixpanel-browser";

import useCustomMessage from "hooks/useCustomMessage";
import { useCreatePanel } from "hooks/apiService";
import PanelForm from "components/PanelForm";

function AddPanel() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [createPanel] = useCreatePanel();
  const { message } = useCustomMessage();

  useEffect(() => {
    mixpanel.track("Dashboard Add Panel Page Viewed", {
      "Dashboard ID": id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async ({ measureIds, formValues, time, pollInterval, customAxes }) => {
    try {
      await createPanel({
        dashboardId: id,
        input: {
          ...formValues,
          measureIds,
          type: "LINE_CHART",
          time,
          pollInterval,
          customAxes,
        },
      });
      mixpanel.track("Dashboard Panel Added", {
        "Dashboard ID": id,
      });
      navigate(-1);
    } catch (err) {
      message.error(
        t({
          id: "dashboard.errors.add-panel",
          message: "Failed to create panel",
        })
      );
    }
  };
  return <PanelForm isNew onSave={onSave} />;
}

export default AddPanel;
