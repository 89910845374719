/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
export function computeRowSpan(tableData) {
  let lastAssetId = null;
  let rowSpanCounter = 0;

  tableData.forEach((row, index) => {
    if (row.assetId !== lastAssetId) {
      if (lastAssetId !== null) {
        tableData[index - rowSpanCounter].rowSpan = rowSpanCounter;
      }
      lastAssetId = row.assetId;
      rowSpanCounter = 1;
    } else {
      rowSpanCounter++;
      row.rowSpan = 0;
    }
  });

  if (rowSpanCounter > 1) {
    tableData[tableData.length - rowSpanCounter].rowSpan = rowSpanCounter;
  }

  return tableData;
}
