import * as React from "react";

const EgnyteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="logosandtypes_com"
    data-name="logosandtypes com"
    viewBox="0 0 150 150"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill-rule:evenodd;fill:#32bdb5}"}</style>
    </defs>
    <path
      d="M0 0h150v150H0V0Z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M79.63 40.02h-8.9c-.6 0-1.09-.49-1.09-1.1V4.45c0-.6.49-1.1 1.09-1.1h8.9c.6 0 1.09.49 1.09 1.1v34.47c0 .6-.49 1.1-1.09 1.1ZM98.35 88.21l4.44-7.76c.3-.52.96-.71 1.49-.41l29.63 17.23c.51.3.7.97.41 1.51l-4.44 7.76c-.3.52-.96.71-1.49.41L98.76 89.72c-.53-.32-.7-.99-.41-1.51ZM47.57 80.45l4.44 7.76c.3.52.13 1.2-.41 1.51l-29.63 17.23c-.51.3-1.2.13-1.49-.41l-4.44-7.76c-.3-.52-.13-1.2.41-1.51l29.63-17.23c.51-.3 1.2-.13 1.49.41Z"
      className="cls-2"
    />
    <path
      d="m131.26 47.63-9.2-16.04a1.971 1.971 0 0 0-2.71-.73l-44.18 25.7-44.19-25.7c-.94-.56-2.16-.21-2.71.73l-9.2 16.04c-.56.95-.21 2.17.73 2.73l44.18 25.7v51.4c0 1.1.87 2 1.98 2h18.38c1.09 0 1.99-.88 1.99-2v-51.4l44.18-25.7c.96-.56 1.28-1.78.75-2.73Z"
      style={{
        fillRule: "evenodd",
        fill: "#0b1f34",
      }}
    />
  </svg>
);
export default EgnyteIcon;
