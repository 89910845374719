import React from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import ResponsivePlot from "components/ResponsivePlot";
import styles from "./PanelModal.module.less";

function PanelModal({ open, setOpen, data }) {
  const dates = data
    ? Array.from({ length: data.data.length }, (_, i) =>
        dayjs().subtract(i, "days").format("YYYY-MM-DD HH:mm:ss")
      ).reverse()
    : [];

  return (
    <Modal
      title={data?.title}
      open={open}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      centered
      width="80vw"
      onCancel={() => setOpen(false)}
    >
      <div className={styles.panelContainer}>
        {data && (
          <ResponsivePlot
            data={[
              {
                x: dates,
                y: data.data,
                mode: "lines",
                type: "scatter",
                line: {
                  color: "#008b8a",
                },
              },
            ]}
            layout={{
              hovermode: "x unified",
              xaxis: {
                type: "date",
                range: [dates[0], dates[dates.length - 1]],
                ticklabelposition: "outside bottom",
                ticklabeloverflow: "allow",
                rangeslider: {
                  thickness: 0.1,
                },
                automargin: false,
              },
              yaxis: {
                fixedrange: true,
                title: {
                  text: data.yAxisTitle,
                },
                margin: 30,
                tickprefix: "  ",
                ticksuffix: data.unit,
              },

              legend: {
                cursor: "default",
              },
            }}
            config={{ displayModeBar: false, responsive: true }}
          />
        )}
      </div>
    </Modal>
  );
}

export default PanelModal;
