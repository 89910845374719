import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_FAVORITE } from "graphql/apiService";

export default function useDeleteFavorite() {
  const { currentOrganization } = useUser();

  const [deleteFavoriteMutation, { loading, error }] = useMutation(DELETE_FAVORITE, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetDashboardsByOrganization", "GetMyFavorites"],
  });

  const deleteFavorite = async ({ itemType, itemId }) => {
    const res = await deleteFavoriteMutation({ variables: { input: { itemType, itemId } } });
    return res.data.deleteFavorite;
  };

  return [deleteFavorite, { loading, error }];
}
