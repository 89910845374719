import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Mention, MentionsInput } from "react-mentions";
import dayjs from "dayjs";
import { t, Trans } from "@lingui/macro";
import mixpanel from "mixpanel-browser";

import { useCreateAnnotation } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Button, Form, Input, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { singleEquipment } from "../../helpers/annotation.helpers";

import styles from "./NewAnnotation.module.less";

export default function NewAnnotation({
  annotation,
  setActiveAnnotation,
  setAnnotations,
  users,
  panel,
  zoomStartTime,
  zoomEndTime,
  onClose,
}) {
  const { panelId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dashboardId = searchParams.get("dashboardId");
  const { message } = useCustomMessage();

  const [createAnnotation, { loading: loadingCreation }] = useCreateAnnotation();
  const [currentMessage, setCurrentMessage] = useState("");
  const usersMap = users.reduce(
    (result, user) => ({
      [user.auth0Id]: user.name,
      ...result,
    }),
    {}
  );

  const handleNewAnnotationName = (e) => {
    setAnnotations((a) => a.map((an) => (an.new ? { ...an, title: e.target.value } : an)));
  };
  const handleSaveAnnotation = async (e) => {
    try {
      const { message: firstMessage, measures: measureIds, title } = e;
      const timeRange = { from: zoomStartTime, to: zoomEndTime };

      const newAnnotation = await createAnnotation({
        dashboardId,
        firstMessage,
        measureIds: measureIds?.length ? measureIds : [],
        panelId,
        time: annotation.time,
        timeRange,
        title,
      });
      setAnnotations((a) => a.map((an) => (an.new ? newAnnotation : an)));
      searchParams.set("annotationId", newAnnotation.id);
      setSearchParams(searchParams, { replace: true });
      setActiveAnnotation(newAnnotation);
      mixpanel.track("Annotation Created", {
        "Annotation ID": newAnnotation.id,
        "Panel ID": panelId,
        "Dashboard ID": dashboardId,
        "Measure IDs": measureIds?.length ? measureIds : [],
        Time: annotation.time,
        Title: title,
        "First Message": firstMessage,
      });
    } catch {
      message.error(
        t({
          id: "panel.annotation.errors.failed-annotation-creation",
          message: "Failed to create annotation",
        })
      );
    }
  };

  return (
    <>
      <div className={styles.annotationHeaderNew}>
        <h5 className={styles.annotationHeaderTitle}>Add Annotation</h5>
        <div className={styles.closeAnnotation} onClick={onClose} aria-hidden>
          <CloseOutlined />
        </div>
      </div>
      <div className={styles.annotationContent}>
        <Form layout="vertical" onFinish={handleSaveAnnotation}>
          <Form.Item
            name="timestamp"
            label={t({
              id: "panel.annotation.form.timestamp.label",
              message: "Timestamp",
            })}
            tooltip={t({
              id: "panel.annotation.form.timestamp.tooltip",
              message: "The time point of the annotation",
            })}
            initialValue={dayjs(annotation.time).format("MM-DD-YYYY HH:mm:ss")}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="title"
            label={t({
              id: "panel.annotation.form.title.label",
              message: "Annotation Title",
            })}
            tooltip={t({
              id: "panel.annotation.form.title.tooltip",
              message: "This will be shown on the plot and is a short description of the annotation",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "panel.annotation.form.rules.title",
                  message: "Please write a short description of the annotation",
                }),
              },
            ]}
          >
            <Input
              placeholder={t({
                id: "panel.annotation.form.title.placeholder",
                message: "Short annotation description",
              })}
              onBlur={handleNewAnnotationName}
              showCount
              maxLength={30}
            />
          </Form.Item>
          <Form.Item
            name="message"
            label={t({
              id: "panel.annotation.form.message.label",
              message: "Add Message",
            })}
            tooltip={t({
              id: "panel.annotation.form.message.tooltip",
              message: "You can add a first message and notify colleagues by highlighting them",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "panel.annotation.form.rules.first-message",
                  message: "Please write a first message to give more details",
                }),
              },
            ]}
          >
            <MentionsInput
              className="mentions"
              value={currentMessage}
              onChange={(ev, newValue) => setCurrentMessage(newValue)}
              placeholder={t({
                id: "panel.annotation.form.first-message.placeholder",
                message: "Mention people using '@'",
              })}
              a11ySuggestionsListLabel={t({
                id: "panel.annotation.form.mention-suggestion-list-label",
                message: "Suggested mentions",
              })}
              allowSuggestionsAboveCursor
              allowSpaceInQuery
            >
              <Mention
                appendSpaceOnAdd
                className={styles.mentionHighlight}
                markup="(user:__id__)"
                trigger="@"
                data={users.map(({ auth0Id, name }) => ({ id: auth0Id, display: name }))}
                displayTransform={(userId) => `@${usersMap[userId]}`}
                renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                  <div className={`user ${focused ? "focused" : ""}`}>{highlightedDisplay}</div>
                )}
              />
            </MentionsInput>
          </Form.Item>
          <Form.Item
            name="measures"
            label={t({
              id: "panel.annotation.form.measures.label",
              message: "Measures",
            })}
            tooltip={t({
              id: "panel.annotation.form.measures.tooltip",
              message: "Select the measures this annotation applies to",
            })}
          >
            <Select
              mode="multiple"
              options={panel.measures.map((measure) => ({
                label: `${measure.description || measure.slug}${
                  !singleEquipment({ measures: panel.measures }) ? ` (${measure.equipment.name})` : ""
                }`,
                value: measure.id,
              }))}
              placeholder={t({
                id: "panel.annotation.form.measures.placeholder",
                message: "Select the measures",
              })}
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <div className={styles.actionButtons}>
              <Button type="primary" htmlType="submit" loading={loadingCreation}>
                <Trans id="panel.annotation.form.button.save">Save</Trans>
              </Button>
              <Button onClick={onClose}>
                <Trans id="panel.annotation.form.button.cancel">Cancel</Trans>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
