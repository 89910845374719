import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { useMediaQuery } from "react-responsive";
import { useUser } from "context/UserContext";
import { modules as modulesFn } from "config/modules";
import { useGetMyUserAccount } from "hooks/apiService";

import { App, Carousel, Spin } from "antd";
import { ReactComponent as CarouselArrowLeft } from "assets/carousel-arrow-left.svg";
import { ReactComponent as CarouselArrowRight } from "assets/carousel-arrow-right.svg";

import bannerBackground from "assets/banner.svg";

import showNoAccessModal from "utils/showNoAccessModal";
import { isCustomerApm, isServiceApm, isProductApm } from "utils/auth-utils";

import FavoriteDashboards from "./components/FavoriteDashboards";

import styles from "./Home.module.less";

const env = process.env.REACT_APP_ENV || "dev";
const isCustomer = isCustomerApm();
const isService = isServiceApm();
const isProduct = isProductApm();

function Home() {
  const { features, hasPermissions, currentOrganization, recentModules, loading, grafanaOrgId } = useUser();
  const { myUserAccount } = useGetMyUserAccount();
  const [PlantModel, setPlantModel] = useState(null);

  const { modal } = App.useApp();
  const allModules = modulesFn();

  useEffect(() => {
    if (isProduct) {
      import("./components/PlantModel").then((module) => {
        setPlantModel(() => module.default);
      });
    }
  }, []);

  const isXlScreen = useMediaQuery({
    query: "(min-width: 1800px)",
  });

  const isLgScreen = useMediaQuery({
    query: "(min-width: 1400px)",
  });

  const isMdScreen = useMediaQuery({
    query: "(min-width: 960px)",
  });

  const isSmScreen = useMediaQuery({
    query: "(min-width: 700px)",
  });

  const isXsScreen = useMediaQuery({
    query: "(min-width: 576px)",
  });

  let modulesToShow = 2;
  if (isXlScreen) {
    modulesToShow = 8;
  } else if (isLgScreen) {
    modulesToShow = 6;
  } else if (isMdScreen) {
    modulesToShow = 5;
  } else if (isSmScreen) {
    modulesToShow = 4;
  } else if (isXsScreen) {
    modulesToShow = 3;
  }

  let homeModules;

  const defaultRecentModules = [
    "notifications",
    "overview",
    "assets",
    "dashboards",
    "incident-reports",
    "electrolyzer-simulation",
    "roles-admin",
    "work-requests",
  ];

  if (isCustomer) {
    homeModules = ["system-overview", "assets", "alarm-analytics", "document-center", "service-support", "help-center"];
  } else if (isService) {
    homeModules = ["system-overview", "dashboards", "assets", "alarm-analytics", "document-center", "work-requests"];
  } else {
    homeModules = recentModules?.length ? recentModules : defaultRecentModules;
  }

  const hasModuleAccess = (module) => {
    if (!module) {
      return false;
    }
    const { permissions, globalPermissions, requiredFeature, restrictedToOrgs, restrictedToEnvs, disabledInOrgs } =
      module;
    if (permissions && !hasPermissions(permissions)) {
      return false;
    }
    if (requiredFeature && !features?.includes(requiredFeature)) {
      return null;
    }
    if (globalPermissions && !hasPermissions(globalPermissions, true)) {
      return false;
    }
    if (restrictedToEnvs?.length && !restrictedToEnvs?.includes(env)) {
      return false;
    }
    if (restrictedToOrgs?.length && !restrictedToOrgs.includes(currentOrganization)) {
      return false;
    }
    if (restrictedToOrgs?.length && !restrictedToOrgs.includes(currentOrganization)) {
      return false;
    }
    if (disabledInOrgs?.length && disabledInOrgs.includes(currentOrganization)) {
      return false;
    }
    return true;
  };
  const modules = homeModules.map((key) => allModules[key]).filter((module) => hasModuleAccess(module));
  // eslint-disable-next-line no-unused-vars
  const ArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div {...props}>
      <CarouselArrowLeft className={styles.arrow} />
    </div>
  );
  // eslint-disable-next-line no-unused-vars
  const ArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div {...props}>
      <CarouselArrowRight className={styles.arrow} />
    </div>
  );

  const renderModules = () =>
    modules.map((module) => {
      const { name, link, externalLink, icon: Icon, color, locked, key } = module;
      const ModuleCard = () => (
        <div key={key} className={styles.appCard}>
          <div
            className={styles.cardContent}
            style={{ color }}
            aria-hidden
            onClick={() => {
              if (locked) showNoAccessModal(modal, { name, locked, source: "Home" });
            }}
          >
            <Icon className={styles.appIcon} />
            <div className={styles.appName}>{name}</div>
          </div>
        </div>
      );
      const externalUrl = key === "grafana" ? `${externalLink}?orgId=${grafanaOrgId}` : externalLink;
      return locked ? (
        <div className={styles.cardContainer} key={key}>
          <ModuleCard />
        </div>
      ) : (
        <div className={styles.cardContainer} key={key}>
          {externalLink ? (
            <a target="_blank" href={externalUrl} rel="noreferrer" style={{ width: "100%" }}>
              <ModuleCard />
            </a>
          ) : (
            <Link to={{ pathname: link }} key={key} style={{ width: "100%" }}>
              <ModuleCard />
            </Link>
          )}
        </div>
      );
    });
  const renderContent = () => {
    if (isProduct) {
      return (
        <div className={styles.customerContainer}>
          <div className={styles.plantContainer}>
            <div className={styles.plantTitle}>Customer Address</div>
            <div className={styles.modelContainer}>{PlantModel ? <PlantModel /> : <Spin />}</div>
          </div>
        </div>
      );
    }
    return (
      <>
        <section className={styles.recentModules}>
          {!loading && (
            <Carousel
              arrows
              dots={false}
              infinite={false}
              slidesToShow={Math.min(modulesToShow, modules?.length)}
              nextArrow={<ArrowRight />}
              prevArrow={<ArrowLeft />}
            >
              {renderModules()}
            </Carousel>
          )}
        </section>
        <section className={styles.contentSection}>
          <div className={styles.favoritesTitle}>
            <Trans id="home.favorites.title">My Favorites</Trans>
          </div>
          <FavoriteDashboards />
        </section>
      </>
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.topBanner} style={{ backgroundImage: `url(${bannerBackground})` }} />
      {loading && (
        <div className={styles.spinContainer}>
          <Spin />
        </div>
      )}
      <div className={styles.bannerText} style={loading ? { visibility: "hidden" } : undefined}>
        <div className={styles.bannerTitle}>
          <Trans id="home.banner.title">Welcome to APM, {myUserAccount?.name?.split(" ")?.[0]}</Trans>
        </div>
        <div className={styles.bannerSubtitle}>
          {!isProduct && <Trans id="home.banner.subtitle">Recently Visited Modules</Trans>}
        </div>
      </div>
      {renderContent()}
    </div>
  );
}

export default Home;
