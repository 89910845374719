import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_THREAD_READ_STATE } from "graphql/apiService";

export default function useUpdateThreadReadState() {
  const { currentOrganization } = useUser();

  const [updateThreadReadStateMutation, { loading, error }] = useMutation(UPDATE_THREAD_READ_STATE, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateThreadReadState = async ({ input }) => {
    const res = await updateThreadReadStateMutation({ variables: { input } });
    return res.data.updateThreadReadState;
  };

  return [updateThreadReadState, { loading, error }];
}
