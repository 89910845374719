import React from "react";
import { Layout } from "antd";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import styles from "./GlobalFooter.module.less";

const { Footer } = Layout;

function GlobalFooter({ containerStyle = {}, textPrimary = "#7e7e7e", textSecondary = "#ababab" }) {
  return (
    <Footer style={containerStyle}>
      <div className={styles.copyright} style={{ color: textPrimary }}>
        <Trans id="footer.copyright">
          Copyright © {new Date().getFullYear()} Electric Hydrogen<sup>®</sup>, Co.
        </Trans>
      </div>
      <div className={styles.links} style={{ color: textSecondary }}>
        <Link to="/privacy">
          <Trans id="footer.links.privacy">Privacy</Trans>
        </Link>
        <Link to="/terms-of-service">
          <Trans id="footer.links.terms-of-service">Terms Of Service</Trans>
        </Link>
      </div>
    </Footer>
  );
}

export default GlobalFooter;
