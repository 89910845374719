/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { SizeMe } from "react-sizeme";
import { v4 as uuidv4 } from "uuid";
import { t, Trans } from "@lingui/macro";
import dayjs from "dayjs";
import mixpanel from "mixpanel-browser";

import captureException from "utils/capture-sentry-exception";

import {
  useAnnotations,
  useGetAnnotation,
  usePanel,
  useUpdatePanel,
  useDeletePanel,
  useUserOrganizationProfiles,
} from "hooks/apiService";
import usePanelTime from "hooks/usePanelTime";
import useCustomMessage from "hooks/useCustomMessage";
import { useWorkOrderRequest } from "context/WorkOrderRequestContext";
import { useUser } from "context/UserContext";

import { ArrowLeftOutlined, FormOutlined, MoreOutlined, UnorderedListOutlined } from "@ant-design/icons";

import { Button, Dropdown, App, Card as AntdCard, Tooltip, Switch, Breadcrumb } from "antd";
import Card from "components/Card";
import PanelComponent from "components/Panel";
import TimeSelectorAdvanced from "components/TimeSelectorAdvanced";
import WorkOrderRequestDrawer from "components/WorkOrderRequestDrawer";

import AnnotationPane from "./components/AnnotationPane";

import styles from "./Panel.module.less";

export default function Panel() {
  const { panelId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const annotationId = searchParams.get("annotationId");
  const dashboardId = searchParams.get("dashboardId");
  const addAnnotationParam = searchParams.get("addAnnotation");
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ maxWidth: 575 });
  const { modal } = App.useApp();
  const { message } = useCustomMessage();
  const { hasPermissions } = useUser();

  const { mount, unmount, workOrderVisibility, openWorkOrderRequest, closeWorkOrderRequest } = useWorkOrderRequest();

  const panelRef = useRef();

  const { panel, loading, error: panelError } = usePanel({ id: panelId });
  const [deletePanel] = useDeletePanel();
  const [updatePanel] = useUpdatePanel();
  const [saving, setSaving] = useState(false);
  const { showLegend } = panel || {};
  const legendSwitchTooltip = showLegend ? "Hide Legend" : "Show Legend";

  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    timeInterval,
    setTimeInterval,
    timeMode,
    setTimeMode,
    pollInterval,
    setPollInterval,
    refetch,
    setRefetch,
    handleOnRelayout,
    initializeTime,
    zoomStartTime,
    zoomEndTime,
  } = usePanelTime({ panel });

  const { annotations: annotationsFromAPI } = useAnnotations({ panelId });
  const [getAnnotation] = useGetAnnotation();
  const [addAnnotation, setAddAnnotation] = useState(false);
  const [activeAnnotation, setActiveAnnotation] = useState(undefined);
  const [annotations, setAnnotations] = useState([]);
  const [listView, setListView] = useState(false);

  const { userOrganizationProfiles, error: usersError } = useUserOrganizationProfiles();
  const users = useMemo(
    () =>
      (userOrganizationProfiles &&
        userOrganizationProfiles.map(({ userAccount }) => userAccount).sort((a, b) => a.name.localeCompare(b.name))) ||
      [],
    [userOrganizationProfiles]
  );

  useEffect(() => {
    if (annotationsFromAPI) {
      setAnnotations(annotationsFromAPI);
    }
  }, [annotationsFromAPI]);

  const handleAnnotationLink = async () => {
    try {
      const annotation = await getAnnotation({ id: annotationId });
      setActiveAnnotation(annotation);
      if (zoomStartTime && zoomEndTime && dayjs(annotation.time).isBetween(zoomStartTime, zoomEndTime)) {
        return;
      }
      const { from, to } = annotation.timeRange;
      initializeTime({
        type: "HISTORICAL",
        from,
        to,
      });
    } catch (err) {
      captureException(err);
      message.error({
        key: "failed-get-annotation-message",
        content: t({
          id: "panel.get-annotation-error",
          message: "Failed to get panel annotation",
        }),
      });
    }
  };

  const handleViewAllAnnotations = () => {
    setListView(true);
    setActiveAnnotation(undefined);
    searchParams.delete("annotationId");
    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    mixpanel.track("Panel Page Viewed", {
      "Panel ID": panelId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addAnnotationParam) {
      setAddAnnotation(true);
    }
  }, [addAnnotationParam]);

  useEffect(() => {
    // Mounting and unmounting Work Order Request Drawer
    mount();
    return () => {
      unmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (annotationId) {
      handleAnnotationLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationId]);

  useEffect(() => {
    if (panelError) {
      captureException(panelError);
      message.error(
        t({
          id: "panel.errors.get-panel",
          message: "Failed to get panel",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelError]);

  const deleteModal = () => {
    modal.warning({
      title: t({
        id: "panels.panel.delete-modal.title",
        message: "Delete Panel",
      }),
      content: t({
        id: "panels.panel.delete-modal.content",
        message: "Are you sure you want to delete this Panel?",
      }),
      closable: true,
      maskClosable: true,
      onOk() {
        handleDelete();
      },
      okText: t({
        id: "panels.panel.delete-modal.confirm-button",
        message: "Delete",
      }),
      okType: "danger",
    });
  };
  const handleDelete = async () => {
    try {
      await deletePanel({ id: panelId });
      navigate("/equipment");
    } catch (error) {
      captureException(error);
      message.error(
        t({
          id: "panels.errors.delete-panel",
          message: "Failed to delete panel",
        })
      );
    }
  };

  const handleSaveTime = async () => {
    setSaving(true);
    try {
      await updatePanel({
        input: {
          id: panelId,
          pollInterval,
          time: {
            type: timeMode,
            from: startTime,
            to: endTime,
            interval: timeInterval,
          },
        },
      });
      message.success(
        t({
          id: "panels.success.save-panel",
          message: "Successfully saved panel time",
        })
      );
    } catch (err) {
      message.error(
        t({
          id: "panels.errors.save-panel",
          message: "Failed to save panel",
        })
      );
    } finally {
      setSaving(false);
    }
  };

  const handleAddAnnotation = (e) => {
    setAddAnnotation(e);
    mixpanel.track("Annotation Add Button Clicked", {
      "Panel ID": panelId,
    });
  };
  const handleClickAnnotation = (e) => {
    const {
      annotation: { id },
    } = e;
    setActiveAnnotation(annotations.find((annotation) => annotation.id === id));
    searchParams.set("annotationId", id);
    setSearchParams(searchParams, { replace: true });
    mixpanel.track("Annotation Opened", {
      "Annotation ID": id,
      "Panel ID": panelId,
    });
  };

  const handleClick = (e) => {
    if (addAnnotation) {
      const { points } = e;
      const time = dayjs(points[0].x).toISOString();
      const id = uuidv4();
      const newAnnotation = {
        id,
        time,
        status: "ACTIVE",
        new: true,
      };
      setActiveAnnotation(newAnnotation);
      setAnnotations((a) => [...a.filter((an) => !an.new), newAnnotation]);
      setAddAnnotation(false);
      mixpanel.track("Annotation Adding Started", {
        "Panel ID": panelId,
      });
    }
  };

  useEffect(() => {
    if (usersError) {
      message.error("Failed to get users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersError]);

  const dropdownMenuItems = [
    {
      key: "annotations",
      onClick: handleViewAllAnnotations,
      label: (
        <span className={styles.menuButton}>
          <Trans id="panels.panel.dropdown.view-all-annotations">View All Annotations</Trans>
        </span>
      ),
    },
    hasPermissions(["update:panels"]) && {
      key: "edit",
      label: (
        <Link to={{ pathname: `/panels/${panel?.id}/edit` }} state={{ fromPanel: true }}>
          <span className={styles.menuButton}>
            <Trans id="panels.panel.dropdown.edit-button">Edit</Trans>
          </span>
        </Link>
      ),
    },
    hasPermissions(["create:work_orders"]) && {
      key: "wo-request",
      onClick: openWorkOrderRequest,
      label: (
        <span className={styles.menuButton}>
          <Trans id="panels.panel.dropdown.create-work-order-request">Attach to Work Order Request</Trans>
        </span>
      ),
    },
    hasPermissions(["delete:panels"]) && {
      key: "remove",
      onClick: deleteModal,
      danger: true,
      label: (
        <span className={styles.menuButton}>
          <Trans id="panels.panel.dropdown.remove-button">Remove</Trans>
        </span>
      ),
    },
  ];

  const renderHeader = () =>
    isSmallScreen ? (
      <div className={styles.plotHeaderMobile}>
        <div className={styles.headerTop}>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              navigate(dashboardId ? `/dashboards/${dashboardId}` : -1);
            }}
          >
            <Trans id="panel.back-button">Back</Trans>
          </Button>
          <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <Tooltip title={legendSwitchTooltip}>
              <Switch
                checkedChildren={<UnorderedListOutlined />}
                unCheckedChildren={<UnorderedListOutlined />}
                checked={showLegend}
                onChange={(checked) => updatePanel({ input: { showLegend: checked } })}
              />
            </Tooltip>
            {hasPermissions(["create:annotations"]) && (
              <Button className={styles.addAnnotation} type="primary" ghost>
                <FormOutlined />
              </Button>
            )}
            {hasPermissions(["update:panels"]) && (
              <Button onClick={handleSaveTime} loading={saving} type="primary">
                <Trans id="panels.save">Save</Trans>
              </Button>
            )}
            {hasPermissions(["create:work_orders", "update:panels", "delete:panels"], { mode: "some" }) && (
              <Dropdown menu={{ items: dropdownMenuItems }} trigger={["click"]} placement="bottomRight">
                <MoreOutlined className={styles.icon} />
              </Dropdown>
            )}
          </div>
        </div>
        <TimeSelectorAdvanced
          {...{
            setStartTime,
            setEndTime,
            startTime,
            endTime,
            timeInterval,
            setTimeInterval,
            timeMode,
            setTimeMode,
            pollInterval,
            setPollInterval,
          }}
        />
      </div>
    ) : (
      <div className={styles.plotHeader}>
        {panel?.dashboard && (
          <Breadcrumb
            separator=">"
            items={[
              { title: <Link to={`/dashboards/${panel.dashboard.id}`}>{panel.dashboard.name}</Link> },
              { title: <Link to={`/panels/${panel.id}`}>{panel.name}</Link> },
            ]}
          />
        )}
        <div className={styles.topRightMenu}>
          <TimeSelectorAdvanced
            {...{
              setStartTime,
              setEndTime,
              startTime,
              endTime,
              timeInterval,
              setTimeInterval,
              timeMode,
              setTimeMode,
              pollInterval,
              setPollInterval,
              setRefetch,
            }}
          />
          <Tooltip title={legendSwitchTooltip}>
            <Switch
              checkedChildren={<UnorderedListOutlined />}
              unCheckedChildren={<UnorderedListOutlined />}
              checked={showLegend}
              onChange={(checked) => updatePanel({ input: { id: panel.id, showLegend: checked } })}
            />
          </Tooltip>
          {hasPermissions(["read:annotations"]) && (
            <Tooltip placement="bottom" title={t({ id: "panel.tooltip.add-annotation", message: "Add Annotation" })}>
              <Switch
                checked={addAnnotation}
                checkedChildren={<FormOutlined />}
                unCheckedChildren={<FormOutlined />}
                onChange={handleAddAnnotation}
              />
            </Tooltip>
          )}
          {hasPermissions(["update:panels"]) && (
            <Button onClick={handleSaveTime} loading={saving} type="primary">
              <Trans id="panels.save">Save</Trans>
            </Button>
          )}
          {hasPermissions(["create:work_orders", "update:panels", "delete:panels"], { mode: "some" }) && (
            <Dropdown menu={{ items: dropdownMenuItems }} trigger={["click"]} placement="bottomRight">
              <MoreOutlined className={styles.icon} />
            </Dropdown>
          )}
        </div>
      </div>
    );

  const panelComponent = useMemo(() => {
    if (!loading) {
      return (
        <SizeMe>
          {({ size }) => (
            <div className={styles.plotContainer} ref={panelRef}>
              <div>
                <p className={styles.title}>{panel.name}</p>
              </div>
              <PanelComponent
                className={addAnnotation && styles.addAnnotation}
                panel={panel}
                annotations={annotations}
                showResolvedAnnotations={false}
                time={{
                  from: startTime,
                  to: endTime,
                  interval: timeInterval,
                  type: timeMode,
                }}
                zoomStartTime={zoomStartTime}
                zoomEndTime={zoomEndTime}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                setTimeMode={setTimeMode}
                style={{ height: "100%" }}
                width={size.width}
                showLegend={showLegend}
                legendConfig={{ orientation: "h" }}
                rangeSlider
                pollInterval={pollInterval}
                onClick={handleClick}
                onClickAnnotation={handleClickAnnotation}
                onRelayout={handleOnRelayout}
                refetch={refetch}
              />
            </div>
          )}
        </SizeMe>
      );
    }
    return <span />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startTime,
    endTime,
    timeInterval,
    timeMode,
    loading,
    pollInterval,
    refetch,
    addAnnotation,
    annotations,
    showLegend,
  ]);

  if (loading) return <AntdCard loading />;

  return (
    <>
      <div className={styles.container}>
        {panel && (
          <div
            className={`${styles.plotAnnotationContainer} ${(activeAnnotation || listView) && styles.annotationActive}`}
          >
            <Card className={styles.headerPlotContainer}>
              {renderHeader()}
              {panelComponent}
            </Card>
            <Card className={styles.annotationContainer}>
              <AnnotationPane
                annotations={annotations}
                listView={listView}
                setListView={setListView}
                activeAnnotation={activeAnnotation}
                setActiveAnnotation={setActiveAnnotation}
                setAnnotations={setAnnotations}
                panel={panel}
                users={users}
                startTime={startTime}
                endTime={endTime}
                timeMode={timeMode}
                timeInterval={timeInterval}
                zoomStartTime={zoomStartTime}
                zoomEndTime={zoomEndTime}
              />
            </Card>
          </div>
        )}
      </div>
      <WorkOrderRequestDrawer panelRef={panelRef} visibility={workOrderVisibility} close={closeWorkOrderRequest} />
    </>
  );
}
