import React from "react";
import { Link } from "react-router-dom";
import { useWorkOrderRequest } from "context/WorkOrderRequestContext";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Card from "components/Card";

import bannerBackground from "assets/banner.svg";
import { ReactComponent as CallIcon } from "./assets/call.svg";
import { ReactComponent as WorkOrderIcon } from "./assets/work-order.svg";

import styles from "./ServiceSupport.module.less";

export default function ConnectWithEH2() {
  const { openWorkOrderRequest } = useWorkOrderRequest();
  return (
    <div className={styles.container}>
      <div className={styles.topBanner} style={{ backgroundImage: `url(${bannerBackground})` }} />
      <div className={styles.bannerText}>
        <div className={styles.bannerTitle}>
          Get EH2<sup>®</sup> Service Support
        </div>
      </div>
      <div className={styles.cardsContainer}>
        <Card className={styles.card}>
          <div className={styles.iconContainer}>
            <CallIcon />
            <span className={styles.contact}>Call Us</span>
          </div>
          <div className={styles.contact}>123-456-7890</div>
        </Card>
        <Card className={styles.card}>
          <div className={styles.iconContainer}>
            <WorkOrderIcon />
            <span className={styles.contact}>Submit a Work Request</span>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              onClick={openWorkOrderRequest}
              className={styles.button}
            >
              New Work Request
            </Button>
            <Link to="/work-requests">
              <Button size="large" type="primary" className={styles.button}>
                All Work Requests
              </Button>
            </Link>
          </div>
        </Card>
      </div>
      <div className={styles.disclaimer}>
        Thank you for contacting us. An EH2 service team member will get back to you within 24 hours
      </div>
    </div>
  );
}
