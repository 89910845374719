import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";

import { useSharedEquipmentMeasures, useSetSharedEquipmentMeasures } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Button, Table, App } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import TableColumns from "./TableColumns";
import ShareEditModal from "./ShareEditModal";

import styles from "./SharingTab.module.less";

export default function SharingTab({ asset }) {
  const { modal } = App.useApp();
  const { message } = useCustomMessage();
  const navigate = useNavigate();

  const [shareEditModalOpen, setShareEditModalOpen] = useState(false);
  const [organizationToUpdate, setOrganizationToUpdate] = useState(null);
  const { sharedEquipmentMeasures } = useSharedEquipmentMeasures({ equipmentId: asset.id });
  const [updateSharedEquipmentMeasures, { loading: updating }] = useSetSharedEquipmentMeasures();

  const equipmentMeasures = useMemo(
    () => asset?.measures?.slice().sort((a, b) => a.slug.localeCompare(b.slug)) || [],
    [asset]
  );

  const currentSharedMeasures = useMemo(
    () =>
      sharedEquipmentMeasures.find((measure) => measure.receivingOrganizationSlug === organizationToUpdate)?.measures ||
      [],
    [organizationToUpdate, sharedEquipmentMeasures]
  );

  const isSharingAllMeasures = useMemo(
    () =>
      sharedEquipmentMeasures.find((measure) => measure.receivingOrganizationSlug === organizationToUpdate)
        ?.allMeasures || false,
    [organizationToUpdate, sharedEquipmentMeasures]
  );

  const organizations = sharedEquipmentMeasures.map(({ receivingOrganizationSlug, allMeasures, measures }) => ({
    key: receivingOrganizationSlug,
    name: receivingOrganizationSlug,
    allMeasures,
    measures,
  }));

  const handleShare = () => {
    navigate(`/assets/${asset.id}/share`, { state: { asset } });
  };

  const confirmEquipmentRevoke = ({ receivingOrganizationSlug }) => {
    modal.confirm({
      title: t({
        id: "asset-map.asset.tabs.sharing.revoke-confirm.title",
        message: `Revoke Access`,
      }),
      content: t({
        id: "asset-map.asset.tabs.shared-with.revoke-confirm.content",
        message: `This will revoke all access to ${asset.name} from the ${receivingOrganizationSlug} organization`,
      }),
      centered: true,
      okText: t({
        id: "asset-map.asset.tabs.shared-with.revoke-confirm.ok-text",
        message: `Revoke`,
      }),
      onOk: async () => {
        try {
          await updateSharedEquipmentMeasures({
            equipmentId: asset.id,
            receivingOrganizationSlugs: [receivingOrganizationSlug],
            measureIds: [],
          });
        } catch (err) {
          captureException(err);
          message.error(
            t({ id: "asset-map.asset.tabs.shared-with.revoke-error", message: "Failed to revoke equipment share" })
          );
        }
      },
    });
  };

  const handleUpdateEquipmentShare = async ({ selectedIds, shareAllMeasures }) => {
    try {
      await updateSharedEquipmentMeasures({
        equipmentId: asset.id,
        receivingOrganizationSlugs: [organizationToUpdate],
        measureIds: selectedIds,
        allMeasures: shareAllMeasures,
      });
    } catch (error) {
      message.error(
        t({ id: "asset-map.asset.tabs.shared-with.update-error", message: "Failed to update equipment share" })
      );
    } finally {
      setShareEditModalOpen(false);
      setOrganizationToUpdate(null);
    }
  };

  return (
    <>
      <Table
        columns={TableColumns({
          onDelete: confirmEquipmentRevoke,
          onEdit: ({ receivingOrganizationSlug }) => {
            setOrganizationToUpdate(receivingOrganizationSlug);
            setShareEditModalOpen(true);
          },
        })}
        dataSource={organizations}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <div className={styles.bottomMenu}>
        <div />
        <div>
          <Button className={styles.bottomMenuButton} onClick={handleShare}>
            <PlusCircleOutlined className={styles.bottomMenuButtonIcon} />
            <Trans id="asset-map.asset.tabs.shared-with.add-share">Share</Trans>
          </Button>
        </div>
      </div>
      <ShareEditModal
        open={shareEditModalOpen}
        setOpen={setShareEditModalOpen}
        equipmentMeasures={equipmentMeasures}
        sharedMeasures={currentSharedMeasures}
        isSharingAllMeasures={isSharingAllMeasures}
        updating={updating}
        onUpdate={handleUpdateEquipmentShare}
      />
    </>
  );
}
