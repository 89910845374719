import React from "react";
import { useMediaQuery } from "react-responsive";
import ResponsivePlot from "components/ResponsivePlot";

export default function DonutChart({ values, labels, colors }) {
  const isBigScreen = useMediaQuery({ minWidth: 1700 });

  return (
    <ResponsivePlot
      data={[
        {
          type: "pie",
          hole: 0.4,
          marker: {
            colors,
          },
          values,
          labels,
          sort: false,
        },
      ]}
      layout={{
        margin: {
          l: 20,
          r: 20,
          b: 10,
          t: 5,
          pad: 4,
        },
        font: {
          size: isBigScreen ? 14 : 12,
        },
        legend: { orientation: "h", side: "bot" },
      }}
      config={{
        modeBarButtons: [[]],
        displaylogo: false,
        responsive: true,
      }}
    />
  );
}
