export const defaultAxisData = {
  name: "Default Axis",
  unit: "",
  position: "left",
  color: "#494949",
  scale: [],
};

export const defaultFormData = {
  axes: [defaultAxisData],
};

export const generateNewAxisName = (axesForm) => {
  const existingNames = axesForm.map((axisForm) => axisForm.name);
  const baseName = "New Axis";
  if (!existingNames.includes(baseName)) {
    return baseName;
  }

  let counter = 2;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const newName = `${baseName} (${counter})`;
    if (!existingNames.includes(newName)) {
      return newName;
    }
    counter++;
  }
};

export const getMeasuresByEquipment = (panel) => {
  const allMeasures = panel?.measures || [];

  return allMeasures.reduce((res, measure) => {
    res[measure.equipment.id] = {
      name: measure.equipment.name,
      measures: [...(res[measure.equipment.id]?.measures || []), measure],
    };
    return res;
  }, {});
};

const processAxisData = (axisData, axisIndex, measureAssignments) => {
  const { name, position, unit, color, scale, id } = axisData;
  return {
    id,
    name: name || "",
    unit: unit || "",
    colorHex: typeof color === "string" ? color : color.toHex(),
    position,
    measureIds: Object.keys(measureAssignments).filter((mId) => measureAssignments[mId] === axisIndex),
    logarithmic: scale.includes("logarithmic"),
    reversed: scale.includes("reversed"),
  };
};

export const processFormData = (formData, measureAssignments) =>
  formData?.map((axisData, axisIndex) => processAxisData(axisData, axisIndex, measureAssignments));

export const getFormData = (panel) => {
  if (panel?.customAxes?.length) {
    const axesFormData = panel.customAxes.map((axis) => getAxisFormData(axis));
    return {
      axes: axesFormData,
    };
  }
  return defaultFormData;
};

const getAxisFormData = (axis) => {
  const { colorHex, name, position, unit, logarithmic, reversed, id } = axis;

  const scale = [];
  if (logarithmic) {
    scale.push("logarithmic");
  }
  if (reversed) {
    scale.push("reversed");
  }
  return {
    id,
    color: colorHex,
    position,
    name: name || "",
    unit: unit || "",
    scale,
  };
};
