import React, { useState, useContext, createContext, useMemo, useEffect, useCallback } from "react";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";

import captureException from "utils/capture-sentry-exception";
import { useAuth0 } from "@auth0/auth0-react";
import useGetMyPermissions from "hooks/apiService/auth/useGetMyPermissions";
import useGetUserOrganizationProfile from "hooks/apiService/userOrganizationProfile/useGetUserOrganizationProfile";
import { getOrgFromSubdomain } from "utils/auth-utils";

const proxyUrl = process.env.REACT_APP_THIRDPARTY_PROXY_URL || "https://api.v1.betterh2.com";

const UserContext = createContext([]);

const UserProvider = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [getMyPermissions] = useGetMyPermissions();
  const currentOrganization = getOrgFromSubdomain();
  const [getUserOrganizationProfile, { userOrganizationProfile, loading: userOrganizationProfileLoading }] =
    useGetUserOrganizationProfile();

  const { recentModules = [] } = userOrganizationProfile || {};

  const [permissions, setPermissions] = useState([]);
  const [globalPermissions, setGlobalPermissions] = useState([]);
  const [features, setFeatures] = useState([]);
  const [grafanaOrgId, setGrafanaOrgId] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);

  const auth0ApiUrl = `${proxyUrl}/thirdparty/auth0`;

  const initUser = async () => {
    if (user) {
      getUserOrganizationProfile({ id: user.sub });
      const myOrgPermissions = await getMyPermissions({ organizationSlug: currentOrganization });
      setPermissions(myOrgPermissions);
      const myGlobalPermissions = await getMyPermissions({ organizationSlug: "*" });
      setGlobalPermissions(myGlobalPermissions);
      setLoading(false);
    }
  };

  const hasPermissions = useCallback(
    (requiredScopes, { global = false, mode = "every" } = {}) => {
      if (mode === "some") {
        return requiredScopes.some((scope) =>
          global ? globalPermissions.includes(scope) : permissions.includes(scope)
        );
      }
      return requiredScopes.every((scope) =>
        global ? globalPermissions.includes(scope) : permissions.includes(scope)
      );
    },
    [permissions, globalPermissions]
  );

  const initFeatures = async () => {
    if (user) {
      const response = await fetch(`${auth0ApiUrl}/organizations/${getOrgFromSubdomain()}/`);
      const organization = await response.json();
      if (organization?.metadata?.features) {
        setFeatures(organization.metadata.features?.split(" "));
      }
    }
  };

  const getCurrentUserOrganizations = async () => {
    const accessToken = await getAccessTokenSilently();
    const requestParameters = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return fetch(`${auth0ApiUrl}/users/self/organizations`, requestParameters)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((res) => res.json());
  };

  const getOrganization = async (orgSlug) =>
    fetch(`${auth0ApiUrl}/organizations/${orgSlug}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((res) => res.json());

  const fetchOrgs = async () => {
    if (user) {
      try {
        const response = await getCurrentUserOrganizations();
        setOrganizations(response);
      } catch (err) {
        captureException(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    initFeatures();
    initUser();
    fetchOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getOrganization(currentOrganization).then((res) => setGrafanaOrgId(res?.metadata?.grafanaID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.sub) {
      Sentry.setUser({
        email: user?.email,
        nickname: user?.nickname,
        organization: currentOrganization,
      });

      mixpanel.identify(user.sub);
      if (currentOrganization) mixpanel.people.union("Organizations", currentOrganization);
      mixpanel.people.set({ $name: user.name, $email: user.email });
    } else {
      mixpanel.reset();
    }
  }, [user, currentOrganization]);

  const values = useMemo(
    () => ({
      user,
      permissions,
      globalPermissions,
      hasPermissions,
      loading: loading || userOrganizationProfileLoading,
      features,
      organizations,
      currentOrganization,
      grafanaOrgId: process.env.REACT_APP_ORG_OVERRIDE_ID ? process.env.REACT_APP_ORG_OVERRIDE_ID : grafanaOrgId,
      recentModules,
    }),
    [
      user,
      permissions,
      globalPermissions,
      hasPermissions,
      loading,
      userOrganizationProfileLoading,
      features,
      organizations,
      currentOrganization,
      grafanaOrgId,
      recentModules,
    ]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);

export { useUser, UserProvider };
