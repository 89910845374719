import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_PANEL, GET_EQUIPMENT, GET_DASHBOARD } from "graphql/apiService";

export default function useDeletePanel({ dashboardId } = {}) {
  const { currentOrganization } = useUser();

  const [deletePanelMutation, { loading, error }] = useMutation(DELETE_PANEL, {
    variables: { organizationSlug: currentOrganization },
    update: (cache, { data }) => {
      const { id: deletedPanelId, equipmentIds } = data.deletePanel;
      equipmentIds.forEach((equipmentId) => {
        const equipmentData = cache.readQuery({
          query: GET_EQUIPMENT,
          variables: {
            organizationSlug: currentOrganization,
            id: equipmentId,
          },
        });
        if (!equipmentData) {
          return;
        }
        cache.writeQuery({
          query: GET_EQUIPMENT,
          variables: {
            organizationSlug: currentOrganization,
            id: equipmentId,
          },
          data: {
            getEquipment: {
              ...equipmentData.getEquipment,
              panels: equipmentData.getEquipment.panels.filter((p) => p.id !== deletedPanelId),
            },
          },
        });
      });
      if (dashboardId) {
        const dashboardData = cache.readQuery({
          query: GET_DASHBOARD,
          variables: {
            organizationSlug: currentOrganization,
            id: dashboardId,
          },
        });
        cache.writeQuery({
          query: GET_DASHBOARD,
          variables: {
            organizationSlug: currentOrganization,
            id: dashboardId,
          },
          data: {
            getDashboard: {
              ...dashboardData.getDashboard,
              panels: dashboardData.getDashboard.panels.filter((p) => p.id !== deletedPanelId),
            },
          },
        });
      }
    },
  });

  const deletePanel = async ({ id }) => {
    const res = await deletePanelMutation({ variables: { id } });
    return res.data?.deletePanel;
  };

  return [deletePanel, { loading, error }];
}
