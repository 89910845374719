import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_MY_RECENT_MODULES } from "graphql/apiService";

export default function useUpdateMyRecentModules() {
  const { currentOrganization } = useUser();

  const [updateMyRecentModulesMutation, { loading, error }] = useMutation(UPDATE_MY_RECENT_MODULES, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetUserOrganizationProfile"],
  });

  const updateMyRecentModules = async ({ module }) => {
    const res = await updateMyRecentModulesMutation({ variables: { module } });
    return res.data.updateMyRecentModules;
  };

  return [updateMyRecentModules, { loading, error }];
}
