import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { t } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";
import mixpanel from "mixpanel-browser";

import { useDashboard, useDashboards } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Spin } from "antd";
import GrafanaDashboard from "../GrafanaDashboard";
import CustomDashboard from "../CustomDashboard";
import SupersetDashboard from "../SupersetDashboard";

import styles from "./Dashboard.module.less";

function Dashboard() {
  const { id } = useParams();
  const { dashboards, loading: dashboardsLoading, error: dashboardsError } = useDashboards();
  const { dashboard, loading, error } = useDashboard({ id });
  const { message } = useCustomMessage();

  useEffect(() => {
    if (dashboard?.type) {
      mixpanel.track("Dashboard Page Viewed", {
        "Dashboard ID": id,
        "Dashboard Type": dashboard.type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard?.type]);

  useEffect(() => {
    if (dashboardsError) {
      captureException(dashboardsError);
      message.error(
        t({
          id: "dashboards.errors.get-dashboards",
          message: "Failed to get dashboards",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardsError]);

  useEffect(() => {
    if (error) {
      captureException(error);
      message.error(
        t({
          id: "dashboards.errors.get-dashboard",
          message: "Failed to get dashboard",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (loading || dashboardsLoading) {
    return (
      <div className={styles.spinContainer}>
        <Spin size="large" />
      </div>
    );
  }

  if (dashboard?.type === "GRAFANA") {
    return <GrafanaDashboard dashboards={dashboards} />;
  }
  if (dashboard?.type === "SUPERSET") {
    return <SupersetDashboard dashboards={dashboards} />;
  }
  return <CustomDashboard dashboard={dashboard} dashboards={dashboards} />;
}

export default Dashboard;
