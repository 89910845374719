import React, { forwardRef } from "react";

import { DatePicker, Form, Input, Select, TreeSelect } from "antd";
import TextArea from "antd/lib/input/TextArea";

import styles from "./AssetInput.module.less";

const AssetInput = forwardRef(
  (
    {
      property,
      name,
      placeholder,
      textfield,
      options,
      limbleOptions,
      tags,
      date,
      disabled,
      loading,
      rules,
      treeData,
      onFocus = () => {},
    },
    ref
  ) => {
    if (textfield) {
      return (
        <div className={styles.propertiesContainer}>
          <p className={styles.property}>{property}</p>
          <div className={styles.inputContainer}>
            <Form.Item name={name} rules={rules}>
              <TextArea
                className={`${styles.input} ${styles.textarea}`}
                autoSize={{ minRows: 2, maxRows: 6 }}
                disabled={disabled}
                placeholder={placeholder}
              />
            </Form.Item>
          </div>
        </div>
      );
    }
    if (options) {
      return (
        <div className={styles.propertiesContainer}>
          <p className={styles.property}>{property}</p>
          <div className={styles.inputContainer}>
            <Form.Item name={name} rules={rules}>
              <Select
                className={styles.select}
                disabled={disabled || loading}
                loading={loading}
                placeholder={placeholder}
              >
                {options.map((option) => (
                  <Select.Option key={option.value || option} value={option.value || option}>
                    {option.name || option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      );
    }
    if (limbleOptions) {
      return (
        <div className={styles.propertiesContainer}>
          <p className={styles.property}>{property}</p>
          <div className={styles.inputContainer}>
            <Form.Item name={name} rules={rules}>
              <TreeSelect
                treeData={limbleOptions}
                treeDataSimpleMode={{
                  id: "assetID",
                  pId: "parentAssetID",
                }}
                fieldNames={{
                  label: "name",
                  value: "assetID",
                }}
                showSearch
                treeLine
                allowClear
                showCheckedStrategy="SHOW_PARENT"
                loading={loading}
                disabled={disabled || loading}
                treeNodeFilterProp="name"
                placeholder={placeholder}
              />
            </Form.Item>
          </div>
        </div>
      );
    }
    if (tags) {
      return (
        <div className={styles.propertiesContainer}>
          <p className={styles.property}>{property}</p>
          <div className={styles.inputContainer}>
            <Form.Item name={name} rules={rules}>
              <Select
                className={styles.tags}
                disabled={disabled || loading}
                loading={loading}
                mode="tags"
                placeholder={placeholder}
                tokenSeparators={[","]}
              >
                {tags.map((tag) => (
                  <Select.Option key={tag} value={tag}>
                    {tag}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      );
    }
    if (date) {
      return (
        <div className={styles.propertiesContainer}>
          <p className={styles.property}>{property}</p>
          <div className={styles.inputContainer}>
            <Form.Item name={name} rules={rules}>
              <DatePicker className={styles.input} format="ddd MMM DD YYYY" disabled={disabled} />
            </Form.Item>
          </div>
        </div>
      );
    }
    if (treeData) {
      return (
        <div className={styles.propertiesContainer}>
          <p className={styles.property}>{property}</p>
          <div className={styles.inputContainer}>
            <Form.Item name={name} rules={rules}>
              <TreeSelect treeLine placeholder={placeholder} treeDefaultExpandAll treeData={treeData} />
            </Form.Item>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.propertiesContainer}>
        <p className={styles.property}>{property}</p>
        <div className={styles.inputContainer}>
          <Form.Item name={name} rules={rules}>
            <Input
              className={styles.input}
              placeholder={placeholder}
              disabled={disabled}
              ref={ref && ref}
              onFocus={onFocus}
            />
          </Form.Item>
        </div>
      </div>
    );
  }
);

export default AssetInput;
