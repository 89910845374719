import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_EQUIPMENT_ATTACHMENT } from "graphql/apiService";

export default function useCreateEquipmentAttachment() {
  const { currentOrganization } = useUser();

  const [createEquipmentAttachmentMutation, { loading, error }] = useMutation(CREATE_EQUIPMENT_ATTACHMENT, {
    variables: { organizationSlug: currentOrganization },
  });

  const createEquipmentAttachment = async ({ input }) => {
    const res = await createEquipmentAttachmentMutation({ variables: { input } });
    return res.data.createEquipmentAttachment;
  };

  return [createEquipmentAttachment, { loading, error }];
}
