import { useUser } from "context/UserContext";
import { GET_ORGANIZATION_PROFILE } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useGetOrganizationProfile(organizationSlug) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_ORGANIZATION_PROFILE, {
    variables: {
      organizationSlug: organizationSlug || currentOrganization,
    },
  });

  const organizationProfile = data?.getOrganizationProfile;
  return { organizationProfile, loading, error };
}
