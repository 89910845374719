import { useUser } from "context/UserContext";
import { GET_PART_INFO } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useInfo() {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_PART_INFO, {
    variables: {
      organizationSlug: currentOrganization,
    },
  });

  const partInfo = data?.getPartInfo || [];

  return { partInfo, loading, error };
}
