import { sum, average } from "utils/math-utils";

const DELTA_T = 5;
const MIN_PER_HR = 60;
const ELECTROLYZER_CAPACITY = 50; // MW
const NUMBER_OF_DAYS = 3;
const HOURS_PER_DAY = 24;
const MWHTOKWH = 1000;
const ELECTROLYSIS_CONVERSION_EFFICIENCY = 60; // kWh/kg H2 <-- in line with commercial units
const GRID_PREMIUM = 60; // $/MWh - amount over wholesale rate that grid operator charges

/**
 *
 * @param {integer} gridCiThreshold - kgCO2e/MWh threshold
 * @param {array} dataFrame - list of data in the form of src/containers/carbonIntensity.json
 * @returns {object}
 *  - gridPowerUse: MW used by grid
 *  - finalUnsuppliedEzPower: MW electrolyzers underutilized by
 *  - CO2e: kgCO2e emitted by power consumed
 */
// eslint-disable-next-line import/prefer-default-export
export const getGridPowerUseByCO2Threshold = (gridCiThreshold, dataFrame) => {
  const gridPowerUse = new Array(dataFrame.times.length).fill(0);
  const finalUnsuppliedEzPower = [...dataFrame.unsupplied_ez_power];
  const CO2e = new Array(dataFrame.times.length).fill(0);
  const fuelMix = {};
  dataFrame.times.forEach((time, idx) => {
    if (dataFrame.grid_ci_avg[idx] <= gridCiThreshold && dataFrame.unsupplied_ez_power[idx] > 0) {
      gridPowerUse[idx] = dataFrame.unsupplied_ez_power[idx];
      finalUnsuppliedEzPower[idx] = 0;
      CO2e[idx] = (gridPowerUse[idx] * dataFrame.grid_ci_avg[idx] * DELTA_T) / MIN_PER_HR;
      const dataKeys = Object.keys(dataFrame).filter((key) => key.match(/^grid_/) && key !== "grid_ci_avg");
      const totalFuelMix = sum(dataKeys.map((key) => Math.max(dataFrame[key][idx], 0)));
      for (let i = 0; i < dataKeys.length; i += 1) {
        const key = dataKeys[i];
        const val = dataFrame[key][idx];
        // turn the key "grid_solar" into "solar"
        const shortKey = key.split("grid_")[1];
        fuelMix[shortKey] = fuelMix[shortKey] || 0;
        // Get the percentage of the fuel mix and multiply by the power used by the grid
        fuelMix[shortKey] += Math.max((val * gridPowerUse[idx] * DELTA_T) / (totalFuelMix * MIN_PER_HR), 0);
      }
    }
  });
  // take the sum of carbonIntensity.solar_to_ez, carbonIntensity.batter_to_ez, and gridPowerUse
  const electrolyzerPowerUse = dataFrame.solar_to_ez.map((d, i) => d + dataFrame.battery_to_ez[i] + gridPowerUse[i]);
  const averageElectrolyzerPower = average(electrolyzerPowerUse);
  const operationalCapacity = averageElectrolyzerPower / ELECTROLYZER_CAPACITY;
  const totalMWhToElectrolyzer = averageElectrolyzerPower * NUMBER_OF_DAYS * HOURS_PER_DAY;
  const totalH2Production = (totalMWhToElectrolyzer * MWHTOKWH) / ELECTROLYSIS_CONVERSION_EFFICIENCY;
  const h2AvgKgPerDay = totalH2Production / NUMBER_OF_DAYS;
  const totalKgCO2e = sum(CO2e);
  const h2CarbonIntensity = totalKgCO2e / totalH2Production;
  const gridRevenue = sum(dataFrame.price.map((price, i) => price * dataFrame.solar_to_grid[i] * DELTA_T)) / MIN_PER_HR;
  const gridCost =
    sum(dataFrame.price.map((price, i) => (price + GRID_PREMIUM) * gridPowerUse[i] * DELTA_T)) / MIN_PER_HR;
  const totalGridPowerUse = (sum(gridPowerUse) * DELTA_T) / MIN_PER_HR;
  const totalSolarPowerUse = (sum(dataFrame.solar_to_ez) * DELTA_T) / MIN_PER_HR;

  return {
    gridPowerUse,
    totalGridPowerUse,
    totalSolarPowerUse,
    totalH2Production,
    gridRevenue,
    gridCost,
    operationalCapacity,
    h2AvgKgPerDay,
    totalKgCO2e,
    h2CarbonIntensity,
    finalUnsuppliedEzPower,
    fuelMix,
    electrolyzerPowerUse,
  };
};
