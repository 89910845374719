import { useUser } from "context/UserContext";
import { GET_ALARM_WITH_FULL_MEASURES } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useAlarm({ id }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_ALARM_WITH_FULL_MEASURES, {
    variables: {
      organizationSlug: currentOrganization,
      id,
    },
  });

  const alarm = data?.getAlarm;
  return { alarm, loading, error };
}
