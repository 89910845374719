import React from "react";
import comingSoonImage from "assets/coming-soon.svg";
import { Space, Button } from "antd";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import styles from "./PageComingSoon.module.less";

function PageComingSoon() {
  return (
    <div className={styles.container}>
      <Space direction="vertical" align="center" size="large" className={styles.space}>
        <img src={comingSoonImage} alt="404" />
        <div className={styles.textContainer}>
          <h1>Coming Soon</h1>
          <span className={styles.secondaryText}>
            This module is currently under development. Stay tuned for updates!
          </span>
        </div>
        <Link to="/">
          <Button>
            <Trans id="404-page.home">Back Home</Trans>
          </Button>
        </Link>
      </Space>
    </div>
  );
}

export default PageComingSoon;
