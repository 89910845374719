import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { Trans } from "@lingui/macro";

import Apollo from "context/ApolloContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { GlobalContextProvider } from "context/GlobalLayoutContext";
import { NotificationsProvider } from "context/NotificationsContext";
import { IntercomProvider } from "context/IntercomContext";
import { AblyProvider } from "context/AblyContext";
import { WorkOrderRequestProvider } from "context/WorkOrderRequestContext";
import { H2ToolsProvider } from "context/H2ToolsContext";
import { UserProvider } from "context/UserContext";

import { App as AppAntD, Button } from "antd";
import Routes from "components/Routes";
import ErrorScreen from "components/ErrorScreen";
import Message from "components/Message";

import { getConfig } from "auth0_config";

import "@knocklabs/react-notification-feed/dist/index.css";
import "./App.module.less";

const config = getConfig();

const scopes = [
  "profile",
  "email",
  "admin:dashboards",
  "grant:all_roles",
  "read:all_roles",
  "read:reports",
  "archive:reports",
  "revoke:all_roles",
  "invite:users",
  "read:invitations",
  "delete:invitations",
  "read:dashboards",
  "update:dashboards",
  "create:dashboards",
  "delete:dashboards",
  "delete:organization_members",
  "read:telemetry_data",
  "create:equipment",
  "read:equipment",
  "update:equipment",
  "delete:equipment",
  "create:hydrogen_questions",
  "create:measures",
  "read:measures",
  "update:measures",
  "delete:measures",
  "create:panels",
  "read:panels",
  "update:panels",
  "delete:panels",
  "read:org_data_partners",
  "invite:org_data_partners",
  "approve:org_data_partners",
  "revoke:org_data_partners",
  "read:user_organization_profiles",
  "delete:user_organization_profiles",
  "update:my_user_organization_profile",
  "create:work_orders",
  "read:work_orders",
  "read:work_order_statuses",
  "read:work_order_tags",
  "read:work_order_priorities",
  "share:telemetry_data",
  "read:telemetry_data_shares",
  "read:organization_profile",
  "update:organization_profile",
  "create:equipment_attachments",
  "read:equipment_attachments",
  "update:equipment_attachments",
  "delete:equipment_attachments",
  "create:annotations",
  "read:annotations",
  "update:annotations",
  "create:annotation_messages",
  "read:annotation_messages",
  "delete:annotation_messages",
  "create:incident_reports",
  "read:incident_reports",
  "update:incident_reports",
  "delete:incident_reports",
  "read:my_user_account",
  "update:my_user_account",
  "read:organization_profiles",
  "create:organization_profiles",
  "read:alarms",
  "read:alarm_rules",
  "create:alarms",
  "update:alarms",
  "delete:alarms",
  "read:bi_dashboards",
  "create:bi_dashboards",
  "admin:bi_dashboards",
  "refresh:db_views",
  "read:s3_assets",
  "create:threads",
  "read:threads",
  "create:comments",
  "read:comments",
];

const auth0ProviderConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/login`,
    audience: "webAPI",
    scope: scopes.join(" "),
    useCookiesForTransactions: true,
    organization: localStorage.getItem("currentOrgId") || undefined,
  },
};

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || "DISABLED");

const BUILD_CHECK_INTERVAL = 1000 * 60 * 60; // 1 hour
const CURRENT_BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION;
const handleClickReload = () => {
  window.location.reload();
};

function App() {
  const { message } = AppAntD.useApp();

  const checkBuildVersion = async () => {
    const response = await fetch("/build_version.json", { cache: "no-store" });
    const { build_version: buildVersion } = await response.json();

    if (buildVersion !== CURRENT_BUILD_VERSION) {
      message.warning({
        key: "new-version",
        content: (
          <Message
            content={
              <div>
                <p>
                  <Trans id="app.new-version.text">
                    We have updated our application. Please reload the page to get the latest version.
                  </Trans>
                </p>
                <Button type="primary" ghost onClick={handleClickReload}>
                  <Trans id="app.new-version.button">Reload</Trans>
                </Button>
              </div>
            }
          />
        ),
        duration: 0,
      });
    }
  };

  useEffect(() => {
    let buildCheck;
    if (["staging", "production"].includes(process.env.REACT_APP_ENV)) {
      buildCheck = setInterval(checkBuildVersion, BUILD_CHECK_INTERVAL);
    }
    return () => {
      clearInterval(buildCheck);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={ErrorScreen} showDialog>
      <BrowserRouter>
        <Auth0Provider {...auth0ProviderConfig}>
          <Apollo>
            <UserProvider>
              <IntercomProvider>
                <AblyProvider>
                  <GlobalContextProvider>
                    <NotificationsProvider>
                      <WorkOrderRequestProvider>
                        <H2ToolsProvider>
                          <Routes />
                        </H2ToolsProvider>
                      </WorkOrderRequestProvider>
                    </NotificationsProvider>
                  </GlobalContextProvider>
                </AblyProvider>
              </IntercomProvider>
            </UserProvider>
          </Apollo>
        </Auth0Provider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}

export default App;
