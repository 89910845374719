import { useUser } from "context/UserContext";
import { GET_USER_ROLES } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useUserRoles({ userId, organizationSlug }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_USER_ROLES, {
    variables: {
      organizationSlug: organizationSlug || currentOrganization,
      userId,
    },
  });

  const userRoles = data?.getUserRoles;
  return { userRoles, loading, error };
}
