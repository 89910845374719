import React from "react";
import { activateLocale } from "utils/i18n";
import { useLingui } from "@lingui/react";
import { Select } from "antd";
import styles from "./LanguageSelector.module.less";

const { Option } = Select;

function LanguageSelector() {
  const { i18n } = useLingui();

  function handleChange(locale) {
    activateLocale(locale);
  }

  return (
    <div className="select">
      <Select onChange={handleChange} value={i18n.locale} className={styles.select}>
        <Option value="en">English</Option>
      </Select>
    </div>
  );
}

export default LanguageSelector;
