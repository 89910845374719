import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { usePanel, useUpdatePanel } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";
import { t } from "@lingui/macro";

import PanelForm from "components/PanelForm";

function PanelEdit() {
  const { panelId } = useParams();
  const { panel, loading, error } = usePanel({ id: panelId });
  const { message } = useCustomMessage();
  const navigate = useNavigate();

  const [updatePanel, { loading: updateLoading }] = useUpdatePanel();

  const handleUpdatePanel = async ({ measureIds, events, formValues, time, pollInterval, customAxes }) => {
    try {
      await updatePanel({
        input: {
          ...formValues,
          id: panelId,
          measureIds,
          events,
          time,
          pollInterval,
          customAxes,
        },
      });
      navigate(`/panels/${panel.id}`, {
        replace: true,
      });
    } catch (updateError) {
      message.error(
        t({
          id: "panels.errors.update-panel",
          message: "Failed to update panel",
        })
      );
    }
    return undefined;
  };

  useEffect(() => {
    if (error) {
      message.error(
        t({
          id: "panels.errors.get-panel",
          message: "Failed to get panel",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return <PanelForm panel={panel} panelLoading={loading} onSave={handleUpdatePanel} actionLoading={updateLoading} />;
}

export default PanelEdit;
