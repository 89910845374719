import React from "react";
import ResponsivePlot from "components/ResponsivePlot";
import { ReactComponent as VoltageLegendMarker } from "../../../assets/voltage-legend-marker.svg";

export default function StackPerformanceChart({ voltages }) {
  const xValues = voltages.map((_, index) => index + 1);
  const averageVoltages = voltages.map((v) => v.avg);

  const errorArray = voltages.map((v) => v.max - v.avg);
  const errorArrayMinus = voltages.map((v) => v.avg - v.min);
  const tooltipText = voltages.map(
    (v, index) => `Stack #${index + 1}<br>Max: ${v.max} V<br>Avg: ${v.avg} V<br>Min: ${v.min} V`
  );
  const barTooltipText = voltages.map((n) => `Cumulative runtime: ${n.runtime} hours`);

  const barXValues = voltages.map((_, index) => index + 1);
  const barYValues = voltages.map((v) => v.runtime);

  const maxRuntime = Math.max(voltages.map((v) => v.runtime));

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 28, fontSize: 12 }}>
        <div style={{ display: "flex", gap: 8 }}>
          <div style={{ width: 15, height: 15, background: "#C7DDCC", margin: "auto" }} />
          <div>Cumulative Runtime</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 3 }}>
            <VoltageLegendMarker />
            <div style={{ display: "flex", flexDirection: "column", fontSize: 10, lineHeight: 1.3 }}>
              <div>max</div>
              <div>avg</div>
              <div>min</div>
            </div>
          </div>
          <div style={{ paddingLeft: 5 }}>Voltage</div>
        </div>
      </div>
      <div style={{ flex: 1, width: "100%", minHeight: 165 }}>
        <ResponsivePlot
          data={[
            {
              x: xValues,
              y: averageVoltages,
              error_y: {
                type: "data",
                color: "#008B8A",
                symmetric: false,
                array: errorArray,
                arrayminus: errorArrayMinus,
              },
              type: "scatter",
              mode: "markers",
              name: "Voltage",
              marker: {
                color: "#333",
                opacity: 1,
                size: 6,
                line: {
                  color: "#008B8A",
                  width: 2,
                },
              },
              yaxis: "y2",
              text: tooltipText,
              hoverinfo: "text",
            },
            {
              x: barXValues,
              y: barYValues,
              type: "bar",
              xaxis: "x",
              yaxis: "y",
              width: 1,
              opacity: 1,
              marker: {
                color: "#C7DDCC",
              },
              name: "Cumulative Runtime",
              hovertemplate: barTooltipText.map((text) => `${text}<extra></extra>`),
            },
          ]}
          layout={{
            xaxis: {
              title: "Stack #",
              tickvals: xValues,
              ticktext: xValues,
            },
            yaxis: {
              title: "Cumulative Runtime (hrs)",
              side: "right",
              range: [1, maxRuntime * 1.2],
              titlefont: {
                size: 12,
              },
              showgrid: false,
            },
            yaxis2: {
              title: "Cell Voltage (V)",
              overlaying: "y",
              side: "left",
              anchor: "free",
              titlefont: {
                size: 12,
              },
            },

            bargap: 0,
            barmode: "overlay",
            showlegend: false,
            margin: {
              l: 50,
              r: 50,
              b: 40,
              t: 5,
              pad: 4,
            },
          }}
          config={{
            modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
            displaylogo: false,
            responsive: true,
          }}
        />
      </div>
    </div>
  );
}
