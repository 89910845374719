import dayjs from "dayjs";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

const unitToMinutes = {
  m: 1,
  h: 60,
  d: 60 * 24,
  w: 60 * 24 * 7,
};

const intervals = {
  "5m": t({ id: "time-selector.5m", message: "Last 5 minutes" }),
  "15m": t({ id: "time-selector.15m", message: "Last 15 minutes" }),
  "1h": t({ id: "time-selector.1h", message: "Last hour" }),
  "3h": t({ id: "time-selector.3h", message: "Last 3 hours" }),
  "24h": t({ id: "time-selector.24h", message: "Last 24 hours" }),
  "3d": t({ id: "time-selector.3d", message: "Last 3 days" }),
  "7d": t({ id: "time-selector.7d", message: "Last 7 days" }),
  "30d": t({ id: "time-selector.30d", message: "Last 30 days" }),
  "90d": t({ id: "time-selector.90d", message: "Last 90 days" }),
};

export const intervalToMinutes = (interval) => {
  const amount = parseInt(interval, 10);
  const unit = interval.replace(amount, "");
  return amount * unitToMinutes[unit];
};

export const intervalToAbsoluteRange = (interval) => ({
  from: dayjs().add(-intervalToMinutes(interval), "minutes").toISOString(),
  to: dayjs().toISOString(),
});

export const intervalToString = (interval) => intervals[interval];

export const timeString = ({ time }) =>
  time.type === "HISTORICAL"
    ? `From ${i18n.date(new Date(time.from), { dateStyle: "short", timeStyle: "short" })} to ${i18n.date(
        new Date(time.to),
        { dateStyle: "short", timeStyle: "short" }
      )}`
    : intervalToString(time.interval);

export const absolutePanelTime = (time) =>
  time.type === "REALTIME"
    ? intervalToAbsoluteRange(time.interval)
    : { from: dayjs(time.from).toISOString(), to: dayjs(time.to).toISOString() };
