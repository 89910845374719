import { useMemo } from "react";
import { t } from "@lingui/macro";

import { useEquipmentAndMeasuresList } from "hooks/apiService";

import { arrayToTree } from "containers/AssetMap/helpers/nestedObject";
import { useUser } from "context/UserContext";

import { icons } from "containers/AssetMap/helpers/mapping";

export default function useAssetTree() {
  const { equipmentList: assetList, loading: equipmentListLoading } = useEquipmentAndMeasuresList();
  const { currentOrganization } = useUser();
  const assetTree = useMemo(
    () => [
      {
        key: "global",
        value: "global",
        title: t({
          id: "asset-map.asset-tree.all-assets",
          message: "All Assets",
        }),
        name: t({
          id: "asset-map.asset-tree.all-assets",
          message: "All Assets",
        }),
        type: "FOLDER",
        icon: icons.FOLDER.react,
        children: arrayToTree(
          [
            ...assetList
              .filter(({ organizationSlug }) => organizationSlug === currentOrganization)
              .map((equipment) => ({
                key: equipment.id,
                value: equipment.id,
                title: equipment.name,
                parentId: equipment.parentId,
                type: "ASSET",
                icon: icons[equipment.type].react || icons.ASSET.react,
                iconMap: icons[equipment.type].map || icons.ASSET.map,
                draggable: true,
                ...equipment,
              })),
          ],
          "id",
          "parentId"
        ),
      },
      {
        key: "shared",
        value: "shared",
        title: t({
          id: "asset-map.asset-tree.shared-assets",
          message: "Shared Assets",
        }),
        name: t({
          id: "asset-map.asset-tree.shared-assets",
          message: "Shared Assets",
        }),
        type: "SHARED",
        icon: icons.FOLDER.react,
        children: arrayToTree(
          assetList
            .filter(({ organizationSlug }) => organizationSlug !== currentOrganization)
            .map((equipment) => ({
              key: equipment.id,
              value: equipment.id,
              title: equipment.name,
              parentId: null,
              type: "ASSET",
              icon: icons[equipment.type].react || icons.ASSET.react,
              iconRaw: icons[equipment.type].map || icons.ASSET.map,
              iconMap: icons[equipment.type].map || icons.ASSET.map,
              draggable: false,
              ...equipment,
            })),
          "id",
          "parentId"
        ),
      },
    ],
    [assetList, currentOrganization]
  );

  const assetIdMap = useMemo(() => {
    const map = new Map();
    assetList.forEach((asset) => map.set(asset.id, asset));
    return map;
  }, [assetList]);

  return { assetTree, assetList, assetIdMap, loading: equipmentListLoading };
}
