import React from "react";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import { useDeleteDashboard } from "hooks/apiService";
import { Button, Dropdown, App } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import captureException from "utils/capture-sentry-exception";
import useCustomMessage from "hooks/useCustomMessage";

import styles from "./DashboardActions.module.less";

function DashboardActions({ record }) {
  const { modal } = App.useApp();
  const { message } = useCustomMessage();
  const navigate = useNavigate();

  const [deleteDashboard] = useDeleteDashboard();

  const actionItems = [
    {
      label: t({ id: "common.edit", message: "Edit" }),
      key: "edit",
      onClick: () => navigate(`${record.id}/edit`),
    },
    {
      label: t({ id: "common.remove", message: "Remove" }),
      key: "remove",
      danger: true,
      onClick: () => confirmRemove({ name: record.name, id: record.id }),
    },
  ];

  const confirmRemove = ({ name, id }) => {
    modal.confirm({
      title: t({ id: "dashboards.confirm.remove-dashboard", message: `Are you sure you want to remove ${name}?` }),
      centered: true,
      onOk: async () => {
        try {
          await deleteDashboard({ id });
          message.success(
            t({
              id: "dashboards.success.remove-dashboard",
              message: `Successfully removed ${name}`,
            })
          );
        } catch (err) {
          captureException(err);
          message.error(
            t({
              id: "dashboards.errors.remove-dashboard",
              message: `Failed to remove ${name}`,
            })
          );
        }
      },
    });
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return isSmallScreen ? (
    <div className={styles.actionsSmallContainer}>
      <div className={styles.dropdownButtonContainer}>
        <Dropdown menu={{ items: actionItems }} trigger="click">
          <Button shape="circle" type="text" icon={<MoreOutlined />} />
        </Dropdown>
      </div>
    </div>
  ) : (
    <div className={styles.actions}>
      {actionItems.map((item) => (
        <Button key={item.key} type="link" className={styles.actionLink} onClick={item.onClick}>
          {item.label}
        </Button>
      ))}
    </div>
  );
}

export default DashboardActions;
