import React, { useState, useEffect } from "react";

import dayjs from "dayjs";
import { Form, Input, Select, Divider, DatePicker, Row, Col, Radio } from "antd";
import { t } from "@lingui/macro";
import { useEquipmentList } from "hooks/apiService";
import useThirdPartyProxy from "hooks/useThirdPartyProxy";

import LocationModal from "components/LocationModal";
import { severities as severitiesFn } from "../../../helpers/enums";

export default function BasicInfo({ form, setLocationCoordinates, userOptions, isSmallScreen }) {
  const { equipmentList } = useEquipmentList();
  const { getTasks } = useThirdPartyProxy();

  const severities = severitiesFn();

  const equipmentOptions = equipmentList.map((e) => ({ label: e.name, value: e.id }));

  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [workOrderNumberOptions, setWorkOrderNumberOptions] = useState([]);

  const handleGetTasks = async () => {
    const tasks = await getTasks();
    const options = tasks.map((task) => ({ value: task.taskID, label: `#${task.taskID} - ${task.name}` }));
    setWorkOrderNumberOptions(options);
  };

  useEffect(() => {
    handleGetTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocationModalOk = ({ location, formattedAddress }) => {
    form.setFieldsValue({
      incidentLocation: formattedAddress || "",
    });
    setLocationCoordinates(location);
    setLocationModalOpen(false);
  };

  const handleLocationModalCancel = () => {
    setLocationModalOpen(false);
  };

  const handleLocationFocus = (e) => {
    setLocationModalOpen(true);
    e.target.blur();
  };

  return (
    <>
      <LocationModal
        location={form.getFieldValue("incidentLocation")}
        title={t({
          id: "incident-report-form.location-modal-title",
          message: "Enter the location of the incident",
        })}
        open={locationModalOpen}
        onOk={handleLocationModalOk}
        onCancel={handleLocationModalCancel}
      />
      <Form.Item
        name="severity"
        labelCol={{ span: 24 }}
        label={t({
          id: "incident-report-form.severity",
          message: "Severity",
        })}
        required
        rules={[
          {
            required: true,
            message: t({
              id: "incident-report-form.rules.severity",
              message: "Please select the severity",
            }),
          },
        ]}
      >
        <Radio.Group size={isSmallScreen ? "medium" : "large"}>
          {severities.map((severity) => (
            <Radio.Button key={severity.value} value={severity.value}>
              {severity.name}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Row gutter={64}>
        <Col span={24} lg={12}>
          <Form.Item
            name="peopleInvolved"
            labelCol={{ span: 24 }}
            label={t({
              id: "incident-report-form.people",
              message: "People Involved",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "incident-report-form.rules.people",
                  message: "Please enter the people involved",
                }),
              },
            ]}
          >
            <Select
              mode="tags"
              labelInValue
              optionFilterProp="label"
              placeholder={t({
                id: "incident-report-form.placeholder-default",
                message: "Type or Select",
              })}
              options={userOptions}
            />
          </Form.Item>
          <Form.Item
            name="witnesses"
            labelCol={{ span: 24 }}
            label={t({
              id: "incident-report-form.witnesses-view",
              message: "Witnesses",
            })}
          >
            <Select
              mode="tags"
              labelInValue
              optionFilterProp="label"
              placeholder={t({
                id: "incident-report-form.placeholder-default",
                message: "Type or Select",
              })}
              options={userOptions}
            />
          </Form.Item>
          <Form.Item
            name="partsInvolved"
            labelCol={{ span: 24 }}
            label={t({
              id: "incident-report-form.parts",
              message: "Assets/Parts involved",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "incident-report-form.rules.parts",
                  message: "Please enter the assets/parts involved",
                }),
              },
            ]}
          >
            <Select
              mode="tags"
              labelInValue
              optionFilterProp="label"
              placeholder={t({
                id: "incident-report-form.assets-prompt",
                message: "Type or Select Assets Involved",
              })}
              options={equipmentOptions}
            />
          </Form.Item>
          <Form.Item
            name="discoveryFactor"
            labelCol={{ span: 24 }}
            label={t({
              id: "incident-report-form.discovery-factor",
              message: "How Incident Discovered",
            })}
          >
            <Input
              placeholder={t({
                id: "incident-report-form.discovery-factor-prompt",
                message: "Add how the incident was discovered",
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item
            name="workOrderNb"
            labelCol={{ span: 24 }}
            label={t({
              id: "incident-report-form.wo-number-view",
              message: "WO Number",
            })}
          >
            <Select
              placeholder={t({
                id: "incident-report-form.wo-number-prompt",
                message: "Select the work order number",
              })}
              showSearch
              optionFilterProp="label"
              options={workOrderNumberOptions}
            />
          </Form.Item>
          <Form.Item
            name="incidentLocation"
            labelCol={{ span: 24 }}
            label={t({
              id: "incident-report-form.location",
              message: "Enter Incident Location",
            })}
            required
            rules={[
              {
                required: true,
                message: t({
                  id: "incident-report-form.rules.location",
                  message: "Please enter the location",
                }),
              },
            ]}
          >
            <Input
              placeholder={t({
                id: "incident-report-form.placeholder-location",
                message: "Enter Incident Location",
              })}
              onFocus={handleLocationFocus}
            />
          </Form.Item>

          <Form.Item
            name="incidentDate"
            labelCol={{ span: 24 }}
            label={t({
              id: "incident-report-form.incident-date",
              message: "Date & Time of Incident",
            })}
            initialValue={dayjs()}
            required
          >
            <DatePicker showTime />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
    </>
  );
}
