import React from "react";

import styles from "./AssetProperty.module.less";

export default function AssetProperty({ property, value }) {
  return (
    <div className={styles.propertiesContainer}>
      <p className={styles.property}>{property}</p>
      <div className={styles.value}>{value}</div>
    </div>
  );
}
