import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Avatar as AvatarAntD } from "antd";

import { avatarString } from "components/Avatar/helpers/avatar.helpers";

import styles from "../CustomDashboardComments.module.less";

function Comment({ comment, thread, avatarBackground }) {
  const calculateTimestamp = (createdAt) =>
    dayjs().isBefore(dayjs(createdAt)) ? dayjs().fromNow() : dayjs(createdAt).fromNow();
  const [timestamp, setTimestamp] = useState(calculateTimestamp(comment.createdAt));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(calculateTimestamp(comment.createdAt));
    }, 1000);
    return () => clearInterval(interval);
  }, [comment.createdAt]);

  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentName}>
        {comment.author?.pictureUrl && (
          <AvatarAntD src={comment.author?.pictureUrl} style={avatarBackground(thread.status === "RESOLVED")} />
        )}
        {!comment.author?.pictureUrl && (
          <AvatarAntD style={avatarBackground(thread.status === "RESOLVED", comment.author?.name)}>
            {avatarString(comment.author?.name)}
          </AvatarAntD>
        )}
        {comment.author?.name}
        <div className={styles.timestamp}>{timestamp}</div>
      </div>
      <div className={styles.commentText}>
        <div className={styles.background}>{comment.content}</div>
      </div>
    </div>
  );
}

export default Comment;
