import React from "react";
import mixpanel from "mixpanel-browser";

import { LockOutlined } from "@ant-design/icons";

const showNoAccessModal = (modal, properties = {}) => {
  mixpanel.people.increment("No Access Count");
  mixpanel.track("No Access Modal", properties);

  modal.confirm({
    icon: <LockOutlined />,
    title: "No Access",
    content: (
      <>
        <p>Sorry, you don&apos;t have access to this feature.</p>
        <h5>Let&apos;s Chat!</h5>
        <p>
          <a href="mailto:apm-contact@eh2.com">Contact us</a> to unlock it in your subscription or share how we can
          improve your experience using EH2 APM.
        </p>
      </>
    ),
    okText: "Contact us",
    onOk() {
      document.location = "mailto:apm-contact@eh2.com";
    },
    cancelText: "Maybe later",
    closable: true,
    maskClosable: true,
    centered: true,
  });
};

export default showNoAccessModal;
