import React, { useEffect } from "react";
import { App } from "antd";
import styles from "./LoadingScreen.module.less";

export default function LoadingScreen({ message: displayMessage, keyId }) {
  const { message } = App.useApp();

  useEffect(() => {
    message.open({
      key: keyId,
      type: "loading",
      content: displayMessage,
      duration: 0,
    });
    return () => message.destroy(keyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={styles.container} />;
}
