import dayjs from "dayjs";
import { max as d3max, min as d3min } from "d3-array";
import { saveAs } from "file-saver";
import { t } from "@lingui/macro";

export const convertToLocalTime = (timeUTC) => dayjs(timeUTC).format("YYYY-MM-DD HH:mm:ss");

export const colorScale = [
  "#1f77b4", // muted blue
  "#ff7f0e", // safety orange
  "#2ca02c", // cooked asparagus green
  "#d62728", // brick red
  "#9467bd", // muted purple
  "#8c564b", // chestnut brown
  "#e377c2", // raspberry yogurt pink
  "#7f7f7f", // middle gray
  "#bcbd22", // curry yellow-green
  "#17becf", // blue-teal
];

export const colorRange = ({ traces, measureID }) =>
  Array(Math.ceil(traces.length / (colorScale.length - 1)))
    .fill(colorScale)
    .flat()[traces.findIndex(({ measure }) => measure.slug === measureID)];

export const hasTrace = ({ traces, measureID }) => traces.some(({ measure }) => measure.slug === measureID);

export const nameString = (trace) =>
  trace.measure.description ? `${trace.measure.description} (${trace.measure.slug})` : trace.measure.slug;

export const singleEquipment = (traces) => {
  /*
    This function is used to show grouped legends when having multiple
    equipment on the same plot.
  */

  const equipment = {};
  traces.forEach((trace) => {
    equipment[trace.equipment.id] += 1;
  });
  return Object.keys(equipment).length === 1;
};

export const lowestValue = ({ traces, thresholds }) =>
  d3min([...traces.map((trace) => d3min(trace.y)), d3min(thresholds, (d) => d.min)]);
export const highestValue = ({ traces, thresholds }) =>
  d3max([...traces.map((trace) => d3max(trace.y)), d3max(thresholds, (d) => d.max)]);

export const minLineShapes = ({ thresholds, traces }) =>
  thresholds
    .filter(({ measureID, min }) => hasTrace({ traces, measureID }) && min)
    .map(({ measureID, min }) => ({
      type: "line",
      xref: "paper",
      x0: 0,
      y0: min,
      x1: 1,
      y1: min,
      line: {
        color: colorRange({ traces, measureID }),
        width: 1,
      },
    }));

export const maxLineShapes = ({ thresholds, traces }) =>
  thresholds
    .filter(({ measureID, max }) => hasTrace({ traces, measureID }) && max)
    .map(({ measureID, max }) => ({
      type: "line",
      xref: "paper",
      x0: 0,
      y0: max,
      x1: 1,
      y1: max,
      line: {
        color: colorRange({ traces, measureID }),
        width: 1,
      },
    }));

export const bandShapes = ({ thresholds, traces }) =>
  thresholds
    .filter(({ measureID, min, max }) => hasTrace({ traces, measureID }) && (min || max))
    .map(({ measureID, min, max }) => ({
      type: "rect",
      xref: "paper",
      x0: 0,
      y0: min || lowestValue({ traces, thresholds }),
      x1: 1,
      y1: max || highestValue({ traces, thresholds }),
      fillcolor: colorRange({ traces, measureID }),
      opacity: 0.2,
      line: {
        width: 0,
      },
    }));

export const annotationShapes = ({ annotations, showResolved = false, annotationId }) =>
  annotations.map((annotation) => {
    if (!showResolved && annotation.status !== "ACTIVE" && annotationId !== annotation.id) {
      return null;
    }
    return {
      type: "line",
      xref: "x",
      yref: "paper",
      x0: dayjs(annotation.time).format("YYYY-MM-DD HH:mm:ss"),
      y0: 0,
      x1: dayjs(annotation.time).format("YYYY-MM-DD HH:mm:ss"),
      y1: 1,
      line: {
        width: 1,
        dash: "4px",
        color: annotation.status === "ACTIVE" ? "#008B8A" : "#008B8A50",
      },
    };
  });

export const annotationItems = ({ annotations, showResolved = false, annotationId }) =>
  annotations.map((annotation, i) => {
    if (!showResolved && annotation.status !== "ACTIVE" && annotation.id !== annotationId) {
      return null;
    }
    return {
      id: annotation.id,
      x: dayjs(annotation.time).format("YYYY-MM-DD HH:mm:ss"),
      y: 1,
      text: `<b>${
        annotation.title || t({ id: "panel.annotation.plot.title-placeholder", message: "New Annotation" })
      }</b><br />${dayjs(annotation.time).format("MM-DD-YYYY HH:mm:ss")}`,
      align: "left",
      captureevents: true,
      xref: "x",
      xanchor: "left",
      yref: "paper",
      yanchor: "center",
      bgcolor: "#ffffff",
      borderwidth: 2,
      bordercolor: annotation.status === "ACTIVE" ? "#008B8A" : "#008B8A50",
      borderpad: 5,
      showarrow: false,
      xshift: -1,
      yshift: (i % 3) * -50,
    };
  });

export const exportCsv = ({ gd, name }) => {
  const colNames = [
    "time",
    ...gd.data.map((dataGroup) =>
      dataGroup?.legendgrouptitle?.text ? `${dataGroup.legendgrouptitle.text} - ${dataGroup.name}` : dataGroup.name
    ),
  ];

  const dataRows = [gd.data[0].x, ...gd.data.map((dataGroup) => dataGroup.y)];
  const transposedData = dataRows[0].map((x, i) => dataRows.map((r) => r[i]));
  const res = [colNames, ...transposedData];
  const blob = new Blob([res.join("\r\n")], { type: "text/csv" });
  saveAs(blob, `${name}.csv`);
};

export const getAxesProps = (customAxes) => {
  const defaultAxisProps = {
    ticklabelposition: "outside top",
    hoverformat: ".8~g",
    fixedrange: true,
    showline: true,
    automargin: true,
    fixedRange: true,
    color: "#494949",
    tickprefix: " ",
    ticksuffix: " ",
  };

  if (!customAxes?.length) {
    return {
      yaxis: {
        ...defaultAxisProps,
      },
    };
  }
  const axesProps = customAxes.reduce((res, axis, index) => {
    const axisKey = `yaxis${index > 0 ? index + 1 : ""}`;
    res[axisKey] = {
      ...defaultAxisProps,
      type: axis.logarithmic ? "log" : undefined,
      autorange: axis.reversed ? "reversed" : undefined,
      name: axis.name,
      side: axis.position,
      overlaying: index > 0 ? "y" : undefined,
      ticksuffix: axis.unit ? ` ${axis.unit} ` : " ",
      tickprefix: " ",
      autoshift: index > 0,
      anchor: index > 0 ? "free" : undefined,
      color: axis.colorHex,
    };
    return res;
  }, {});
  return axesProps;
};

export const getAssignedAxis = (trace, customAxes) => {
  if (!customAxes?.length) {
    return "y";
  }
  const axisIndex = customAxes?.findIndex((axis) => axis.measureIds.includes(trace?.measure?.id));
  if (axisIndex === -1) {
    return "y";
  }
  return `y${axisIndex + 1}`;
};
