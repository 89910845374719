import React, { useState, useCallback, useMemo, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import OnboardingModal from "components/OnboardingModal";

const GlobalLayoutContext = React.createContext({
  sidebarCollapsed: false,
  toggleSidebarCollapsed: () => {},
  setSidebarCollapsed: () => {},
  drawerVisible: false,
  setDrawerVisible: () => {},
});

const GlobalContextProvider = ({ children }) => {
  const location = useLocation();

  const [sidebarCollapsed, setSidebarCollapsed] = useState(JSON.parse(window.localStorage.getItem("sidebarCollapsed")));
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleSidebarCollapsed = useCallback(() => {
    setSidebarCollapsed(!sidebarCollapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.localStorage.setItem("sidebarCollapsed", JSON.stringify(sidebarCollapsed));
  }, [sidebarCollapsed]);

  useEffect(() => {
    setDrawerVisible(false);
  }, [location.pathname]);

  const values = useMemo(
    () => ({
      sidebarCollapsed,
      toggleSidebarCollapsed,
      setSidebarCollapsed,
      drawerVisible,
      setDrawerVisible,
    }),
    [drawerVisible, sidebarCollapsed, toggleSidebarCollapsed]
  );

  return (
    <GlobalLayoutContext.Provider value={values}>
      {children}
      <OnboardingModal />
    </GlobalLayoutContext.Provider>
  );
};

const useLayout = () => useContext(GlobalLayoutContext);

export { useLayout, GlobalContextProvider };
