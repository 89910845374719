import { GET_USER_ORGANIZATION_PROFILE } from "graphql/apiService";
import { useLazyQuery } from "@apollo/client";
import { getOrgFromSubdomain } from "utils/auth-utils";

export default function useGetUserOrganizationProfile({ organizationSlug } = {}) {
  const currentOrganization = getOrgFromSubdomain();

  const [getProfileQuery, { loading, data, error }] = useLazyQuery(GET_USER_ORGANIZATION_PROFILE, {
    variables: {
      organizationSlug: organizationSlug || currentOrganization,
    },
  });

  const getUserOrganizationProfile = async ({ id }) => {
    const res = await getProfileQuery({
      variables: {
        auth0Id: id,
      },
    });
    return res.data?.getUserOrganizationProfile;
  };

  const userOrganizationProfile = data?.getUserOrganizationProfile;

  return [getUserOrganizationProfile, { userOrganizationProfile, loading, error }];
}
