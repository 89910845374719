export default function populateOmittedMeasures({ telemetryData, measureFilters }) {
  const populatedMeasures = [...telemetryData.measures];

  measureFilters.forEach((filter) => {
    const { equipmentId } = filter;
    filter.measureIds.forEach((measureId) => {
      const measure = telemetryData.measures.find((m) => m.equipmentSlug === equipmentId && m.slug === measureId);
      if (!measure) {
        populatedMeasures.push({
          equipmentSlug: equipmentId,
          slug: measureId,
          values: [],
        });
      }
    });
  });

  return {
    ...telemetryData,
    measures: populatedMeasures,
  };
}
