import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { UPDATE_MY_USER_ACCOUNT_PICTURE } from "graphql/apiService";

export default function useUpdateMyUserAccountPicture() {
  const { currentOrganization } = useUser();

  const [updateMyUserAccountPictureMutation, { loading, error }] = useMutation(UPDATE_MY_USER_ACCOUNT_PICTURE, {
    variables: { organizationSlug: currentOrganization },
  });

  const updateMyUserAccountPicture = async () => {
    const res = await updateMyUserAccountPictureMutation();
    return res.data.updateMyUserAccountPicture;
  };

  return [updateMyUserAccountPicture, { loading, error }];
}
