import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { generateDashboardURL } from "utils/superset-utils";

import DashboardSelector from "../DashboardSelector";
import styles from "../../Dashboards.module.less";

function SupersetDashboard({ dashboards }) {
  const { id: dashboardId } = useParams();
  const currentDashboard = useMemo(() => dashboards.find((d) => d.id === dashboardId), [dashboards, dashboardId]);
  const dashboardUrl = generateDashboardURL({ id: currentDashboard?.externalId });

  return (
    <div className={styles.externalContainer}>
      <div className={styles.toolbar}>
        <DashboardSelector dashboard={currentDashboard} dashboards={dashboards} />
      </div>
      {currentDashboard && (
        <div className={styles.iframeContainer}>
          <iframe title="dashboard" src={dashboardUrl} frameBorder={0} height="100px" />
        </div>
      )}
    </div>
  );
}

export default SupersetDashboard;
