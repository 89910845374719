import React from "react";
import styles from "./Grid.module.less";

export default function Grid({ children, columns, columnGap: gridColumnGap, rowGap: gridRowGap, className }) {
  return (
    <div
      className={`${styles.grid} ${className && className}`}
      style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`, gridColumnGap, gridRowGap }}
    >
      {children}
    </div>
  );
}
