import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { DELETE_EQUIPMENT_ATTACHMENT } from "graphql/apiService";

export default function useDeleteEquipmentAttachment() {
  const { currentOrganization } = useUser();

  const [deleteEquipmentAttachmentMutation, { loading, error }] = useMutation(DELETE_EQUIPMENT_ATTACHMENT, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetEquipmentAttachments"],
  });

  const deleteEquipmentAttachment = async ({ id }) => {
    const res = await deleteEquipmentAttachmentMutation({ variables: { id } });
    return res.data.deleteEquipmentAttachment;
  };

  return [deleteEquipmentAttachment, { loading, error }];
}
