import React, { useEffect, useState } from "react";
import captureException from "utils/capture-sentry-exception";
import { t, Trans } from "@lingui/macro";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";
import useCustomMessage from "hooks/useCustomMessage";
import { useThreads, useCreateThread, useUpdateThreadReadState, useGetMyUserAccount } from "hooks/apiService";

import { Card, Button, List, Avatar as AvatarAntD, Select, Checkbox, Badge, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { avatarString, colorShade, stringToColor } from "components/Avatar/helpers/avatar.helpers";
import MentionsInput from "components/MentionsInput";
import Thread from "./Thread";

import styles from "./CustomDashboardComments.module.less";

function CustomDashboardComments({ handleCloseComments, dashboardId, userOrgProfileIds, setNumUnread, users }) {
  const { message } = useCustomMessage();
  const { threads, threadLoading, error: getThreadError } = useThreads({ dashboardId });
  const [createThread] = useCreateThread({ userOrgProfileIds, dashboardId });
  const [updateThreadReadState] = useUpdateThreadReadState();
  const { myUserAccount } = useGetMyUserAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const openedThread = searchParams.get("threadId");

  const [threadData, setThreadData] = useState([]);
  const [filteredThreadData, setFilteredThreadData] = useState([]);
  const [filteredAtMeThread, setFilteredAtMeThread] = useState([]);
  const [showCurrentThread, setShowCurrentThread] = useState(false);
  const [currentThread, setCurrentThread] = useState(null);
  const [input, setInput] = useState("");
  const [atMeChecked, setAtMeChecked] = useState(false);
  const [selectValue, setSelectValue] = useState("all");

  useEffect(() => {
    if (getThreadError) {
      captureException(getThreadError);
      message.error(
        t({
          id: "dashboards.errors.thread",
          message: "Failed to get Threads",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getThreadError]);

  useEffect(() => {
    if (threads) {
      const unread = threads.filter((thread) => thread.readStates?.[0].status === "UNREAD");
      const sortedThread = [...threads].reverse();
      setNumUnread(unread.length);
      setThreadData(sortedThread);
      setFilteredThreadData(sortedThread);
      setFilteredAtMeThread(sortedThread.filter((thread) => thread.atUsers.includes(myUserAccount.auth0Id)));
      setSelectValue("all");
      setShowCurrentThread(openedThread !== null);
      setCurrentThread(sortedThread.find((thread) => thread.id === openedThread));
    }
  }, [threads, myUserAccount, setNumUnread, openedThread]);

  const handldFilterChange = (value) => {
    let data;
    setSelectValue(value);
    switch (value) {
      case "read":
        data = threadData.filter((thread) => thread.readStates?.[0].status === "READ");
        break;
      case "unread":
        data = threadData.filter((thread) => thread.readStates?.[0].status === "UNREAD");
        break;
      case "resolved":
        data = threadData.filter((thread) => thread.status === "RESOLVED");
        break;
      default:
        data = threadData;
    }
    setFilteredThreadData(data);
    setFilteredAtMeThread(data.filter((thread) => thread.atUsers.includes(myUserAccount.auth0Id)));
  };

  const options = [
    { label: "All Statuses", value: "all" },
    { label: "Read", value: "read" },
    { label: "Unread", value: "unread" },
    { label: "Resolved", value: "resolved" },
  ];

  const closeButton = () => (
    <Button className={styles.closeButton} shape="circle" type="text" onClick={handleCloseComments}>
      <CloseOutlined />
    </Button>
  );

  const handleSelectThread = (thread) => {
    searchParams.set("threadId", thread.id);
    setSearchParams(searchParams, { replace: true });

    setCurrentThread(thread);
    setShowCurrentThread(true);
    updateReadStatus(thread.readStates?.[0].id, "READ");
  };

  const handleCloseThread = () => {
    searchParams.delete("threadId");
    setSearchParams(searchParams, { replace: true });
    setCurrentThread(null);
    setShowCurrentThread(false);
  };

  const updateReadStatus = async (readStateId, status) => {
    await updateThreadReadState({ input: { id: readStateId, status } });
  };

  const avatarBackground = (resolved, name) =>
    resolved ? { background: "#00000073" } : { background: colorShade(stringToColor(name || "default"), 40) };

  const renderThread = (thread) => (
    <div className={styles.threadItemContainer}>
      <Badge color={thread.readStates?.[0].status === "READ" ? "transparent" : "red"} />
      <div className={styles.textContainer}>
        <div className={styles.threadName}>
          {thread.author?.pictureUrl && (
            <AvatarAntD src={thread.author?.pictureUrl} style={avatarBackground(thread.status === "RESOLVED")} />
          )}
          {!thread.author?.pictureUrl && (
            <AvatarAntD style={avatarBackground(thread.status === "RESOLVED", thread.author?.name)}>
              {avatarString(thread.author?.name)}
            </AvatarAntD>
          )}
          {thread.author?.name}
          <div className={styles.timestamp}>{dayjs(thread.createdAt).fromNow()}</div>
        </div>
        <div className={styles.threadText}>{thread.content}</div>
      </div>
    </div>
  );

  const handleSubmitThread = async () => {
    if (input !== "") {
      const threadInput = { content: input };
      try {
        await createThread({
          input: threadInput,
        });
        setInput("");

        message.success(
          t({
            id: "thread.success.add,",
            message: "Successfully added comment thread",
          })
        );
      } catch (err) {
        captureException(err);
        message.error(
          t({
            id: "thread.error.add",
            message: "Failed to add comment thread",
          })
        );
      }
    }
  };

  return (
    <div className={styles.siderContainer}>
      <div className={styles.threadContainer}>
        <div className={styles.threadHeader}>
          <Card
            bordered={false}
            title={<Trans id="dashboards.all-comments">All Comments</Trans>}
            extra={closeButton()}
            bodyStyle={{ height: "40px", padding: "5px" }}
          >
            <div className={styles.threadTool}>
              <Select
                className={styles.selectStatus}
                onChange={handldFilterChange}
                options={options}
                value={selectValue}
              />
              <div className={styles.threadCheckbox}>
                <Trans id="threads.tools.atMe">@Me</Trans>
                <label htmlFor="checkbox">
                  {/* Including comment here for an odd behavior where the label component complains about 
                    missing control without this line */}
                  <Checkbox id="checkbox" checked={atMeChecked} onChange={(e) => setAtMeChecked(e.target.checked)} />
                </label>
              </div>
            </div>
          </Card>
        </div>
        <div className={styles.threads}>
          {threadLoading ? (
            <div className={styles.spinContainer}>
              <Spin />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={atMeChecked ? filteredAtMeThread : filteredThreadData}
              renderItem={(thread) => (
                <List.Item
                  key={thread.id}
                  onClick={() => handleSelectThread(thread)}
                  style={thread.status === "RESOLVED" ? { backgroundColor: "#F0F0F0" } : {}}
                >
                  {renderThread(thread)}
                </List.Item>
              )}
            />
          )}
        </div>
        <div className={styles.input}>
          <MentionsInput users={users} input={input} setInput={setInput} />
          <div className={styles.inputButton}>
            <Button onClick={() => handleSubmitThread()} type="primary">
              <Trans id="threads.input.comment">Comment</Trans>
            </Button>
            <Button onClick={handleCloseComments}>
              <Trans id="threads.input.cancel">Cancel</Trans>
            </Button>
          </div>
        </div>
      </div>
      {currentThread && (
        <Thread
          showCurrentThread={showCurrentThread}
          handleCloseThread={handleCloseThread}
          onToggleComments={handleCloseComments}
          thread={currentThread}
          users={users}
          avatarBackground={avatarBackground}
        />
      )}
    </div>
  );
}

export default CustomDashboardComments;
