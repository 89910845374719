import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_PANEL } from "graphql/apiService";

export default function useCreatePanel() {
  const { currentOrganization } = useUser();

  const [createPanelMutation, { loading, error }] = useMutation(CREATE_PANEL, {
    variables: { organizationSlug: currentOrganization },
    refetchQueries: ["GetDashboard"],
  });

  const createPanel = async ({ input, dashboardId }) => {
    const res = await createPanelMutation({ variables: { input, dashboardId } });
    return res.data.createPanel;
  };

  return [createPanel, { loading, error }];
}
