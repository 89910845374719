import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { intervalToAbsoluteRange } from "utils/time-utils";

export default function usePanelTime({ panel }) {
  const [searchParams] = useSearchParams();
  const [startTime, setStartTime] = useState(dayjs().add(-3, "hours"));
  const [endTime, setEndTime] = useState(dayjs());
  const [timeInterval, setTimeInterval] = useState("3h");
  const [timeMode, setTimeMode] = useState(undefined);
  const [zoomStartTime, setZoomStartTime] = useState(dayjs().add(-3, "hours"));
  const [zoomEndTime, setZoomEndTime] = useState(dayjs());
  const [pollInterval, setPollInterval] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const annotationId = searchParams.get("annotationId");

  useEffect(() => {
    if (panel?.pollInterval) {
      setPollInterval(panel.pollInterval);
    }
    if (panel && !annotationId) {
      initializeTime(panel.time);
    }
    if (!panel) {
      initializeTime({
        type: "REALTIME",
        interval: "3h",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panel]);

  useEffect(() => {
    if (timeMode && timeInterval) {
      if (timeMode === "HISTORICAL") {
        setStartTime(zoomStartTime);
        setEndTime(zoomEndTime);
        setPollInterval(0);
      } else if (timeMode === "REALTIME") {
        const time = intervalToAbsoluteRange(timeInterval);
        setStartTime(dayjs(time.from));
        setEndTime(dayjs(time.to));
        setZoomStartTime(dayjs(time.from));
        setZoomEndTime(dayjs(time.to));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInterval, timeMode]);

  const initializeTime = (time) => {
    if (!time) return;
    const { type, from, to, interval } = time;
    if (interval) {
      setTimeInterval(interval);
    }
    if (from) {
      setStartTime(dayjs(from));
      setZoomStartTime(dayjs(from));
    }
    if (to) {
      setEndTime(dayjs(to));
      setZoomEndTime(dayjs(to));
    }
    setTimeMode(type);
  };

  const handleOnRelayout = (e) => {
    if (e["xaxis.range"]) {
      setZoomStartTime(dayjs(e["xaxis.range"][0]));
      setZoomEndTime(dayjs(e["xaxis.range"][1]));
    } else if (e["xaxis.range[0]"]) {
      setZoomStartTime(dayjs(e["xaxis.range[0]"]));
      setZoomEndTime(dayjs(e["xaxis.range[1]"]));
    }
  };

  return {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    timeInterval,
    setTimeInterval,
    timeMode,
    setTimeMode,
    pollInterval,
    setPollInterval,
    refetch,
    setRefetch,
    handleOnRelayout,
    initializeTime,
    zoomStartTime,
    zoomEndTime,
  };
}
