import React from "react";
import Card from "components/Card";

import { isServiceApm } from "utils/auth-utils";

import bannerBackground from "assets/banner.svg";
import { ReactComponent as LimbleIcon } from "./assets/limble.svg";
import EgnyteIcon from "./assets/egnyte";

import styles from "./DocumentCenter.module.less";

const isService = isServiceApm();

export default function DocumentCenter() {
  return (
    <div className={styles.container}>
      <div className={styles.topBanner} style={{ backgroundImage: `url(${bannerBackground})` }} />
      <div className={styles.bannerText}>
        <div className={styles.bannerTitle}>Document Center</div>
      </div>
      <div className={styles.cardsContainer}>
        {isService && (
          <Card className={styles.card}>
            <h2>Operations And Maintenance</h2>
            <div className={styles.iconContainer}>
              <LimbleIcon width={90} style={{ width: 90 }} />
            </div>
            <div className={styles.redirectPrompt}>Redirect to Limble CMMS</div>
          </Card>
        )}
        <Card className={styles.card}>
          <h2>Product Documentation</h2>
          <a href="https://eh2.egnyte.com" target="blank" rel="noreferrer">
            <div className={styles.iconContainer}>
              <EgnyteIcon width={80} />
            </div>
          </a>
          <div className={styles.redirectPrompt}>Redirect to Egnyte</div>
        </Card>
      </div>
    </div>
  );
}
