/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tree } from "antd";

import { DownOutlined } from "@ant-design/icons";
import { findNestedObj, getParentKey } from "../../helpers/nestedObject";

import styles from "./AssetTree.module.less";

export default function AssetTree({ assetTree, isShared }) {
  const navigate = useNavigate();
  const { assetId } = useParams();

  const [selectedKey, setSelectedKey] = useState(assetId || (isShared ? "shared" : "global"));
  const [expandedKeys, setExpandedKeys] = useState([assetId || (isShared ? "shared" : "global")]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  useEffect(() => {
    setSelectedKey(assetId || (isShared ? "shared" : "global"));
    if (assetId) {
      const newExpandedKeys = findMatchingKeys("key", assetId, assetTree);
      setExpandedKeys((currentKeys) => [...new Set([...currentKeys, ...newExpandedKeys])]);
      setAutoExpandParent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTree, assetId]);

  const findMatchingKeys = useCallback(
    (key, value, array) => [
      ...new Set(
        [
          ...array.filter((item) => item.children).map((item) => findMatchingKeys(key, value, item.children)),
          ...array
            .filter((item) => item[key].toLowerCase().indexOf(value.toLowerCase()) > -1)
            .map((item) => (item.type === "FOLDER" ? item.key : getParentKey(item.key, assetTree))),
        ].flat()
      ),
    ],
    [assetTree]
  );

  const handleSelectAsset = (selectedKeys) => {
    if (!selectedKeys[0]) return;
    setSelectedKey(selectedKeys[0]);
    if (selectedKeys[0] === "global") {
      navigate("/assets");
      return;
    }
    const selectedNode = findNestedObj(assetTree, "key", selectedKeys[0]);
    navigate(`/assets/${selectedNode.key}`);
  };

  const treeData = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        const title = <span>{item.title}</span>;
        if (item.children) {
          return {
            ...item,
            title,
            children: loop(item.children.sort((a, b) => a.title.localeCompare(b.title))),
          };
        }
        return {
          ...item,
          title,
        };
      });
    return loop(assetTree);
  }, [assetTree]);

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  return (
    <div className={styles.assetTreeContainer}>
      <Tree
        autoExpandParent={autoExpandParent}
        expandedKeys={expandedKeys}
        onExpand={onExpand}
        onSelect={handleSelectAsset}
        selectedKeys={[selectedKey]}
        showIcon
        switcherIcon={<DownOutlined />}
        titleRender={(node) => <div className={styles.assetTitle}> {node.title}</div>}
        treeData={treeData}
      />
    </div>
  );
}
