/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import dayjs from "dayjs";

import { Row, Col, Input, DatePicker, Form, AutoComplete } from "antd";
import { EditOutlined } from "@ant-design/icons";
import SignatureModal from "../../SignatureModal";
import styles from "./NamesAndSignatures.module.less";

export default function NamesAndSignatures({ form, userOptions, authorName }) {
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [currentSignatureTarget, setCurrentSignatureTarget] = useState();
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  const initialSupervisorOptions = useMemo(
    () =>
      userOptions
        .filter((user, index, arr) => arr.findIndex((option) => option.label === user.label) === index)
        .map((user) => ({ value: user.label })),
    [userOptions]
  );

  useEffect(() => {
    if (initialSupervisorOptions?.length) {
      setSupervisorOptions(initialSupervisorOptions);
    }
  }, [initialSupervisorOptions]);

  const onSignatureSave = ({ dataURL, isEmpty = false }) => {
    if (isEmpty) {
      form.setFieldsValue({
        [`${currentSignatureTarget}SignatureDate`]: null,
        [`${currentSignatureTarget}Signature`]: null,
      });
    } else {
      form.setFieldsValue({
        [`${currentSignatureTarget}SignatureDate`]: dayjs(),
        [`${currentSignatureTarget}Signature`]: dataURL,
      });
    }
  };

  const Signature = ({ signatureTarget }) => {
    const signatureUrl = form.getFieldValue(`${signatureTarget}Signature`);
    return (
      <div className={styles.signatureContainer}>
        {signatureUrl ? (
          <div
            className={styles.signatureImageContainer}
            onClick={() => {
              setCurrentSignatureTarget(signatureTarget);
              setSignatureModalVisible(true);
            }}
          >
            <img className={styles.signatureImage} src={signatureUrl} alt={`${signatureTarget} signature`} />

            <div className={styles.iconContainer}>
              <EditOutlined />
            </div>
          </div>
        ) : (
          <div
            className={styles.addButton}
            onClick={() => {
              setCurrentSignatureTarget(signatureTarget);
              setSignatureModalVisible(true);
            }}
          >
            <Trans id="incident-report-form.signature.add">Add Signature</Trans>
          </div>
        )}
      </div>
    );
  };

  return (
    <Row gutter={16}>
      <SignatureModal
        open={signatureModalVisible}
        setOpen={setSignatureModalVisible}
        onSignatureSave={onSignatureSave}
        currentSignatureURL={form.getFieldValue(`${currentSignatureTarget}Signature`)}
      />
      <Col span={24} lg={12}>
        <Form.Item
          name="supervisorName"
          labelCol={{ span: 24 }}
          label={t({
            id: "incident-report-form.supervisor-name",
            message: "Supervisor Name",
          })}
          required
          rules={[
            {
              required: true,
              message: t({
                id: "incident-report-form.rules.supervisor",
                message: "Please enter the supervisor name",
              }),
            },
          ]}
        >
          <AutoComplete
            options={supervisorOptions}
            onSearch={(text) => {
              setSupervisorOptions(
                initialSupervisorOptions.filter((option) => option.value.toLowerCase().includes(text.toLowerCase()))
              );
            }}
            placeholder={t({
              id: "incident-report-form.placeholder-default",
              message: "Type or Select",
            })}
          />
        </Form.Item>
        <Form.Item
          name="supervisorSignatureDate"
          label={t({
            id: "incident-report-form.date",
            message: "Date",
          })}
        >
          <DatePicker
            disabled
            placeholder={t({
              id: "incident-report-form.signature-date-placeholder",
              message: "Sign to set date",
            })}
          />
        </Form.Item>
        <Form.Item
          name="authorName"
          labelCol={{ span: 24 }}
          label={t({
            id: "incident-report-form.author-name",
            message: "Report Author Name",
          })}
          initialValue={authorName}
          rules={[
            {
              required: true,
              message: t({
                id: "incident-report-form.rules.supervisor",
                message: "Please enter the supervisor name",
              }),
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="employeeSignatureDate"
          label={t({
            id: "incident-report-form.employee-signature-date",
            message: "Date",
          })}
        >
          <DatePicker
            disabled
            placeholder={t({
              id: "incident-report-form.signature-date-placeholder",
              message: "Sign to set date",
            })}
          />
        </Form.Item>
      </Col>
      <Col span={24} lg={12}>
        <Form.Item
          name="supervisorSignature"
          label={t({
            id: "incident-report-form.supervisor-signature",
            message: "Supervisor Signature",
          })}
          required
          rules={[
            {
              required: true,
              message: t({
                id: "incident-report-form.rules.employee-signature",
                message: "Signature is required",
              }),
            },
          ]}
        >
          <Signature signatureTarget="supervisor" />
        </Form.Item>
        <Form.Item
          name="employeeSignature"
          label={t({
            id: "incident-report-form.employee-signature",
            message: "Report Author Signature",
          })}
          required
          rules={[
            {
              required: true,
              message: t({
                id: "incident-report-form.rules.employee-signature",
                message: "Signature is required",
              }),
            },
          ]}
        >
          <Signature signatureTarget="employee" />
        </Form.Item>
      </Col>
    </Row>
  );
}
