import { useUser } from "context/UserContext";
import { GET_EQUIPMENT_ATTACHMENTS } from "graphql/apiService";
import useQuery from "hooks/useQuery";

export default function useEquipmentList({ equipmentId }) {
  const { currentOrganization } = useUser();

  const { data, loading, error } = useQuery(GET_EQUIPMENT_ATTACHMENTS, {
    variables: {
      organizationSlug: currentOrganization,
      equipmentId,
    },
  });

  const equipmentAttachments = data?.getAttachmentsByEquipment || [];

  return { equipmentAttachments, loading, error };
}
