import React from "react";
import {
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  FilePptOutlined,
  FileExcelOutlined,
  FileWordOutlined,
} from "@ant-design/icons";

import styles from "./FileIcon.module.less";

function FileIcon({ contentType, ...props }) {
  if (contentType === "image/png") return <FileImageOutlined {...props} />;
  if (contentType === "image/gif") return <FileGifOutlined {...props} />;
  if (contentType === "image/jpg" || contentType === "image/jpeg") return <FileJpgOutlined {...props} />;
  if (contentType === "application/pdf") return <FilePdfOutlined {...props} />;
  if (contentType === "application/zip") return <FileZipOutlined {...props} />;
  if (contentType.includes("word")) return <FileWordOutlined {...props} />;
  if (contentType.includes("excel") || contentType.includes("spreadsheet")) return <FileExcelOutlined {...props} />;
  if (contentType.includes("powerpoint") || contentType.includes("presentation")) return <FilePptOutlined {...props} />;
  return <FileOutlined className={styles.fileIcon} {...props} />;
}

export default FileIcon;
