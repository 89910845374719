import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { t } from "@lingui/macro";

import { useUserRoles, useUserOrganizationProfile } from "hooks/apiService";
import useCustomMessage from "hooks/useCustomMessage";

import { Card } from "antd";
import UserRoles from "../UserRoles";

function Member() {
  const { id } = useParams();
  const { message } = useCustomMessage();

  const [searchParams] = useSearchParams();
  const organizationSlug = searchParams.get("organizationSlug");

  const {
    userOrganizationProfile,
    loading: userLoading,
    error: userError,
  } = useUserOrganizationProfile({ id, organizationSlug });
  const { userRoles, error: userRolesError } = useUserRoles({ userId: id, organizationSlug });

  useEffect(() => {
    if (userError) {
      message.error(t({ id: "messages.error.get-user", message: "Failed to get user" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userError]);

  useEffect(() => {
    if (userRolesError) {
      message.error(t({ id: "messages.error.get-user-role", message: "Failed to get user roles" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRolesError]);

  if (!userOrganizationProfile || !userRoles) {
    return <Card loading={userLoading} />;
  }
  return <UserRoles selectedUser={userOrganizationProfile.userAccount} userRoles={userRoles} />;
}

export default Member;
