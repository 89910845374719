import { useUser } from "context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATE_THREAD, GET_THREADS_BY_DASHBOARD } from "graphql/apiService";

export default function useCreateThread({ dashboardId, userOrgProfileIds }) {
  const { currentOrganization } = useUser();

  const [createThreadMutation, { loading, error }] = useMutation(CREATE_THREAD, {
    variables: { organizationSlug: currentOrganization },
    update: (cache, { data: { createThread: newThread } }) => {
      const { getThreadsByDashboard: threads } = cache.readQuery({
        query: GET_THREADS_BY_DASHBOARD,
        variables: {
          organizationSlug: currentOrganization,
          dashboardId,
        },
      });
      cache.writeQuery({
        query: GET_THREADS_BY_DASHBOARD,
        variables: {
          organizationSlug: currentOrganization,
          dashboardId,
        },
        data: {
          getThreadsByDashboard: [...threads, newThread],
        },
      });
    },
    refetchQueries: ["GetThreadsByDashboard"],
  });

  const createThread = async ({ input }) => {
    const res = await createThreadMutation({
      variables: { input: { dashboardId, userOrgProfileIds, ...input } },
    });
    return res.data.createThread;
  };

  return [createThread, { loading, error }];
}
