import React from "react";
import { Button } from "antd";
import Card from "components/Card";

import bannerBackground from "assets/banner.svg";
import { ReactComponent as FeedbackIcon } from "./assets/feedback.svg";

import styles from "./HelpCenter.module.less";

export default function ConnectWithEH2() {
  return (
    <div className={styles.container}>
      <div className={styles.topBanner} style={{ backgroundImage: `url(${bannerBackground})` }} />
      <div className={styles.bannerText}>
        <div className={styles.bannerTitle}>General Support</div>
      </div>
      <div className={styles.cardsContainer}>
        <Card className={styles.card}>
          <div className={styles.iconContainer}>
            <FeedbackIcon />
            <span className={styles.contact}>Give Feedback</span>
          </div>
          <Button size="large" type="primary" className={styles.button}>
            support@eh2.com
          </Button>
          <div className={styles.mainText}>
            Your experience is vital to us. If we amazed you or there&apos;s an area where we can improve, let us know.
            Your feedback helps us enhance our services.
          </div>
        </Card>
      </div>
    </div>
  );
}
