import { gql } from "@apollo/client";
import {
  BASIC_ALARM,
  BASIC_TRIGGERED_ALARM,
  BASIC_ANNOTATION_MESSAGE,
  BASIC_DASHBOARD,
  BASIC_EQUIPMENT,
  BASIC_MEASURE,
  BASIC_THREAD,
  BASIC_COMMENT,
  BASIC_THREAD_READ_STATE,
  EQUIPMENT_AND_MEASURES,
  FULL_ALARM,
  FULL_TRIGGERED_ALARM,
  FULL_ALARM_RULE,
  FULL_ANNOTATION,
  FULL_DASHBOARD,
  FULL_EQUIPMENT,
  FULL_INCIDENT_REPORT,
  FULL_ORGANIZATION_DATA_PARTNER,
  FULL_ORGANIZATION_PROFILE,
  FULL_PANEL,
  FULL_USER_ACCOUNT,
  FULL_USER_PROFILE,
  MIN_EQUIPMENT,
  ALARM_RULE_WITH_FULL_MEASURES,
} from "./fragments";

export const GET_EQUIPMENT = gql`
  ${FULL_EQUIPMENT}
  query GetEquipment($organizationSlug: String!, $id: UUID!) {
    getEquipment(organizationSlug: $organizationSlug, id: $id) {
      ...FullEquipment
    }
  }
`;

export const LIST_EQUIPMENT = gql`
  ${MIN_EQUIPMENT}
  query GetAllEquipment($organizationSlug: String!) {
    getAllEquipment(organizationSlug: $organizationSlug) {
      ...MinEquipment
    }
  }
`;

export const LIST_EQUIPMENT_AND_MEASURES = gql`
  ${EQUIPMENT_AND_MEASURES}
  query GetAllEquipment($organizationSlug: String!) {
    getAllEquipment(organizationSlug: $organizationSlug) {
      ...EquipmentAndMeasures
    }
  }
`;

export const CREATE_EQUIPMENT = gql`
  ${FULL_EQUIPMENT}
  mutation CreateEquipment($organizationSlug: String!, $input: CreateEquipmentInput!) {
    createEquipment(organizationSlug: $organizationSlug, input: $input) {
      ...FullEquipment
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  ${BASIC_EQUIPMENT}
  ${BASIC_MEASURE}
  mutation UpdateEquipment($organizationSlug: String!, $input: UpdateEquipmentInput!) {
    updateEquipment(organizationSlug: $organizationSlug, input: $input) {
      ...BasicEquipment
      measures {
        ...BasicMeasure
      }
    }
  }
`;

export const DELETE_EQUIPMENT = gql`
  mutation DeleteEquipment($organizationSlug: String!, $id: UUID!) {
    deleteEquipment(organizationSlug: $organizationSlug, id: $id) {
      id
    }
  }
`;

export const GET_EQUIPMENT_ATTACHMENTS = gql`
  query GetEquipmentAttachments($organizationSlug: String!, $equipmentId: UUID!) {
    getAttachmentsByEquipment(organizationSlug: $organizationSlug, equipmentId: $equipmentId) {
      id
      fileName
      upload
      s3Url
      contentType
      contentLength
      createdAt
      updatedAt
      createdBy
    }
  }
`;

export const CREATE_EQUIPMENT_ATTACHMENT = gql`
  mutation CreateEquipmentAttachment($organizationSlug: String!, $input: CreateEquipmentAttachmentInput!) {
    createEquipmentAttachment(organizationSlug: $organizationSlug, input: $input) {
      id
      uploadUrl
    }
  }
`;

export const DELETE_EQUIPMENT_ATTACHMENT = gql`
  mutation DeleteEquipmentAttachment($organizationSlug: String!, $id: UUID!) {
    deleteEquipmentAttachment(organizationSlug: $organizationSlug, id: $id) {
      id
    }
  }
`;

export const MARK_EQUIPMENT_ATTACHMENT_UPLOADED = gql`
  mutation MarkEquipmentAttachmentAsUploaded($organizationSlug: String!, $id: UUID!) {
    markEquipmentAttachmentAsUploaded(organizationSlug: $organizationSlug, id: $id) {
      id
    }
  }
`;

export const GET_SHARED_EQUIPMENT_MEASURES = gql`
  query GetSharedEquipmentMeasures($organizationSlug: String!, $equipmentId: String!) {
    getSharedEquipmentMeasures(organizationSlug: $organizationSlug, equipmentId: $equipmentId) {
      receivingOrganizationSlug
      allMeasures
      measures {
        id
        slug
        description
        unit
      }
    }
  }
`;

export const GET_SHARED_EQUIPMENT_ORGANIZATIONS = gql`
  query GetSharedEquipmentMeasures($organizationSlug: String!, $equipmentId: String!) {
    getSharedEquipmentMeasures(organizationSlug: $organizationSlug, equipmentId: $equipmentId) {
      receivingOrganizationSlug
    }
  }
`;

export const SET_SHARED_EQUIPMENT_MEASURES = gql`
  mutation SetSharedEquipmentMeasures(
    $organizationSlug: String!
    $receivingOrganizationSlugs: [String]!
    $equipmentId: String!
    $measureIds: [String]
    $allMeasures: Boolean
  ) {
    setSharedEquipmentMeasures(
      organizationSlug: $organizationSlug
      receivingOrganizationSlugs: $receivingOrganizationSlugs
      equipmentId: $equipmentId
      measureIds: $measureIds
      allMeasures: $allMeasures
    ) {
      id
    }
  }
`;

export const GENERATE_MEASURES = gql`
  ${BASIC_MEASURE}
  mutation GenerateMeasures($organizationSlug: String!, $equipmentId: UUID!) {
    generateMeasures(organizationSlug: $organizationSlug, equipmentId: $equipmentId) {
      ...BasicMeasure
    }
  }
`;

export const GET_MEASURES_BY_EQUIPMENT = gql`
  ${BASIC_MEASURE}
  query GetMeasuresByEquipment($organizationSlug: String!, $equipmentId: UUID!) {
    getMeasuresByEquipment(organizationSlug: $organizationSlug, equipmentId: $equipmentId) {
      ...BasicMeasure
    }
  }
`;

export const UPDATE_MEASURE = gql`
  ${BASIC_MEASURE}
  mutation UpdateMeasure($organizationSlug: String!, $input: UpdateMeasureInput!) {
    updateMeasure(organizationSlug: $organizationSlug, input: $input) {
      ...BasicMeasure
    }
  }
`;

export const GET_PANEL = gql`
  ${FULL_PANEL}
  query GetPanel($organizationSlug: String!, $id: UUID!) {
    getPanel(organizationSlug: $organizationSlug, id: $id) {
      ...FullPanel
    }
  }
`;

export const GET_PANELS_BY_ORGANIZATION = gql`
  ${FULL_PANEL}
  query GetPanelsByOrganization($organizationSlug: String!) {
    getPanelsByOrganization(organizationSlug: $organizationSlug) {
      ...FullPanel
    }
  }
`;

export const CREATE_PANEL = gql`
  ${FULL_PANEL}
  ${BASIC_MEASURE}
  mutation CreatePanel($organizationSlug: String!, $input: CreatePanelInput!, $dashboardId: String) {
    createPanel(organizationSlug: $organizationSlug, input: $input, dashboardId: $dashboardId) {
      ...FullPanel
      equipment {
        id
        name
        slug
        measures {
          ...BasicMeasure
        }
      }
    }
  }
`;

export const UPDATE_PANEL = gql`
  ${FULL_PANEL}
  mutation UpdatePanel($organizationSlug: String!, $input: UpdatePanelInput!) {
    updatePanel(organizationSlug: $organizationSlug, input: $input) {
      ...FullPanel
    }
  }
`;

export const DELETE_PANEL = gql`
  mutation DeletePanel($organizationSlug: String!, $id: UUID!) {
    deletePanel(organizationSlug: $organizationSlug, id: $id) {
      id
      equipmentIds
    }
  }
`;

export const GET_DASHBOARD = gql`
  ${FULL_DASHBOARD}
  query GetDashboard($organizationSlug: String!, $id: UUID!) {
    getDashboard(organizationSlug: $organizationSlug, id: $id) {
      ...FullDashboard
    }
  }
`;

export const GET_DASHBOARDS_BY_ORGANIZATION = gql`
  ${BASIC_DASHBOARD}
  query GetDashboardsByOrganization($organizationSlug: String!) {
    getDashboardsByOrganization(organizationSlug: $organizationSlug) {
      ...BasicDashboard
    }
  }
`;

export const CREATE_DASHBOARD = gql`
  ${FULL_DASHBOARD}
  mutation CreateDashboard($organizationSlug: String!, $input: CreateDashboardInput!) {
    createDashboard(organizationSlug: $organizationSlug, input: $input) {
      ...FullDashboard
    }
  }
`;

export const UPDATE_DASHBOARD = gql`
  ${FULL_DASHBOARD}
  mutation UpdateDashboard($organizationSlug: String!, $input: UpdateDashboardInput!) {
    updateDashboard(organizationSlug: $organizationSlug, input: $input) {
      ...FullDashboard
    }
  }
`;

export const DELETE_DASHBOARD = gql`
  mutation DeleteDashboard($organizationSlug: String!, $id: UUID!) {
    deleteDashboard(organizationSlug: $organizationSlug, id: $id) {
      id
    }
  }
`;

export const GET_THREADS_BY_DASHBOARD = gql`
  ${BASIC_THREAD}
  query GetThreadsByDashboard($organizationSlug: String!, $dashboardId: UUID!) {
    getThreadsByDashboard(organizationSlug: $organizationSlug, dashboardId: $dashboardId) {
      ...BasicThread
    }
  }
`;

export const CREATE_THREAD = gql`
  ${BASIC_THREAD}
  mutation CreateThread($organizationSlug: String!, $input: CreateThreadInput!) {
    createThread(organizationSlug: $organizationSlug, input: $input) {
      ...BasicThread
    }
  }
`;

export const UPDATE_THREAD = gql`
  ${BASIC_THREAD}
  mutation UpdateThread($organizationSlug: String!, $input: UpdateThreadInput!) {
    updateThread(organizationSlug: $organizationSlug, input: $input) {
      ...BasicThread
    }
  }
`;

export const UPDATE_THREAD_READ_STATE = gql`
  ${BASIC_THREAD_READ_STATE}
  mutation UpdateThreadReadState($organizationSlug: String!, $input: UpdateThreadReadStateInput!) {
    updateThreadReadState(organizationSlug: $organizationSlug, input: $input) {
      ...BasicThreadReadState
    }
  }
`;

export const GET_COMMENTS_BY_THREAD = gql`
  ${BASIC_COMMENT}
  query GetCommentsByThread($organizationSlug: String!, $threadId: UUID!) {
    getCommentsByThread(organizationSlug: $organizationSlug, threadId: $threadId) {
      ...BasicComment
    }
  }
`;

export const CREATE_COMMENT = gql`
  ${BASIC_COMMENT}
  mutation CreateComment($organizationSlug: String!, $input: CreateCommentInput!) {
    createComment(organizationSlug: $organizationSlug, input: $input) {
      ...BasicComment
    }
  }
`;

export const GET_ORGANIZATION_DATA_PARTNER = gql`
  ${FULL_ORGANIZATION_DATA_PARTNER}
  query GetOrganizationDataPartner($organizationSlug: String!, $id: UUID!) {
    getOrganizationDataPartner(organizationSlug: $organizationSlug, id: $id) {
      ...FullOrganizationDataPartner
    }
  }
`;

export const GET_ORGANIZATION_DATA_PARTNERS_RECEIVING = gql`
  ${FULL_ORGANIZATION_DATA_PARTNER}
  query GetOrganizationDataPartnersReceiving($organizationSlug: String!, $status: [AccessGrantStatus]) {
    getOrganizationDataPartners(
      organizationSlug: $organizationSlug
      sharingOrganizationSlug: $organizationSlug
      status: $status
    ) {
      ...FullOrganizationDataPartner
    }
  }
`;

export const GET_ORGANIZATION_DATA_PARTNERS_SHARING = gql`
  ${FULL_ORGANIZATION_DATA_PARTNER}
  query GetOrganizationDataPartnersSharing($organizationSlug: String!, $status: [AccessGrantStatus]) {
    getOrganizationDataPartners(
      organizationSlug: $organizationSlug
      receivingOrganizationSlug: $organizationSlug
      status: $status
    ) {
      ...FullOrganizationDataPartner
    }
  }
`;

export const CREATE_ORGANIZATION_DATA_PARTNER_REQUEST = gql`
  ${FULL_ORGANIZATION_DATA_PARTNER}
  mutation CreateOrganizationDataPartnerRequest($organizationSlug: String!, $receivingOrganizationSlug: String!) {
    createOrganizationDataPartnerRequest(
      organizationSlug: $organizationSlug
      receivingOrganizationSlug: $receivingOrganizationSlug
    ) {
      ...FullOrganizationDataPartner
    }
  }
`;

export const APPROVE_ORGANIZATION_DATA_PARTNER_REQUEST = gql`
  ${FULL_ORGANIZATION_DATA_PARTNER}
  mutation ApproveOrganizationDataPartnerRequest($id: UUID!, $organizationSlug: String!) {
    approveOrganizationDataPartnerRequest(id: $id, organizationSlug: $organizationSlug) {
      ...FullOrganizationDataPartner
    }
  }
`;

export const REVOKE_ORGANIZATION_DATA_PARTNER_REQUEST = gql`
  ${FULL_ORGANIZATION_DATA_PARTNER}
  mutation RevokeOrganizationDataPartnerRequest($id: UUID!, $organizationSlug: String!) {
    revokeOrganizationDataPartnerRequest(id: $id, organizationSlug: $organizationSlug) {
      ...FullOrganizationDataPartner
    }
  }
`;

export const GET_MY_USER_ACCOUNT = gql`
  ${FULL_USER_ACCOUNT}
  query GetMyUserAccount($organizationSlug: String!) {
    getMyUserAccount(organizationSlug: $organizationSlug) {
      ...FullUserAccount
      knockToken
      intercomHash
      notificationPreferences {
        email
      }
    }
  }
`;

export const UPDATE_MY_USER_ACCOUNT = gql`
  ${FULL_USER_ACCOUNT}
  mutation UpdateMyUserAccount($name: String!, $organizationSlug: String!) {
    updateMyUserAccount(name: $name, organizationSlug: $organizationSlug) {
      ...FullUserAccount
    }
  }
`;

export const UPDATE_MY_USER_ACCOUNT_PICTURE = gql`
  mutation UpdateMyUserAccountPicture($organizationSlug: String!) {
    updateMyUserAccountPicture(organizationSlug: $organizationSlug) {
      auth0Id
      uploadUrl
    }
  }
`;

export const UPLOAD_USER_ACCOUNT_PICTURE_SUCCESS = gql`
  mutation UploadUserAccountPictureSuccess($organizationSlug: String!) {
    uploadUserAccountPictureSuccess(organizationSlug: $organizationSlug)
  }
`;

export const UPDATE_MY_NOTIFICATION_PREFERENCES = gql`
  mutation UpdateMyNotificationPreferences(
    $notificationPreferences: NotificationPreferencesInput!
    $organizationSlug: String!
  ) {
    updateNotificationPreferences(
      notificationPreferences: $notificationPreferences
      organizationSlug: $organizationSlug
    ) {
      email
    }
  }
`;

export const CREATE_USER_ORGANIZATION_PROFILES = gql`
  mutation CreateUserOrganizationProfiles($invitees: [InviteeInput!]!, $organizationSlug: String!) {
    createUserOrganizationProfiles(invitees: $invitees, organizationSlug: $organizationSlug)
  }
`;

export const GET_USER_ORGANIZATION_PROFILE = gql`
  ${FULL_USER_PROFILE}
  query GetUserOrganizationProfile($auth0Id: String!, $organizationSlug: String!) {
    getUserOrganizationProfile(auth0Id: $auth0Id, organizationSlug: $organizationSlug) {
      ...FullUserProfile
    }
  }
`;

export const GET_USER_ORGANIZATION_PROFILES = gql`
  ${FULL_USER_PROFILE}
  query GetUserOrganizationProfiles($organizationSlug: String!) {
    getUserOrganizationProfiles(organizationSlug: $organizationSlug) {
      ...FullUserProfile
    }
  }
`;

export const DELETE_USER_ORGANIZATION_PROFILE = gql`
  mutation DeleteUserOrganizationProfile($auth0Id: String!, $organizationSlug: String!) {
    deleteUserOrganizationProfile(auth0Id: $auth0Id, organizationSlug: $organizationSlug) {
      id
    }
  }
`;

export const GET_ORGANIZATION_PROFILES = gql`
  ${FULL_ORGANIZATION_PROFILE}
  query GetOrganizationProfiles {
    getOrganizationProfiles {
      ...FullOrganizationProfile
    }
  }
`;

export const GET_ORGANIZATION_PROFILE = gql`
  ${FULL_ORGANIZATION_PROFILE}
  query GetOrganizationProfile($organizationSlug: String!) {
    getOrganizationProfile(organizationSlug: $organizationSlug) {
      ...FullOrganizationProfile
    }
  }
`;

export const UPDATE_ORGANIZATION_PROFILE = gql`
  mutation UpdateOrganizationProfilePicture($organizationSlug: String!) {
    updateOrganizationProfilePicture(organizationSlug: $organizationSlug) {
      organizationSlug
      uploadUrl
    }
  }
`;

export const SYNC_ORGANIZATION_PROFILES = gql`
  ${FULL_ORGANIZATION_PROFILE}
  mutation SyncOrganizationProfiles {
    syncOrganizationProfiles {
      ...FullOrganizationProfile
    }
  }
`;

export const GET_AVAILABLE_EQUIPMENT_SLUGS = gql`
  query GetAvailableEquipmentSlugs($organizationSlug: String!) {
    getAvailableEquipmentSlugs(organizationSlug: $organizationSlug)
  }
`;

export const GET_ANNOTATION = gql`
  ${FULL_ANNOTATION}
  query GetAnnotation($id: UUID!, $organizationSlug: String!) {
    getAnnotation(id: $id, organizationSlug: $organizationSlug) {
      ...FullAnnotation
    }
  }
`;

export const GET_ANNOTATIONS_BY_PANEL = gql`
  ${FULL_ANNOTATION}
  query GetAnnotationsByPanel($organizationSlug: String!, $input: GetAnnotationsInput!) {
    getAnnotationsByPanel(organizationSlug: $organizationSlug, input: $input) {
      ...FullAnnotation
    }
  }
`;

export const CREATE_ANNOTATION = gql`
  ${FULL_ANNOTATION}
  mutation CreateAnnotation($organizationSlug: String!, $input: CreateAnnotationInput!) {
    createAnnotation(organizationSlug: $organizationSlug, input: $input) {
      ...FullAnnotation
    }
  }
`;

export const MARK_ANNOTATION_AS_RESOLVED = gql`
  ${FULL_ANNOTATION}
  mutation MarkAnnotationAsResolved($id: UUID!, $organizationSlug: String!) {
    markAnnotationAsResolved(id: $id, organizationSlug: $organizationSlug) {
      ...FullAnnotation
    }
  }
`;
export const MARK_ANNOTATION_AS_ACTIVE = gql`
  ${FULL_ANNOTATION}
  mutation MarkAnnotationAsActive($id: UUID!, $organizationSlug: String!) {
    markAnnotationAsActive(id: $id, organizationSlug: $organizationSlug) {
      ...FullAnnotation
    }
  }
`;

export const CREATE_ANNOTATION_MESSAGE = gql`
  ${BASIC_ANNOTATION_MESSAGE}
  mutation CreateAnnotationMessage($organizationSlug: String!, $input: CreateAnnotationMessageInput!) {
    createAnnotationMessage(organizationSlug: $organizationSlug, input: $input) {
      ...BasicAnnotationMessage
    }
  }
`;

export const DELETE_ANNOTATION_MESSAGE = gql`
  ${BASIC_ANNOTATION_MESSAGE}
  mutation DeleteAnnotationMessage($id: UUID!, $organizationSlug: String!) {
    deleteAnnotationMessage(id: $id, organizationSlug: $organizationSlug) {
      ...BasicAnnotationMessage
    }
  }
`;

export const GET_ABLY_TOKEN_REQUEST = gql`
  query GetAblyTokenRequest($organizationSlug: String!) {
    getAblyTokenRequest(organizationSlug: $organizationSlug)
  }
`;

export const CREATE_INCIDENT_REPORT = gql`
  mutation CreateIncidentReport($organizationSlug: String!, $input: CreateIncidentReportInput!) {
    createIncidentReport(organizationSlug: $organizationSlug, input: $input) {
      id
    }
  }
`;

export const GET_INCIDENT_REPORT = gql`
  ${FULL_INCIDENT_REPORT}
  query GetIncidentReport($organizationSlug: String!, $id: UUID!) {
    getIncidentReport(organizationSlug: $organizationSlug, id: $id) {
      ...FullIncidentReport
    }
  }
`;

export const GET_INCIDENT_REPORTS_BY_ORGANIZATION = gql`
  ${FULL_INCIDENT_REPORT}
  query GetIncidentReportsByOrganization($organizationSlug: String!) {
    getIncidentReportsByOrganization(organizationSlug: $organizationSlug) {
      ...FullIncidentReport
    }
  }
`;

export const MARK_INCIDENT_REPORT_AS_WITH_SAFETY_TEAM = gql`
  mutation MarkIncidentReportAsWithSafetyTeam($organizationSlug: String!, $id: UUID!) {
    markIncidentReportAsWithSafetyTeam(organizationSlug: $organizationSlug, id: $id) {
      id
    }
  }
`;

export const UPDATE_INCIDENT_REPORT = gql`
  mutation UpdateIncidentReport($organizationSlug: String!, $input: UpdateIncidentReportInput!) {
    updateIncidentReport(organizationSlug: $organizationSlug, input: $input) {
      id
      suggestedTitle
      suggestedSummary
    }
  }
`;

export const UPDATE_REPORT_SUGGESTIONS = gql`
  mutation UpdateSuggestions($organizationSlug: String!, $id: UUID!) {
    updateSuggestions(organizationSlug: $organizationSlug, id: $id) {
      id
      suggestedTitle
      suggestedSummary
    }
  }
`;

export const DELETE_INCIDENT_REPORT = gql`
  mutation DeleteIncidentReport($organizationSlug: String!, $id: UUID!) {
    deleteIncidentReport(organizationSlug: $organizationSlug, id: $id) {
      id
    }
  }
`;

export const GET_ROLES = gql`
  query GetRoles($organizationSlug: String!, $slug: String, $type: String) {
    getRoles(organizationSlug: $organizationSlug, slug: $slug, type: $type) {
      slug
      name
      level
      module
      description
    }
  }
`;

export const GET_USER_ROLES = gql`
  query GetUserRoles($organizationSlug: String!, $userId: String!) {
    getUserRoles(organizationSlug: $organizationSlug, userId: $userId) {
      module
      slug
      name
      description
    }
  }
`;

export const SET_USER_ROLES = gql`
  mutation SetUserRoles($organizationSlug: String!, $userId: String!, $roleSlugs: [String]!) {
    setUserRoles(organizationSlug: $organizationSlug, userId: $userId, roleSlugs: $roleSlugs) {
      id
      access {
        slug
        name
      }
    }
  }
`;

export const GET_MY_PERMISSIONS = gql`
  query GetMyPermissions($organizationSlug: String!) {
    getMyPermissions(organizationSlug: $organizationSlug)
  }
`;

export const CREATE_HYDROGEN_QUESTION = gql`
  mutation CreateHydrogenQuestion($organizationSlug: String!, $question: String!) {
    createHydrogenQuestion(organizationSlug: $organizationSlug, question: $question)
  }
`;

export const GET_ALARMS = gql`
  ${BASIC_ALARM}
  query GetAlarms($organizationSlug: String!) {
    getAlarms(organizationSlug: $organizationSlug) {
      ...BasicAlarm
    }
  }
`;

export const GET_TRIGGERED_ALARMS = gql`
  ${FULL_TRIGGERED_ALARM}
  query GetTriggeredAlarms($organizationSlug: String!, $from: Date, $alarmId: UUID) {
    getTriggeredAlarms(organizationSlug: $organizationSlug, from: $from, alarmId: $alarmId) {
      ...FullTriggeredAlarm
    }
  }
`;

export const UPDATE_TRIGGERED_ALARM = gql`
  ${BASIC_TRIGGERED_ALARM}
  mutation UpdateTriggeredAlarm($organizationSlug: String!, $id: UUID!, $acknowledged: Boolean, $saved: Boolean) {
    updateTriggeredAlarm(organizationSlug: $organizationSlug, id: $id, acknowledged: $acknowledged, saved: $saved) {
      ...BasicTriggeredAlarm
    }
  }
`;

export const GET_ALARM = gql`
  ${BASIC_ALARM}
  ${FULL_ALARM_RULE}
  ${FULL_USER_ACCOUNT}
  query GetAlarm($id: UUID!, $organizationSlug: String!) {
    getAlarm(id: $id, organizationSlug: $organizationSlug) {
      ...BasicAlarm
      alarmRules {
        ...FullAlarmRule
      }
      notifyUsers {
        ...FullUserAccount
      }
    }
  }
`;

export const GET_ALARM_WITH_FULL_MEASURES = gql`
  ${ALARM_RULE_WITH_FULL_MEASURES}
  ${FULL_USER_ACCOUNT}
  query GetAlarm($id: UUID!, $organizationSlug: String!) {
    getAlarm(id: $id, organizationSlug: $organizationSlug) {
      id
      name
      description
      organizationSlug
      priority
      muted
      reportMissingData
      status
      lastTimeTriggered
      createdAt
      createdBy
      updatedAt
      changeLogs {
        name
        description
        alarmRulesSnapshot
        changeType
        createdAt
        createdBy
      }
      alarmRules {
        ...AlarmRuleWithFullMeasures
      }
      notifyUsers {
        ...FullUserAccount
      }
      plcAlarmInfo {
        type
        status
        threshold
        thresholdChangeLogs {
          id
          floatValue
          observedAt
          createdAt
        }
        timeseriesMeasure {
          id
          slug
          description
          equipment {
            id
            name
            slug
            description
          }
        }
      }
    }
  }
`;

export const CREATE_ALARM = gql`
  ${FULL_ALARM}
  mutation CreateAlarm($organizationSlug: String!, $input: CreateAlarmInput!) {
    createAlarm(organizationSlug: $organizationSlug, input: $input) {
      ...FullAlarm
    }
  }
`;

export const UPDATE_ALARM = gql`
  ${FULL_ALARM}
  mutation UpdateAlarm($organizationSlug: String!, $input: UpdateAlarmInput!) {
    updateAlarm(organizationSlug: $organizationSlug, input: $input) {
      ...FullAlarm
    }
  }
`;

export const DELETE_ALARM = gql`
  ${BASIC_ALARM}
  mutation DeleteAlarm($organizationSlug: String!, $id: UUID!) {
    deleteAlarm(organizationSlug: $organizationSlug, id: $id) {
      ...BasicAlarm
    }
  }
`;

export const GET_MY_FAVORITES = gql`
  query GetMyFavorites($organizationSlug: String!) {
    getMyFavorites(organizationSlug: $organizationSlug) {
      id
      userOrgProfileId
      itemType
      itemId
      createdAt
      updatedAt
      item {
        id
        name
        updatedAt
        ... on Dashboard {
          tags
        }
      }
    }
  }
`;

export const CREATE_FAVORITE = gql`
  mutation CreateFavorite($organizationSlug: String!, $input: FavoriteItemInput!) {
    createFavorite(organizationSlug: $organizationSlug, input: $input) {
      id
      userOrgProfileId
      itemType
      itemId
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_FAVORITE = gql`
  mutation DeleteFavorite($organizationSlug: String!, $input: FavoriteItemInput!) {
    deleteFavorite(organizationSlug: $organizationSlug, input: $input) {
      id
    }
  }
`;

export const UPDATE_MY_RECENT_MODULES = gql`
  mutation UpdateMyRecentModules($organizationSlug: String!, $module: String!) {
    updateMyRecentModules(organizationSlug: $organizationSlug, module: $module)
  }
`;

export const GET_PLANT_IMAGES = gql`
  query GetPlantImages($organizationSlug: String!) {
    getPlantImages(organizationSlug: $organizationSlug) {
      url
    }
  }
`;

export const GET_PLANT_MODELS = gql`
  query GetPlantModels($organizationSlug: String!) {
    getPlantModels(organizationSlug: $organizationSlug) {
      url
    }
  }
`;

export const GET_PART_INFO = gql`
  query GetPartInfo($organizationSlug: String!) {
    getPartInfo(organizationSlug: $organizationSlug) {
      url
    }
  }
`;
