import React from "react";
import { Link } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import dayjs from "dayjs";

import { getConfig } from "auth0_config";
import { useGetOrganizationProfiles, useSyncOrganizationProfiles } from "hooks/apiService";

import { SyncOutlined } from "@ant-design/icons";
import { ReactComponent as OpenIcon } from "assets/open-icon.svg";

import { Button, Table, Tabs } from "antd";
import Card from "components/Card";

import styles from "./SuperAdminConsole.module.less";

export default function SuperAdminConsole() {
  const { organizationProfiles, loading: loadingOrganizationProfiles } = useGetOrganizationProfiles();
  const [syncOrganizationProfiles, { loading: loadingSyncOrganizationProfiles }] = useSyncOrganizationProfiles();

  const auth0DomainRegex = /(.*)\.(.+)\.auth0\.com/;
  const auth0Domain = {
    slug: auth0DomainRegex.exec(getConfig().domain)[1],
    region: auth0DomainRegex.exec(getConfig().domain)[2],
  };

  const tableColumns = [
    {
      key: "organizationSlug",
      dataIndex: "organizationSlug",
      title: t({ id: "super-admin-console.organizations.table.column.organizationSlug", message: "Organization Slug" }),
      width: 180,
      sorter: (a, b) => a.organizationSlug.localeCompare(b.organizationSlug),
    },
    {
      key: "name",
      title: t({ id: "super-admin-console.organizations.table.column.name", message: "Name" }),
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: t({ id: "super-admin-console.organizations.table.column.created-date", message: "Created At" }),
      render: (_, record) => i18n.date(dayjs(record.createdAt), { dateStyle: "medium", timeStyle: "short" }),
      width: 220,
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      key: "action",
      title: t({ id: "super-admin-console.organizations.table.column.action", message: "Action" }),
      render: (_, record) => (
        <div className={styles.links}>
          <Link to={`/roles-admin/members?organizationSlug=${record.organizationSlug}`}>
            <Trans id="super-admin-console.organizations.table.column.action.view">Members</Trans>
          </Link>
        </div>
      ),
      width: 180,
    },
  ];

  const OrganizationTab = () => (
    <div>
      <div className={styles.organizationHeader}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://manage.auth0.com/dashboard/${auth0Domain.region}/${auth0Domain.slug}`}
        >
          <Button className={styles.buttonCenter} type="primary">
            <OpenIcon />
            <Trans id="super-admin-console.organizations.buttons.manage-auth0">Manage Auth0</Trans>
          </Button>
        </a>
        <Button
          className={styles.buttonCenter}
          type="primary"
          onClick={syncOrganizationProfiles}
          loading={loadingSyncOrganizationProfiles}
        >
          <SyncOutlined />
          <Trans id="super-admin-console.organizations.buttons.sync-auth0">Sync Organizations from Auth0</Trans>
        </Button>
      </div>
      <Table
        columns={tableColumns}
        dataSource={organizationProfiles}
        loading={loadingOrganizationProfiles}
        rowKey={(record) => record.auth0Id}
        pagination={{
          showSizeChanger: true,
        }}
      />
    </div>
  );

  const tabs = [
    {
      key: "organizations",
      label: t({ id: "super-admin-console.tabs.organization", message: "Organizations" }),
      children: <OrganizationTab />,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>
          <Trans id="super-admin-console.title">Super Admin Console</Trans>
        </h2>
      </div>
      <Card className={styles.content}>
        <Tabs className={styles.tabs} defaultActiveKey="organizations" items={tabs} />
      </Card>
    </div>
  );
}
