export default function unifyDataPartners(dataPartners) {
  const uniquePartners = {};
  dataPartners.forEach((partner) => {
    uniquePartners[partner.organization] =
      !uniquePartners[partner.organization] || partner.status === "ACTIVE" || partner.status === "PENDING"
        ? partner
        : uniquePartners[partner.organization];
  });
  return Object.values(uniquePartners);
}
