import React from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { ReactComponent as ToggleBar } from "assets/sidebar-toggle.svg";
import styles from "./SidebarToggle.module.less";

const SidebarToggle = ({ toggleCollapsed, collapsed }) => (
  <div onClick={toggleCollapsed} aria-hidden>
    <ToggleBar className={styles.toggleBar} />
    {collapsed && <RightOutlined className={styles.toggleIcon} />}
    {!collapsed && <LeftOutlined className={styles.toggleIcon} />}
  </div>
);

export default SidebarToggle;
