import React from "react";
import { Col, Divider, Row } from "antd";

import { ReactComponent as LinkIcon } from "assets/link-icon.svg";

import styles from "./AnalyticsDashboard.module.less";
import DonutChart from "./DonutChart";
import BarChart from "./BarChart";

const List = ({ items }) => (
  <div className={styles.list}>
    {items.map(([property, value]) => (
      <div key={property} className={styles.listItem}>
        <div className={styles.property}>{property}</div>
        <div className={styles.value}>{value}</div>
      </div>
    ))}
  </div>
);

export default function AnalyticsDashboard() {
  return (
    <div className={styles.grid}>
      <div className={styles.card}>
        <h2 style={styles.cardTitle}>Alarm Summary &#9432;</h2>
        <div className={styles.subtitle}>
          <span className={styles.numberDisplay}>62</span> <span className={styles.text}>Total Alarms</span>
        </div>
        <List items={[["Most Frequent:", "O2 Separator Diff. Pressure HH"]]} />
        <Divider />
        <div className={styles.secondaryTitle}>Top 3 sub-systems with most frequent alarms:</div>
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <Row gutter={[16, 16]} style={{ height: "100%", width: "100%", maxHeight: 400 }}>
            <Col span={8}>
              <div className={styles.subCard}>
                <div className={styles.subCardTitle}>Oxygen Separator</div>
                <div className={styles.subCardValue}>11</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.subCard}>
                <div className={styles.subCardTitle}>Anode Water Pump</div>
                <div className={styles.subCardValue}>11</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.subCard}>
                <div className={styles.subCardTitle}>Makeup Water Tank</div>
                <div className={styles.subCardValue}>6</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.card}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <h2 style={styles.cardTitle}>Alarm Severity &#9432;</h2>
          <div className={styles.chartContainer}>
            <DonutChart
              labels={["Priority: Urgent", "Priority: High", "Priority: Medium", "Priority: Low"]}
              values={[23, 78, 30, 33]}
              colors={["#a90000", "#ff0f0f", "#fa7616", "#f3b416"]}
            />
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <h2 style={styles.cardTitle}>Urgent Alarms Breakdown &#9432;</h2>
          <div className={styles.subtitle}>
            <span className={styles.numberDisplay}>30.8 %</span> <span className={styles.text}>SIS Alarms</span>
          </div>
          <div className={styles.chartContainer}>
            <DonutChart
              labels={[
                "Oxygen Separator Differential Pressure High High Alarm",
                "Anode Water Pump Discharge Pressure Low Low Alarm",
                "H2 Analyzer 1 (O2 Separator) LEL High High",
                "Makeup Water Tank Pressure High High Alarm",
              ]}
              values={[30, 30, 25, 12]}
              colors={["#008b8a", "#f56138", "#222", "#faad36"]}
            />
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 style={styles.cardTitle}>Top 3 most frequent alarms that initiated work order &#9432;</h2>
            <div style={{ cursor: "pointer" }}>
              <LinkIcon />
            </div>
          </div>
          <div className={styles.chartContainer}>
            <DonutChart
              labels={[
                "H2 Analyzer 1 (O2 Separator) LEL High High",
                "V-371 Level Low Low",
                "Air compressor discharge pressure low Low",
              ]}
              values={[36, 34, 29]}
              colors={["#008b8a", "#222", "#faad36"]}
            />
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <h2 style={styles.cardTitle}>Alarm Frequency &#9432;</h2>
          <div className={styles.chartContainer}>
            <BarChart
              x={[13, 11, 8, 6, 4]}
              y={[
                "H2 Analyzer 1 (O2 Separator)<br> LEL High High",
                "Oxygen Separator<br>Differential Pressure<br>High High",
                "Anode Water Pump<br>Discharge Pressure Low Low",
                "Makeup Water Tank <br>Pressure High High",
                "Makeup Water Tank <br>Level Low Low",
              ]}
              color="#008b8a"
            />
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <h2 style={styles.cardTitle}>Plant Trip Frequency &#9432;</h2>
          <div className={styles.chartContainer}>
            <BarChart
              x={[8, 6, 5, 3, 2]}
              y={[
                "H2 Analyzer 1 (O2 Separator)<br>LEL High High",
                "V-371 Level Low Low",
                "Air compressor<br>discharge pressure low Low",
                "Makeup Water Tank <br>Pressure High High",
                "Makeup Water Tank <br>Level Low Low",
              ]}
              color="#222"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
